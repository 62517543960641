import React, { Component } from 'react';
import RetryableContent from 'components/RetryableContent';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, } from 'reactstrap';
import { connect } from 'react-redux';

import { LoadStatus } from "../../../utils";

import "./WaringQuoteChangeModal.scss"

class WaringQuoteChangeModal extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };

    initialState = {
        loadStatus: LoadStatus.NONE,
    };


    state = {
        ...this.initialState
    };



    onHandleConfirm = () => {
        const { toggle } = this.props
        toggle()
    }

    render() {
        const {
            isOpen, toggle, loadStatus
        } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="cash-warning-quote-change-modal"
            >
                <RetryableContent status={loadStatus} disableRetry={true}>
                    <ModalBody className="confirm-end">
                        <div className='headB---700-20-30 title'>
                            <FormattedMessage id="CM4" />
                        </div>
                        <div className='txt---500-14-20 content'>
                            <FormattedMessage id="FE003026" />
                        </div>
                        {/* Thao tác */}
                        <div className="row gutters-5 align-items-center h-row container-btn">
                            {/* Làm mới */}
                            {/* <div className="col-6">
                                <button className="btn-refresh txt---500-16-24 btn"
                                    onClick={() => toggle()}
                                >
                                    <FormattedMessage id="common.btn-cancel" />
                                </button>
                            </div> */}
                            {/* Xác nhận */}
                            <div className="col-12">
                                <button className="btn-confirm txt---500-16-24 btn"
                                    onClick={this.onHandleConfirm}
                                >
                                    <FormattedMessage id="common.btn-confirm" />
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </RetryableContent >
            </Modal >

        )
    };
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WaringQuoteChangeModal);
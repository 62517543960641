import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import RetryableContent from "../../../components/RetryableContent";
import { ModalConfirmType } from '../../../utils/constants';
import { CommonUtils, TransferType, KeyCodeUtils } from '../../../utils';
import { FormattedAmount, FormattedPrice } from 'components/Formating';
import { LoadStatus } from 'constants/config';
import { ReactComponent as WarningIcon_Dark } from '../../../assets/icons/screenmodal/WarningIcon_Dark.svg';
import CustomRowItem from './../../../components/CustomRowItem/CustomRowItem';
import CustomScrollbars from 'components/CustomScrollbars';
import moment from 'moment';
import './ScreenConfirm.scss';
class ScreenConfirm extends Component {
    state = {
        loadStatus: LoadStatus.NONE
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        };
        this.confirmbtnRef = React.createRef();
    };
    dateConverter = (startDate, timeEnd) => {
        let start = moment(startDate, 'DD/MM/YYYY');
        let end = moment(timeEnd, 'DD/MM/YYYY');
        let daysDiff = end.diff(start, 'days');
        return daysDiff
    }

    renderCashTransferInternal = () => {
        const { dataInfo } = this.props
        const {
            transferAmount, accountSend, accountReceived, transferType, description
        } = { ...dataInfo };
        let descriptionConvert = CommonUtils.returnWithoutAccents(description)
        return (
            <div>
                {/* Hình thức giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={transferType} id={'account.utilities.cash-transfer.transfer-type.' + transferType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {accountSend.id}
                    </div>
                </div>
                {/* Nội bộ - Tiểu khoản nhận */}
                {transferType === TransferType.INTERNAL &&
                    <div className="row-item">
                        <div className="label txt---400-14-20">
                            <FormattedMessage id="account.utilities.cash-transfer.sub-account-received" />
                        </div>

                        <div className='txt---400-14-20'>
                            {accountReceived.entypename} - {accountReceived.id}
                        </div>

                    </div>
                }
                {/* Ngoài - Số tiền chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="account.utilities.cash-transfer.transfer-amount" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>
                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.content" />
                    </div>
                    <div className='txt---400-14-20'>
                        {descriptionConvert}</div>
                </div>
            </div>
        )
    }

    renderExtendDebtManagement = () => {
        const { dataInfo } = this.props
        const {
            row, currentAccount, fromDate, extenddays
        } = { ...dataInfo }
        return (
            <div>
                {/* Mã món vay  */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.loan-schedule-code" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        {row.autoid}
                    </div>
                </div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.custodycd" />
                    </div>
                    <div className='txt---400-14-20'>
                        {currentAccount.custodycd}
                    </div>
                </div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.sub" />
                    </div>
                    <div className='txt---400-14-20'>
                        {currentAccount.cdcontent}
                    </div>
                </div>

                {/* Ngày giải ngân */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.disbursement-date" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        <div >
                            {row.rlsdate}
                        </div>
                    </div>
                </div>
                {/* Ngày bắt đầu gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.renewal-start-date" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.overduedate}
                    </div>
                </div>
                {/* Gốc còn lại */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.remaining-original" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.prinamt} />
                    </div>
                </div>
                {/* Ngày đến hạn mới */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.new-due-date" />
                    </div>
                    <div className='txt---400-14-20'>
                        {fromDate}
                    </div>
                </div>
                {/* Số lần đã gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.renewed-number-of-times" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.extimes}
                        {/* <FormattedMessage id="debt-management.year" /> */}
                    </div>
                </div>
                {/* Số ngày gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.number-of-extension-days" />
                    </div>
                    <div className='txt---400-14-20'>
                        {this.dateConverter(row.overduedate, row.nextoverduedate)}
                    </div>
                </div>
                {/* Gốc còn lại */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.remaining-original" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.prinamt} />
                    </div>
                </div>
                {/* Lãi tạm tính */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.unpaid-interest" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.intamt} />
                    </div>
                </div>
                {/* Số dư tiền mặt */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.cash-balance" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.baldefovd} />
                    </div>
                </div>
                {/* Phí gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.renewal-fee" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.feeamt} />
                    </div>
                </div>
                {/* Lãi suất khoản vay */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.loan-interest-rate" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.interestrate}%
                    </div>
                </div>
                {/* Tổng phải trả */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.total-pay" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.sumpaid} />
                    </div>
                </div>
            </div>
        )
    }

    renderStockTransferInternal = () => {
        const { dataInfo } = this.props
        const {
            username, selectedSymbol, quantityTransfer,
            accountSend, accountReceived, transferType
        } = { ...dataInfo };
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={transferType} id={'stock-transfer.transfer-type.' + transferType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="stock-transfer.account" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={username} />
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.sub-account-transfer" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {accountSend.id}
                    </div>
                </div>
                {/* Tiểu khoản nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.sub-account-received" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountReceived.entypename} - {accountReceived.id}
                    </div>

                </div>
                {/* Mã chứng khoán */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="stock-transfer.stock-code" />
                    </div>
                    <div className='txt---400-14-20'>
                        {selectedSymbol}
                    </div>
                </div>
                {/* Khối lượng */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.mass" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedAmount value={quantityTransfer} />
                    </div>
                </div>
            </div>
        )
    }

    renderPayDerivative = () => {
        const { dataInfo } = this.props
        const {
            description, transferAmount,
            accountSend, accountReceived, tradingType
        } = { ...dataInfo };
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={tradingType} id={'trading-derivative.trading-type.' + tradingType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-derivative.sub-account-transfer" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {CommonUtils.formatFDS(accountSend.id)}
                    </div>
                </div>
                {/* Tiểu khoản nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-derivative.sub-account-withdraw" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountReceived.entypename} - {CommonUtils.formatFDS(accountReceived.id)}
                    </div>
                </div>
                {/* Số tiền chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-derivative.transfer-amount" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedAmount value={transferAmount} />
                    </div>
                </div>
                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="trading-derivative.content" />
                    </div>
                    <div className='txt---400-14-20'>
                        {description}
                    </div>
                </div>
            </div>
        )
    }

    renderWithdrawalDerivative = () => {
        const { dataInfo } = this.props
        const {
            description, transferAmount,
            accountSend, accountReceived, tradingType
        } = { ...dataInfo };
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={tradingType} id={'trading-derivative.trading-type.' + tradingType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-derivative.sub-account-transfer" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {CommonUtils.formatFDS(accountSend.id)}
                    </div>
                </div>
                {/* Tiểu khoản nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-derivative.sub-account-withdraw" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountReceived.entypename} - {CommonUtils.formatFDS(accountReceived.id)}
                    </div>
                </div>
                {/* Số tiền rút */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-derivative.withdraw-amount" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedAmount value={transferAmount} />
                    </div>
                </div>
                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="trading-derivative.content" />
                    </div>
                    <div className='txt---400-14-20'>
                        {description}
                    </div>
                </div>
            </div>
        )
    }

    renderConfirmNoti = () => {
        const { dataInfo } = this.props

        const {
            notiId
        } = { ...dataInfo }
        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    <div className='text-center col-12'>
                        <WarningIcon_Dark />
                    </div>
                    <div className='text-center col-12'>
                        <FormattedMessage id={notiId} />
                    </div>
                </div>
            </div>
        )
    }

    renderChildBlbWarning = () => {
        const { dataInfo, language } = this.props

        const {
            en_warningMsg,
            warningMsg,
            showWarnningIcon
        } = { ...dataInfo }
        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    {showWarnningIcon === true && <div className='text-center col-12'>
                        <WarningIcon_Dark />
                    </div>}
                    <div className='text-center col-12'>
                        {language === 'en' ? en_warningMsg : warningMsg}
                    </div>
                </div>
            </div>
        )
    }

    renderCancelMainBlbOrderWarning = () => {
        const { dataInfo } = this.props

        const {
            orderId
        } = { ...dataInfo }
        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    <div className='text-center col-12'>
                        <WarningIcon_Dark />
                    </div>
                    <div className='text-center col-12'>
                        {`Hủy toàn bộ lệnh con của chỉ thị lệnh ${orderId}`}
                    </div>
                </div>
            </div>
        )
    }

    renderBlbCancelFromFixNet = () => {
        const { dataInfo } = this.props
        const {
            exectype, symbol, quantity, price, blorderid
        } = { ...dataInfo }
        let orderInfoDisplay = (symbol || '') + ' - ' + (exectype || '') + ' - ' + (quantity || '');
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={150}
                autoHeightMax={150}
            >


                <div>
                    <CustomRowItem
                        showLabel="Lệnh tổng"
                        showValue={orderInfoDisplay}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Giá"
                        showValue={price || 0}
                        isPrice={true}
                        classNameValue={""}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Số hiệu lệnh"
                        showValue={blorderid || ''}
                    />
                </div>

            </CustomScrollbars>
        )
    }


    renderSplitOrderWarning = () => {
        const { dataInfo, intl } = this.props
        const { oddOrderNumber, oddOrderQtty, orderNumber, sumOrderNumb, qttyPerOrder } = dataInfo;
        let warnningMsg = '';
        if (oddOrderQtty > 0) {
            warnningMsg = intl.formatMessage({ id: "trade.order.split-order.warnning-with-odd" }, { orderNumber: orderNumber || 0, qttyPerOrder: qttyPerOrder || 0, oddOrderNumber: oddOrderNumber || 0, oddOrderQtty: oddOrderQtty || 0 });
        } else {
            warnningMsg = intl.formatMessage({ id: "trade.order.split-order.warnning-no-odd" }, { orderNumber: orderNumber || 0, qttyPerOrder: qttyPerOrder || 0 });
        }

        // descriptionTransfer = intl.formatMessage({ id: "account.utilities.cash-transfer.transfer-external" }, { custodycd: custodycd || '', accountsend: accountSend ? (accountSend.cdcontent || '') : '', name: userInfo.fullname || '' });
        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    {warnningMsg}
                </div>
            </div>
        )
    }


    renderSplitOrderByExchangeWarning = () => {
        const { dataInfo, language } = this.props

        const { warning, warning_en } = dataInfo;

        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    {language === 'vi' ? warning : warning_en}
                </div>
            </div>
        )
    }

    renderSellAllSecNoti = () => {
        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    <div className='text-center col-12 icon-container'>
                        <WarningIcon_Dark />
                    </div>
                    <div className='text-center col-12'>
                        <p> <FormattedMessage id='open-position.sell-all-note' /></p>
                        <p><FormattedMessage id='open-position.sell-all-confirm' /></p>
                    </div>
                </div>
            </div>
        )
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { dataInfo } = this.props;
        const { focusConfirm } = dataInfo;
        if (focusConfirm === true) {
            this.confirmbtnRef.current && this.confirmbtnRef.current.focus();
        }
    }


    //Xác thực 1 lớp
    renderBody = () => {
        const { typeInfo } = this.props;
        switch (typeInfo) {
            case ModalConfirmType.CASH_TRANSFER_INTERNAL:           //Chuyển tiền nội bộ
                return this.renderCashTransferInternal();
            case ModalConfirmType.STOCK_TRANSFER_INTERNAL:          //Chuyển chứng khoán nội bộ
                return this.renderStockTransferInternal();
            case ModalConfirmType.PAY_DERIVATIVE:                   //Nộp tiền vào tài khoản phái sinh
                return this.renderPayDerivative();
            case ModalConfirmType.WITHDRAWAL_DERIVATIVE:            //Rút tiền từ tài khoản phái sinh
                return this.renderWithdrawalDerivative();
            // case ModalConfirmType.DEBT_MANAGEMENT_EXTEND:                 // Quản lý dư nợ - Gia hạn nợ
            //     return this.renderExtendDebtManagement();        //chuyển sang tab xác thực (VIX)
            case ModalConfirmType.CONFIRM_NOTI:                    //Hiển thị thông báo
                return this.renderConfirmNoti();
            case ModalConfirmType.BLB_CANCEL_FROM_FIXNET:
                return this.renderBlbCancelFromFixNet();
            case ModalConfirmType.BLB_CHILD_ORDER_WARNING:
                return this.renderChildBlbWarning();
            case ModalConfirmType.BLB_MAIN_ORDER_CANCEL_WARNNING:
                return this.renderCancelMainBlbOrderWarning();
            case ModalConfirmType.SPLIT_ORDER_WARNNING:
                return this.renderSplitOrderWarning();
            case ModalConfirmType.SPLIT_ORDER_BY_EXCHANGE_WARNNING:
                return this.renderSplitOrderByExchangeWarning();
            case ModalConfirmType.SELL_ALL_OPENPOSITION_NOTE:        // Note bán nhiều mã
                return this.renderSellAllSecNoti();
            default:
                return <div></div>
                break;
        }
    }

    onSubmit = () => {
        const { dataInfo } = this.props;
        const { onSubmit } = { ...dataInfo };
        onSubmit()
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setLoadStatus(nextProps.loadStatusScreenConfirm ? nextProps.loadStatusScreenConfirm : this.state.loadStatus)
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    handlerKeyDownConfirm = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatus } = this.state;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (loadStatus === LoadStatus.LOADING) return;
            this.confirmbtnRef.current && this.confirmbtnRef.current.click();
        }
    }

    render() {
        const { loadStatus } = this.state
        const { toggle, confirmOnly } = this.props;

        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="screen-confirm" >
                {this.renderBody()}
                {/**Không nên dùng thêm biến confirmOnly*/}
                {/*< div className="row-step container-btn" >
                    <button className={(confirmOnly ? "btn-confirm" : "btn-refresh") + " txt---400-16-24 btn"} onClick={toggle} >
                        <FormattedMessage id={confirmOnly ? "common.btn-confirm" : "common.btn-back"} />
                    </button>
                    {!confirmOnly && <button disabled={loadStatus === LoadStatus.LOADING} className="btn-confirm txt---400-16-24 btn" onClick={() => this.onSubmit()}
                    >
                        <FormattedMessage id="common.btn-confirm" />
                    </button>}
                </div>*/}

                < div className="row-step container-btn" >
                    <button className="btn-refresh txt---400-16-24 btn" onClick={toggle} >
                        <FormattedMessage id="common.btn-back" />
                    </button>
                    <button ref={this.confirmbtnRef} disabled={loadStatus === LoadStatus.LOADING} className="btn-confirm txt---400-16-24 btn" onClick={() => this.onSubmit()}
                        onKeyDown={this.handlerKeyDownConfirm}

                    >
                        <FormattedMessage id="common.btn-confirm" />
                    </button>
                </div>
            </RetryableContent >
        );
    }
}

const mapStateToProps = state => {
    let listIsOpenModal = state.layout.listIsOpenModal["C&B"]
    return {
        loadStatusScreenConfirm: listIsOpenModal["ScreenConfirm"].loadStatusScreenConfirm,
        language: state.app.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ScreenConfirm));
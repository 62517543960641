import React, { Component } from 'react';
import { debounce } from "lodash";
import { FormattedMessage, injectIntl } from 'react-intl';
import { batch, connect } from "react-redux";
import { withRouter } from 'react-router';
import moment from 'moment';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/ArrowRightIcon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/EmptyIcon.svg';
import { ReactComponent as EmptyWalletIcon } from '../../assets/icons/EmptyWalletIcon.svg';
import { ReactComponent as CloseIconDark } from '../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../assets/icons/header/CloseIcon_Light.svg';

import { ReactComponent as SearchIconDark } from '../../assets/icons/header/SearchIcon_Dark.svg';
import { ReactComponent as SearchIconLight } from '../../assets/icons/header/SearchIcon_Light.svg';
import { ReactComponent as SearchIconActive } from '../../assets/icons/header/SearchIconActive.svg';

import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { dispatch } from '../../redux';
import { symbolService } from "../../services";
import * as actions from "../../store/actions";
import { CommonWidgetUtils, Role, ToastUtil, CommonUtils, OPENNING_SCREEN_TYPE, KeyCodeUtils } from "../../utils";

import './SearchDropdown.scss';
import ModalDetailNews from './ModalDetailNews';
import config from '../../combineConfig';
import Util from '../../modules/util';
import { getMarketStatusKey } from '../../services/marketinfo.service';
import { menuBroker, menuCustomer } from 'containers/MenuSideBar/MenuSideBarConfig';
import CustomScrollbars from 'components/CustomScrollbars';
import _ from "lodash"

let listBlockBroker =
    [
        "menu_sidebar_title_3_2",
        "menu_sidebar_title_3_3",
        "menu_sidebar_title_3_4",
        "menu-sidebar.title-3.5",
        "menu-sidebar.title-3.6",
        "menu-sidebar.title-4.4",
        "menu-sidebar.title-5.1",
        "menu-sidebar.title-5.4",
        "menu-sidebar.title-8.1",
        "menu-sidebar.title-8.2",
        "menu-sidebar.title-8.3",
        "menu-sidebar.title-8.4",
        "menu-sidebar.title-9.1",
        "menu-sidebar.title-9.2",
    ]
let menuDisableBroker =
    [
        "menu-sidebar.title-9.1",
        "menu-sidebar.title-9.2",
        "menu-sidebar.title-5.4"
    ]

let menuDisableCustomer =
    [
        "menu-sidebar.title-2.3",
        'menu-sidebar.title-2.4',
        'menu-sidebar.title-7.1',
        'menu-sidebar.title-7.2',
        'menu-sidebar.title-7.3',
        'menu-sidebar.title-7.4',
        'menu-sidebar.title-7.5',
        'menu-sidebar.title-7.4',
        'menu-sidebar.title-7.7',
        'menu-sidebar.title-7.8',
        'menu-sidebar.title-7.9',
        'menu-sidebar.title-7.11',
        'menu-sidebar.title-7.10'
    ]
class SearchDropdown extends Component {

    state = {
        isShowSetting: false,
        isOpenDetailNew: false,
        accountInfo: null,
        userTokens: "{}",
        tabActive: 1,
        stocks: [],
        checkInput: false,
        userInput: "",
        localStocks: [],
        func: [],
        localFunc: [],
        news: [],
        paramNew: {},
        localNews: [],
        cursor: 0,
    };

    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.inputSearch = React.createRef();
    }

    search = (userInput) => {
        const { symbolWithIndex, language, marketInfos } = this.props;
        const { tabActive } = this.state;

        if (tabActive === 1) {
            let results = _.map(Object.keys(symbolWithIndex),
                (key) => Object.assign(symbolWithIndex[key],
                    {
                        Id: symbolWithIndex[key].symbol || "",
                        FN: symbolWithIndex[key].desc || "",
                        EX: symbolWithIndex[key].exchange || ""
                    }))
            // let indexes = config.ALL_INDEXES
            let indexes = _.map(config.ALL_INDEXES, item => { return item.code });
            // let indexSearchInfo = {};
            indexes.forEach(item => {
                let marketInfo = marketInfos[`${item}`] ? marketInfos[`${item}`] : {};
                let marketConfigInfo = _.find(config.ALL_INDEXES, configItem => { return configItem.code === item });
                if (marketConfigInfo) {
                    results.push({
                        ...marketInfo,
                        Id: marketConfigInfo['searchKey'],
                        // Id: item.title,
                        FN: item.code,
                        FullName: marketInfo['floorCode'] || '',
                        desc: marketInfo['floorCode'] || '',
                        id: marketConfigInfo['searchKey'],
                        symbol: marketConfigInfo['searchKey'],
                        exchange: marketInfo['floorCode'],
                        stockType: marketInfo['floorCode'],
                        alertType: 'index',
                        subName: marketConfigInfo['subName']
                    })
                }
            });
            // //console.log("inputChange---: ", userInput)
            let input = userInput.toUpperCase()
            let dataFirst = results.filter((item) => item.Id && item.Id.startsWith(input))
            let dataSecond = results.filter((item) => item.Id && !item.Id.startsWith(input) && item.Id.includes(input))
            let data = [...dataFirst, ...dataSecond]
            // let dataResult = data.reverse()
            this._setState({ stocks: data, checkInput: false });
            if (input)
                this._setState({ stocks: data, checkInput: true, userInput: userInput, cursor: 0 });
            else if (input === "") {
                this._setState({ stocks: [], checkInput: false, userInput: userInput, cursor: 0 });
            } else {
                this._setState({ stocks: [], checkInput: true, userInput: userInput, cursor: 0 });
            }
        }
        else if (tabActive === 2) {
            let lang = language === 'en' ? 'en-US' : 'vi-VN';
            if (userInput) {
                this._setState({ checkInput: true, userInput: userInput });
                this.handleDebounceInput(lang, userInput)
            }
            else if (userInput === "") {
                this._setState({ func: [], checkInput: false, userInput: userInput });
            } else {
                this._setState({ func: [], checkInput: true, userInput: userInput });
            }
        }
        else if (tabActive === 3) {
            let isLoggedIn = this.props.isLoggedIn
            let initFun = LayoutUtils.getFunctionMenu
            let menuBroker = initFun.filter(item => {
                return !menuDisableBroker.includes(item.value)
            });
            let menuCustomer = initFun.filter(item => {
                return !menuDisableCustomer.includes(item.value)
            });
            let functions = !isLoggedIn ? initFun : this.isBroker() ? menuBroker : menuCustomer
            let search = CommonUtils.removeDiacritics(userInput)
            let data = functions.filter((item) =>
                this.props.language === "vi" ? CommonUtils.removeDiacritics(item.vi.toLowerCase()).includes(search) || CommonUtils.removeDiacritics(item.vi).includes(search)
                    : CommonUtils.removeDiacritics(item.en.toLowerCase()).includes(search) || CommonUtils.removeDiacritics(item.en).includes(search))
            this._setState({ func: data, checkInput: false });
            if (userInput)
                this._setState({ func: data, checkInput: true, userInput: userInput });
            else if (userInput === "") {
                this._setState({ func: [], checkInput: false, userInput: userInput });
            } else {
                this._setState({ func: [], checkInput: true, userInput: userInput });
            }
        }
    };

    handleDebounceInput = debounce((lang, userInput) => {
        symbolService.getNewsSearch(1, lang, 10, userInput)
            .then(data => {
                this._setState({ news: data });
            }).catch(error => {
                this._setState({ news: [] });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }, 500);

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        this._setState({ localStocks: parsedArray.stocks, localFunc: parsedArray.func, localNews: parsedArray.news })
    }

    componentWillUnmount() {
        // document.removeEventListener('mousedown', this.handleClickOutside);
        this.mounted = false
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.clearInput()
            this._setState({ isShowSetting: false, tabActive: 1 })
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    toggleQuickSetting = () => {
        this._setState({ isShowSetting: !this.state.isShowSetting }, () => {
            if (this.state.isShowSetting) {
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        })
    }

    toggleModalDetailNew = () => {
        this._setState({ isOpenDetailNew: !this.state.isOpenDetailNew })
    }

    handleCheck(val) {
        return this.state.data.some(item => val.name === item.name);
    }

    clearInput = () => {
        this._setState({ checkInput: false, userInput: "" })
    }

    addStockLocal = (stock) => {
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        const checkExists = parsedArray.stocks.some(item => stock.Id === item.Id)
        if (!checkExists) {
            const newStocks = [...parsedArray.stocks, stock]
            const newLocal = {
                ...parsedArray,
                stocks: newStocks
            }
            this._setState({ localStocks: newStocks })
            localStorage.setItem("searchs", JSON.stringify(newLocal));
        }
    }

    addNewLocal = (news) => {
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        const checkExists = parsedArray.news.some(item => news.NEWS_ID === item.NEWS_ID)
        if (!checkExists) {
            const newNews = [...parsedArray.news, news]
            const newLocal = {
                ...parsedArray,
                news: newNews
            }
            this._setState({ localNews: newNews })
            localStorage.setItem("searchs", JSON.stringify(newLocal));
        }
    }

    addfunLocal = (fun) => {
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        const checkExists = parsedArray.func.some(item => fun.value === item.value)
        if (!checkExists) {
            const newFunc = [...parsedArray.func, fun]
            const newLocal = {
                ...parsedArray,
                func: newFunc
            }
            this._setState({ localFunc: newFunc })
            localStorage.setItem("searchs", JSON.stringify(newLocal));
        }
    }

    removeStockLocal = (e, stock) => {
        e.stopPropagation()
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        const newStocks = parsedArray.stocks.filter(item => stock.Id !== item.Id)
        this._setState({ localStocks: newStocks })
        const newLocal = {
            ...parsedArray,
            stocks: newStocks
        }
        localStorage.setItem("searchs", JSON.stringify(newLocal));
    }

    removeNewLocal = (e, news) => {
        e.stopPropagation()
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        const newNews = parsedArray.news.filter(item => news.NEWS_ID !== item.NEWS_ID)
        this._setState({ localNews: newNews })
        const newLocal = {
            ...parsedArray,
            news: newNews
        }
        localStorage.setItem("searchs", JSON.stringify(newLocal));
    }

    removeFuncLocal = (e, func) => {
        e.stopPropagation()
        const array = localStorage.getItem("searchs");
        const parsedArray = array ? JSON.parse(array) : [];
        const newFunc = parsedArray.func.filter(item => func.value !== item.value)
        this._setState({ localFunc: newFunc })
        const newLocal = {
            ...parsedArray,
            func: newFunc
        }
        localStorage.setItem("searchs", JSON.stringify(newLocal));
    }


    componentDidUpdate(prevProps, prevState) {
        this.inputSearch.current.focus();
    }


    onChangeTabActive = (tab) => {
        const { userInput } = this.state;
        //this.clearInput()
        this._setState({ tabActive: tab }, () => {
            this.search(userInput)
        })
    }

    populateMarketInfo(marketInfo) {
        let info = {};
        if (marketInfo) {
            let chart = config.ALL_INDEXES.find(item => item.code === marketInfo.floorCode);
            info.indexName = chart ? chart.title : '';
            info.marketIndex = Util.formatAccounting(marketInfo.marketIndex, 2);
            info.changeValue = marketInfo.changedIndex;
            info.changePercent = marketInfo.percentIndex; // Haki.: Lấy trực tiếp từ data trả về
            info.changeValue = Util.formatAccounting(
                info.changeValue,
                2
            );
            info.totalShareTradedInfo = Util.formatAccounting(
                Number(marketInfo.totalShareTraded) + Number(marketInfo.oddLotTotalVolume),
                0
            );
            info.totalValueTradedInfo = Util.formatNumberShortLarge(Number(marketInfo.totalValueTraded) + Number(marketInfo.oddLotTotalValue))

            info.totalShareTraded = Util.formatAccounting(
                marketInfo.totalShareTraded,
                0
            );
            info.totalValueTraded = Util.formatNumberShortLarge(marketInfo.totalValueTraded)

            info.advance = marketInfo.advance;
            info.noChange = marketInfo.noChange;
            info.decline = marketInfo.decline;
            info.numberOfCe = marketInfo.numberOfCe;
            info.numberOfFl = marketInfo.numberOfFl;
            info.colorClass = Util.getMarketInfoClasses(marketInfo).color;
            info.arrowClass = Util.getMarketInfoClasses(marketInfo).arrow;
            info.statusKey = getMarketStatusKey(marketInfo);
            info.floorCode = marketInfo.floorCode;
            info.priorMarketIndex = marketInfo.priorMarketIndex;
        }

        return info;
    }

    onIndexClickHandeler = (oldMarketInfo) => {
        const { marketInfos } = this.props;
        let marketCode = oldMarketInfo['floorCode'] || '';
        if (marketCode) {
            let marketInfo = marketInfos[`${marketCode}`] ? marketInfos[`${marketCode}`] : {};
            let result = CommonUtils.populateMarketInfo(marketInfo)
            // //console.log("binh_onIndexClickHandeler", { result, marketInfo })
            // this.props.setMarketInfoDetailData(result);
            // if (marketInfo && marketInfo.floorCode) {
            // Mở layout chi tiết Index

            let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === marketInfo.floorCode });

            // this.openDetailIndex(menuBroker['SYMBOL_DETAIL'].key)

            let symbolObj = {
                ...result,
                FullName: marketInfo['floorCode'] || '',
                desc: marketInfo['floorCode'] || '',
                id: marketConfigInfo['searchKey'],
                symbol: marketConfigInfo['searchKey'],
                exchange: marketInfo['floorCode'],
                stockType: marketInfo['floorCode'],
                StockType: marketInfo['floorCode'],
                alertType: 'index',
                subName: marketConfigInfo['subName'],
                isDetailIndex: true
            }

            this.props.updateDataSymbolDetailLayoutPage(symbolObj);
        }

        // this.props.setIsOpenModalHaveData("ScreenModal", {
        //     isOpenScreenModal: true,
        //     curScreenModalActive: "menu-sidebar.title-1.2",
        //     useCustomHeader: true
        // })
        // }
    }

    onDetailSymbol = (symbol) => {
        const { accountInfo, setTradeCurrentSymbolById } = this.props;
        this.addStockLocal(symbol)
        let key = this.isBroker() ? "B#CS#SYMBOL_DETAIL" : "C#CS#SYMBOL_DETAIL"
        CommonUtils.openLayoutByKey(key);
        let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + menuBroker['SYMBOL_DETAIL'].key) // Mặc định theo role

        batch(() => {
            dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
            // dispatch(actions.changeMenuActive(keyName))
            dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
        });
        if (symbol.EX) {
            this.props.updatedSymbolLayoutPage(symbol.Id, "search_symbol_detail")
        } else {
            this.onIndexClickHandeler(symbol)
        }
        this.toggleQuickSetting()
        this.clearInput()
    }

    onDetailNews = (news) => {
        this.addNewLocal(news)
        document.removeEventListener('mousedown', this.handleClickOutside);
        symbolService.getDetailNew(news.NEWS_ID)
            .then(data => {
                this._setState({ paramNew: data });
                this.toggleModalDetailNew()
            }).catch(error => {
                this._setState({ news: [] });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
        this.toggleQuickSetting()
    }

    onChangeMenuActive = (keyName, activeURL) => {
        let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + keyName) // Mặc định theo role
        batch(() => {
            dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
            // dispatch(actions.changeMenuActive(keyName))
            dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
        });
        if (this.isBroker()) {
            if (keyName === menuBroker['TRADE#01'].key) {
                this.openNormalTrade()
            } else if (keyName === menuBroker['TRADE#02'].key) {
                this.openProTrade()
            } else if (keyName === menuBroker['TRADE#03'].key) {
                this.openPutthroughTrade()
            } else if (keyName === menuBroker['ASSET_TOTAL'].key) {
                this.openAssetTotal()
            } else if (keyName === menuBroker['CUSTOMER_ORGANIZATION'].key) {
                this.openCustomerOrganization()
            }
        } else {
            if (keyName === menuCustomer['TRADE#01'].key) {
                this.openNormalTrade()
            } else if (keyName === menuCustomer['TRADE#02'].key) {
                this.openProTrade()
            } else if (keyName === menuCustomer['ASSET_TOTAL'].key) {
                this.openAssetTotal()
            }
        }
    }

    openPutthroughTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = "B#CS#TRADE#TT"
        CommonUtils.openLayoutByKey(key)
    }

    openCustomerOrganization = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = "B#CS#CUSTOMER_ORGANIZATION"
        CommonUtils.openLayoutByKey(key)
    }

    openAssetTotal = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#ASSET_TOTAL" : "C#CS#ASSET_TOTAL"
        CommonUtils.openLayoutByKey(key)
    }

    openProTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#NC" : "C#CS#TRADE#NC"
        CommonUtils.openLayoutByKey(key)
    }

    openNormalTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#CB" : "C#CS#TRADE#CB"
        CommonUtils.openLayoutByKey(key)
    }

    onOpenModalActionMethod = () => {
        this.props.setIsOpenModalByKey(
            "isOpenModalActionMethodCondition",
            true,
        )
    };

    onCheckLogined = (keyName) => {
        if (keyName.indexOf("PRICEBOARD#CB") < 0) {
            // Check Login với menu khác bảng giá
            let isLoggedIn = CommonUtils.checkLogined()
            if (!isLoggedIn) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    updateOpenningScreenInfo = (keyName, screenType, data = {}) => {
        // Chưa login ===> Lưu lại thông tin màn hình muốn mở => sau khi login mở lại màn hình với thông tin được lưu
        const { setOpenningScreenInfo } = this.props;
        setOpenningScreenInfo(keyName, screenType, data);
    }

    returnType = (type) => {
        if (type === "modal") return OPENNING_SCREEN_TYPE.MODAL
        if (type === "newtab") return OPENNING_SCREEN_TYPE.LAYOUT
        if (type === "screenmodal") return OPENNING_SCREEN_TYPE.SCREEN_MODAL
    }

    isCheckOpenFun = (fun) => {
        if (this.isBroker()) {
            let checkExist = menuDisableBroker.includes(fun)
            if (checkExist) return
        } else {
            let checkExist = menuDisableCustomer.includes(fun)
            if (checkExist) return
        }
    }

    onActiveFun = (item) => {
        const { accountInfo, contactInfos, clientConfig } = this.props
        this.addfunLocal(item)
        if (!this.onCheckLogined(item.value)) {
            // this.isCheckOpenFun(item.value)
            let value = item.type === "newtab" ? item.keyName : item.value
            this.updateOpenningScreenInfo(value, this.returnType(item.type), {
                allowBroker: true, isPermissionSearch: true,
                menuDisableBroker: menuDisableBroker,
                menuDisableCustomer: menuDisableCustomer,
                fun: item.value
            });
            this.toggleQuickSetting()
            return;
        }
        let check = this.isBroker() ? listBlockBroker.includes(item.value) : false
        if (!check) {
            if (item.type === "screenmodal") {
                this.props.setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: true,
                    curScreenModalActive: item.value,
                    accountInfo: accountInfo,
                })
            } else if (item.type === "newtab") {
                this.onChangeMenuActive(item.keyName)
            } else if (item.type === "modal") {
                if (item.value === "menu-sidebar.title-3.4") {
                    this.props.setIsOpenTradeMoneyBIDV(true)
                    this.props.setIsOpenModalHaveData("ScreenModal", {
                        isOpenScreenModal: false,
                    })
                }
                if (item.value === "menu-sidebar.title-8.1") { // Liên hệ
                    this.props.setIsOpenModalByKey(
                        "isOpenContactModal",
                        true,
                    )
                }
            } else if (item.type === "link") {
                if (item.value === "menu-sidebar.title-8.3") { // Sổ tay giao dịch
                    clientConfig && clientConfig.handboo_web && window.open(clientConfig.handboo_web, '_blank', 'noopener,noreferrer');
                }
            } else if (item.type === "") {
                return
            }
            this.toggleQuickSetting()
        } else {
            ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker')
            return
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < SearchIconDark /> : < SearchIconLight />
                break;
            case 2:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
                break;
            default:
                break;
        }
    }

    genderIconHeader = () => {
        return this.props.defaultTheme === 'dark' ? this.state.isShowSetting ? <SearchIconActive /> : <SearchIconDark /> : this.state.isShowSetting ? <SearchIconActive /> : < SearchIconDark />
    }

    onInputKeyDown = (event) => {
        //!checkInput && localStocks && localStocks.length > 0
        const { cursor, stocks, checkInput, localStocks, tabActive } = this.state
        const keyCode = event.which || event.keyCode;
        if (tabActive === 1) {
            let _array = !checkInput && localStocks && localStocks.length ? localStocks : stocks
            if (keyCode === 38 && cursor > 0) {
                this._setState(prevState => ({
                    cursor: prevState.cursor - 1
                }))
            } else if (keyCode === 40 && cursor < _array.length - 1) {
                this._setState(prevState => ({
                    cursor: prevState.cursor + 1
                }))
            }
            if (keyCode === KeyCodeUtils.ENTER) {
                if (_array.length > 0) {
                    let item = _array[cursor]
                    this.onDetailSymbol(item)
                }
            }
        }
    };

    onKeyDownDetailSymbol = (event) => {
        const keyCode = event.which || event.keyCode;
        // //console.log("onKeyDownDetailSymbol---: ", keyCode)
        if (keyCode === KeyCodeUtils.ENTER) {
            // this.onDetailSymbol(item)
        }
    }

    render() {
        const { className, marketInfos } = this.props;
        const { isShowSetting, tabActive, stocks, checkInput, userInput, localStocks, func, localFunc, localNews, cursor } = this.state;
        return (
            <div className="search-dropdown txt---500-14-20" ref={this.setWrapperRef}>
                <button id="btn-setting-id" className={className} onClick={this.toggleQuickSetting}>
                    {this.genderIconHeader()}
                </button>
                <div className="search-container" hidden={isShowSetting ? '' : 'hidden'}>
                    <div className='col-12 search-custom'>
                        <div className='col-1 p-0'>
                            {this.genderIcon(1)}
                        </div>
                        <div className='col-10 p-0'>
                            <input
                                type="text"
                                name="search"
                                ref={this.inputSearch}
                                className="search-bar"
                                autoComplete="off"
                                placeholder={this.props.language === "vi" ? 'Bạn đang tìm kiếm gì...' : "What are you looking for..."}
                                value={userInput}
                                onKeyDown={this.onInputKeyDown}
                                onChange={(e) => {
                                    this.search(e.target.value);
                                }}
                            />
                        </div>
                        {/* <div className='col-1 p-0' style={{ cursor: "pointer" }} onClick={(e) => this.clearInput()}>
                            {this.genderIcon(2)}
                        </div> */}
                    </div>
                    <div className='container-button'>
                        <button
                            onClick={() => this.onChangeTabActive(1)}
                            className={'btn-custom' + (tabActive === 1 ? ' active' : '')}>
                            <FormattedMessage id="search-dropdown.stock" />
                        </button>
                        {/* <button
                            onClick={() => this.onChangeTabActive(2)}
                            className={'btn-custom' + (tabActive === 2 ? ' active' : '')}>
                            <FormattedMessage id="search-dropdown.news" />
                        </button> */}
                        <button
                            onClick={() => this.onChangeTabActive(3)}
                            className={'btn-custom' + (tabActive === 3 ? ' active' : '')}>
                            <FormattedMessage id="search-dropdown.function" />
                        </button>
                    </div>
                    {
                        tabActive === 1 &&
                        <CustomScrollbars className='container-list'>
                            <div className='title-search'>
                                <FormattedMessage id="search-dropdown.recent-search" />
                            </div>
                            {
                                checkInput && this.state.stocks && this.state.stocks.length > 0 &&
                                this.state.stocks.map((item, i) => (
                                    <div className={"item-stock " + (cursor === i ? 'active' : null)} onClick={(e) => this.onDetailSymbol(item)}>
                                        <div className='n-column'>
                                            <div className='d-flex' style={{ gap: "5px" }}>
                                                <div>{item.Id}</div>
                                                {item.EX && <div>|</div>}
                                                {item.EX && <div>{item.EX}</div>}
                                            </div>
                                            <div>
                                                {item.FN}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                checkInput && this.state.stocks.length === 0 &&
                                <div className='empty-search'>
                                    <EmptyIcon />
                                    <div className='d-flex'>
                                        <div className='text-near-active'>
                                            <FormattedMessage id="search-dropdown.no-results-were-found" />
                                        </div>
                                        <div className='ml-1'>"{userInput}"</div>
                                    </div>
                                </div>
                            }
                            {
                                !checkInput && localStocks && localStocks.length > 0 &&
                                localStocks.map((item, i) => (
                                    <div className={"item-stock " + (cursor === i ? 'active' : null)} onClick={(e) => this.onDetailSymbol(item)}>
                                        <div className='n-column'>
                                            <div className='d-flex' style={{ gap: "5px" }}>
                                                <div>{item.Id}</div>
                                                {item.EX && <div>|</div>}
                                                {item.EX && <div>{item.EX}</div>}
                                            </div>
                                            <div>
                                                {item.FN}
                                            </div>
                                        </div>
                                        {/* <div style={{ cursor: "pointer" }} onClick={(e) => { this.removeStockLocal(e, item) }}>
                                            {this.genderIcon(2)}
                                        </div> */}
                                    </div>
                                ))
                            }
                        </CustomScrollbars>
                    }
                    {
                        tabActive === 2 &&
                        <CustomScrollbars className='container-list'>
                            <div className='title-search'>
                                <FormattedMessage id="search-dropdown.recent-search" />
                            </div>
                            {
                                checkInput && this.state.news.length > 0 && this.state.news &&
                                this.state.news.map((item, i) => (
                                    <div className='item-news'
                                        onClick={(e) => this.onDetailNews(item)}
                                    >
                                        <div >
                                            <img src={item.IMG_URL} alt="logo" className='s-image' />
                                        </div>
                                        <div className='n-column'>
                                            <div>{item.TITLE} </div>
                                            <div className='text-near-active d-flex' style={{ gap: "5px" }}>
                                                <div>{item.SOURCE_NEWS}</div>
                                                <div>|</div>
                                                <div>{moment(item.POST_DATE).format('DD-MM-YYYY HH:mm')}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                checkInput && this.state.news.length === 0 &&
                                <div className='empty-search'>
                                    <EmptyIcon />
                                    <div className='d-flex'>
                                        <div className='text-near-active'>
                                            <FormattedMessage id="search-dropdown.no-results-were-found" /> </div>
                                        <div className='ml-1'>"{userInput}"</div>
                                    </div>
                                </div>
                            }
                            {
                                !checkInput && localNews && localNews.length > 0 &&
                                localNews.map((item, i) => (
                                    <div className='item-news' onDetailNews
                                        onClick={(e) => this.onDetailNews(item)}
                                    >
                                        <div className='col-1 p-0'>
                                            <img src={item.IMG_URL} alt="logo" className='s-image' />
                                        </div>
                                        <div className='n-column col-10 p-0'>
                                            <div>{item.TITLE} </div>
                                            <div className='text-near-active d-flex' style={{ gap: "5px" }}>
                                                <div>{item.SOURCE_NEWS}</div>
                                                <div>|</div>
                                                <div>{moment(item.POST_DATE).format('DD-MM-YYYY HH:mm')}</div>
                                            </div>
                                        </div>
                                        {/* <div className='col-1 p-0' style={{ cursor: "pointer" }} onClick={(e) => { this.removeNewLocal(e, item) }}>
                                            {this.genderIcon(2)}
                                        </div> */}
                                    </div>
                                ))
                            }
                        </CustomScrollbars>
                    }

                    {
                        tabActive === 3 &&
                        <CustomScrollbars className='container-list'>
                            <div className='title-search'>
                                {
                                    checkInput ? <div> {this.state.func.length} <FormattedMessage id="search-dropdown.result" /></div> : <FormattedMessage id="search-dropdown.recent-search" />
                                }
                            </div>
                            {
                                checkInput && this.state.func && this.state.func.length > 0 &&
                                this.state.func.map((item, i) => (
                                    <div className='item-func' onClick={(e) => this.onActiveFun(item)}>
                                        <div className='d-flex' style={{ gap: "5px" }}>
                                            <div>
                                                <EmptyWalletIcon />
                                            </div>
                                            <div style={{ alignSelf: "center" }}>
                                                {this.props.language === "vi" ? item.vi : item.en}
                                            </div>
                                        </div>
                                        <div>
                                            <ArrowRightIcon />
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                checkInput && this.state.func.length === 0 &&
                                <div className='empty-search'>
                                    <EmptyIcon />
                                    <div className='d-flex'>
                                        <div className='text-near-active'>
                                            <FormattedMessage id="search-dropdown.no-results-were-found" /> </div>
                                        <div className='ml-1'>"{userInput}"</div>
                                    </div>
                                </div>
                            }
                            {
                                !checkInput && localFunc && localFunc.length > 0 &&
                                localFunc.map((item, i) => (
                                    <div className='item-func' onClick={(e) => this.onActiveFun(item)}>
                                        <div className='d-flex' style={{ gap: "5px" }}>
                                            <div>
                                                <EmptyWalletIcon />
                                            </div>
                                            <div style={{ alignSelf: "center" }}>
                                                {this.props.language === "vi" ? item.vi : item.en}
                                            </div>
                                        </div>
                                        {/* <div style={{ cursor: "pointer" }} onClick={(e) => { this.removeFuncLocal(e, item) }}>
                                            {this.genderIcon(2)}
                                        </div> */}
                                    </div>
                                ))
                            }
                        </CustomScrollbars>
                    }
                </div>
                {
                    this.state.isOpenDetailNew &&
                    <ModalDetailNews
                        isOpen={this.state.isOpenDetailNew}
                        toggle={this.toggleModalDetailNew}
                        paramNew={this.state.paramNew}
                    ></ModalDetailNews>
                }
            </div >
        );
    }
}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    //new
    const _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    const _accountInfo = _layoutPage && _layoutPage["accountInfo"];
    const contactInfos = state.app.contactInfos ? state.app.contactInfos : {};
    const clientConfig = state.app.config ? state.app.config : {};
    return {
        marketInfos: state.marketInfoStore.marketInfos,
        language: state.app.language,
        accounts: state.account.accounts,
        lang: state.app.language,
        defaultTheme: defaultTheme,
        userInfo: userInfo,
        currentAccount: state.account.currentAccount,
        currentCustomer: state.customer.currentCustomer,
        isLoggedIn: state.user.isLoggedIn,
        usersToken: state.user.usersToken,
        symbolWithIndex: state.symbol.symbolWithIndex,
        accountInfo: _accountInfo,
        contactInfos: contactInfos,
        clientConfig: clientConfig
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeDefaultTheme: (theme) => dispatch(actions.changeDefaultTheme(theme)),
        changeLanguage: (language) => dispatch(actions.changeLanguage(language)),
        logout: () => dispatch(actions.logout()),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
        logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
        updateTokenStore: (usersToken) => dispatch(actions.updateTokenStore(usersToken)),
        setIsOpenModalByKey: (key, status) => dispatch(actions.setIsOpenModalByKey(key, status)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setTradeCurrentSymbolById: (symbolId, isRedirect) => dispatch(actions.setTradeCurrentSymbolById(symbolId, isRedirect)),
        setOrderInput: (orderInput, symbol) => dispatch(actions.setTradeOrderInput(orderInput, symbol)),
        setIsOpenTradeMoneyBIDV: (isOpen) => dispatch(actions.setIsOpenTradeMoneyBIDV(isOpen)),
        onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),
        changeLayoutPageActive: (currentTabActive) => dispatch(actions.changeLayoutPageActive(currentTabActive)),
        setMarketInfoDetailData: (marketInfo) => dispatch(actions.setMarketInfoDetailData(marketInfo)),
        setOpenningScreenInfo: (keyName, type, data) => dispatch(actions.setOpenningScreenInfo(keyName, type, data)),

        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        updateDataSymbolDetailLayoutPage: (symbolObj) => dispatch(actions.updateDataSymbolDetailLayoutPage(symbolObj)),
    };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchDropdown)));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotificationOrderConfirm extends Component {
    render() {
        const { templateid, subject, accountid, onClickMessLink } = this.props;
        return (
            <div className="title">
                <Link to={'#'} onClick={() => onClickMessLink(templateid, accountid)}>
                    {subject}
                </Link>
                &nbsp;<span className="icon-notification-confirm"><i className="fas fa-flag"></i></span>
            </div>
        );
    }
}

export default NotificationOrderConfirm;
import _ from "lodash"
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { emitter } from 'utils/EventEmitter';

import { ReactComponent as AccountIconDark } from '../../assets/icons/header/AccountIcon_Dark.svg'
import { ReactComponent as AccountIconLight } from '../../assets/icons/header/AccountIcon_Light.svg'
import { ReactComponent as AddAccountIconDark } from '../../assets/icons/header/AddAccountIcon_Dark.svg'
import { ReactComponent as AddAccountIconLight } from '../../assets/icons/header/AddAccountIcon_Light.svg'
import { ReactComponent as DeleteIconDark } from '../../assets/icons/header/DeleteIcon_Dark.svg'
import { ReactComponent as DeleteIconLight } from '../../assets/icons/header/DeleteIcon_Light.svg'
import { ReactComponent as LogOutIconDark } from '../../assets/icons/header/LogOutIcon_Dark.svg'
import { ReactComponent as LogOutIconLight } from '../../assets/icons/header/LogOutIcon_Light.svg'
import { ReactComponent as AccountIconActive } from '../../assets/icons/header/UserIconActive.svg'
import { authService, inquiryService } from "../../services";
import * as actions from "../../store/actions";
import { CommonUtils, CommonWidgetUtils, LanguageUtils, path, Random, Role, ToastUtil } from "../../utils";

import './LoginAccountSelector.scss';

class LoginAccountSelector extends Component {

    state = {
        isShowSetting: false,
        accountInfo: null,
        userTokens: "{}"
    };

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    getPersionalInfo = () => {
        const { accounts, userInfo } = this.props;
        if (accounts.length > 0) {
            let foundAccount = accounts.find(item => item.custodycd === userInfo.custodycd);
            if (foundAccount) {
                this._setState({ accountInfo: foundAccount });
            }
        }
    }

    getBrokerInfo = () => {
        inquiryService.getBrokerInfo()
            .then((data) => {
                this._setState({ accountInfo: data });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
            });
    }

    onStorageUpdate = (e) => {
        const { key, newValue } = e;
        if (key === "LogoutCustodycd") {
            let currentCustodycd = this.getCustodycd();
            let logoutCus = newValue.split('|')[0];
            // Nếu có tab nhấn đăng xuất của tài khoản trên tab hiện tại ===> LOGOUT
            if (logoutCus === currentCustodycd) {
                localStorage.setItem('LogoutCustodycd', '');
                this.props.logoutSingleUser(currentCustodycd, false);
            }
        }

        if (key === "token-users") {
            this.reloadInfoFromLocal();
        }

        if (key === "LogoutId") {
            let currentCustodycd = this.getCustodycd();
            currentCustodycd && this.props.logoutSingleUser(currentCustodycd, false);
        }
    };

    /**
     * load dữ liệu accesstoken từ storage
     * @param {*} callCheck // Có gọi api check Accesstoken để hiển thị trạng thái và xử lý hết phiên không
     * @returns 
     */
    reloadInfoFromLocal = (callCheck = true) => {
        let usersTokens = localStorage.getItem('token-users') ? JSON.parse(localStorage.getItem('token-users')) : {};
        this.props.updateTokenStore(usersTokens);
        // this._setState({ userTokens: localStorage.getItem('token-users') });
        // Gán thêm trạng thái hiệu lực của token và set State
        // //console.log("reloadInfoFromLocal,:usersTokens", usersTokens)
        if (!callCheck) return;
        if (usersTokens && !_.isEmpty(usersTokens)) {
            this.checkValidTokens(usersTokens);
        } else {
            this._setState({ userTokens: '{}' });
        }
    }

    callback = () => {
        this.reloadInfoFromLocal(false);
    }

    listenToTheEmitter() {
        emitter.on('UPDATE_TOKEN_STORE', this.callback);
    }

    checkValidTokens = (usersTokens) => {
        let dataCheck = { accessTokens: [] };
        let currentCustodycd = this.getCustodycd();
        Object.keys(usersTokens).forEach(key => {
            // dataCheck += usersTokens[`${key}`].access_token ? usersTokens[`${key}`].access_token + ',' : '';
            if (usersTokens[`${key}`]['access_token']) {
                dataCheck['accessTokens'].push(usersTokens[`${key}`]['access_token']);
            } else {
                // Case nếu accesstoken đã bị clear ở các tab khác  ===> bắn hết phiên 
                if (currentCustodycd === key) {
                    this.handlerTokenExpireByCheckAccessToken();
                }
                usersTokens[`${key}`]['status'] = false;
            }
        });
        authService.checkValidTokens(dataCheck).then(data => {
            if (Object.keys(data).length > 0) {
                Object.keys(usersTokens).forEach(key => {
                    // Nếu không có trong data trả về ===> Hết hiệu lực
                    usersTokens[`${key}`]['status'] = data[`${key}`] ? data[`${key}`] : false;
                    if (!usersTokens[`${key}`]['status'] && usersTokens[`${key}`]['access_token']) {
                        // Nếu hết hiệu lực khi check => Xóa token trong store
                        this.clearTokenStore(key);
                        // Nếu hết hiệu lực khi check (Chưa bị clear acces_token) => xử lý bắn hết phiên
                        if (currentCustodycd === key) {
                            this.handlerTokenExpireByCheckAccessToken();
                        }
                        // this.logoutHandler(key);
                        // this.clearTokenStore(key);
                    }
                });
            }
            this._setState({ userTokens: JSON.stringify(usersTokens) });
        }).catch(error => {
            // Lỗi lấy trạng thái hiệu lực ===> Vẫn gán lại token để hiển thị và xử lý
            this._setState({ userTokens: JSON.stringify(usersTokens) });
            // ToastUtil.errorApi(error, 'common.fail-to-load-data');
        }
        )
    }

    handlerTokenExpireByCheckAccessToken = async () => {
        const { dispatch } = this.props;
        await dispatch(actions.logoutExpiredToken())
        await dispatch(actions.setIsOpenExpiredTokenErrorScreen(true))
        await dispatch(actions.logoutBySessionExpired())
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        let usersTokens = localStorage.getItem('token-users');
        this.reloadInfoFromLocal(false);
        this._setState({ userTokens: usersTokens });
        const { isBroker } = this.props;
        if (isBroker) {
            this.getBrokerInfo();
        } else {
            this.getPersionalInfo();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener("storage", this.onStorageUpdate);
        this.listenToTheEmitter();
    }

    componentWillUnmount() {
        emitter.removeListener('UPDATE_TOKEN_STORE', this.callback);
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener("storage", this.onStorageUpdate);
        this.mounted = false
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this._setState({ isShowSetting: false })
        }
    }

    setOpenQuickSetting = (isOpen) => {
        this._setState({ isShowSetting: isOpen }, () => {
            this.props.updateSettingOpenState && this.props.updateSettingOpenState(isOpen);
        })
    }

    toggleQuickSetting = () => {
        this._setState({ isShowSetting: !this.state.isShowSetting }, () => {
            this.props.updateSettingOpenState && this.props.updateSettingOpenState(this.state.isShowSetting);
        });
    }

    logoutHandler = (custodycd) => {
        const { isCheckExistLayoutSave, callBackSaveLayout } = CommonWidgetUtils.onCheckSaveLayout()
        if (isCheckExistLayoutSave) {
            let timer = setTimeout(function () {
                callBackSaveLayout && callBackSaveLayout()
                clearTimeout(timer) 
            }, 10)
            return
        }
        let currentCustodycd = this.getCustodycd();
        if (currentCustodycd === custodycd) {
            this.props.logoutSingleUser(custodycd, true);
            this.props.setSymbolSelectorCollectionId("VN30")
            this.props.setSymbolSelectorUserCollectionId("favourite")
        } else {
            // Xóa store (Trường hợp logout nhưng không phải custodycd hiện tại)
            this.clearTokenStore(custodycd);
        }
        // Thay đổi mã logout để các tab tài khoản đang đăng nhập bắt được tín hiệu để logout trên tab
        let logoutCusCode = custodycd + "|" + Random.randomLogoutId();
        localStorage.setItem('LogoutCustodycd', logoutCusCode);
        sessionStorage.setItem('isTradeAuthenticated', "false");
        localStorage.setItem("statusCurrentUserCollectionId", "noneActive");
    };

    clearTokenStore = (custodycd) => {
        let savedUserToken = JSON.parse(localStorage.getItem('token-users'));
        if (savedUserToken && custodycd && savedUserToken[`${custodycd}`]) savedUserToken[`${custodycd}`] = { ...savedUserToken[`${custodycd}`], access_token: '', refresh_token: '', expires_in: 0 }; // xóa token lưu trong localStorage
        localStorage.setItem('token-users', JSON.stringify(savedUserToken));
        this.reloadInfoFromLocal();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoggedIn } = this.props;
        const { isLoggedIn: previsLoggedIn } = prevProps;
        const { isShowSetting } = this.state;
        const { isShowSetting: prevIsShowSetting } = prevState;
        if (isLoggedIn != previsLoggedIn || (isShowSetting !== prevIsShowSetting && isShowSetting === true)) {
            this.reloadInfoFromLocal();
        }
    }

    changeLoginAccountHandler = (dataItem) => {
        if (this.props.isLoggedIn) {
            //console.log("changeLoginAccountHandler1.1", dataItem)
            // let currentCustodycd = this.getCustodycd();
            // if (currentCustodycd !== dataItem.custodycd) {
            //     var url = window.location.origin.split('?')[0];
            //     //console.log("changeLoginAccountHandler1.2", url)
            //     var lastUrl = url + path.PRICEBOARD
            //     CommonUtils.openInNewTab(`${lastUrl}?custodycd=${dataItem.custodycd}`);
            // }
            this.cloneTab(dataItem);
        } else {
            sessionStorage.setItem('activeCustID', dataItem.custodycd);
            //console.log("changeLoginAccountHandler2", dataItem)
            if (dataItem.status) {
                //console.log("changeLoginAccountHandler2.1", dataItem)
                this.props.loginByTokenData(dataItem);
            } else {
                //console.log("changeLoginAccountHandler2.2", dataItem)
                this.props.loginHandler(dataItem.custodycd);
            }
        }
        this.toggleQuickSetting();
    }

    cloneTab = (dataItem) => {
        var url = window.location;
        CommonUtils.openInNewTab(`${url}?custodycd=${dataItem.custodycd}`);
    }

    deleteAccountHandler = (custodycd) => {
        let usersTokens = JSON.parse(localStorage.getItem('token-users'));
        if (usersTokens[`${custodycd}`]) delete usersTokens[`${custodycd}`];
        localStorage.setItem(`token-users`, JSON.stringify(usersTokens));
        // Nếu là tài khoản hiện tại ====> Logout
        let currentCustodycd = this.getCustodycd();
        if (currentCustodycd === custodycd) {
            this.logoutHandler(custodycd);
        } else {
            // Logout tài khoản cần xóa trên các tab
            localStorage.setItem('LogoutCustodycd', custodycd);
        }
        this.reloadInfoFromLocal();

    }

    addNewAccountHandler = () => {
        const { isLoggedIn } = this.props;
        if (isLoggedIn) {
            var url = window.location.href.split('?')[0];
            var lastUrl = url + path.PRICEBOARD
            CommonUtils.openInNewTab(`${lastUrl}?NewAccount=true`);
        } else {
            this.props.loginHandler('');
        }
    }

    logoutAllAccount = () => {
        let logoutRamdomString = Random.randomLogoutId();
        // lOGOUT aLL
        localStorage.setItem('LogoutId', logoutRamdomString);
        let savedUserToken = JSON.parse(localStorage.getItem('token-users'));
        if (savedUserToken && Object.keys(savedUserToken).length > 0) {
            Object.keys(savedUserToken).forEach(key => {
                savedUserToken[`${key}`] = { ...savedUserToken[`${key}`], access_token: '', refresh_token: '', expires_in: 0 };
            })
        }
        localStorage.setItem('token-users', JSON.stringify(savedUserToken));
        let currentCustodycd = this.getCustodycd();
        currentCustodycd && this.props.logoutSingleUser(currentCustodycd, false);
        this.props.setSymbolSelectorCollectionId("VN30")
        this.props.setSymbolSelectorUserCollectionId("favourite")
        sessionStorage.setItem('isTradeAuthenticated', "false")
        localStorage.setItem("statusCurrentUserCollectionId", "noneActive");
    }

    getCustodycd = () => {
        const { userInfo, currentCustomer } = this.props;
        let custodycd = '';
        if (userInfo.role === Role.CUSTOMER) {
            custodycd = userInfo.custodycd;
        } if (userInfo.role === Role.BROKER) {
            custodycd = userInfo ? userInfo.username : '';
        };
        return custodycd;
    }

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < AccountIconDark /> : < AccountIconLight />
                break;
            case 2:
                return defaultTheme === 'dark' ? < AddAccountIconDark /> : < AddAccountIconLight />
                break;
            case 3:
                return defaultTheme === 'dark' ? < LogOutIconDark /> : < LogOutIconLight />
                break;
            case 4:
                return defaultTheme === 'dark' ? < DeleteIconDark /> : < DeleteIconLight />
                break;
            default:
                break;
        }
    }

    genderIconHeader = () => {
        return this.props.defaultTheme === 'dark' ? this.state.isShowSetting ? <AccountIconActive /> : <AccountIconDark /> : this.state.isShowSetting ? <AccountIconActive /> : < AccountIconDark />
    }

    render() {
        const { className, defaultTheme, language, userInfo, currentAccount, currentCustomer, isBroker, isLoggedIn, loginHandler } = this.props;
        const { isShowSetting, accountInfo, userTokens } = this.state;
        let theme = defaultTheme;

        let custodycd = this.getCustodycd();
        // let usersTokens = JSON.parse(localStorage.getItem('token-users')); //{002C100523: {access_token: '...',refresh_token:'...',},002C100666: {access_token: '...',refresh_token:'...',}...}
        let usersTokens = JSON.parse(userTokens);
        let loginAccountList = [];
        loginAccountList = usersTokens ? Object.keys(usersTokens).map(key => { return { ...usersTokens[`${key}`], custodycd: key, active: custodycd === key } }) : [];

        let userName = '';
        if (isBroker) {
            userName = accountInfo ? accountInfo.brokerName : '';
        } else {
            userName = accountInfo ? accountInfo.name : '';
        }

        return (
            <div className="login-account-selector txt---500-14-20" ref={this.setWrapperRef}>
                <button id="btn-setting-id" className={className} onClick={this.toggleQuickSetting}>
                    {this.genderIconHeader()}
                </button>
                {isShowSetting && <div className="setting-container" hidden={isShowSetting ? '' : 'hidden'}>
                    <div className="select-account-header"><p>
                        <FormattedMessage id="header.login-account-selector.list-of-accounts" />
                    </p></div>

                    {loginAccountList && loginAccountList.length > 0 && loginAccountList.map((item, index) => {
                        // return <div className='login-account-item' onClick={() => this.changeLoginAccountHandler(item)}> {item}</div>
                        return (
                            <div className={"select-account-item row glutter-5" + (item.active ? " active" : "")}>
                                <div className='col-2 icon-container'> {this.genderIcon(1)}</div>
                                <div className='col-1 icon-container clone label-button' onClick={() => { this.cloneTab(item) }}> <i className={'fas fa-clone'} /></div>
                                <div className='col-6 status-container' onClick={() => this.changeLoginAccountHandler(item)}>
                                    <p className='name-display'>{item.name ? item.name : ''}</p>
                                    <p className='info-display'>{`${item.custodycd} - ` + (item.status ?
                                        LanguageUtils.getMessageByKey("header.login-account-selector.logged", this.props.lang)
                                        :
                                        LanguageUtils.getMessageByKey("header.login-account-selector.not-logged-in-yet", this.props.lang))}</p>
                                </div>
                                <div className='col-3 function-container'>
                                    <span onClick={() => this.deleteAccountHandler(item.custodycd)}>
                                        {this.genderIcon(4)}
                                    </span>
                                    {item.status ?
                                        (<span onClick={() => this.logoutHandler(item.custodycd)}>{this.genderIcon(3)}</span>)
                                        :
                                        (<div style={{ width: "24px", height: "24px", marginRight: "5px", float: "left" }}></div>)
                                    }
                                </div>
                            </div>
                        )
                    })}

                    <div className="add-login-account row glutter-5" onClick={this.addNewAccountHandler}>
                        <div className='col-2 icon-container'> {this.genderIcon(2)}</div>
                        <div className='col-10 txt-container'>
                            <p>
                                <FormattedMessage id="header.login-account-selector.add-new-account" />
                            </p>
                        </div>
                    </div>

                    <div className='logout-all-account custom-form-group txt---400-16-24'>
                        <button className="btn-action" onClick={this.logoutAllAccount}>
                            <FormattedMessage id="header.login-account-selector.log-out-all-accounts" />
                        </button>
                    </div>


                    {/*<div className="info-container">
                        <div className="info-content text-center">
                            <p className="full-name">{userName}</p>
                            <p> <FormattedMessage id="common.notifications.account" /> : {userInfo.username}</p>
                            {loginAccountList && loginAccountList.length > 0 && (<p>
                                <div className="custom-form-group  text-center">
                                    {loginAccountList.map((item, index) => {
                                        return <div className='login-account-item' onClick={() => this.changeLoginAccountHandler(item)}> {item}</div>
                                    })}
                                </div>

                            </p>)}
                            <p>
                                <span className='add-account' onClick={() => this.logoutHandler(custodycd)}><i className="fas fa-plus"></i> Thêm tài khoản mới</span>
                            </p>
                        </div>
                    </div> */}


                </div>
                }
            </div >
        );
    }
}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    const layoutsCustom = state.layout.layoutsCustom
    const layoutsConfig = state.layout.layoutsConfig
    return {
        language: state.app.language,
        accounts: state.account.accounts,
        lang: state.app.language,
        defaultTheme: defaultTheme,
        userInfo: userInfo,
        currentAccount: state.account.currentAccount,
        currentCustomer: state.customer.currentCustomer,
        accounts: state.account.accounts,
        isLoggedIn: state.user.isLoggedIn,
        usersToken: state.user.usersToken,
        layoutsCustom: layoutsCustom,
        layoutsConfig: layoutsConfig,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        changeDefaultTheme: (theme) => dispatch(actions.changeDefaultTheme(theme)),
        changeLanguage: (language) => dispatch(actions.changeLanguage(language)),
        logout: () => dispatch(actions.logout()),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
        logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
        updateTokenStore: (usersToken) => dispatch(actions.updateTokenStore(usersToken)),
        loginByTokenData: (tokenData) => dispatch(actions.loginByTokenData(tokenData)),
        setSymbolSelectorCollectionId: (collectionId) => dispatch(actions.setSymbolSelectorCollectionId(collectionId)),
        setSymbolSelectorUserCollectionId: (collectionId) => dispatch(actions.setSymbolSelectorUserCollectionId(collectionId)),
    };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LoginAccountSelector)));

import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { destroySessionStorage, getSessionStorage } from '../common/utils'
import { history } from '../redux';
import { userService, inquiryService, transferService } from "../services";
import * as actions from "../store/actions";
import { JsxUtils, KeyCodeUtils, LoadStatus, path, ToastUtil, LanguageUtils, resetPinPassTypes, CommonWidgetUtils, ModalConfirmType, Role } from "../utils";
import { SESSION_STORAGE_LIST_MARKET_CHART } from '../utils/constants'

import './ChangePinPassModal.scss';

class ChangePinPassModal extends Component {

    initialState = {
        newPassword: '',
        retypeNewPassword: '',
        newPin: '',
        retypeNewPin: '',
        isSaveButtonDisable: false,
        showPass: false,
        showPin: false,
        showRetypeNewPassword: false,
        showRetypeNewPin: false,
        oldPassword: '',
        oldPin: '',
        showOldPass: false,
        showOldPin: false,
        accountInfos: {},
        passwordLength: 8,
        pinLength: 6,
        changePassBrokerState: false // Trạng thái hiển thị đổi mk với case không bắt buộc của môi giới
    };

    state = {
        ...this.initialState
    };

    PIN_PASS_MAX_LENGTH = 30;

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
        this.changePasswordRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        //document.addEventListener('keydown', this.handlerKeyDown);
        this.getAccountInfo(this.props.currentAccountId);
        this.loadSysVar();
    }

    getAccountInfo = (currentAccountId) => {
        const { isLoggedIn } = this.props;
        if (currentAccountId && isLoggedIn) {
            inquiryService.getAccountInfo(currentAccountId)
                .then(data => {
                    this._setState({ accountInfos: data ? data : {} })
                }).catch(error => {
                    this._setState({ accountInfos: {} })
                    this._setState({ accountInfos: {} })
                    ToastUtil.errorApi(error, 'common.fail-to-load-afacctno-info');
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentAccountId, isLoggedIn } = this.props;
        const { currentAccountId: prevCurrentAccountId, isLoggedIn: prevIsLoggedIn } = prevProps;
        if (currentAccountId !== prevCurrentAccountId || isLoggedIn !== prevIsLoggedIn) {
            this.getAccountInfo(currentAccountId);
        }
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }

    }

    onNewPasswordChanged = (event) => {
        this._setState({ newPassword: event.target.value }, () => {
            this.changeSaveButtonState();
        });
    };

    onRetypeNewPasswordChanged = (event) => {
        this._setState({ retypeNewPassword: event.target.value }, () => {
            this.changeSaveButtonState();
        });
    };

    onNewPinChanged = (event) => {
        this._setState({ newPin: event.target.value }, () => {
            this.changeSaveButtonState();
        });
    };

    onRetypeNewPinChanged = (event) => {
        this._setState({ retypeNewPin: event.target.value }, () => {
            this.changeSaveButtonState();
        });
    };

    changeSaveButtonState = () => {
        // if ((this.state.newPassword !== null && this.state.newPassword.length > 0)
        //     && (this.state.retypeNewPassword !== null && this.state.retypeNewPassword.length > 0)
        //     && (this.state.newPin !== null && this.state.newPin.length > 0)
        //     && (this.state.retypeNewPin !== null && this.state.retypeNewPin.length > 0)) {
        //     this._setState({ isSaveButtonDisable: false });
        // } else {
        //     this._setState({ isSaveButtonDisable: true });
        // }
    };

    //check string có từ 6 kí tự trở lên, có chứa chữ thường, chữ hoa, số
    checkValidPass = (password) => {
        //var re = /^(?=.*\d)(?=.*[A-Z])\w{6,}$/;
        // var re = /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z`~!@#$%^&*()_+-=\[\]{}:;\|\\<>,.\/? ]{8,}$/; // Tối thiểu 8 ký tự có chứ số và ký tự viết hoa 
        let re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d@$!%*#?&]{6,}$/g; // Tối thiểu 6 ký tự. Bao gồm một chữ viết hoa và  một số , 1 ký tự
        return re.test(password);
    };

    onSave = () => {
        const { newPassword, retypeNewPassword, newPin, retypeNewPin, oldPin, oldPassword, changePassBrokerState } = this.state;
        const { resetPinPassType, isChangePass } = this.props;

        const isBroker = this.isBroker()
        // let notForceChage = isBroker && isChangePass === 'W';// Không bắt buộc đổi mk (Môi giới)

        const checkPass = resetPinPassType === resetPinPassTypes.PASS || resetPinPassType === null; // true nếu phải check pass // Cho Khách hàng
        // const checkPassExt = (isBroker && isChangePass === 'Y') || (isBroker && notForceChage && changePassBrokerState) || (!isBroker && checkPass) // Thêm case môi giới
        const checkPassExt = ((isChangePass === 'Y' || (isChangePass === 'W' && !isBroker)) && changePassBrokerState) || (!isBroker && checkPass);

        const checkPin = resetPinPassType === resetPinPassTypes.PIN || resetPinPassType === null; // true nếu phải check pin


        if (checkPassExt) {
            if (!oldPassword || !newPassword || !retypeNewPassword) {
                ToastUtil.error('FE005001');
                return;
            }

            if (oldPassword === newPassword) {
                ToastUtil.error('FE005006');
                return;
            }

            if (newPassword !== retypeNewPassword) {
                ToastUtil.error('FE005004');
                return;
            }

            // if (!this.checkValidPass(newPassword)) { // off check valid pin pass để tự call xuống back check
            //     ToastUtil.error('FE005002');
            //     return;
            // }

        }

        if (checkPin) {
            if (!oldPin || !newPin || !retypeNewPin) {
                ToastUtil.error('FE005001');
                return;
            }

            if (oldPin === newPin) {
                ToastUtil.error('FE005007');
                return;
            }

            if (newPin !== retypeNewPin) {
                ToastUtil.error('FE005005');
                return;
            }
        }

        if (checkPass && checkPin) {
            if (newPin === newPassword) {
                ToastUtil.error('FE005003');
                return;
            }
        }

        // Check pin nếu cần
        // if (!this.checkValidPass(newPin) && checkPin) {
        //     ToastUtil.error('settings.personal.change-pin-pass.invalid-pin-err');
        //     return;
        // }

        // call api to process change pin pass
        if (resetPinPassType === null) {
            userService.changePinPass(oldPin, oldPassword, newPin, newPassword)
                .then(() => {
                    this._setState({
                        ...this.initialState
                    });
                    ToastUtil.success('settings.personal.change-pin-pass.success');
                    this.props.navigate(path.LOG_OUT);
                    //settimeout để chờ action logout hoàn tất (xóa token, cookie, ...)
                    let timer = setTimeout(() => {
                        var lastUrl = path.PRICEBOARD;
                        clearTimeout(timer)
                        window.location.href = lastUrl + "?NewAccount=true";
                    }, 1000);
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'settings.personal.change-pin-pass.fail');
                });
        } else if (resetPinPassType === resetPinPassTypes.PASS || resetPinPassType === resetPinPassTypes.PIN) {
            let transferBody = {
                pwtType: resetPinPassType === resetPinPassTypes.PASS ? "LOGINPWD" : "TRADINGPWD",
                oldPassword: resetPinPassType === resetPinPassTypes.PASS ? oldPassword : oldPin,
                password: resetPinPassType === resetPinPassTypes.PASS ? newPassword : newPin
            }

            transferService.checkChangePassword(transferBody)
                .then(data => {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataChangePassword(transferBody)
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.CHANGE_PASSWORD,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })

                    });
                }).catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.bad-request');
                })
            // transferService.processChangePassword(transferBody)
            //     .then(() => {
            //         if (resetPinPassType === resetPinPassTypes.PIN) {
            //             ToastUtil.success('settings.personal.change-pin-pass.pin-success');

            //             this.props.loadUserInfo()
            //             this.props.setStatePinPassModal(false)
            //         } else {
            //             ToastUtil.success('settings.personal.change-pin-pass.pass-success');
            //             this.props.navigate(path.LOG_OUT)
            //         }
            //     })
            //     .catch((error) => {
            //         this._setState({ loadStatus: LoadStatus.FAIL });
            //         ToastUtil.errorApi(error, 'settings.personal.change-pin-pass.fail');
            //     });
        }

    };


    getDataChangePassword = (transferBody) => {
        const { resetPinPassType } = this.props;
        let _info = null

        let processAuthFunc = transferService.processChangePassword;

        _info = {
            initFuncData: transferBody,
            authRef: this.changePasswordRef,
            processTransfer: () => { this.processChangePassword() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.changePasswordCb() },
            title: resetPinPassType === resetPinPassTypes.PIN ? "security.order-password" : "security.change-login-password",
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    processChangePassword = () => {
        this.changePasswordRef.current.processAuth();
    };

    changePasswordCb = () => {
        const { resetPinPassType } = this.props;
        if (resetPinPassType === resetPinPassTypes.PIN) {
            ToastUtil.success('settings.personal.change-pin-pass.pin-success');

            this.props.loadUserInfo()
            this.props.setStatePinPassModal(false)
        } else {
            ToastUtil.success('settings.personal.change-pin-pass.pass-success');
            this.props.navigate(path.LOG_OUT)
        }
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    }

    onReset = () => {
        this._setState({
            ...this.initialState
        });
    };

    toggleDisplayOldPass = () => {
        this._setState(prevState => ({
            showOldPass: !prevState.showOldPass
        }));
    }

    toggleDisplayOldPin = () => {
        this._setState(prevState => ({
            showOldPin: !prevState.showOldPin
        }));
    }

    toggleDisplayPass = () => {
        this._setState(prevState => ({
            showPass: !prevState.showPass
        }));
    };

    toggleDisplayPin = () => {
        this._setState(prevState => ({
            showPin: !prevState.showPin
        }));
    };

    toggleDisplayRetypeNewPassword = () => {
        this._setState(prevState => ({
            showRetypeNewPassword: !prevState.showRetypeNewPassword
        }));
    }

    toggleDisplayRetypeNewPin = () => {
        this._setState(prevState => ({
            showRetypeNewPin: !prevState.showRetypeNewPin
        }));
    }

    onLogout = () => {
        let listMarketChartFromSessionStorage = getSessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        if (listMarketChartFromSessionStorage && listMarketChartFromSessionStorage.length > 0) {
            destroySessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        }

        history.push(path.LOG_OUT);
    }

    onOldPasswordChanged = (event) => {
        this._setState({ oldPassword: event.target.value }, () => {
            this.changeSaveButtonState();
        });
    };

    onOldPinChanged = (event) => {
        this._setState({ oldPin: event.target.value }, () => {
            this.changeSaveButtonState();
        });
    };

    loadSysVar = async () => {
        await Promise.all([
            userService.getSysvar('SYSTEM', 'USERONLINEPASSLENGTH', 'BASE'),
            userService.getSysvar('SYSTEM', 'USERONLINEPINLENGTH', 'BASE'),
        ]
        ).then((data) => {
            const [dataPass, dataPin] = data
            if (data && data.length !== 0) {
                let resultPassLength = parseInt(dataPass[0].varvalue)
                let resultPinLength = parseInt(dataPin[0].varvalue)
                this._setState({
                    passwordLength: resultPassLength,
                    pinLength: resultPinLength,
                });
            }
        })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-sysvar');
            });
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    // Đóng modal với case không bắt buộc đổi mk (Khách hàng)
    onToggleChangePinPassModal = () => {
        const { isresetpin } = this.props;
        if (isresetpin === 'Y') {
            this.setState({ changePassBrokerState: false }, () => {
                this.props.toogleChangePinPassModal(resetPinPassTypes.PIN);
            })
        } else {
            this.props.setStatePinPassModal(false);
        }
    }

    toggleBrokerChangePassState = () => {
        this.setState({ changePassBrokerState: !this.state.changePassBrokerState });
    }

    getShowPass = () => {
        const { resetPinPassType, isChangePass, isresetpass, isresetpin } = this.props;
        const { changePassBrokerState } = this.state;
        const showPassChange = resetPinPassType === resetPinPassTypes.PASS || resetPinPassType === null;
        const isBroker = this.isBroker();

        if (isresetpass === 'Y') {
            return true;
        }

        if (isChangePass === 'Y' || (isChangePass === 'W' && !isBroker)) {
            if (changePassBrokerState) {
                return true;
            } else {
                return false;
            }
        }

        if ((!isBroker && showPassChange)) {
            return true;
        }
    }

    render() {
        const { isOpen, lang, resetPinPassType, isChangePass, isresetpass } = this.props;
        const { accountInfos, changePassBrokerState } = this.state;
        const showPassChange = resetPinPassType === resetPinPassTypes.PASS || resetPinPassType === null;
        const showPinChange = resetPinPassType === resetPinPassTypes.PIN || resetPinPassType === null;
        const isBroker = this.isBroker();
        let notForceChage = !isBroker && isChangePass === 'W' && !showPinChange && isresetpass !== 'Y';// Không bắt buộc đổi mk (Khách hàng)
        let isChangePassRelate = ((isChangePass === 'Y' || (isChangePass === 'W' && !isBroker)) && isresetpass !== 'Y' && !showPinChange);
        // let showChangePassAll = (isBroker && isChangePass === 'Y') || (isBroker && notForceChage && changePassBrokerState) || (!isBroker && showPassChange)
        // let showChangePassAll = ((isChangePass === 'Y' || isChangePass === 'W') && changePassBrokerState) || (!isBroker && showPassChange);
        let showChangePassAll = this.getShowPass();
        //Các case mở đổi pass
        // Case Khách hàng: 
        // + Không bắt buộc : isChangePass = W ,Chỉ show đổi mk khi changePassBrokerState = true (toggle hiện đổi mk)
        // + Bắt buộc : isChangePass = Y ,Chỉ show đổi mk khi changePassBrokerState = true (toggle hiện đổi mk)
        // + Case quên mật khẩu: Khi isresetpass = 'Y'
        // Case Môi giới:
        // + Bắt buộc : isChangePass = Y ,Chỉ show đổi mk khi changePassBrokerState = true (toggle hiện đổi mk)
        return (
            <Modal isOpen={isOpen} className={'layout-setting-modal change-pin-pass-modal ' + ((isBroker || (isChangePassRelate && !changePassBrokerState)) ? 'broker-modal' : '')} centered={true} scrollable={true}>
                <div className="modal-header headB---700-16-24">
                    <div className="modal-title">
                        <FormattedMessage id="settings.menu.personal.change-pin-pass" />
                    </div>
                    <div className="btn-icon-fm" onClick={() => { notForceChage ? this.onToggleChangePinPassModal() : this.onLogout() }}>
                        <i className="fal fa-times"></i>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="change-pin-pass">
                        <div className="row gutters-5">

                            <div className="col-12">
                                <div className="utilities-form">
                                    <div className="row gutters-5">

                                        <div className="col-12">
                                            <div className="section">

                                                <div className="section-body">
                                                    <div className="row gutters-5 label-padding">
                                                        {/**Màn thông báo đổi mk không bắt buộc (Môi giới) */}
                                                        {isChangePassRelate && !changePassBrokerState && (
                                                            <Fragment>
                                                                <div className='row col-12 block-half jt-center'>
                                                                    <p>
                                                                        {isChangePass === 'W' ? 
                                                                            <FormattedMessage id={"settings.personal.change-pin-pass.change-pass-broker-note-not-force"} />
                                                                        :
                                                                            <FormattedMessage id={"settings.personal.change-pin-pass.change-pass-broker-note"} />
                                                                        }
                                                                    </p>

                                                                </div>
                                                                <div className='row col-12 block-half jt-center'>
                                                                    <button
                                                                        className='btn btn-confirm change-pass-toggle txt---500-14-20'
                                                                        onClick={() => this.toggleBrokerChangePassState()}
                                                                    >
                                                                        <FormattedMessage id={"settings.personal.change-pin-pass.change-pass-short"} /></button>
                                                                </div>
                                                            </Fragment>
                                                        )}

                                                        {!isBroker && (showChangePassAll || showPinChange) && (
                                                            <div className='row col-12 col-sm-6 block-half account-info'>
                                                                <div className="col-12 input-title headM---500-16-24">
                                                                    <FormattedMessage id={"settings.personal.change-pin-pass.personal-info"} />
                                                                </div>

                                                                <div className="row col-12 txt---500-14-20 account-info-item">
                                                                    <div className='col-6 text-align-left info-title'><FormattedMessage id={"settings.personal.change-pin-pass.full-name"} /></div>
                                                                    <div className='col-6 text-align-right info-value'>{accountInfos ? accountInfos.fullname : ''}</div>
                                                                </div>

                                                                <div className="row col-12 txt---500-14-20 account-info-item">
                                                                    <div className='col-6 text-align-left info-title'><FormattedMessage id={"settings.personal.change-pin-pass.id-code"} /></div>
                                                                    <div className='col-6 text-align-right info-value'>{accountInfos ? accountInfos.idcode : ''}</div>
                                                                </div>

                                                                <div className="row col-12 txt---500-14-20 account-info-item">
                                                                    <div className='col-6 text-align-left info-title'><FormattedMessage id={"settings.personal.change-pin-pass.address"} /></div>
                                                                    <div className='col-6 text-align-right info-value'>{accountInfos ? accountInfos.address : ''}</div>
                                                                </div>

                                                                <div className="row col-12 txt---500-14-20 account-info-item">
                                                                    <div className='col-6 text-align-left info-title'><FormattedMessage id={"settings.personal.change-pin-pass.phone"} /></div>
                                                                    <div className='col-6 text-align-right info-value'>{accountInfos ? accountInfos.mobile1 : ''}</div>
                                                                </div>

                                                                <div className="row col-12 txt---500-14-20 account-info-item">
                                                                    <div className='col-6 text-align-left info-title'><FormattedMessage id={"settings.personal.change-pin-pass.email"} /></div>
                                                                    <div className='col-6 text-align-right info-value'>{accountInfos ? accountInfos.email : ''}</div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/**Input đổi pin pass lần đầu */}
                                                        <div className={'row block-half account-info ' + (isBroker ? 'col-12' : 'col-12 col-sm-6')}>

                                                            {/**Pass Input */}
                                                            {showChangePassAll && (
                                                                <Fragment>
                                                                    <div className="col-12 input-title headM---500-16-24">
                                                                        <FormattedMessage id={"settings.personal.change-pin-pass.pass-change"} />
                                                                    </div>


                                                                    <div className="col-12 input-item txt---400-16-24">
                                                                        <div className={JsxUtils.addClassCondition('custom-form-group editable enable-edit ', 'editing', this.state.showOldPass)}>
                                                                            <input autoComplete="off"
                                                                                value={this.state.oldPassword}
                                                                                // type={this.state.showOldPass ? "text" : "password"}
                                                                                className={"custom-form-control " + ((this.state.showOldPass || !this.state.oldPassword) ? '' : 'key')}
                                                                                onChange={this.onOldPasswordChanged}
                                                                                maxLength={this.MAX_PASS_LENGTH}
                                                                                placeholder={LanguageUtils.getMessageByKey("settings.personal.change-pin-pass.old-password", lang)}
                                                                            />
                                                                            <button tabIndex="-1" className="btn-edit" onClick={this.toggleDisplayOldPass}><i className="icon-eye fas fa-eye" /></button>
                                                                            <button tabIndex="-1" className="btn-save" onClick={this.toggleDisplayOldPass}><i className="icon-eye fas fa-eye-slash" /></button>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-12 input-item txt---400-16-24">
                                                                        <div className={JsxUtils.addClassCondition('custom-form-group editable enable-edit', 'editing', this.state.showPass)}>
                                                                            <input
                                                                                value={this.state.newPassword}
                                                                                // type={this.state.showPass ? "text" : "password"}
                                                                                className={"custom-form-control pin-pass " + ((this.state.showPass || !this.state.newPassword) ? '' : 'key')}
                                                                                onChange={this.onNewPasswordChanged}
                                                                                maxLength={this.PIN_PASS_MAX_LENGTH}
                                                                                autoComplete="new-password"
                                                                                placeholder={LanguageUtils.getMessageByKey("settings.personal.change-pin-pass.new-password", lang)}
                                                                            />
                                                                            <button tabIndex="-1" className="btn-edit" onClick={this.toggleDisplayPass}><i className="icon-eye fas fa-eye" /></button>
                                                                            <button tabIndex="-1" className="btn-save" onClick={this.toggleDisplayPass}><i className="icon-eye fas fa-eye-slash" /></button>
                                                                        </div>
                                                                    </div>



                                                                    <div className={showPinChange === true ? "col-12 input-item txt---400-16-24" : "col-12 input-item last-child txt---400-16-24"}>
                                                                        <div className={JsxUtils.addClassCondition('custom-form-group editable enable-edit', 'editing', this.state.showRetypeNewPassword)}>
                                                                            <input
                                                                                value={this.state.retypeNewPassword}
                                                                                // type={this.state.showRetypeNewPassword ? "text" : "password"}
                                                                                className={"custom-form-control " + ((this.state.showRetypeNewPassword || !this.state.retypeNewPassword) ? '' : 'key')}
                                                                                onChange={this.onRetypeNewPasswordChanged}
                                                                                maxLength={this.PIN_PASS_MAX_LENGTH}
                                                                                placeholder={LanguageUtils.getMessageByKey("settings.personal.change-pin-pass.retype-new-password", lang)}
                                                                                onKeyDown={this.handlerKeyDown}
                                                                            />
                                                                            <button tabIndex="-1" className="btn-edit" onClick={this.toggleDisplayRetypeNewPassword}><i className="icon-eye fas fa-eye" /></button>
                                                                            <button tabIndex="-1" className="btn-save" onClick={this.toggleDisplayRetypeNewPassword}><i className="icon-eye fas fa-eye-slash" /></button>
                                                                        </div>
                                                                    </div>

                                                                    <div className={showPinChange === true ? "col-12 input-item" : "col-12 input-item last-child"}>
                                                                        <div className="note">
                                                                            <FormattedMessage id="security.password-note" values={{ x: this.state.passwordLength }} />
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )}


                                                            {/**Pin Input */}
                                                            {showPinChange && (
                                                                <Fragment>
                                                                    <div className={showPassChange === true ? "col-12 input-title padding-top-20 headM---500-16-24" : "col-12 input-title headM---500-16-24"}>
                                                                        <FormattedMessage id={"settings.personal.change-pin-pass.pin-change"} />
                                                                    </div>

                                                                    <div className="col-12 input-item txt---400-16-24">
                                                                        <div className={JsxUtils.addClassCondition('custom-form-group editable enable-edit', 'editing', this.state.showOldPin)}>
                                                                            <input
                                                                                autoComplete="new-password"
                                                                                value={this.state.oldPin}
                                                                                // type={this.state.showOldPin ? "text" : "password"}
                                                                                className={"custom-form-control " + ((this.state.showOldPin || !this.state.oldPin) ? '' : 'key')}
                                                                                onChange={this.onOldPinChanged}
                                                                                maxLength={this.MAX_PIN_LENGTH}
                                                                                placeholder={LanguageUtils.getMessageByKey("settings.personal.change-pin-pass.old-pin", lang)}
                                                                            />
                                                                            <button tabIndex="-1" className="btn-edit" onClick={this.toggleDisplayOldPin}><i className="icon-eye fas fa-eye" /></button>
                                                                            <button tabIndex="-1" className="btn-save" onClick={this.toggleDisplayOldPin}><i className="icon-eye fas fa-eye-slash" /></button>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-12 input-item txt---400-16-24">
                                                                        <div className={JsxUtils.addClassCondition('custom-form-group editable enable-edit', 'editing', this.state.showPin)}>
                                                                            <input
                                                                                value={this.state.newPin}
                                                                                // type={this.state.showPin ? "text" : "password"}
                                                                                className={"custom-form-control pin-pass " + ((this.state.showPin || !this.state.newPin) ? '' : 'key')}
                                                                                onChange={this.onNewPinChanged}
                                                                                maxLength={this.PIN_PASS_MAX_LENGTH}
                                                                                placeholder={LanguageUtils.getMessageByKey("settings.personal.change-pin-pass.new-pin", lang)}
                                                                            />
                                                                            <button tabIndex="-1" className="btn-edit" onClick={this.toggleDisplayPin}><i className="icon-eye fas fa-eye" /></button>
                                                                            <button tabIndex="-1" className="btn-save" onClick={this.toggleDisplayPin}><i className="icon-eye fas fa-eye-slash" /></button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 input-item last-child txt---400-16-24">
                                                                        <div className={JsxUtils.addClassCondition('custom-form-group editable enable-edit', 'editing', this.state.showRetypeNewPin)}>
                                                                            <input
                                                                                value={this.state.retypeNewPin}
                                                                                // type={this.state.showRetypeNewPin ? "text" : "password"}
                                                                                className={"custom-form-control " + ((this.state.showRetypeNewPin || !this.state.retypeNewPin) ? '' : 'key')}
                                                                                onChange={this.onRetypeNewPinChanged}
                                                                                maxLength={this.PIN_PASS_MAX_LENGTH}
                                                                                placeholder={LanguageUtils.getMessageByKey("settings.personal.change-pin-pass.retype-new-pin", lang)}
                                                                                onKeyDown={this.handlerKeyDown}
                                                                            />
                                                                            <button tabIndex="-1" className="btn-edit" onClick={this.toggleDisplayRetypeNewPin}><i className="icon-eye fas fa-eye" /></button>
                                                                            <button tabIndex="-1" className="btn-save" onClick={this.toggleDisplayRetypeNewPin}><i className="icon-eye fas fa-eye-slash" /></button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 input-item last-child">
                                                                        <div className="note">
                                                                            <FormattedMessage id="security.pin-note" values={{ x: this.state.pinLength }} />
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )}

                                                            {(showChangePassAll || showPinChange) && (
                                                                <div className="row col-12 btn-container headM---500-16-24">
                                                                    <div className='col-12'>
                                                                        <button
                                                                            onClick={this.onSave}
                                                                            disabled={this.state.isSaveButtonDisable}
                                                                            className='btn btn-confirm'
                                                                            ref={this.confirmBtnRef}
                                                                            onKeyDown={this.handlerKeyDown}
                                                                        ><FormattedMessage id={"settings.personal.change-pin-pass.confirm"} /></button>
                                                                    </div>
                                                                    {/*<div className='col-6'>
                                                                        <button onClick={this.onLogout}><FormattedMessage id={"settings.personal.change-pin-pass.logout"} /></button>
                                                                    </div>*/}
                                                                </div>
                                                            )}


                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal >
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        logout: () => dispatch(actions.logout()),
        loadUserInfo: () => dispatch(actions.loadUserInfo()),
        setStatePinPassModal: (flag) => dispatch(actions.setStatePinPassModal(flag)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        toogleChangePinPassModal: (type) => dispatch(actions.toogleChangePinPassModal(type)),
    };
};

const mapStateToProps = (state, props) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()

    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive];
    let currentAccountId = _layoutPage.accountInfo && _layoutPage.accountInfo['currentAccountId'] ? _layoutPage.accountInfo['currentAccountId'] : "";
    return {
        lang: state.app.language,
        currentAccountId: currentAccountId,
        isLoggedIn: state.user.isLoggedIn,
        resetPinPassType: state.app.resetPinPassType,
        isChangePass: state.user.userInfo.isChangePass,
        isresetpass: state.user.userInfo.isresetpass,
        isresetpin: state.user.userInfo.isresetpin,
        role: state.user.userInfo.role,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePinPassModal);

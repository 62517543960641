// Haki_lt.: WidgetIcons: Config các icon tương ứng với từng Widget
import {ReactComponent as IconActiveOrders} from '../../../assets/images/widget-icons/icon_active_orders.svg';
import {ReactComponent as IconClosePositions} from '../../../assets/images/widget-icons/icon_close_positions.svg';
import {ReactComponent as IconConditionOrder} from '../../../assets/images/widget-icons/icon_condition_order.svg';
import {ReactComponent as IconConfirmOrder} from '../../../assets/images/widget-icons/icon_confirm_order.svg';
import {ReactComponent as IconDepthChart} from '../../../assets/images/widget-icons/icon_depth_chart.svg';
import {ReactComponent as IconDraftOrders} from '../../../assets/images/widget-icons/icon_draft_orders.svg';
import {ReactComponent as IconIndexsList} from '../../../assets/images/widget-icons/icon_indexs_list.svg';
import {ReactComponent as IconMatchedOrder} from '../../../assets/images/widget-icons/icon_matched_orders.svg';
import {ReactComponent as IconOpenPositions} from '../../../assets/images/widget-icons/icon_open_positions.svg';
import {ReactComponent as IconOrderbook} from '../../../assets/images/widget-icons/icon_orderbook.svg';
import {ReactComponent as IconOrdersList} from '../../../assets/images/widget-icons/icon_orders_list.svg';
import {ReactComponent as IconPlaceOrder} from '../../../assets/images/widget-icons/icon_place_order.svg';
import {ReactComponent as IconPriceChart} from '../../../assets/images/widget-icons/icon_price_chart.svg';
import {ReactComponent as IconRecentTrades} from '../../../assets/images/widget-icons/icon_recent_trades.svg';
import {ReactComponent as IconSymbolInfo} from '../../../assets/images/widget-icons/icon_symbol_info.svg';
import {ReactComponent as IconSymbolSelector} from '../../../assets/images/widget-icons/icon_symbol_selector.svg';
export default {
    IconPlaceOrder,
    IconSymbolInfo,
    IconOrderbook,
    IconPriceChart,
    IconDepthChart,
    IconRecentTrades,
    IconSymbolSelector,
    IconActiveOrders,
    IconOrdersList,
    IconDraftOrders,
    IconOpenPositions,
    IconIndexsList,
    IconClosePositions,
    IconMatchedOrder,
    IconConditionOrder,
    IconConfirmOrder
}

import React, { Component, Fragment } from 'react';
// import IconToolTip from 'components/IconToolTip';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { batch } from 'react-redux'
import { push } from 'connected-react-router';
// import { ReactComponent as ICON_DAT_LENH_DARK } from '../../assets/icons_new/menu_tool/datlenh_dark.svg'
// import { ReactComponent as ICON_DAT_LENH_LIGHT } from '../../assets/icons_new/menu_tool/datlenh_light.svg'
import { ReactComponent as ICON_DAT_LENH_ACTIVE } from '../../assets/icons_new/menu_tool/datlenh_active.svg'

// import { ReactComponent as ICON_PHAN_TICH_DARK } from '../../assets/icons_new/menu_tool/phantich_dark.svg'
// import { ReactComponent as ICON_PHAN_TICH_LIGHT } from '../../assets/icons_new/menu_tool/phantich_light.svg'
import { ReactComponent as ICON_PHAN_TICH_ACTIVE } from '../../assets/icons_new/menu_tool/phantich_active.svg'

import { ReactComponent as ICON_TPRL_ACTIVE } from '../../assets/icons_new/menu_tool/tprl_active.svg'


// import { ReactComponent as ICON_TAI_SAN_DARK } from '../../assets/icons_new/menu_tool/taisan_dark.svg'
// import { ReactComponent as ICON_TAI_SAN_LIGHT } from '../../assets/icons_new/menu_tool/taisan_light.svg'
import { ReactComponent as ICON_TAI_SAN_ACTIVE } from '../../assets/icons_new/menu_tool/taisan_active.svg'

// import { ReactComponent as ICON_NOP_TIEN_DARK } from '../../assets/icons_new/menu_tool/noptien_dark.svg'
// import { ReactComponent as ICON_NOP_TIEN_LIGHT } from '../../assets/icons_new/menu_tool/noptien_light.svg'
import { ReactComponent as ICON_NOP_TIEN_ACTIVE } from '../../assets/icons_new/menu_tool/noptien_active.svg'

// import { ReactComponent as ICON_AI_TRADING_DARK } from '../../assets/icons_new/menu_tool/i_invest_dark.svg'
// import { ReactComponent as ICON_AI_TRADING_LIGHT } from '../../assets/icons_new/menu_tool/i_invest_light.svg'
import { ReactComponent as ICON_AI_TRADING_ACTIVE } from '../../assets/icons_new/menu_tool/ai_trading_active.svg'

// import { ReactComponent as ICON_I_BROKER_DARK } from '../../assets/icons_new/menu_tool/i_broker_dark.svg'
// import { ReactComponent as ICON_I_BROKER_LIGHT } from '../../assets/icons_new/menu_tool/i_broker_light.svg'
import { ReactComponent as ICON_I_BROKER_ACTIVE } from '../../assets/icons_new/menu_tool/i_broker_active.svg'

// import { ReactComponent as ICON_HO_TRO_DARK } from '../../assets/icons_new/menu_tool/hotro_dark.svg'
// import { ReactComponent as ICON_HO_TRO_LIGHT } from '../../assets/icons_new/menu_tool/hotro_light.svg'
import { ReactComponent as ICON_HO_TRO_ACTIVE } from '../../assets/icons_new/menu_tool/hotro_active.svg'

import { ReactComponent as ICON_NOTI_ACTIVE } from '../../assets/icons_new/menu_tool/noti_active.svg'

import { ReactComponent as ICON_LINE_DARK } from '../../assets/icons_new/menu_tool/line_dark.svg'

// import { ReactComponent as ICON_ADD_DARK } from '../../assets/icons_new/menu_tool/add_dark.svg'
// import { ReactComponent as ICON_ADD_LIGHT } from '../../assets/icons_new/menu_tool/add_light.svg'
import { ReactComponent as ICON_ADD_ACTIVE } from '../../assets/icons_new/menu_tool/add_active.svg'

// import { ReactComponent as ICON_WIDGET_DARK } from '../../assets/icons_new/menu_tool/widget_dark.svg'
// import { ReactComponent as ICON_WIDGET_LIGHT } from '../../assets/icons_new/menu_tool/widget_light.svg'
import { ReactComponent as ICON_WIDGET_ACTIVE } from '../../assets/icons_new/menu_tool/widget_active.svg'

// import { ReactComponent as ICON_BROKER_ORDER_BOOL_DARK } from '../../assets/icons_new/menu_tool/broker_order_book_dark.svg'
// import { ReactComponent as ICON_BROKER_ORDER_BOOL_LIGHT } from '../../assets/icons_new/menu_tool/broker_order_book_light.svg'
import { ReactComponent as ICON_BROKER_ORDER_BOOL_ACTIVE } from '../../assets/icons_new/menu_tool/broker_order_book_active.svg'

import { ReactComponent as ICON_TONGHOP_KL_ACTIVE } from '../../assets/icons_new/menu_tool/tonghopkl_active.svg'


import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { dispatch } from '../../redux';
import * as actions from '../../store/actions';
import actionTypes from "../../store/actions/actionTypes";
import { CommonUtils, CommonWidgetUtils, path, Role, ToastUtil, OPENNING_SCREEN_TYPE, TYPE_ACCOUNT, ORDER_TYPE_USE } from "../../utils";
import { userService } from "../../services";

import ModalHeaderCreateLayout from '../Header/ModalHeaderCreateLayout'
import { menuBroker, menuCustomer } from './MenuSideBarConfig'
import './MenuTool.scss'
// import { Link } from 'react-router-dom';
// import { push } from "connected-react-router";
import { emitter } from "utils/EventEmitter";
// import { thisExpression } from '@babel/types';
import CustomScrollbars from 'components/CustomScrollbars';
const iInvest = "https://invest.bsc.com.vn/"
class MenuTool extends Component {


    initialState = {
        isOpenModalCreatLayout: false,
        hoveringIconId: '',
        promotion_link: '',
        isShowMenuTool: true
    };

    state = {
        ...this.initialState
    };

    toolMenu = [

    ]

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    createNewLayoutCustom = () => {
        let isLoggedIn = CommonUtils.checkLogined()
        if (!isLoggedIn) {
            return;
        }
        dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        }))
        let { isDeskTop } = this.props
        let OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#CS#TRADE#NC') // Mặc định theo role
        let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
        if (!OBJLAYOUT_CUSTOM) {
            ToastUtil.warn("WR000001");
            return null
        }
        this._setState({
            isOpenModalCreatLayout: true
        })
    }

    toggleModalCreateLayout = () => {
        this._setState({
            isOpenModalCreatLayout: false
        })
    }

    subCreateNewLayout = (nameLayoutCustom) => {
        let { isDeskTop, userInfo } = this.props
        let OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#CS#TRADE#NC') // Mặc định theo role
        let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
        // nameLayoutCustom = window.prompt('Nhập tên layout tùy chỉnh cần khởi tạo:..', nameLayoutCustomSuggest)
        if (nameLayoutCustom) {
            // check trùng tên so với database. nếu chưa tồn tại thì cho phép khởi tạo.
            let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
            let layoutPageCurrent = getLayoutPageCurrent.LayoutPageCurrent;
            // let keyName = 'CS#TRADE#CB'
            // let activeURL = "/trade/"
            let activeURL = path.CUSTOMETRADE + '/' + CommonWidgetUtils.getPathLayoutCustom(OBJLAYOUT_CUSTOM)
            let newData = {}
            newData[OBJLAYOUT_CUSTOM] = {
                "keyName": OBJLAYOUT_CUSTOM,
                "activeURL": activeURL,
                "accountInfo": layoutPageCurrent && layoutPageCurrent.accountInfo,
                "symbols": "",
                "typeLayout": "C",
                "showMenu": true,
                "typeUser": userInfo.role || ""
                // "layoutCurrent": layoutCurrent
            }
            let layoutCurrent = {
                id: OBJLAYOUT_CUSTOM,
                layoutName: nameLayoutCustom,
                typeLayout: "C",
            }
            let from = "MenuTool.:subCreateNewLayout"
            this.props.addNewLayoutCustom(newData, OBJLAYOUT_CUSTOM, layoutCurrent, false, false, from)
            this._setState({
                isOpenModalCreatLayout: false
            })
            this.props.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            })
            // const { navigate } = this.props;
            // navigate(activeURL)
            return null;
        } else {
            // alert('Vui lòng nhập tên layout tùy chỉnh!')
        }
    }
    selectedLayoutCustom = (OBJLAYOUT) => {
        const { userInfo } = this.props
        //console.log('selectedLayoutCustom=================1', OBJLAYOUT)

        if (CommonWidgetUtils.onCheckPermistionKeyLayout(OBJLAYOUT)) {
            let isLoggedIn = CommonUtils.checkLogined()
            if (!isLoggedIn) {
                return;
            }
        }
        // //console.log('layoutsCustom=================',OBJLAYOUT)
        // this.props.changeLayoutPageActive(OBJLAYOUT)
        // let OBJLAYOUT_CUSTOM = 'C#CS#TRADE#CB$TC001'
        let { isDeskTop, layoutPage, layoutsCustom } = this.props
        let layoutCurrent;
        let index = layoutsCustom && layoutsCustom.findIndex((e) => e.id === OBJLAYOUT)
        if (index > -1) {
            layoutCurrent = layoutsCustom[index]
            layoutCurrent.isEditLayout = false
        }
        //console.log('selectedLayoutCustom=================', OBJLAYOUT, layoutCurrent, layoutsCustom)
        // if (layoutPage) {
        //     let count = 0;
        //     Object.keys(layoutPage).forEach(e => {
        //         //console.log('selectedLayoutCustom=================1', e)
        //         if (e.indexOf(OBJLAYOUT) > -1) {
        //             count++
        //         }
        //     })
        //     if (count > 0) {
        //         count++
        //         OBJLAYOUT = OBJLAYOUT + '&' + count
        //     }
        // }

        let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent(false, OBJLAYOUT);
        let layoutPageCurrent = getLayoutPageCurrent.LayoutPageCurrent;
        // let keyName = 'CS#TRADE#CB'
        // let activeURL = "/trade/"
        // alert('selectedLayoutCustom_' + layoutPageCurrent.activeURL)
        let activeURL = path.CUSTOMETRADE + '/' + CommonWidgetUtils.getPathLayoutCustom(OBJLAYOUT)
        let newData = {}
        newData[OBJLAYOUT] = {
            "keyName": OBJLAYOUT,
            "activeURL": activeURL,
            "accountInfo": layoutPageCurrent && layoutPageCurrent.accountInfo,
            "symbols": "",
            "typeLayout": "C",
            "showMenu": true,
            "typeUser": userInfo.role,
            "isEditLayout": false
        }
        let from = "MenuTool.:selectedLayoutCustom"
        this.props.addNewLayoutCustom(newData, OBJLAYOUT, layoutCurrent, false, false, from)
        // VIX : Không đóng đặt lệnh nhanh khi mở screenmodal
        // dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        dispatch(push(activeURL));
        // Xóa layoutTemp
        dispatch({
            type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
            layoutCustom: undefined,
            isSaveByBtn: true,
            from: 'selectedLayoutCustom'
        });
    }

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        let disableStyle = { opacity: 0.5 }
        switch (keyIcon) {
            case 1:
                // return defaultTheme === 'dark' ? < ICON_DAT_LENH_DARK /> : < ICON_DAT_LENH_LIGHT />
                return <ICON_DAT_LENH_ACTIVE style={disableStyle} />
                break;
            case 2:
                // return defaultTheme === 'dark' ? < ICON_PHAN_TICH_DARK /> : < ICON_PHAN_TICH_LIGHT />
                return <ICON_PHAN_TICH_ACTIVE style={disableStyle} />
                break;
            case 3:
                // return defaultTheme === 'dark' ? < ICON_TAI_SAN_DARK /> : < ICON_TAI_SAN_LIGHT />
                return <ICON_TAI_SAN_ACTIVE style={disableStyle} />
                break;
            case 4:
                // return defaultTheme === 'dark' ? < ICON_NOP_TIEN_DARK /> : < ICON_NOP_TIEN_LIGHT />
                return <ICON_NOP_TIEN_ACTIVE style={disableStyle} />
                break;
            case 5:
                // return defaultTheme === 'dark' ? < ICON_AI_TRADING_DARK /> : < ICON_AI_TRADING_LIGHT />
                return <ICON_AI_TRADING_ACTIVE style={disableStyle} />
                break;
            case 6:
                // return defaultTheme === 'dark' ? < ICON_I_BROKER_DARK /> : < ICON_I_BROKER_LIGHT />
                return <ICON_I_BROKER_ACTIVE style={disableStyle} />
                break;
            case 7:
                // return defaultTheme === 'dark' ? < ICON_HO_TRO_DARK /> : < ICON_HO_TRO_LIGHT />
                return <ICON_HO_TRO_ACTIVE style={disableStyle} />
                break;
            case 8:
                return defaultTheme === 'dark' ? < ICON_LINE_DARK /> : < ICON_LINE_DARK />
                break;
            case 0:
                // return defaultTheme === 'dark' ? < ICON_ADD_DARK /> : < ICON_ADD_LIGHT />
                return <ICON_ADD_ACTIVE style={disableStyle} />
                break;
            case 9:
                // return defaultTheme === 'dark' ? < ICON_WIDGET_DARK /> : < ICON_WIDGET_LIGHT />
                return <ICON_WIDGET_ACTIVE style={disableStyle} />
            case 10:
                // return defaultTheme === 'dark' ? < ICON_NOTI_ACTIVE /> : < ICON_NOTI_ACTIVE />
                return <ICON_NOTI_ACTIVE style={disableStyle} />
                break;
            case 11:
                // return defaultTheme === 'dark' ? < ICON_BROKER_ORDER_BOOL_DARK /> : < ICON_BROKER_ORDER_BOOL_LIGHT />
                return <ICON_BROKER_ORDER_BOOL_ACTIVE style={disableStyle} />
                break;
            case 12:
                // return defaultTheme === 'dark' ? < ICON_BROKER_ORDER_BOOL_DARK /> : < ICON_BROKER_ORDER_BOOL_LIGHT />
                return <ICON_TONGHOP_KL_ACTIVE style={disableStyle} />
                break;
            case 13:
                return < ICON_AI_TRADING_ACTIVE />
            case 14:
                return < ICON_TPRL_ACTIVE style={disableStyle} />
            default:
                break;
        }
    }


    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    openNormalTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#CB" : "C#CS#TRADE#CB"
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        CommonUtils.openLayoutByKey(key)
    }

    openProTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#NC" : "C#CS#TRADE#NC"
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        CommonUtils.openLayoutByKey(key)
    }

    openTPRLTradeNM = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#TPRL_NM" : "C#CS#TRADE#TPRL_NM"
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        let keyLayout = CommonWidgetUtils.getReplaceLayoutPageActive()
        if (CommonUtils.getOrderTypeUseLayout(keyLayout) == ORDER_TYPE_USE.TPRL_OR_ORDER || CommonUtils.getOrderTypeUseLayout(keyLayout) == ORDER_TYPE_USE.TPRL_NM_ORDER) {
            this.props.updatedLayoutPage({
                "showMenu": false,
            }, CommonWidgetUtils.getReplaceLayoutPageActive())
        }
        CommonUtils.openLayoutByKey(key)
    }

    openMarketWatch = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#MARKET_WATCH" : "C#CS#MARKET_WATCH"
        CommonUtils.openLayoutByKey(key)
        dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        }))
    }

    openAiTrading = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#AI_TRADING" : "C#CS#AI_TRADING"
        CommonUtils.openLayoutByKey(key)
        CommonUtils.openLayoutByKey(key)
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
    }

    updateOpenningScreenInfo = (keyName, screenType, data = {}) => {
        // Chưa login ===> Lưu lại thông tin màn hình muốn mở => sau khi login mở lại màn hình với thông tin được lưu
        const { setOpenningScreenInfo } = this.props;
        setOpenningScreenInfo(keyName, screenType, data);
    }

    onChangeMenuActiveModal = (keyName, allowBroker = true) => {
        // Haki.: set CurrentMenuActiveModal
        if (!this.onCheckLogined(keyName)) {
            this.updateOpenningScreenInfo(keyName, OPENNING_SCREEN_TYPE.SCREEN_MODAL, { allowBroker: allowBroker, isPermissionSearch: false });
            return;
        }

        let accountInfoBase = {
            curCustomer: '',
            accounts: [],
            currentAccountId: '',
            typeAccount: TYPE_ACCOUNT.BASE,
        }

        let { accountInfo, isDeskTop, _accountInfo } = this.props
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: true,
            curScreenModalActive: keyName,
            accountInfo: keyName === 'menu-sidebar.title-11' ? accountInfoBase : _accountInfo, //Sổ lệnh môi giới không sử dụng chung tài khoản khách hàng đã chọn ở layout
            useCustomHeader: keyName === 'menu-sidebar.title-11' // Sổ lện môi giới không dùng header 
        })
        if (!isDeskTop) {
            this.closeMenuSideBar(0)
        }
    }

    closeMenuSideBar = (status) => {
        this.props.onChangeStatusMenuSideBar(status)
    }

    onCheckLogined = (keyName) => {
        if (keyName.indexOf("PRICEBOARD#CB") < 0) {
            // Check Login với menu khác bảng giá
            let isLoggedIn = CommonUtils.checkLogined()
            if (!isLoggedIn) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        this.loadConfig()
    }

    loadConfig = () => {
        const { setClientConfig } = this.props;
        userService.getClientConfig()
            .then((data) => {
                this._setState({ promotion_link: data.configData.promotion_link });
                setClientConfig(data);
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    handleClickIInvest = () => {
        window.open(iInvest, '_blank', 'noopener,noreferrer');
        dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        }))
    }

    onClickIBroker = (e, keyName, activeURL, allowBroker = true) => {
        // //console.log('onChangeMenuActiveSideBar==e=', data, item)
        if (keyName !== "CS#PRICEBOARD#CB") {
            let isLoggedIn = CommonUtils.checkLogined()
            if (!isLoggedIn) {
                return;
            }
        }
        e.stopPropagation();
        batch(() => {
            this.props.onChangeMenuActiveSideBar(keyName)
            this.props.onChangeActiveURLSideBar(activeURL)
            this.props.changeLayoutPageActive(this.isBroker() ? "B#CS#PRICEBOARD#CB" : "C#CS#PRICEBOARD#CB")
            // dispatch(actions.setCurrentCustomer((data && data.accountInfo) && data.accountInfo.curCustomer))
            this.props.onChangeStatusMenuSideBar(0)
            this.props.setIsOpenModalHaveData("QuickOrder", {
                isOpenQuickOrder: false,
            })
            this.props.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            })
        });
        emitter.emit("ibroker", "IBROKER")
    }

    onChangePayment = (allowBroker = true) => {
        // Haki.: set currentMenuActive
        let keyName = "menu-tool.title-05"
        if (!this.onCheckLogined(keyName)) {
            this.updateOpenningScreenInfo(keyName, OPENNING_SCREEN_TYPE.MODAL, { allowBroker: allowBroker, isPermissionSearch: false });
            return;
        }
        this.props.setIsOpenPayment(true)
        dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        }))
    }

    openAssetTotal = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#ASSET_TOTAL" : "C#CS#ASSET_TOTAL"
        CommonUtils.openLayoutByKey(key)
        dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        }))
    }

    openIBroker = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#IBROKER" : "C#CS#IBROKER"
        CommonUtils.openLayoutByKey(key)
        dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        }))
    }

    onChangeMenuActive = (e, keyName, activeURL, allowBroker = true) => {
        // Haki.: set currentMenuActive
        // //console.log("binh_onChangeMenuActive1", _OBJLAYOUT)
        if (!this.onCheckLogined(keyName)) {
            this.updateOpenningScreenInfo(keyName, OPENNING_SCREEN_TYPE.LAYOUT, { allowBroker: allowBroker, isPermissionSearch: false });
            return;
        }
        e.stopPropagation();
        let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + keyName) // Mặc định theo role
        batch(() => {
            // dispatch(actions.changeMenuActive(keyName))
            dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
        });
        //console.log("binh_onChangeMenuActive2", _OBJLAYOUT)
        if (this.isBroker()) {
            if (keyName === menuBroker['TRADE#01'].key) {
                this.openNormalTrade()
            } else if (keyName === menuBroker['ASSET_TOTAL'].key) {
                this.openAssetTotal()
            } else if (keyName === menuBroker['TRADE#04'].key) {
                this.openTPRLTradeNM()
            } else if (keyName === menuBroker['MARKET_WATCH'].key) {
                this.openMarketWatch()
            } else if (keyName === menuBroker['AI_TRADING'].key) {
                this.openAiTrading()
            } else if (keyName === menuBroker['IBROKER'].key) {
                this.openIBroker()
            }
        } else {
            if (keyName === menuCustomer['TRADE#01'].key) {
                this.openNormalTrade()
            } else if (keyName === menuCustomer['ASSET_TOTAL'].key) {
                this.openAssetTotal()
            } else if (keyName === menuCustomer['TRADE#04'].key) {
                this.openTPRLTradeNM()
            } else if (keyName === menuCustomer['MARKET_WATCH'].key) {
                this.openMarketWatch()
            } else if (keyName === menuCustomer['AI_TRADING'].key) {
                this.openAiTrading()
            } else if (keyName === menuCustomer['IBROKER'].key) {
                this.openIBroker()
            }
        }
        let { isDeskTop } = this.props
        if (!isDeskTop) {
            this.closeMenuSideBar(0)
        }
    }

    handleOpenSupport = () => {
        this.closeMenuSideBar(1)
        emitter.emit("OPEN_MENU_SUPPORT", "")
    }


    handleOpenPromotion = (link) => {
        link && CommonUtils.openInNewTab(link)
        this.props.onChangeClickPromotion(true)
    }

    onMouseOverIcon = (iconId) => {
        this._setState({ hoveringIconId: iconId });
    }

    onMouseLeaveIcon = () => {
        this._setState({ hoveringIconId: '' });
    }

    genActiveIcon = (iconId) => {
        switch (iconId) {
            case 1:
                return < ICON_DAT_LENH_ACTIVE />
                break;
            case 2:
                return < ICON_PHAN_TICH_ACTIVE />
                break;
            case 3:
                return < ICON_TAI_SAN_ACTIVE />
                break;
            case 4:
                return < ICON_NOP_TIEN_ACTIVE />
                break;
            case 5:
                return < ICON_AI_TRADING_ACTIVE />
                break;
            case 6:
                return < ICON_I_BROKER_ACTIVE />
                break;
            case 7:
                return < ICON_HO_TRO_ACTIVE />
                break;
            case 8:
                return < ICON_LINE_DARK />
                break;
            case 0:
                return < ICON_ADD_ACTIVE />
                break;
            case 9:
                return < ICON_WIDGET_ACTIVE />
                break;
            case 10:
                return < ICON_NOTI_ACTIVE />
                break;
            case 11:
                return < ICON_BROKER_ORDER_BOOL_ACTIVE />
                break;
            case 12:
                return < ICON_TONGHOP_KL_ACTIVE />
                break;
            case 13:
                return < ICON_AI_TRADING_ACTIVE />
                break;
            case 14:
                return < ICON_TPRL_ACTIVE />
            default:
                break;
        }

        if (iconId.includes('customLayout')) {
            return < ICON_WIDGET_ACTIVE />
        }
    }

    toggleMenuTool = () => {
        this._setState({ isShowMenuTool: !this.state.isShowMenuTool });
        this.props.setIsOpenMenuTool(!this.state.isShowMenuTool);
    }

    render() {
        let { isLoggedIn, isDeskTop, widthMenuSideBar, isOpenScreenModal,
            layoutsCustom, defaultTheme, currentLayoutPageActive, isCheckClickPromotion } = this.props
        const { hoveringIconId, promotion_link, isShowMenuTool } = this.state;
        //console.log('layoutsCustom=================1', layoutsCustom)
        let width = isOpenScreenModal && widthMenuSideBar === "226px" ? "0px" : "75px";
        // const isFullMenuHeight = (currentLayoutPageActive && !currentLayoutPageActive.includes('PRICEBOARD')) || isOpenScreenModal;
        let isCheckMoblieLogout = !isLoggedIn && !isDeskTop
        return (
            <Fragment>
                <div className='btn-extend-container'>
                    {!isShowMenuTool &&
                        <button className='btn-extend-close' onClick={() => this.toggleMenuTool()}>
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    }
                </div>
                <div className={'menu-tool '
                    // + (isFullMenuHeight && isDeskTop ? 'full' : '') 
                    + (isCheckMoblieLogout ? " menu-tool-top-moblie-logout" : "")
                    + (isShowMenuTool ? ' ' : ' close-menu-tool')
                } style={{ width: width }}>
                    {
                        this.state.isOpenModalCreatLayout &&
                        <ModalHeaderCreateLayout
                            isOpen={this.state.isOpenModalCreatLayout}
                            toggle={this.toggleModalCreateLayout}
                            isCreateNewCustom={true}
                        ></ModalHeaderCreateLayout>
                    }


                    <div className='btn-extend-container'>
                        {isShowMenuTool &&
                            <button className='btn-extend open-extend' onClick={() => this.toggleMenuTool()}>
                                <i class="fas fa-chevron-left"></i>
                            </button>
                        }
                        {!isShowMenuTool &&
                            <button className='btn-extend close-extend' onClick={() => this.toggleMenuTool()}>
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        }
                    </div>

                    <CustomScrollbars>
                        <div className='menu-container scroll-x-moblie'>
                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(1)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={(e) => this.onChangeMenuActive(e, menuBroker['TRADE#01'].key, menuBroker['TRADE#01'].path)}
                            >
                                <p><span>
                                    {hoveringIconId === 1 ? this.genActiveIcon(1) : this.genderIcon(1)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 1 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-01" /></div>
                            </button>

                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(12)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-12") }}
                            >
                                <p><span>
                                    {hoveringIconId === 12 ? this.genActiveIcon(12) : this.genderIcon(12)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 12 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-12" /></div>
                            </button>

                            {this.isBroker() &&
                                <button
                                    className={'menu-icon icon-tooltip'}
                                    onMouseOver={() => this.onMouseOverIcon(11)}
                                    onMouseLeave={this.onMouseLeaveIcon}
                                    onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-11") }}
                                >
                                    <p><span>
                                        {hoveringIconId === 11 ? this.genActiveIcon(11) : this.genderIcon(11)}
                                    </span></p>
                                    <div className={'label txt---400-12-18 ' + (hoveringIconId === 11 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-11" /></div>
                                </button>
                            }

                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(2)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={(e) => this.openMarketWatch()}
                            >
                                <p><span>
                                    {hoveringIconId === 2 ? this.genActiveIcon(2) : this.genderIcon(2)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 2 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-02" /></div>
                            </button>
                            {/* TPRL */}
                            {/* <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(14)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={(e) => this.onChangeMenuActive(e, menuBroker['TRADE#04'].key, menuBroker['TRADE#04'].path)}
                            >
                                <p><span>
                                    {hoveringIconId === 14 ? this.genActiveIcon(14) : this.genderIcon(14)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 14 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-14" /></div>
                            </button> */}

                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(3)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={(e) => this.onChangeMenuActive(e, menuBroker['ASSET_TOTAL'].key, menuBroker['ASSET_TOTAL'].path)}
                            >
                                <p><span>
                                    {hoveringIconId === 3 ? this.genActiveIcon(3) : this.genderIcon(3)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 3 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-03" /></div>
                            </button>

                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(13)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                // onClick={() => this.openAiTrading()}
                                onClick={(e) => this.onChangeMenuActive(e, menuBroker['AI_TRADING'].key, menuBroker['AI_TRADING'].path)}
                            >
                                <p><span>
                                    {hoveringIconId === 13 ? this.genActiveIcon(13) : this.genderIcon(13)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 13 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-13" /></div>
                            </button>


                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(4)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={() => this.isBroker() ?
                                    ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') :
                                    this.onChangePayment()}
                            >
                                <p><span>
                                    {hoveringIconId === 4 ? this.genActiveIcon(4) : this.genderIcon(4)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 4 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-04" /></div>
                            </button>


                            {/*<IconToolTip className='menu-icon' tooltipText={<FormattedMessage id="menu-tool.title-05" />}
                        onMouseOver={() => this.onMouseOverIcon(5)}
                        onMouseLeave={this.onMouseLeaveIcon}
                    >
                        <span onClick={() => this.handleClickIInvest()}>
                            {hoveringIconId === 5 ? this.genActiveIcon(5) : this.genderIcon(5)}
                        </span>
                    </IconToolTip>*/}
                            {/* <IconToolTip className='menu-icon' tooltipText={<FormattedMessage id="menu-tool.title-06" />}
                        onMouseOver={() => this.onMouseOverIcon(6)}
                        onMouseLeave={this.onMouseLeaveIcon}
                    >
                        <Link to={menuBroker["IBROKER"].path} onClick={(e) => this.onChangeMenuActive(e, menuBroker['IBROKER'].key, menuBroker['IBROKER'].path)}>
                            {hoveringIconId === 6 ? this.genActiveIcon(6) : this.genderIcon(6)}
                        </Link>
                    </IconToolTip> */}
                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(7)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={() => this.handleOpenSupport()}
                            >
                                <p><span>
                                    {hoveringIconId === 7 ? this.genActiveIcon(7) : this.genderIcon(7)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 7 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-07" /></div>
                            </button>

                            {/*<IconToolTip className={'menu-icon ' + (isCheckClickPromotion ? "" : "animation-rung-lac")} tooltipText={<FormattedMessage id="menu-tool.title-10" />}
                        onMouseOver={() => this.onMouseOverIcon(10)}
                        onMouseLeave={this.onMouseLeaveIcon}
                    >
                        <span onClick={() => this.handleOpenPromotion(promotion_link)} >
                            {hoveringIconId === 10 ? this.genActiveIcon(10) : this.genderIcon(10)}
                        </a>
                    </IconToolTip>*/}

                            <div className='menu-icon item-center'
                                onMouseOver={() => this.onMouseOverIcon(8)}
                                onMouseLeave={this.onMouseLeaveIcon}
                            >
                                {hoveringIconId === 8 ? this.genActiveIcon(8) : this.genderIcon(8)}
                            </div>

                            <button
                                className={'menu-icon icon-tooltip'}
                                onMouseOver={() => this.onMouseOverIcon(0)}
                                onMouseLeave={this.onMouseLeaveIcon}
                                onClick={() => this.createNewLayoutCustom()}
                            >
                                <p><span >
                                    {hoveringIconId === 0 ? this.genActiveIcon(0) : this.genderIcon(0)}
                                </span></p>
                                <div className={'label txt---400-12-18 ' + (hoveringIconId === 0 ? 'text-active' : '')}><FormattedMessage id="menu-tool.title-00" /></div>
                            </button>
                            {
                                layoutsCustom && layoutsCustom.map((e, index) => {
                                    let OBJLAYOUT = e && e.id
                                    let typeLayout = e && e.typeLayout
                                    let layoutName = e && e.layoutName
                                    const isActive = currentLayoutPageActive === e.id
                                    if ((OBJLAYOUT && OBJLAYOUT.indexOf('$TC_TEMP') > -1) || typeLayout == "DC") {
                                        // Bỏ qua template chưa save của layout Mặc định
                                        return null;
                                    }
                                    return (
                                        <button
                                            className={'menu-icon icon-tooltip'}
                                            onMouseOver={() => this.onMouseOverIcon('customLayout' + index)}
                                            onMouseLeave={this.onMouseLeaveIcon}
                                            onClick={() => this.selectedLayoutCustom(OBJLAYOUT)}
                                        >
                                            <p><span>
                                                {(isActive || hoveringIconId === 'customLayout' + index) ? this.genActiveIcon('customLayout' + index) : this.genderIcon(9)}
                                            </span></p>
                                            <div className={'label txt---400-12-18 ' + (isActive || hoveringIconId === ('customLayout' + index) ? 'text-active' : '')}>{layoutName}</div>
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </CustomScrollbars>
                </div >
                <div className={'fakeMenuTool' + (isShowMenuTool ? '' : ' close-menu-tool')}></div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
    const layoutPage = state.layout.layoutPage
    const layoutsCustom = state.layout.layoutsCustom
    let isDeskTop = state.app.isDeskTop
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let isBroker = CommonUtils.isBroker();

    const _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    const _accountInfo = _layoutPage && _layoutPage["accountInfo"]

    let _layoutPageBroker = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    let _accountInfoBroker = _layoutPage && _layoutPageBroker.accountInfo

    let accountDefault = isBroker ? _accountInfoBroker : _accountInfo
    //console.log("isBroker---: ", isBroker)
    return {
        isDeskTop: isDeskTop,
        layoutPage: layoutPage,
        layoutsCustom: layoutsCustom,
        widthMenuSideBar: widthMenuSideBar,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        defaultTheme: defaultTheme,
        userInfo: state.user.userInfo,
        currentLayoutPageActive: currentLayoutPageActive,
        accountInfo: accountDefault,
        _accountInfo: _accountInfo,
        isLoggedIn: state.user.isLoggedIn,
        isDeskTop: state.app.isDeskTop,
        isCheckClickPromotion: state.app.isCheckClickPromotion
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // navigate: (path) => dispatch(push(path)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        addNewLayoutCustom: (newLayout, OBJLAYOUT_CUSTOM, layoutCustom, isSaveByBtn, isOverRidesDf, from) => dispatch(actions.addNewLayoutCustom(newLayout, OBJLAYOUT_CUSTOM, layoutCustom, isSaveByBtn, isOverRidesDf, from)),
        changeLayoutPageActive: (currentLayoutPageActive) => dispatch(actions.changeLayoutPageActive(currentLayoutPageActive)),
        addLayoutPage: (newLayout) => dispatch(actions.addLayoutPage(newLayout)),
        setIsOpenPayment: (isOpen) => dispatch(actions.setIsOpenPayment(isOpen)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setOpenningScreenInfo: (keyName, type, data) => dispatch(actions.setOpenningScreenInfo(keyName, type, data)),
        onChangeStatusMenuSideBar: (statusMenuSideBar) => dispatch(actions.onChangeStatusMenuSideBar(statusMenuSideBar)),
        setSymbolSelectorCollectionId: (collectionId) => dispatch(actions.setSymbolSelectorCollectionId(collectionId)),
        onChangeMenuActiveSideBar: (keyName) => dispatch(actions.onChangeMenuActiveSideBar(keyName)),
        onChangeActiveURLSideBar: (activeURL) => dispatch(actions.onChangeActiveURLSideBar(activeURL)),
        onChangeClickPromotion: (status) => dispatch(actions.onChangeClickPromotion(status)),
        setClientConfig: (config) => dispatch(actions.setClientConfig(config)),
        setIsOpenMenuTool: (isOpen) => dispatch(actions.setIsOpenMenuTool(isOpen)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuTool);

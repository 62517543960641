import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import RetryableContent from "../../../../components/RetryableContent";
import { tradeApiService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { LoadStatus, CommonWidgetUtils, ACTION_USER_ORDER, Role, ORDER_TYPE_USE, BLB_ORDER_ACTION, BLB_ACK_OPTIONS, JsxUtils } from "../../../../utils";
import { orderActions } from '../../../../utils';

import CustomRowItem from '../../../../components/CustomRowItem/CustomRowItem';
import './TradeAuthBlb.scss';
import TradeAuth2 from '../../../../components/TradeAuth2/TradeAuth2';
import CustomScrollbars from 'components/CustomScrollbars';
import { ReactComponent as WarningIcon_Dark } from '../../../../assets/icons/screenmodal/WarningIcon_Dark.svg';



class TradeAuthBlb extends Component {

    propAttrs = {
        // props from redux
        // listAuthType: this.props.listAuthType,    // list các loại hình xác thực được phép chọn
        permissionInfo: this.props.permissionInfo,
        custodycd: this.props.custodycd,      // biến phục vụ TransAuth Component
        currentAccountId: this.props.currentAccountId,      // biến phục vụ TransAuth Component
        tradeAuthenticated: this.props.tradeAuthenticated,    // biến phục vụ TransAuth Component

        currentReconfirmOrderType: this.props.currentReconfirmOrderType,  // biến truyền vào loại sổ lệnh cần confirm là thường hay điều kiện
        showReconfirmOrder: this.props.showReconfirmOrder,    // biến truyền vào true khi người dùng tích enable xác thực 2 lớp
        currentCustomer: this.props.currentCustomer,

        //props từ component cha
        intl: this.props.intl,        // thu vien
        onFinish: this.props.onFinish,   // function callback để thực hiện đóng modal
        transFunction: this.props.transFunction,     // function callback dùng để initTransaction
    };

    state = {
        loadStatus: LoadStatus.NONE,
        listReconfirmOrder: this.props.listReconfirmOrder ? this.props.listReconfirmOrder : [],
        availableTrade: null
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        }
        this.tradeAuthRef = React.createRef();
    };

    twoFactorAuthCb = (data) => {

        let a = this.props.triggerTradeBlbOrderAuthenCallback(data);
        //console.log('twoFactorAuthCb.:twoFactorAuthCb===', a)
    };

    onFinish = () => {
        const { onFinish } = this.propAttrs;
        if (onFinish) {
            onFinish();
        }
    };


    isBroker = () => {
        const { userinfo } = this.props;
        const role = userinfo ? userinfo.role : '';
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { saveTradeAuthenticated, isSaveTradeAuthenticated, tradeAuthenticated } = this.props;
        if (!tradeAuthenticated) {
            if (isSaveTradeAuthenticated) saveTradeAuthenticated();
        } else {
            if (!isSaveTradeAuthenticated) saveTradeAuthenticated();
        };
        const { listReconfirmOrder } = this.state;
        const { orderType, actionUserOrder } = { ...listReconfirmOrder }
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };



    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.listReconfirmOrder) !== JSON.stringify(prevProps.listReconfirmOrder)) {
            this._setState({ listReconfirmOrder: this.props.listReconfirmOrder });
        }

    }


    renderNormalOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state;
        const { limitPrice, symbolCurrent, quantity, action, orderType, subOrderType } = listReconfirmOrder

        return (
            <div>

                <div>
                    <CustomRowItem
                        showLabel="Mã"
                        showValue={symbolCurrent ? symbolCurrent.id : ''}
                    />
                </div>
                <div>
                    {orderType === 'limit' &&
                        <CustomRowItem
                            showLabel="Giá"
                            showValue={limitPrice || 0}
                            isPrice={true}
                            classNameValue={""}
                        />
                    }
                    {orderType === 'market' &&
                        <CustomRowItem
                            showLabel="Giá"
                            showValue={subOrderType}
                        />
                    }
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Khối lượng"
                        showValue={quantity || 0}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Lệnh"
                        showValue={action && action === orderActions.BUY ? 'trade.order.buy' : 'trade.order.sell'}
                        classNameValue={"text-uppercase " + (action === orderActions.BUY ? "text-green" : "text-red")}
                        isValueText={true}
                    />
                </div>

            </div>
        )

    };

    renderCancelNormalOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state;
        const { cancelingOrder, symbolCurrent } = listReconfirmOrder
        const { limitPrice, quantity, action, orderType, subOrderType } = cancelingOrder

        return (
            <div>

                <div>
                    <CustomRowItem
                        showLabel="Mã"
                        showValue={symbolCurrent ? symbolCurrent.id : ''}
                    />
                </div>
                <div>
                    {orderType === 'limit' &&
                        <CustomRowItem
                            showLabel="Giá"
                            showValue={limitPrice || 0}
                            isPrice={true}
                            classNameValue={""}
                        />
                    }
                    {orderType === 'market' &&
                        <CustomRowItem
                            showLabel="Giá"
                            showValue={subOrderType}
                        />
                    }
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Khối lượng"
                        showValue={quantity || 0}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Lệnh"
                        showValue={action && action === orderActions.BUY ? 'trade.order.buy' : 'trade.order.sell'}
                        classNameValue={"text-uppercase " + (action === orderActions.BUY ? "text-green" : "text-red")}
                        isValueText={true}
                    />
                </div>

            </div>
        )

    };

    renderEditOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state;
        const { limitPrice, symbolCurrent, quantity, action, orderType, subOrderType } = listReconfirmOrder
        return (
            <div>
                <div>
                    <CustomRowItem
                        showLabel="Mã CK"
                        showValue={symbolCurrent ? symbolCurrent.id : ''}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Mua/Bán"
                        showValue={action && action === orderActions.BUY ? 'trade.order.buy' : 'trade.order.sell'}
                        classNameValue={"text-uppercase " + (action === orderActions.BUY ? "text-green" : "text-red")}
                        isValueText={true}
                    />
                </div>
                <div>
                    {orderType === 'limit' &&
                        <CustomRowItem
                            showLabel="Giá (x1000)"
                            showValue={limitPrice || 0}
                            isPrice={true}
                            classNameValue={""}
                        />
                    }
                    {orderType === 'market' &&
                        <CustomRowItem
                            showLabel="Giá"
                            showValue={subOrderType}
                        />
                    }
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Khối lượng"
                        showValue={quantity || 0}
                    />
                </div>
            </div>
        )

    };

    renderCancelChildOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state;
        const { cancelingOrder } = listReconfirmOrder;
        const { desc_exectype, symbol, orderqtty, blprice, orderid, action, pricetype, orderType } = cancelingOrder;

        return (
            <div>
                <div>
                    <CustomRowItem
                        showLabel="Lệnh Hủy"
                        showValue={desc_exectype + ' ' + symbol + ' ' + orderqtty}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Mã CK"
                        showValue={symbol || ''}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Loại lệnh"
                        showValue={action && action === orderActions.BUY ? 'trade.order.buy' : 'trade.order.sell'}
                        classNameValue={"text-uppercase " + (action === orderActions.BUY ? "text-green" : "text-red")}
                        isValueText={true}
                    />
                </div>
                <div>
                    {orderType === 'limit' &&
                        <CustomRowItem
                            showLabel="Giá (x1000)"
                            showValue={blprice || 0}
                            isPrice={true}
                            classNameValue={""}
                        />
                    }
                    {orderType === 'market' &&
                        <CustomRowItem
                            showLabel="Giá"
                            showValue={pricetype || ''}
                        />
                    }
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Số hiệu lệnh"
                        showValue={orderid || ''}
                    />
                </div>
            </div>
        )

    };



    processTradeAuth = (authInfo) => {
        if (this.validateAuth()) {
            return new Promise(function (resolve, reject) {
                return resolve(authInfo);
            });
        } else {
            // xét ảnh hưởng trước khi dùng ,lệnh thỏa thuận noButton = false và không có autoCb (nhảy vào catch trong TradeAuth2)
            return new Promise(function (resolve, reject) {
                return reject('');
            });
        }
    };

    validateAuth() {
        const { accountInfo } = this.props;
        const { currentAccountId, typeAccount } = { ...accountInfo }
        const { listReconfirmOrder } = this.state;
        const { orderType, actionUserOrder } = { ...listReconfirmOrder }
        // Xác nhận lệnh thỏa thuận từ đối tác môi giới cần chọn tài khoản tiểu khoản bên mua

        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setLoadStatus(nextProps.loadStatusBlbModalTrade ? nextProps.loadStatusBlbModalTrade : this.state.loadStatus)
    }

    renderBlbAckConfirm = () => {
        const { listReconfirmOrder } = this.state;
        const { confirmData } = listReconfirmOrder;
        const {
            type: selectedCheckType
        } = { ...confirmData }
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={100}
                autoHeightMax={300}
            >
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    <div className="col-1 custom-form-group text-right" style={{ border: 'none' }}>
                        <div className="custom-form-control">
                            <input className='' type="radio" name="select-ack-option"
                                readOnly
                                checked={selectedCheckType === BLB_ACK_OPTIONS.CHILD_ORD_PRICE}
                            />
                        </div>

                    </div>
                    <div className="col-11 text-left">
                        <label>Giá lệnh con không vượt quá chỉ thị</label>
                    </div>

                    <div className="col-1 custom-form-group text-right" style={{ border: 'none' }}>
                        <div className="custom-form-control">
                            <input className='' type="radio" name="select-ack-option"
                                readOnly
                                checked={selectedCheckType === BLB_ACK_OPTIONS.AVG_PRICE}
                            />
                        </div>

                    </div>
                    <div className="col-11 text-left">
                        <label>Giá trung bình không vượt quá chỉ thị</label>
                    </div>

                    <div className="col-1 custom-form-group text-right " style={{ border: 'none' }}>
                        <div className="custom-form-control">
                            <input className='' type="radio" name="select-ack-option"
                                readOnly
                                checked={selectedCheckType === BLB_ACK_OPTIONS.NOT_CHILD_ORD_PRICE}
                            />
                        </div>

                    </div>
                    <div className="col-11 text-left">
                        <label>Không check giá lệnh con</label>
                    </div>
                </div>
            </CustomScrollbars>
        )
    }

    renderBlbAckSellConditionConfirm = () => {
        const { listReconfirmOrder } = this.state;
        const { confirmData } = listReconfirmOrder;
        const {
            custodycd, afacctno, calQttyInfo
        } = { ...confirmData }

        // Tính Số cấp thêm
        let addQttyMore = 0;
        if (calQttyInfo) {
            let setupQtty = calQttyInfo['setupQtty'] || 0;
            let currSetupQtty = calQttyInfo['currSetupQtty'] || 0
            addQttyMore = parseFloat(setupQtty - currSetupQtty);
        }
        return (
            <div>
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="TK Thiết lập"
                    showValue={`${custodycd || ''} - ${afacctno || ''}`}
                />
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Số cấp tổng"
                    showValue={calQttyInfo && calQttyInfo['setupQtty'] ? calQttyInfo['setupQtty'] : ''}
                    isQtty={true}
                />
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Số cấp thêm"
                    showValue={addQttyMore}
                    isQtty={true}
                />
            </div>
        )
    }

    renderBlbAckEditCancelOrder = () => {
        const { listReconfirmOrder } = this.state;
        const { confirmData } = listReconfirmOrder;
        const {
            blorderid,
            newquantity,
            newprice
        } = { ...confirmData }
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={50}
                autoHeightMax={50}
            >
                <div className="text-center">
                    {`Số hiệu lệnh ${blorderid} KL mới : ${newquantity} - giá mới ${newprice}`}
                </div>
            </CustomScrollbars>
        )
    }

    renderBlbRejectConfirm = () => {
        const { listReconfirmOrder } = this.state;
        const { confirmData } = listReconfirmOrder;
        const {
            orderId
        } = { ...confirmData }
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={100}
                autoHeightMax={100}
            >
                <div className="col-12 text-center">
                    <WarningIcon_Dark />
                </div>
                <div className="col-12 text-center">
                    {`Từ chối lệnh ${orderId}`}
                </div>
            </CustomScrollbars>
        )
    }

    renderBlbCancelFromFixNet = () => {
        const { listReconfirmOrder } = this.state;
        const { confirmData } = listReconfirmOrder;
        const {
            exectype, symbol, quantity, price, blorderid
        } = { ...confirmData }
        let orderInfoDisplay = (symbol || '') + ' - ' + (exectype || '') + ' - ' + (quantity || '');
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={150}
                autoHeightMax={150}
            >


                <div>
                    <CustomRowItem
                        showLabel="Lệnh tổng"
                        showValue={orderInfoDisplay}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Giá"
                        showValue={price || 0}
                        isPrice={true}
                        classNameValue={""}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Số hiệu lệnh"
                        showValue={blorderid || ''}
                    />
                </div>

            </CustomScrollbars>
        )
    }

    renderDone4DayConfirm = () => {
        const { dataInfo } = this.props
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={50}
                autoHeightMax={50}
            >
                <div className="text-center">
                    Bạn có muốn thực thi Done 4 day đối với lệnh này
                </div>
            </CustomScrollbars>
        )
    }


    renderOrderConfirmView = () => {
        const { listReconfirmOrder } = this.state;
        const { actionUserOrder } = { ...listReconfirmOrder }
        const { orderTypeUse } = this.props;

        if (actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER) {
            return this.renderNormalOrderTableOrder();
        } else if (actionUserOrder === ACTION_USER_ORDER.EDIT_ORDER) {
            return this.renderEditOrderTableOrder();
        } else if (orderTypeUse === ORDER_TYPE_USE.BLB_CHILD_TRADE_ORDER) {
            // case ra cho hủy lệnh con và hủy lệnh tổng do dữ liệu hiển thị khác nhau
            if (actionUserOrder === ACTION_USER_ORDER.CANCEL_ORDER) {
                return this.renderCancelChildOrderTableOrder();
            }
        } else if (orderTypeUse === ORDER_TYPE_USE.BLB_TRADE_ORDER) {
            // Dùng của đặt lệnh do chưa có design xác nhận hủy lệnh tổng
            if (actionUserOrder === ACTION_USER_ORDER.CANCEL_ORDER) {
                return this.renderCancelNormalOrderTableOrder();
            } else if (actionUserOrder === BLB_ORDER_ACTION.BLB_ACK_CONFIRM) {
                return this.renderBlbAckConfirm();
            } else if (actionUserOrder === BLB_ORDER_ACTION.BLB_ACK_CONFIRM_SELL_CONDITION) {
                return this.renderBlbAckSellConditionConfirm()
            } else if (actionUserOrder === BLB_ORDER_ACTION.BLB_ACK_EDIT_CANCEL_ORDER) {
                return this.renderBlbAckEditCancelOrder();
            } else if (actionUserOrder === BLB_ORDER_ACTION.BLB_REJECT) {
                return this.renderBlbRejectConfirm();
            } else if (actionUserOrder === BLB_ORDER_ACTION.BLB_CANCEL_FROM_FIXNET) {
                return this.renderBlbCancelFromFixNet();
            } else if (actionUserOrder === BLB_ORDER_ACTION.BLB_DONE_4_DAY) {
                return this.renderDone4DayConfirm();
            }
        }
    };

    processAuth = () => {
        this.tradeAuthRef && this.tradeAuthRef.current && this.tradeAuthRef.current.processAuth();
    }

    handlerKeyDown = (e) => {
        this.tradeAuthRef && this.tradeAuthRef.current && this.tradeAuthRef.current.handlerKeyDown(e);
    }

    render() {
        const {
            autoCb,
            transFunction
        } = this.propAttrs; // Chỉ dành cho các props không thay đổi giá trị
        // const { tradeAuthenticated } = this.props;
        const { toggle, listReconfirmOrder } = this.props;
        const { backStep } = listReconfirmOrder;
        const genAuthFunc = tradeApiService.resendTwoFactorCode;    // gen lại mã xác thực ở tradeApi
        const twoFactorAuthFunc = this.processTradeAuth;
        const initAuthFunc = tradeApiService.initTwoFactorAuth;
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} className="trade-auth-blb">
                <form onSubmit={e => { e.preventDefault(); }}>
                    {/*this.renderNormalOrderTableOrder()*/}
                    {this.renderOrderConfirmView()}
                    <TradeAuth2
                        ref={this.tradeAuthRef}
                        containerClass="row gutters-5 align-items-center auth-container"
                        labelClass="col-5"
                        inputClass="col-7 custom-form-group"
                        inputClassAuth="col-7 custom-form-group-effect"
                        btnContainerClass=""
                        onFinish={this.onFinish}

                        initAuthFunc={initAuthFunc}

                        genAuthFunc={genAuthFunc}

                        twoFactorAuthFunc={twoFactorAuthFunc}

                        twoFactorAuthCb={this.twoFactorAuthCb}

                        setLoadStatus={status => this.setLoadStatus(status)}

                        autoCb={autoCb}

                        isDisableConfirmBtn={false}
                        showOrderSwitch={true}
                        autoFocus={true}
                        fillPassword={true}

                        // tradeOrderInputMsg={this.props.tradeOrderInputMsg}

                        currentReconfirmOrderType={this.propAttrs.currentReconfirmOrderType}
                        isOrder={true}
                        noButton={true}
                    />
                    <div className={JsxUtils.joinClass('trade-auth-btn-container txt---500-14-20')}>
                        <button className="btn-action"
                            onClick={backStep ? backStep : toggle}
                        >
                            <FormattedMessage id="trade.auth.action-close" />
                        </button>
                        <button
                            className="btn-action bg-primary text-custom"
                            onClick={this.processAuth}
                            onKeyDown={this.handlerKeyDown}
                        >
                            <FormattedMessage id="trade.auth.action-auth" />
                        </button>
                    </div>
                </form>
            </RetryableContent >
        );
    }
}

const mapStateToProps = state => {
    const userinfo = state.user.userInfo;
    const permissionInfo = userinfo.permissionInfo ? userinfo.permissionInfo : null;

    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive();
    let _placeBlbOrder = state.orderNew.placeBlbOrder
    let currentSymbol = _currentLayoutPageActive && _placeBlbOrder[_currentLayoutPageActive] && _placeBlbOrder[_currentLayoutPageActive]["symbolCurrent"]

    let currentReconfirmOrderType = 'ORDER' // hardCode truyền
    let listIsOpenModal = state.layout.listIsOpenModal["C&B"];

    // Dữ liệu đặt lệnh tổng blb
    let quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]];
    // _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"];

    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"];
    return {
        loadStatusBlbModalTrade: listIsOpenModal["BlbOrder"].loadStatusBlbModalTrade,

        permissionInfo: permissionInfo,
        userinfo: userinfo,
        custodycd: userinfo.custodycd,
        tradeAuthenticated: state.app.tradeAuthenticated,
        // tradeResponseStatus: state.app.tradeResponseStatus,
        isSaveTradeAuthenticated: listIsOpenModal.isSaveTradeAuthenticated,

        // currentReconfirmOrderType: state.app.currentReconfirmOrderType,
        currentReconfirmOrderType: currentReconfirmOrderType,
        language: state.app.language,
        currentCustomer: state.customer.currentCustomer,

        accounts: state.account.accounts,
        role: state.user.userInfo.role,
        symbolWithIndex: state.symbol.allQuotesWithIndex,
        currentSymbol: currentSymbol,
        quote: quote,
        accountInfo: _accountInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        triggerTradeBlbOrderAuthenCallback: (data) => dispatch(actions.triggerTradeBlbOrderAuthenCallback(data)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TradeAuthBlb));
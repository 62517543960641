import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import RetryableContent from "../../../../components/RetryableContent";
import { tradeApiService, fdsInquiryService, inquiryService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { LoadStatus, CommonUtils, CommonWidgetUtils, PUTTHROUGH_ORDER_TYPE, PUTTHROUGH_ORDER_ACTION, SELECTOR_ACCOUNT_SCREEN, ToastUtil, ACTION_USER_ORDER } from "../../../../utils";
import { orderActions } from '../../../../utils';
import { modules, Role, TYPE_ACCOUNT, EXCHANGE, derivativeExchanges, ORDER_TYPE_USE, CONFIRM_CANCEL_PUTTHROUGH_CDTYPE } from '../../../../utils/constants';

import _ from "lodash";
import './TradeAuthPutthrough.scss';
import TradeAuth2 from './../../../../components/TradeAuth2/TradeAuth2';
import { Fragment } from 'react';
// import PlaceOrderPuthroughTrade from "../PlaceOrderPuthroughTrade";
// import { ORDER_TYPE_USE } from 'utils';
import BlockInputPrice from 'containers/QuickOrder/PlaceOrderQuickOrder/BlockInputPrice/BlockInputPrice';
import BlockInputQtty from 'containers/QuickOrder/PlaceOrderQuickOrder/BlockInputQtty/BlockInputQtty';
import SelectorAccountCustomer from '../../../../components/SelectorAccount/SelectorAccountCustomer';
import SelectorAccountId from '../../../../components/SelectorAccount/SelectorAccountId';
import { FormattedPrice, FormattedAmount } from 'components/Formating';
import LanguageUtils from './../../../../utils/LanguageUtils';


class TradeAuthPutthrough extends Component {

    propAttrs = {
        // props from redux
        // listAuthType: this.props.listAuthType,    // list các loại hình xác thực được phép chọn
        permissionInfo: this.props.permissionInfo,
        custodycd: this.props.custodycd,      // biến phục vụ TransAuth Component
        currentAccountId: this.props.currentAccountId,      // biến phục vụ TransAuth Component
        tradeAuthenticated: this.props.tradeAuthenticated,    // biến phục vụ TransAuth Component

        currentReconfirmOrderType: this.props.currentReconfirmOrderType,  // biến truyền vào loại sổ lệnh cần confirm là thường hay điều kiện
        showReconfirmOrder: this.props.showReconfirmOrder,    // biến truyền vào true khi người dùng tích enable xác thực 2 lớp
        currentCustomer: this.props.currentCustomer,

        //props từ component cha
        intl: this.props.intl,        // thu vien
        onFinish: this.props.onFinish,   // function callback để thực hiện đóng modal
        transFunction: this.props.transFunction,     // function callback dùng để initTransaction
    };

    state = {
        loadStatus: LoadStatus.NONE,
        listReconfirmOrder: this.props.listReconfirmOrder ? this.props.listReconfirmOrder : [],
        availableTrade: null
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        }
    };

    twoFactorAuthCb = (data) => {

        let a = this.props.triggerTradePutthroughOrderAuthenCallback(data);
        //console.log('twoFactorAuthCb.:twoFactorAuthCb===', a)
    };

    onFinish = () => {
        const { onFinish } = this.propAttrs;
        if (onFinish) {
            onFinish();
        }
    };


    isBroker = () => {
        const { userinfo } = this.props;
        const role = userinfo ? userinfo.role : '';
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        this.checkShowBlock();
        const { saveTradeAuthenticated, isSaveTradeAuthenticated, tradeAuthenticated } = this.props;
        if (!tradeAuthenticated) {
            if (isSaveTradeAuthenticated) saveTradeAuthenticated();
        } else {
            if (!isSaveTradeAuthenticated) saveTradeAuthenticated();
        };
        const { listReconfirmOrder } = this.state;
        const { orderType, actionUserOrder } = { ...listReconfirmOrder }
        if ((orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_NOTI || orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_ORDER) && actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER) {
            this.debounceLoadAvailableTradeData();
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    onSymbolChange = () => {

    }

    onClearDayChange = (e) => {
        // this._setState({ clearDay: e.target.value });
        const { updatePlaceQuickOrder } = this.props
        updatePlaceQuickOrder({
            clearDay: e.target.value
        }, ORDER_TYPE_USE.PUTTHROUGH_TRADE_ORDER)
    }

    getQuoteInfo(currentSymbol) {
        const { instruments } = this.props;
        if (currentSymbol) {
            let instrument = instruments[`${currentSymbol}`] ? instruments[`${currentSymbol}`] : {};
            return {
                Id: instrument['SB'],
                EX: instrument['EX'],
                CP: instrument['CP'],
                CH: instrument['CH'],
                CHP: instrument['CHP'],
                FN: instrument['FN'],
            }

        }

    }

    renderMinMaxPrice = () => {
        const { quote } = this.props;

        let floorPrice = 0;
        let ceilPrice = 9999999999999;

        if (quote != null) {
            floorPrice = CommonUtils.divide(quote.floor, 1000)
            ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
        }
        //console.log("renderMinMaxPrice", floorPrice, ceilPrice)
        return {
            minPrice: floorPrice,
            maxPrice: ceilPrice
        }
    }
    getSymbolToDisplay(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    checkShowBlock = () => {
        let a = null;
        let b = null;
        a = document.getElementById(`search-symbol-quick-order-market-alert-confirm`);
        b = document.getElementById(`block-info-symbol-putthrough-order-confirm`);


        if (a && b) {
            a.style.display = "none";
            b.style.display = "block";
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { accountInfo } = this.props;
        const { currentAccountId, typeAction } = { ...accountInfo }
        const { accountInfo: prevAccountInfo
        } = prevProps;
        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountInfo }

        const { listReconfirmOrder } = this.state;
        const { orderType, actionUserOrder } = { ...listReconfirmOrder }

        if ((currentAccountId !== prevCurrentAccountId && (orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_NOTI || orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_ORDER) && actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER)) {
            this.debounceLoadAvailableTradeData();
        }

        if (JSON.stringify(this.props.listReconfirmOrder) !== JSON.stringify(prevProps.listReconfirmOrder)) {
            this._setState({ listReconfirmOrder: this.props.listReconfirmOrder });
        }

    }


    renderNormalOrderTableOrder = () => {
        const { listReconfirmOrder } = this.state
        const { quote, accountQuickOrderInfo, symbolWithIndex, currentSymbol, instruments } = this.props;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)

        const { crosstype: crossType, custodycd, accountId, firm, traderid, symbol, price, orderType, subOrderType, qtty, clearDay } = _listReconfirmOrder;
        let symbolToDisplay = this.getSymbolToDisplay(currentSymbol);

        const quoteInfo = this.getQuoteInfo(symbol ? symbol : '');
        const { currentAccountId, accounts } = { ...accountQuickOrderInfo }
        let { minPrice, maxPrice } = this.renderMinMaxPrice();

        const instrument = currentSymbol && currentSymbol.id ? instruments[`${currentSymbol.id}`] || {} : {};
        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        let stepInputQuantity = currentSymbol && quote ? (instrument.EX == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        this.checkShowBlock();
        let putthroughOrderType = '';
        if (crossType === PUTTHROUGH_ORDER_TYPE.ONE_FIRM) {
            putthroughOrderType = LanguageUtils.getMessageByKey("putthrough-order.1F", this.props.language);
        } else if (crossType === PUTTHROUGH_ORDER_TYPE.TWO_FIRM) {
            putthroughOrderType = LanguageUtils.getMessageByKey("putthrough-order.2F", this.props.language);
        }
        return (
            <Fragment>
                {/*<PlaceOrderPuthroughTrade
                    listReconfirmOrder={_listReconfirmOrder}
                    orderTypeUse={ORDER_TYPE_USE.PUTTHROUGH_TRADE_ORDER}
                    isConfirmMode={true}
                />*/}

                <div className="row putthrough-trade-auth">

                    {/** Thông tin tài khoản */}
                    <div className={"row block-info-account"}>

                        <div className="col-12 title txt---500-14-20"><FormattedMessage id='putthrough-order.account-info' /></div>

                        <div className="row input-item col-12 align-items-center">
                            <div className="col-5 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.putthrough' /></div>
                            <div className="col-7">
                                <div className='custom-form-group'>
                                    <input className="custom-form-control"
                                        readOnly
                                        value={putthroughOrderType}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row input-item col-12 align-items-center">
                            <div className="col-5 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.sell-custodycd' /></div>
                            <div className="col-7 customer-input">

                                <div className={"custom-form-group"}>
                                    <input
                                        ref={this.customerAccountRef}
                                        type="text"
                                        name="custodycd"
                                        maxLength="10"
                                        className="custom-form-control txt---400-14-20"
                                        value={custodycd}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="row input-item col-12 align-items-center">
                            <div className="col-5 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.sell-accountid' /></div>
                            <div className="col-7 account-input">
                                <div className={"custom-form-group"}>
                                    <input
                                        ref={this.customerAccountRef}
                                        type="text"
                                        name="custodycd"
                                        maxLength="10"
                                        className="custom-form-control txt---400-14-20"
                                        value={accountId}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="row input-item col-12 align-items-center">
                            <div className="col-5 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.connect-mem' /></div>
                            <div className="col-7">
                                <div className='custom-form-group'>
                                    <input className="custom-form-control"
                                        readOnly
                                        value={firm}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row input-item col-12 align-items-center">
                            <div className="col-5 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.connect-mem-id' /></div>
                            <div className="col-7">
                                <div className='custom-form-group'>
                                    <input className="custom-form-control"
                                        readOnly
                                        value={traderid}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    {/** Thông tin chứng khoán */}
                    <div className={"row block-info-stock"}>
                        <div className="col-12 title txt---500-14-20"><FormattedMessage id='putthrough-order.sec-info' /></div>

                        <div className="row input-item col-12 align-items-center clear-day-input">
                            <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.symbol-full' /></div>
                            <div className="col-8">
                                <div className='custom-form-group'>
                                    <input className="custom-form-control"
                                        value={symbol}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row input-item col-12 align-items-center">
                            <div className="col-4 txt---400-14-20 input-label"><FormattedMessage id='putthrough-order.price' /></div>
                            <div className="col-8">
                                <BlockInputPrice
                                    name="limit-price"
                                    ref={this.priceOrderRef}
                                    subOrderTypes={""} // Hay gây lỗi
                                    quote={this.props.quote ? this.props.quote : ""}
                                    step={10}
                                    min={minPrice}
                                    max={maxPrice}
                                    value={price}
                                    orderType={orderType}
                                    subOrderType={subOrderType}
                                    valid={true}
                                    validStep={true}
                                    onChange={this.onPriceChanged}
                                    onKeyDown={this.onInputKeyDown}
                                    onBlur={this.onBlurPrice}
                                    allowZero={false}
                                    allowDecimal={true}
                                    allowNegative={false}
                                    onFocus={this.onFocusPrice}
                                    decimalScale={2}
                                    readOnly={true}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className="row input-item col-12 align-items-center">
                            <div className="col-4 txt---400-14-20 input-label"><FormattedMessage id='putthrough-order.volumn' /></div>
                            <div className="col-8">
                                <BlockInputQtty
                                    name="quantity"
                                    step={stepInputQuantity}
                                    min={null}
                                    max={999999999999}
                                    instrument={instrumentQtty}
                                    value={qtty}
                                    valid={true}
                                    onChange={this.onQuantityChanged}
                                    // onKeyDown={this.onInputKeyDown}
                                    allowZero={false}
                                    allowDecimal={false}
                                    allowNegative={false}
                                    orderType={orderType}
                                    ref={this.quantityRef}
                                    // onFocus={this.onFocusQtty}
                                    onBlur={this.onBlurQtty}
                                    readOnly={true}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        {/*<div className="type-view present-order">
                            <div className="content content-left text-left">

                            </div>
                            <div className="content content-right item-center text---400-14-20">
                                {listPercentOrder.map((item, index) => {
                                    return <div tabIndex="0" className={"select-present txt-400-14-20 "} >{item.title}</div>
                                })}
                            </div>
                        </div>*/}

                        <div className="row input-item col-12 align-items-center clear-day-input">
                            <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.clear-day' /></div>
                            <div className="col-8">
                                <div className='custom-form-group'>
                                    <input className="custom-form-control"
                                        type="number"
                                        value={clearDay}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );

    };

    renderNotiTableOrder = () => {
        const { listReconfirmOrder } = this.state
        const { quote, accountQuickOrderInfo, symbolWithIndex, currentSymbol, instruments, accountInfo } = this.props;
        const { curCustomer, typeAccount } = { ...accountInfo }
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)
        const { availableTrade } = this.state;

        const { crosstype: crossType, custodycd, accountId, firm, traderid, symbol, price, orderType, subOrderType, qtty, clearDay, matchprice, matchqtty, actionUserOrder } = _listReconfirmOrder;
        let symbolToDisplay = this.getSymbolToDisplay(currentSymbol);

        const quoteInfo = this.getQuoteInfo(symbol ? symbol : '');
        const { currentAccountId, accounts } = { ...accountQuickOrderInfo }
        let { minPrice, maxPrice } = this.renderMinMaxPrice();

        const instrument = currentSymbol && currentSymbol.id ? instruments[`${currentSymbol.id}`] || {} : {};
        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        let stepInputQuantity = currentSymbol && quote ? (instrument.EX == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        this.checkShowBlock();
        if (actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER) {

            return (
                <Fragment>
                    {/*<PlaceOrderPuthroughTrade
                    listReconfirmOrder={_listReconfirmOrder}
                    orderTypeUse={ORDER_TYPE_USE.PUTTHROUGH_TRADE_ORDER}
                    isConfirmMode={true}
                />*/}

                    <div className="row putthrough-trade-auth">

                        {/** Thông tin tài khoản */}
                        <div className={"row block-info-account"}>

                            <div className="col-12 title txt---500-14-20"><FormattedMessage id='putthrough-order.account-info' /></div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.buy-custodycd' /></div>
                                <div className="col-8 customer-input">
                                    <SelectorAccountCustomer
                                        curCustomer={curCustomer}
                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                        saveAccountInfo={true} // Lưu tài khoản với môi giới
                                    />
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.buy-accountid' /></div>
                                <div className="col-8">
                                    <div className={"custom-form-group"}>
                                        <SelectorAccountId
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                            accountInfo={accountInfo}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.order-type' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        <FormattedMessage id='putthrough-order.buy' />
                                    </div>
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.ppse0' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        {typeAccount === TYPE_ACCOUNT.FDS && <span>{availableTrade ? <FormattedAmount value={symbol ? availableTrade.ppse_short : availableTrade.pp_long} /> : '0'}</span>}
                                        {typeAccount === TYPE_ACCOUNT.BASE && <span>{availableTrade ? <FormattedAmount value={availableTrade.pp || 0} /> : 0}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/** Thông tin chứng khoán */}
                        <div className={"row block-info-stock"}>
                            <div className="col-12 title txt---500-14-20"><FormattedMessage id='putthrough-order.sec-info' /></div>

                            <div className="row input-item col-12 align-items-center clear-day-input">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.symbol-full' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        <input className="custom-form-control"
                                            value={symbol}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"><FormattedMessage id='putthrough-order.price' /></div>
                                <div className="col-8">
                                    <BlockInputPrice
                                        name="limit-price"
                                        ref={this.priceOrderRef}
                                        subOrderTypes={""} // Hay gây lỗi
                                        quote={this.props.quote ? this.props.quote : ""}
                                        step={10}
                                        min={minPrice}
                                        max={maxPrice}
                                        value={price}
                                        orderType={orderType}
                                        subOrderType={subOrderType}
                                        valid={true}
                                        validStep={true}
                                        onChange={this.onPriceChanged}
                                        onKeyDown={this.onInputKeyDown}
                                        onBlur={this.onBlurPrice}
                                        allowZero={false}
                                        allowDecimal={true}
                                        allowNegative={false}
                                        onFocus={this.onFocusPrice}
                                        decimalScale={2}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"><FormattedMessage id='putthrough-order.volumn' /></div>
                                <div className="col-8">
                                    <BlockInputQtty
                                        name="quantity"
                                        step={stepInputQuantity}
                                        min={null}
                                        max={999999999999}
                                        instrument={instrumentQtty}
                                        value={qtty}
                                        valid={true}
                                        onChange={this.onQuantityChanged}
                                        // onKeyDown={this.onInputKeyDown}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        orderType={orderType}
                                        ref={this.quantityRef}
                                        // onFocus={this.onFocusQtty}
                                        onBlur={this.onBlurQtty}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            {/*<div className="type-view present-order">
                                <div className="content content-left text-left">

                                </div>
                                <div className="content content-right item-center text---400-14-20">
                                    {listPercentOrder.map((item, index) => {
                                        return <div tabIndex="0" className={"select-present txt-400-14-20 "} >{item.title}</div>
                                    })}
                                </div>
                            </div>*/}

                            <div className="row input-item col-12 align-items-center clear-day-input">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.match-price' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        <FormattedPrice value={matchprice} />
                                    </div>
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center clear-day-input">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.match-volume' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        <FormattedAmount value={matchqtty} />
                                    </div>
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center clear-day-input">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.clear-day' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group-effect'>
                                        <input className="custom-form-control"
                                            type="number"
                                            onChange={this.onClearDayChange}
                                            value={clearDay}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>

                    <div className="text-center">
                        <FormattedMessage id='putthrough-confirm.cancel-confirm' />
                    </div>
                </Fragment>
            );
        }
    }

    renderConfirmPutthroughOrder = () => {
        const { listReconfirmOrder } = this.state
        const { quote, accountQuickOrderInfo, symbolWithIndex, currentSymbol, instruments, accountInfo } = this.props;
        const { curCustomer, typeAccount } = { ...accountInfo }
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)
        const { availableTrade } = this.state;

        const { crosstype: crossType, custodycd, accountId, firm, traderid, symbol, price, orderType, subOrderType, qtty, clearDay, actionUserOrder, volume } = _listReconfirmOrder;
        let symbolToDisplay = this.getSymbolToDisplay(currentSymbol);

        const quoteInfo = this.getQuoteInfo(symbol ? symbol : '');
        const { currentAccountId, accounts } = { ...accountQuickOrderInfo }
        let { minPrice, maxPrice } = this.renderMinMaxPrice();

        const instrument = currentSymbol && currentSymbol.id ? instruments[`${currentSymbol.id}`] || {} : {};
        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }

        let stepInputQuantity = currentSymbol && quote ? (instrument.EX == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        this.checkShowBlock();
        if (actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER) {

            return (
                <Fragment>

                    <div className="row putthrough-trade-auth">

                        {/** Thông tin tài khoản */}
                        <div className={"row block-info-account col-lg-6"}>

                            <div className="col-12 title txt---500-14-20"><FormattedMessage id='putthrough-order.account-info' /></div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.buy-custodycd' /></div>
                                <div className="col-8 customer-input">
                                    <SelectorAccountCustomer
                                        curCustomer={curCustomer}
                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                        saveAccountInfo={true} // Lưu tài khoản với môi giới
                                    />
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.buy-accountid' /></div>
                                <div className="col-8">
                                    <div className={"custom-form-group"}>
                                        <SelectorAccountId
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                            accountInfo={accountInfo}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.order-type' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        Mua
                                    </div>
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.ppse0' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        {typeAccount === TYPE_ACCOUNT.FDS && <span>{availableTrade ? <FormattedAmount value={symbol ? availableTrade.ppse_short : availableTrade.pp_long} /> : '0'}</span>}
                                        {typeAccount === TYPE_ACCOUNT.BASE && <span>{availableTrade ? <FormattedAmount value={availableTrade.pp || 0} /> : 0}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/** Thông tin chứng khoán */}
                        <div className={"row block-info-stock col-lg-6"}>
                            <div className="col-12 title txt---500-14-20"><FormattedMessage id='putthrough-order.sec-info' /></div>

                            <div className="row input-item col-12 align-items-center clear-day-input">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.symbol-full' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group'>
                                        <input className="custom-form-control"
                                            value={symbol}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"><FormattedMessage id='putthrough-order.price' /></div>
                                <div className="col-8">
                                    <BlockInputPrice
                                        name="limit-price"
                                        ref={this.priceOrderRef}
                                        subOrderTypes={""} // Hay gây lỗi
                                        quote={this.props.quote ? this.props.quote : ""}
                                        step={10}
                                        min={minPrice}
                                        max={maxPrice}
                                        value={price}
                                        orderType={orderType}
                                        subOrderType={subOrderType}
                                        valid={true}
                                        validStep={true}
                                        onChange={this.onPriceChanged}
                                        onKeyDown={this.onInputKeyDown}
                                        onBlur={this.onBlurPrice}
                                        allowZero={false}
                                        allowDecimal={true}
                                        allowNegative={false}
                                        onFocus={this.onFocusPrice}
                                        decimalScale={2}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="row input-item col-12 align-items-center">
                                <div className="col-4 txt---400-14-20 input-label"><FormattedMessage id='putthrough-order.volumn' /></div>
                                <div className="col-8">
                                    <BlockInputQtty
                                        name="quantity"
                                        step={stepInputQuantity}
                                        min={null}
                                        max={999999999999}
                                        instrument={instrumentQtty}
                                        value={volume}
                                        valid={true}
                                        onChange={this.onQuantityChanged}
                                        // onKeyDown={this.onInputKeyDown}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        orderType={orderType}
                                        ref={this.quantityRef}
                                        // onFocus={this.onFocusQtty}
                                        onBlur={this.onBlurQtty}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            {/*<div className="type-view present-order">
                                <div className="content content-left text-left">

                                </div>
                                <div className="content content-right item-center text---400-14-20">
                                    {listPercentOrder.map((item, index) => {
                                        return <div tabIndex="0" className={"select-present txt-400-14-20 "} >{item.title}</div>
                                    })}
                                </div>
                            </div>*/}

                            <div className="row input-item col-12 align-items-center clear-day-input">
                                <div className="col-4 txt---400-14-20 input-label"> <FormattedMessage id='putthrough-order.clear-day' /></div>
                                <div className="col-8">
                                    <div className='custom-form-group-effect'>
                                        <input className="custom-form-control"
                                            type="number"
                                            onChange={this.onClearDayChange}
                                            value={clearDay}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>

                    <div className="putthrough-trade-auth short-msg text-center">
                        <FormattedMessage id='putthrough-confirm.cancel-confirm-putthough-order' />
                    </div>
                </Fragment>
            );
        }
    }

    renderCancelPutthroughOrder = () => {
        const { listReconfirmOrder } = this.state;
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder);
        const { typecd } = _listReconfirmOrder;
        if (typecd === CONFIRM_CANCEL_PUTTHROUGH_CDTYPE.CONFIRM) {
            return (
                <Fragment>

                    <div className="putthrough-trade-auth short-msg   text-center">
                        <FormattedMessage id='putthrough-confirm.confirm-cancel-putthrough-order' />
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>

                    <div className="putthrough-trade-auth short-msg text-center">
                        <FormattedMessage id='putthrough-confirm.refuse-confirm-cancel-putthrough-order' />
                    </div>
                </Fragment>
            );
        }
    }


    getListAuthType = () => {
        const { permissionInfo } = this.propAttrs;
        const { userinfo } = this.props;
        let listAccPermission = [];

        if (userinfo && permissionInfo && permissionInfo.accounts[userinfo.custid]) {
            listAccPermission = permissionInfo.accounts[userinfo.custid];
        }

        return listAccPermission[modules.ORDINPUT] ? listAccPermission[modules.ORDINPUT] : [];
    };

    processTradeAuth = (authInfo) => {
        if (this.validateAuth()) {
            return new Promise(function (resolve, reject) {
                return resolve(authInfo);
            });
        } else {
            // xét ảnh hưởng trước khi dùng ,lệnh thỏa thuận noButton = false và không có autoCb (nhảy vào catch trong TradeAuth2)
            return new Promise(function (resolve, reject) {
                return reject('');
            });
        }
    };

    validateAuth() {
        const { accountInfo } = this.props;
        const { currentAccountId, typeAccount } = { ...accountInfo }
        const { listReconfirmOrder } = this.state;
        const { orderType, actionUserOrder } = { ...listReconfirmOrder }
        // Xác nhận lệnh thỏa thuận từ đối tác môi giới cần chọn tài khoản tiểu khoản bên mua
        if ((orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_NOTI || orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_ORDER) && actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER && !currentAccountId) {
            ToastUtil.error('FE004018');
            return false;
        }
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setLoadStatus(nextProps.loadStatusPutthroughModalTrade ? nextProps.loadStatusPutthroughModalTrade : this.state.loadStatus)
    }

    apiSeqAvailableTrade = new Date().getTime();
    debounceLoadAvailableTradeData = _.debounce(() => {
        this.apiSeqAvailableTrade = new Date().getTime();
        this.loadAvailableTradeData(this.apiSeqAvailableTrade);
    }, 100);

    debounceLoadAvailableTradeDataBySocket = _.debounce(() => {
        this.apiSeqAvailableTrade = new Date().getTime();
        this.loadAvailableTradeData(this.apiSeqAvailableTrade);
    }, 50);

    loadAvailableTradeData = (apiSeqAvailableTrade) => {
        const { accountInfo } = this.props;

        const { currentAccountId, typeAccount } = { ...accountInfo }


        const { listReconfirmOrder } = this.state;
        const { price, symbol } = { ...listReconfirmOrder }

        if (currentAccountId) {
            this._setState({
                availableTrade: null,
            });

            // let price =  // Giá đặt bên bán

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                if (symbol) {
                    fdsInquiryService.getAvailableTrade(currentAccountId, symbol ? symbol : '', price)
                        .then((data) => {
                            if(apiSeqAvailableTrade < this.apiSeqAvailableTrade) return;
                            this._setState({
                                availableTrade: data,
                                // loadStatus: LoadStatus.LOADED,
                            });
                        })
                        .catch((error) => {
                            this._setState({
                                // loadStatus: LoadStatus.NONE,
                            });
                            ToastUtil.errorApi(error, 'common.fail-to-load-available-trade');
                        });
                } else {
                    fdsInquiryService.getCustomerSummary(currentAccountId)
                        .then((data) => {
                            if(apiSeqAvailableTrade < this.apiSeqAvailableTrade) return;
                            this._setState({
                                availableTrade: data[0],
                                // loadStatus: LoadStatus.LOADED,
                            });
                        })
                        .catch((error) => {
                            this._setState({
                                // loadStatus: LoadStatus.NONE,
                            });
                            ToastUtil.errorApi(error, 'common.fail-to-load-available-trade');
                        });
                }
            } else {
                if (symbol) {
                    inquiryService.getAvailableTrade(currentAccountId, symbol ? symbol : '', price, orderActions.BUY)
                        .then((data) => {
                            if(apiSeqAvailableTrade < this.apiSeqAvailableTrade) return;
                            this._setState({
                                availableTrade: data,
                                // loadStatus: LoadStatus.LOADED,
                            });
                        })
                        .catch((error) => {
                            this._setState({
                                // loadStatus: LoadStatus.NONE,
                            });
                            ToastUtil.errorApi(error, 'common.fail-to-load-available-trade');
                        });
                }
            }

        } else {
            this._setState({
                availableTrade: null,
                // loadStatus: LoadStatus.NONE,
            });
        }
    };

    renderOrderConfirmView = () => {
        const { listReconfirmOrder } = this.state;
        const { orderType } = { ...listReconfirmOrder }

        if (orderType === PUTTHROUGH_ORDER_ACTION.PLACE_ORDER) {
            return this.renderNormalOrderTableOrder();
        } else if (orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_NOTI) {
            return this.renderNotiTableOrder();
        } else if (orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_ORDER) {
            return this.renderConfirmPutthroughOrder();
        } else if (orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_CANCEL_PUTTHROUGH_ORDER) {
            return this.renderCancelPutthroughOrder();
        }
        // if (typeSubmitOrder === TYPE_SUBMIT_ORDER.ORDER) {
        //     if (orderType === "limit") {
        //         return this.renderNormalOrderTable();
        //     } else if (orderType === "market") {
        //         return this.renderNormalOrderTable();
        //     } else if (orderType === "condition") {
        //         return this.renderConditionOrderTable();
        //     }
        // } else if (typeSubmitOrder === TYPE_SUBMIT_ORDER.DRAP_ORDER) {

        // } else if (typeSubmitOrder === TYPE_SUBMIT_ORDER.TPSL_ORDER) {
        //     return this.renderTPSLOrderTable();
        // }
    };

    render() {
        const {
            autoCb,
            transFunction
        } = this.propAttrs; // Chỉ dành cho các props không thay đổi giá trị
        // const { tradeAuthenticated } = this.props;
        const genAuthFunc = tradeApiService.resendTwoFactorCode;    // gen lại mã xác thực ở tradeApi
        const twoFactorAuthFunc = this.processTradeAuth;
        const initAuthFunc = tradeApiService.initTwoFactorAuth;
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} className="trade-auth-putthrough-order">
                <form onSubmit={e => { e.preventDefault(); }}>
                    {/*this.renderNormalOrderTableOrder()*/}
                    {this.renderOrderConfirmView()}
                    <TradeAuth2
                        containerClass="row gutters-5 align-items-center auth-container"
                        labelClass="col-5"
                        inputClass="col-7 custom-form-group"
                        inputClassAuth="col-7 custom-form-group-effect"
                        btnContainerClass=""
                        onFinish={this.onFinish}

                        initAuthFunc={initAuthFunc}

                        genAuthFunc={genAuthFunc}

                        twoFactorAuthFunc={twoFactorAuthFunc}

                        twoFactorAuthCb={this.twoFactorAuthCb}

                        setLoadStatus={status => this.setLoadStatus(status)}

                        autoCb={autoCb}

                        isDisableConfirmBtn={this.state.loadStatus == LoadStatus.LOADING ? true : false}
                        showOrderSwitch={true}
                        autoFocus={true}
                        fillPassword={true}

                        // tradeOrderInputMsg={this.props.tradeOrderInputMsg}

                        currentReconfirmOrderType={this.propAttrs.currentReconfirmOrderType}
                        isOrder={true}
                        noteMessageClass="txt---400-14-20 text-not-active font-italic"
                    />

                </form>
            </RetryableContent >
        );
    }
}

const mapStateToProps = state => {
    const userinfo = state.user.userInfo;
    const permissionInfo = userinfo.permissionInfo ? userinfo.permissionInfo : null;

    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive();
    let _placePutthroughOrder = state.orderNew.placePutthroughOrder
    let currentSymbol = _currentLayoutPageActive && _placePutthroughOrder[_currentLayoutPageActive] && _placePutthroughOrder[_currentLayoutPageActive]["symbolCurrent"]

    let currentReconfirmOrderType = 'ORDER' // hardCode truyền
    let listIsOpenModal = state.layout.listIsOpenModal["C&B"];

    // Dữ liệu đặt lệnh thỏa thuận
    // let _placePutthroughOrder = state.orderNew.placePutthroughOrder
    // currentSymbol = _currentLayoutPageActive && _placePutthroughOrder[_currentLayoutPageActive] && _placePutthroughOrder[_currentLayoutPageActive]["symbolCurrent"]
    let quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]];
    // _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"];

    // _placePutthroughOrderCurrent = _currentLayoutPageActive && _placePutthroughOrder[_currentLayoutPageActive]
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"];
    return {
        loadStatusPutthroughModalTrade: listIsOpenModal["Putthrough"].loadStatusPutthroughModalTrade,

        permissionInfo: permissionInfo,
        userinfo: userinfo,
        custodycd: userinfo.custodycd,
        tradeAuthenticated: state.app.tradeAuthenticated,
        // tradeResponseStatus: state.app.tradeResponseStatus,
        isSaveTradeAuthenticated: listIsOpenModal.isSaveTradeAuthenticated,

        // currentReconfirmOrderType: state.app.currentReconfirmOrderType,
        currentReconfirmOrderType: currentReconfirmOrderType,
        language: state.app.language,
        currentCustomer: state.customer.currentCustomer,

        accounts: state.account.accounts,
        role: state.user.userInfo.role,
        symbolWithIndex: state.symbol.allQuotesWithIndex,
        currentSymbol: currentSymbol,
        instruments: state.symbol.instruments,
        quote: quote,
        accountInfo: _accountInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        triggerTradePutthroughOrderAuthenCallback: (data) => dispatch(actions.triggerTradePutthroughOrderAuthenCallback(data)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TradeAuthPutthrough));
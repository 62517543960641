import React, { Component } from "react";

import DraggableModal from '../../../components/Modal/DraggableModal';

import ScreenConfirm from "./ScreenConfirm";
import TranAuthScreenConfirm from "./TranAuthScreenConfirm";
import { connect } from 'react-redux';
import './TranAuthScreenConfirmModal.scss';

class TranAuthScreenConfirmModal extends Component {

    combineFn = () => {
        const { onClose, infoConfirmAuth } = this.props;
        try {
            const { dataInfo } = infoConfirmAuth
            // useBtnCloseExt -----> thực hiện action và đóng modal
            const { useBtnCloseExt, handleClose } = dataInfo
            if (useBtnCloseExt === true) {
                handleClose()
                onClose()
            }
        } catch (error) {
            onClose()
        }
    }

    render() {
        const { isOpen, toggle, onClose, isCheckAuth2, infoConfirmAuth, authtype, listAuthType } = this.props;
        const { typeInfo, dataInfo } = infoConfirmAuth
        const { title, useBtnClose, handleClose, useBtnCloseExt } = dataInfo
        let _className = 'tran-auth-screen-confirm-modal'
        let titleId = title || ''

        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={useBtnClose ? handleClose : useBtnCloseExt === true ? this.combineFn : onClose}
                className={_className}
                titleId={titleId}
            >
                <div className="body">
                    {listAuthType && listAuthType.length > 0 && isCheckAuth2 ? (
                        <TranAuthScreenConfirm
                            typeInfo={typeInfo || ""}
                            dataInfo={dataInfo}
                            toggle={toggle}
                            onClose={onClose}
                        />
                    ) :
                        (
                            <ScreenConfirm
                                typeInfo={typeInfo || ""}
                                dataInfo={dataInfo}
                                toggle={toggle}
                                onClose={onClose}
                            />
                        )
                    }
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    let _infoAuth = state.checkAuth.infoAuth["C&B"]
    let _authKey = _infoAuth.authKey
    let _listAuthType = _infoAuth[_authKey].listAuthType
    let _authtype = _infoAuth[_authKey].authtype
    return {
        authtype: _authtype,
        listAuthType: _listAuthType,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TranAuthScreenConfirmModal);
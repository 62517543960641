// Haki_lt.: Khai bĂ¡o layout Desktop Default [START]
import config from '../../combineConfig';
const staticFalse = config.switch.isWidgetStatic ? true : false;

// STRPROP = C#CS#PRICEBOARD#CB : KhĂ¡ch hĂ ng>CÆ¡ sá»Ÿ>Báº£ng giĂ¡>CÆ¡ báº£n
export const C_CS_PRICEBOARD_CB = {
    "id": "C#CS#PRICEBOARD#CB",
    "grid": {
        "lg": [
            {
                "w": 12,
                "h": 2,
                "x": 0,
                "y": 10,
                "i": "PRICEBOARD",
                "minW": 8,
                "minH": 4,
                "moved": false,
                "static": staticFalse
            },
        ],
        "md": [
            {
                "w": 16,
                "h": 8,
                "x": 0,
                "y": 0,
                "i": "PRICEBOARD",
                "minW": 8,
                "minH": 4,
                "moved": false,
                "static": staticFalse
            },

        ],
        "sm": [
            {
                "w": 10,
                "h": 8,
                "x": 0,
                "y": 0,
                "i": "PRICEBOARD",
                "minW": 8,
                "minH": 4,
                "moved": false,
                "static": staticFalse
            },

        ]
    },
    "visibleSectionIds": [
        // "indexsList",
        // "topIndexes",
        "PRICEBOARD",
        // "derivativeBoard",
        // "coveredWarrantBoard",
        // "bondBoard",
        // "etfBoard",
    ],
    "sourceLayoutId": "C#CS#PRICEBOARD#CB",
    "mergeSections": {
        "PRICEBOARD": [
            // "derivativeBoard",
            // "coveredWarrantBoard",
            // "etfBoard",
            // "bondBoard",
        ]
    },
    //Haki_lt.: Khai floatLayout dáº¡ng Popup
    "floatLayout": [
        // {
        //     "w": 12,
        //     "h": 20,
        //     "x": 0,
        //     "y": 8,
        //     "i": "FilterDetail",
        //     "minW": 6,
        //     "minH": 2,
        //     "moved": false,
        //     "static": staticFalse
        // },
    ]
};

// STRPROP = C#CS#TRADE#CB : KhĂ¡ch hĂ ng>CÆ¡ sá»Ÿ>Äáº·t lá»‡nh>CÆ¡ báº£n

export const C_CS_TRADE_CB = {
    "id": "C#CS#TRADE#CB",
    "grid": {
        "lg": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 21,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        // "PRICEBOARD",
        // "ordersList",
        // "placeOrder",
        // "openPositions",
        // "brokerOrders",
        // "lookupOrders",
        // "lookupOrdersNew"
        'placeOrderNormal',
        // 'priceChart',
        'depthChart',
        'matchedOrder',
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'chartMatchedInfo',
        'conditionOrderBook',
        // 'draftOrderBook',
        // 'brokerOrderBook',
        // 'infoSymbol', // mặc định last
    ],
    "sourceLayoutId": "C#CS#TRADE#CB",
    "mergeSections": {
        // "infoSymbol": [],
        // "placeOrderNormal": [],
        // "priceChart": [
        //     "depthChart",
        //     "matchedOrder"
        // ],
        "matchedOrder": [
            "chartMatchedInfo"
        ],
        "openPositions": [
            "normalOrderBook",
            "orderActive",
            "conditionOrderBook",
            // "draftOrderBook",
            // "brokerOrderBook",
        ]

        // "PRICEBOARD": ["openPositions"],
        // "ordersList": [
        //     "brokerOrders",
        //     // "openPositions",
        //     "lookupOrders",
        //     "lookupOrdersNew"
        // ]
    },
    "floatLayout": []
};

export const B_CS_TRADE_TT = {
    "id": "B#CS#TRADE#TT",
    "grid": {
        "lg": [
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 0,
                "i": "putthroughtOrder",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 12,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": true,
            }
        ],
        "md": [
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 0,
                "i": "putthroughtOrder",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 12,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": true,
            }
        ],
        "sm": [
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 0,
                "i": "putthroughtOrder",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 12,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": true,
            }
        ]
    },
    "visibleSectionIds": [
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'putthroughtOrder',
        'putthroughConfirmOrder',
        'putthroughConfirmCancelOrder',
        'putthroughOrderNoti',
        // 'advertisingOrder'
    ],
    "sourceLayoutId": "B#CS#TRADE#TT",
    "mergeSections": {
        "putthroughtOrder": [
            "putthroughConfirmOrder",
            "putthroughConfirmCancelOrder",
            "putthroughOrderNoti",
            // 'advertisingOrder'
        ],
        "openPositions": [
            "orderActive",
            "normalOrderBook",
            // "conditionOrderBook",
        ]
    },
    "floatLayout": []
};

export const C_CS_TRADE_CB_bk = {
    "id": "C#CS#TRADE#CB",
    "grid": {
        "lg": [
            {
                // "w": 8,
                // "h": 4,
                // "x": 0,
                // "y": 10,
                // "i": "PRICEBOARD",
                // "minW": 8,
                // "minH": 4,
                // "moved": false,
                // "static": staticFalse

                "w": 16,
                "h": 10,
                "x": 0,
                "y": 0,
                "i": "PRICEBOARD",
                "minW": 3,
                "minH": 4,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 13.2,
                "h": 16,
                "x": 3.2,
                "y": 10,
                "i": "ordersList",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            }],
        "md": [
            {
                "w": 16,
                "h": 8,
                "x": 0,
                "y": 0,
                "i": "PRICEBOARD",
                "minW": 8,
                "minH": 4,
                "moved": false,
                "static": staticFalse
            },

        ],
        "sm": [
            {
                "w": 10,
                "h": 8,
                "x": 0,
                "y": 0,
                "i": "PRICEBOARD",
                "minW": 8,
                "minH": 4,
                "moved": false,
                "static": staticFalse
            },

        ]
    },
    "visibleSectionIds": [
        // "indexsList",
        // "topIndexes",
        "PRICEBOARD",
        // "derivativeBoard",
        // "coveredWarrantBoard",
        // "bondBoard",
        // "etfBoard",
        "ordersList"
    ],
    "sourceLayoutId": "C#CS#TRADE#CB",
    "mergeSections": {
        "PRICEBOARD": [
            // "derivativeBoard",
            // "coveredWarrantBoard",
            // "etfBoard",
            // "bondBoard",
        ]
    },
    //Haki_lt.: Khai floatLayout dáº¡ng Popup
    "floatLayout": [
        // {
        //     "w": 12,
        //     "h": 20,
        //     "x": 0,
        //     "y": 8,
        //     "i": "FilterDetail",
        //     "minW": 6,
        //     "minH": 2,
        //     "moved": false,
        //     "static": staticFalse
        // },
    ]
};

// STRPROP = C#CS#TRADE#NC : KhĂ¡ch hĂ ng>CÆ¡ sá»Ÿ>Äáº·t lá»‡nh>NĂ¢ng cao
export const C_CS_TRADE_NC = {
    "id": "C#CS#TRADE#NC",
    "grid": {
        "lg": [
            {
                "w": 27,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "infoSymbol",
                "minW": 5,
                "minH": 3,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 14,
                "x": 27,
                "y": 0,
                "i": "infoPortfolioFavorites",
                "minW": 6,
                "minH": 6,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 23,
                "x": 0,
                "y": 3,
                "i": "recentTrades",
                "minW": 7,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 11,
                "x": 7,
                "y": 3,
                "i": "priceChart",
                "minW": 10,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 12,
                "x": 7,
                "y": 14,
                "i": "placeOrderPro",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 12,
                "x": 28,
                "y": 14,
                "i": "depthChart",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 34,
                "h": 10,
                "x": 0,
                "y": 26,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
        ],
        "md": [
            {
                "w": 27,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "infoSymbol",
                "minW": 5,
                "minH": 3,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 14,
                "x": 27,
                "y": 0,
                "i": "infoPortfolioFavorites",
                "minW": 6,
                "minH": 6,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 23,
                "x": 0,
                "y": 3,
                "i": "recentTrades",
                "minW": 7,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 11,
                "x": 7,
                "y": 3,
                "i": "priceChart",
                "minW": 10,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 12,
                "x": 7,
                "y": 14,
                "i": "placeOrderPro",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 12,
                "x": 28,
                "y": 14,
                "i": "depthChart",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 34,
                "h": 10,
                "x": 0,
                "y": 26,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
        ],
        "sm": [
            {
                "w": 27,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "infoSymbol",
                "minW": 5,
                "minH": 3,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 14,
                "x": 27,
                "y": 0,
                "i": "infoPortfolioFavorites",
                "minW": 6,
                "minH": 6,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 23,
                "x": 0,
                "y": 3,
                "i": "recentTrades",
                "minW": 7,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 11,
                "x": 7,
                "y": 3,
                "i": "priceChart",
                "minW": 10,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 12,
                "x": 7,
                "y": 14,
                "i": "placeOrderPro",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 12,
                "x": 28,
                "y": 14,
                "i": "depthChart",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 34,
                "h": 10,
                "x": 0,
                "y": 26,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
        ]
    },
    "visibleSectionIds": [
        'infoPortfolioFavorites',
        'recentTrades',
        'priceChart',

        'depthChart',
        'openPositions',
        'placeOrderPro',
        'orderActive',
        'normalOrderBook',
        'conditionOrderBook',
        'draftOrderBook',
        'infoSymbol', // mặc định last
        // dưới k dùng
        // 'mixedOrderbook',
        // 'orderMatched',
    ],
    "sourceLayoutId": "C#CS#TRADE#NC",
    "mergeSections": {
        "infoPortfolioFavorites": [],
        "recentTrades": [],
        "priceChart": [],
        "depthChart": [],
        "placeOrderPro": [],
        "infoSymbol": [],
        "openPositions": [
            "orderActive",
            "normalOrderBook",
            "conditionOrderBook",
            "draftOrderBook",
        ]
    },
    //Haki_lt.: Khai floatLayout dáº¡ng Popup
    "floatLayout": [
        // {
        //     "w": 12,
        //     "h": 20,
        //     "x": 0,
        //     "y": 8,
        //     "i": "FilterDetail",
        //     "minW": 6,
        //     "minH": 2,
        //     "moved": false,
        //     "static": staticFalse
        // },
    ]
};

// STRPROP = B#CS#PRICEBOARD#CB : MĂ´i giá»›i>CÆ¡ sá»Ÿ>Báº£ng giĂ¡>CÆ¡ báº£n
export const B_CS_PRICEBOARD_CB = {
}

// STRPROP = B#CS#TRADE#CB : MĂ´i giá»›i>CÆ¡ sá»Ÿ>Äáº·t lá»‡nh>CÆ¡ báº£n
export const B_CS_TRADE_CB = {
    "id": "B#CS#TRADE#CB",
    "grid": {
        "lg": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        // "PRICEBOARD",
        // "ordersList",
        // "placeOrder",
        // "openPositions",
        // "brokerOrders",
        // "lookupOrders",
        // "lookupOrdersNew"
        'placeOrderNormal',
        // 'priceChart',
        'depthChart',
        'matchedOrder',
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'chartMatchedInfo',
        // 'conditionOrderBook',
        // 'brokerOrderBook',
        // 'draftOrderBook',
        // 'infoSymbol', // mặc định last
    ],
    "sourceLayoutId": "B#CS#TRADE#CB",
    "mergeSections": {
        // "placeOrderNormal": [],
        // "placeOrderNormal": [],
        // "infoSymbol": [],
        "matchedOrder": [
            "chartMatchedInfo"
        ],
        // "priceChart": [
        //     "depthChart",
        //     "matchedOrder"
        // ],
        "openPositions": [
            "normalOrderBook",
            "orderActive",
            // 'conditionOrderBook',
            // "brokerOrderBook",
            // "draftOrderBook",
        ]

        // "PRICEBOARD": ["openPositions"],
        // "ordersList": [
        //     "brokerOrders",
        //     // "openPositions",
        //     "lookupOrders",
        //     "lookupOrdersNew"
        // ]
    },
    "floatLayout": []
};

// STRPROP = B#CS#TRADE#NC : MĂ´i giá»›i>CÆ¡ sá»Ÿ>Äáº·t lá»‡nh>NĂ¢ng cao
export const B_CS_TRADE_NC = {
    "id": "B#CS#TRADE#NC",
    "grid": {
        "lg": [
            {
                "w": 27,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "infoSymbol",
                "minW": 5,
                "minH": 3,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 14,
                "x": 27,
                "y": 0,
                "i": "infoPortfolioFavorites",
                "minW": 6,
                "minH": 6,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 23,
                "x": 0,
                "y": 3,
                "i": "recentTrades",
                "minW": 7,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 11,
                "x": 7,
                "y": 3,
                "i": "priceChart",
                "minW": 10,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 12,
                "x": 7,
                "y": 14,
                "i": "placeOrderPro",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 12,
                "x": 28,
                "y": 14,
                "i": "depthChart",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 34,
                "h": 10,
                "x": 0,
                "y": 26,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
        ],
        "md": [
            {
                "w": 27,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "infoSymbol",
                "minW": 5,
                "minH": 3,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 14,
                "x": 27,
                "y": 0,
                "i": "infoPortfolioFavorites",
                "minW": 6,
                "minH": 6,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 23,
                "x": 0,
                "y": 3,
                "i": "recentTrades",
                "minW": 7,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 11,
                "x": 7,
                "y": 3,
                "i": "priceChart",
                "minW": 10,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 12,
                "x": 7,
                "y": 14,
                "i": "placeOrderPro",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 12,
                "x": 28,
                "y": 14,
                "i": "depthChart",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 34,
                "h": 10,
                "x": 0,
                "y": 26,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
        ],
        "sm": [
            {
                "w": 27,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "infoSymbol",
                "minW": 5,
                "minH": 3,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 14,
                "x": 27,
                "y": 0,
                "i": "infoPortfolioFavorites",
                "minW": 6,
                "minH": 6,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 23,
                "x": 0,
                "y": 3,
                "i": "recentTrades",
                "minW": 7,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 11,
                "x": 7,
                "y": 3,
                "i": "priceChart",
                "minW": 10,
                "minH": 10,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 20,
                "h": 12,
                "x": 7,
                "y": 14,
                "i": "placeOrderPro",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 7,
                "h": 12,
                "x": 28,
                "y": 14,
                "i": "depthChart",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 34,
                "h": 10,
                "x": 0,
                "y": 26,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse
            },
        ]
    },
    "visibleSectionIds": [
        'infoPortfolioFavorites',
        'recentTrades',
        'priceChart',

        'depthChart',
        'openPositions',
        'placeOrderPro',
        'orderActive',
        'normalOrderBook',
        'conditionOrderBook',
        'brokerOrderBook',
        'draftOrderBook',
        'infoSymbol', // mặc định last
        // dưới k dùng
        // 'mixedOrderbook',
        // 'orderMatched',
    ],
    "sourceLayoutId": "B#CS#TRADE#NC",
    "mergeSections": {
        "infoPortfolioFavorites": [],
        "recentTrades": [],
        "priceChart": [],
        "depthChart": [],
        "placeOrderPro": [],
        "infoSymbol": [],
        "openPositions": [
            "orderActive",
            "normalOrderBook",
            'conditionOrderBook',
            "brokerOrderBook",
            "draftOrderBook",
        ]
    },
    //Haki_lt.: Khai floatLayout dáº¡ng Popup
    "floatLayout": [
        // {
        //     "w": 12,
        //     "h": 20,
        //     "x": 0,
        //     "y": 8,
        //     "i": "FilterDetail",
        //     "minW": 6,
        //     "minH": 2,
        //     "moved": false,
        //     "static": staticFalse
        // },
    ]
};


export const C_CS_TRADE_TPRL_NM = {
    "id": "C#CS#TRADE#TPRL_NM",
    "grid": {
        "lg": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        'placeOrderNormal',
        'depthChart',
        'matchedOrder',
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'chartMatchedInfo',
    ],
    "sourceLayoutId": "C#CS#TRADE#TPRL_NM",
    "mergeSections": {
        "matchedOrder": [
            "chartMatchedInfo"
        ],
        "openPositions": [
            "normalOrderBook",
            "orderActive",
            "conditionOrderBook",
        ]
    },
    "floatLayout": []
};

export const B_CS_TRADE_TPRL_NM = {
    "id": "B#CS#TRADE#TPRL_NM",
    "grid": {
        "lg": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        'placeOrderNormal',
        'depthChart',
        'matchedOrder',
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'chartMatchedInfo',
    ],
    "sourceLayoutId": "B#CS#TRADE#TPRL_NM",
    "mergeSections": {
        "matchedOrder": [
            "chartMatchedInfo"
        ],
        "openPositions": [
            "normalOrderBook",
            "orderActive",
        ]
    },
    "floatLayout": []
};


export const C_CS_TRADE_TPRL_OR = {
    "id": "C#CS#TRADE#TPRL_OR",
    "grid": {
        "lg": [
            {
                "w": 11,
                "h": 16,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 16,
                "x": 11,
                "y": 0,
                "i": "bondDetailTPRL",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 16,
                "x": 22,
                "y": 0,
                "i": "transactionDetailTPRL",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 16,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            {
                "w": 11,
                "h": 16,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 16,
                "x": 11,
                "y": 0,
                "i": "bondDetailTPRL",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 16,
                "x": 22,
                "y": 0,
                "i": "transactionDetailTPRL",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 16,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            {
                "w": 11,
                "h": 16,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 16,
                "x": 11,
                "y": 0,
                "i": "bondDetailTPRL",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 16,
                "x": 22,
                "y": 0,
                "i": "transactionDetailTPRL",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 16,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        'placeOrderNormal',
        'bondDetailTPRL',
        'transactionDetailTPRL',
        'openPositions',
        'orderBookTPRL',
    ],
    "sourceLayoutId": "C#CS#TRADE#TPRL_OR",
    "mergeSections": {
        "openPositions": [
            "orderBookTPRL",
        ]
    },
    "floatLayout": []
};

export const B_CS_TRADE_TPRL_OR = {
    "id": "B#CS#TRADE#TPRL_OR",
    "grid": {
        "lg": [
            {
                "w": 11,
                "h": 16,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 16,
                "x": 11,
                "y": 0,
                "i": "bondDetailTPRL",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 16,
                "x": 22,
                "y": 0,
                "i": "transactionDetailTPRL",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 16,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 16,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 16,
                "x": 11,
                "y": 0,
                "i": "bondDetailTPRL",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 16,
                "x": 22,
                "y": 0,
                "i": "transactionDetailTPRL",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 16,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 16,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 16,
                "x": 11,
                "y": 0,
                "i": "bondDetailTPRL",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 16,
                "x": 22,
                "y": 0,
                "i": "transactionDetailTPRL",
                "minW": 2,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 16,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        'placeOrderNormal',
        'bondDetailTPRL',
        'transactionDetailTPRL',
        'openPositions',
        'orderBookTPRL',
    ],
    "sourceLayoutId": "B#CS#TRADE#TPRL_OR",
    "mergeSections": {
        "openPositions": [
            "orderBookTPRL",
        ]
    },
    "floatLayout": []
};

export const C_CS_TRADE_TPRL_RP = {
    "id": "C#CS#TRADE#TPRL_RP",
    "grid": {
        "lg": [
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 13,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 13,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 13,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 12,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 13,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        'placeOrderNormal',
        'depthChart',
        'matchedOrder',
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'chartMatchedInfo',
    ],
    "sourceLayoutId": "C#CS#TRADE#TPRL_RP",
    "mergeSections": {
        "matchedOrder": [
            "chartMatchedInfo"
        ],
        "openPositions": [
            "normalOrderBook",
            "orderActive",
            "conditionOrderBook",
        ]
    },
    "floatLayout": []
};

export const B_CS_TRADE_TPRL_RP = {
    "id": "B#CS#TRADE#TPRL_RP",
    "grid": {
        "lg": [
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "md": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 3,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ],
        "sm": [
            // {
            //     "w": 24,
            //     "h": 3,
            //     "x": 0,
            //     "y": 0,
            //     "i": "infoSymbol",
            //     "minW": 5,
            //     "minH": 3,
            //     "moved": false,
            //     "static": staticFalse,
            // },
            {
                "w": 11,
                "h": 15,
                "x": 0,
                "y": 0,
                "i": "placeOrderNormal",
                "minW": 9,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 11,
                "h": 15,
                "x": 11,
                "y": 0,
                "i": "depthChart",
                "minW": 10,
                "minH": 15,
                "moved": false,
                "static": staticFalse
            },
            {
                "w": 12,
                "h": 15,
                "x": 22,
                "y": 0,
                "i": "matchedOrder",
                "minW": 2,
                "minH": 15,
                "moved": false,
                "static": staticFalse,
            },
            {
                "w": 34,
                "h": 12,
                "x": 0,
                "y": 15,
                "i": "openPositions",
                "minW": 2,
                "minH": 2,
                "moved": false,
                "static": staticFalse,
            }
        ]
    },
    "visibleSectionIds": [
        'placeOrderNormal',
        'depthChart',
        'matchedOrder',
        'openPositions',
        'orderActive',
        'normalOrderBook',
        'chartMatchedInfo',
    ],
    "sourceLayoutId": "B#CS#TRADE#TPRL_RP",
    "mergeSections": {
        "matchedOrder": [
            "chartMatchedInfo"
        ],
        "openPositions": [
            "normalOrderBook",
            "orderActive",
        ]
    },
    "floatLayout": []
};


export const C_CS_MARKET_WATCH = {
    "id": "C#CS#MARKET_WATCH",
    "grid": {
        "lg": [
            {
                "w": 34,
                "h": 24,
                "x": 0,
                "y": 0,
                "i": "listStatusMarketIndex",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            // {
            //     "w": 17,
            //     "h": 12,
            //     "x": 17,
            //     "y": 0,
            //     "i": "recommendedList",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
            // {
            //     "w": 34,
            //     "h": 12,
            //     "x": 0,
            //     "y": 12,
            //     "i": "liquidityChart",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
        ],
        "md": [
            {
                "w": 34,
                "h": 24,
                "x": 0,
                "y": 0,
                "i": "listStatusMarketIndex",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            // {
            //     "w": 17,
            //     "h": 12,
            //     "x": 17,
            //     "y": 0,
            //     "i": "recommendedList",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
            // {
            //     "w": 34,
            //     "h": 12,
            //     "x": 0,
            //     "y": 12,
            //     "i": "liquidityChart",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
        ],
        "sm": [
            {
                "w": 34,
                "h": 24,
                "x": 0,
                "y": 0,
                "i": "listStatusMarketIndex",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            // {
            //     "w": 17,
            //     "h": 12,
            //     "x": 17,
            //     "y": 0,
            //     "i": "recommendedList",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
            // {
            //     "w": 34,
            //     "h": 12,
            //     "x": 0,
            //     "y": 12,
            //     "i": "liquidityChart",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
        ]
    },
    "visibleSectionIds": [
        'listStatusMarketIndex',
        'topStockUp',
        'topStockDown',
        'mutationLiquidity',
        // 'recommendedList',
        // 'liquidityChart',
        // 'distributionStockCapitalization',
        // 'foreignBlockChart',
        // 'newsMarket',
        // 'analysisMarket'
    ],
    "sourceLayoutId": "MARKET_WATCH",
    "mergeSections": {
        "listStatusMarketIndex": [
            "topStockUp",
            "topStockDown",
            "mutationLiquidity"
        ],
        // "recommendedList": [
        //     "newsMarket",
        //     "analysisMarket"
        // ],
        // "liquidityChart": [
        //     "distributionStockCapitalization",
        //     "foreignBlockChart",
        // ]
    },
    "floatLayout": []
};

export const B_CS_MARKET_WATCH = {
    "id": "B#CS#MARKET_WATCH",
    "grid": {
        "lg": [
            {
                "w": 34,
                "h": 24,
                "x": 0,
                "y": 0,
                "i": "listStatusMarketIndex",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            // {
            //     "w": 17,
            //     "h": 12,
            //     "x": 17,
            //     "y": 0,
            //     "i": "recommendedList",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
            // {
            //     "w": 34,
            //     "h": 12,
            //     "x": 0,
            //     "y": 12,
            //     "i": "liquidityChart",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
        ],
        "md": [
            {
                "w": 34,
                "h": 24,
                "x": 0,
                "y": 0,
                "i": "listStatusMarketIndex",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            // {
            //     "w": 17,
            //     "h": 12,
            //     "x": 17,
            //     "y": 0,
            //     "i": "recommendedList",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
            // {
            //     "w": 34,
            //     "h": 12,
            //     "x": 0,
            //     "y": 12,
            //     "i": "liquidityChart",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
        ],
        "sm": [
            {
                "w": 34,
                "h": 24,
                "x": 0,
                "y": 0,
                "i": "listStatusMarketIndex",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            // {
            //     "w": 17,
            //     "h": 12,
            //     "x": 17,
            //     "y": 0,
            //     "i": "recommendedList",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
            // {
            //     "w": 34,
            //     "h": 12,
            //     "x": 0,
            //     "y": 12,
            //     "i": "liquidityChart",
            //     "minW": 6,
            //     "minH": 2,
            //     "moved": false,
            //     "static": true
            // },
        ]
    },
    "visibleSectionIds": [
        'listStatusMarketIndex',
        'topStockUp',
        'topStockDown',
        'mutationLiquidity',
        //'listTranslog'
        // 'recommendedList',
        // 'liquidityChart',
        // 'distributionStockCapitalization',
        // 'foreignBlockChart',
        // 'newsMarket',
        // 'analysisMarket'
    ],
    "sourceLayoutId": "MARKET_WATCH",
    "mergeSections": {
        "listStatusMarketIndex": [
            "topStockUp",
            "topStockDown",
            "mutationLiquidity",
            //'listTranslog'
        ],
        // "recommendedList": [
        //     "newsMarket",
        //     "analysisMarket"
        // ],
        // "liquidityChart": [
        //     "distributionStockCapitalization",
        //     "foreignBlockChart",
        // ]
    },
    "floatLayout": []
};


export const C_CS_AI_TRADING = {
    "id": "C#CS#AI_TRADING",
    "grid": {
        "lg": [
            {
                "w": 15,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "marketStatus",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 13,
                "x": 15,
                "y": 0,
                "i": "marketSignal",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 13,
                "i": "ratingSector",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 13,
                "i": "ratingStock",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
        ],
        "md": [
            {
                "w": 15,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "marketStatus",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 13,
                "x": 15,
                "y": 0,
                "i": "marketSignal",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 15,
                "h": 12,
                "x": 0,
                "y": 13,
                "i": "ratingSector",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 12,
                "x": 15,
                "y": 13,
                "i": "ratingStock",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
        ],
        "sm": [
            {
                "w": 15,
                "h": 13,
                "x": 0,
                "y": 0,
                "i": "marketStatus",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 13,
                "x": 15,
                "y": 0,
                "i": "marketSignal",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 15,
                "h": 12,
                "x": 0,
                "y": 13,
                "i": "ratingSector",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 12,
                "x": 15,
                "y": 13,
                "i": "ratingStock",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
        ]
    },
    "visibleSectionIds": [
        'marketStatus',
        'marketSignal',
        'ratingSector',
        'ratingStock',
    ],
    "sourceLayoutId": "AI_TRADING",
    "mergeSections": {
        "marketStatus": [],
        "marketSignal": [],
        "ratingSector": [],
        "ratingStock": []
    },
    "floatLayout": []
};

export const B_CS_AI_TRADING = {
    "id": "B#CS#AI_TRADING",
    "grid": {
        "lg": [
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 0,
                "i": "marketStatus",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 0,
                "i": "marketSignal",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 14,
                "i": "ratingSector",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 14,
                "i": "ratingStock",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
        ],
        "md": [
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 0,
                "i": "marketStatus",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 0,
                "i": "marketSignal",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 14,
                "i": "ratingSector",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 14,
                "i": "ratingStock",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
        ],
        "sm": [
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 0,
                "i": "marketStatus",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 0,
                "i": "marketSignal",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 15,
                "h": 14,
                "x": 0,
                "y": 14,
                "i": "ratingSector",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
            {
                "w": 19,
                "h": 14,
                "x": 15,
                "y": 14,
                "i": "ratingStock",
                "minW": 6,
                "minH": 2,
                "moved": false,
                "static": true
            },
        ]
    },
    "visibleSectionIds": [
        'marketStatus',
        'marketSignal',
        'ratingSector',
        'ratingStock',
    ],
    "sourceLayoutId": "AI_TRADING",
    "mergeSections": {
        "marketStatus": [],
        "marketSignal": [],
        "ratingSector": [],
        "ratingStock": []
    },
};


// Config mặc định dành cho tất cả các widget.: Sử dụng trong trường hợp unmerge hoặc thêm mới
export const DefaultSession = {
    "lg": [
        {
            "w": 25,
            "h": 4,
            "x": 0,
            "y": 0,
            "i": "infoSymbol",
            "minW": 5,
            "minH": 3,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 9,
            "h": 14,
            "x": 25,
            "y": 0,
            "i": "placeOrderNormal",
            "minW": 9,
            "minH": 14,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 20,
            "h": 12,
            "x": 7,
            "y": 14,
            "i": "placeOrderPro",
            "minW": 10,
            "minH": 12,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 8,
            "h": 14,
            "x": 0,
            "y": 4,
            "i": "infoPortfolioFavorites",
            "minW": 6,
            "minH": 6,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 17,
            "h": 14,
            "x": 8,
            "y": 4,
            "i": "priceChart",
            "minW": 10,
            "minH": 10,
            "moved": false,
            "static": staticFalse
        },
    ],
    "md": [
        {
            "w": 25,
            "h": 4,
            "x": 0,
            "y": 0,
            "i": "infoSymbol",
            "minW": 5,
            "minH": 3,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 9,
            "h": 14,
            "x": 25,
            "y": 0,
            "i": "placeOrderNormal",
            "minW": 9,
            "minH": 14,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 20,
            "h": 12,
            "x": 7,
            "y": 14,
            "i": "placeOrderPro",
            "minW": 10,
            "minH": 12,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 8,
            "h": 14,
            "x": 0,
            "y": 4,
            "i": "infoPortfolioFavorites",
            "minW": 6,
            "minH": 6,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 17,
            "h": 14,
            "x": 8,
            "y": 4,
            "i": "priceChart",
            "minW": 10,
            "minH": 10,
            "moved": false,
            "static": staticFalse
        },
    ],
    "sm": [
        {
            "w": 25,
            "h": 4,
            "x": 0,
            "y": 0,
            "i": "infoSymbol",
            "minW": 5,
            "minH": 3,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 9,
            "h": 14,
            "x": 25,
            "y": 0,
            "i": "placeOrderNormal",
            "minW": 9,
            "minH": 14,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 20,
            "h": 12,
            "x": 7,
            "y": 14,
            "i": "placeOrderPro",
            "minW": 10,
            "minH": 12,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 8,
            "h": 14,
            "x": 0,
            "y": 4,
            "i": "infoPortfolioFavorites",
            "minW": 6,
            "minH": 6,
            "moved": false,
            "static": staticFalse
        },
        {
            "w": 17,
            "h": 14,
            "x": 8,
            "y": 4,
            "i": "priceChart",
            "minW": 10,
            "minH": 10,
            "moved": false,
            "static": staticFalse
        },
    ],
}
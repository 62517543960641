import React, { Component } from 'react';


import CustomerSelectorNew from "./CustomerSelectorNew";


import './CustomerSelectorModal.scss';
import { Modal, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

class CustomerSelectorModal extends Component {

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.isOpen !== nextProps.isOpen
            || props.toggle !== nextProps.toggle
            || props.onCustomerDidSelected !== nextProps.onCustomerDidSelected;
    }

    render() {
        const { isOpen, toggle, onClose, onCustomerDidSelected } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                onClose={onClose}
                className='customer-selector-modal'
                //titleId="customer-selector.modal.title"
            >
                <div className="custom-header">
                        <div className=" headB---700-20-30">
                            <FormattedMessage id="customer-selector.modal.title" />
                        </div>
                        <div className="btn-icon-fm" onClick={onClose}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                <ModalBody className="custom-body">
                    <CustomerSelectorNew onCustomerDidSelected={onCustomerDidSelected} />
                </ModalBody>
            </Modal>
        );
    }

}

export default CustomerSelectorModal;

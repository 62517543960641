import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
//import samplePDF from './sample.pdf';
// webpack4
// import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './PreviewPDF.scss';


export default function PreviewPDF({ src }) {
    const [numPages, setNumPages] = useStateIfMounted(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    return (
        <div className="preview-pdf-src">
            <Document
                file={src}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<div className='d-flex justify-content-center align-items-center mt-4'>Loading...</div>}
            // renderMode="svg"
            >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            loading={<div className='d-flex justify-content-center align-items-center mt-4'>Loading page...</div>}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                        />
                    ),
                )}
            </Document>
            {/* code DSC */}
            {/* <Document
                file={src}
                onLoadSuccess={(numPages) => this.onDocumentLoadSuccess(numPages)}>
                {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => <Page
                        loading={<div className='d-flex justify-content-center align-items-center mt-4'>Loading page...</div>}
                        pageNumber={page}
                        scale={1}
                    />)}
            </Document> */}
        </div>
    );
}

import React, { Component } from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";

import RetryableContent from "../../../components/RetryableContent";
import { inquiryService, } from "../../../services";
import * as actions from '../../../store/actions'
import { CommonUtils, execTypes, LoadStatus, reportParams, reportTypes, Role, ToastUtil, PREFIX_CUSTODYCD, SELECTOR_ACCOUNT_SCREEN, KeyCodeUtils, LanguageUtils } from "../../../utils";

import './CustomerDebtMargin.scss';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import TextToolTip from "../../../components/TextToolTip";
import { ReactComponent as DownloadIconLight } from '../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../assets/icons/new/DownloadIcon_Dark.svg'
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';


let columnsDefault =
    [
        /* Số lưu ký*/
        {
            dataField: "custodycd",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        // /* Mã loại hình vay */
        // {
        //     dataField: "actype",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "100",
        //     width: '110',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 2,
        // },
        // /* Ngân hàng */
        // {
        //     dataField: "bankname",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "100",
        //     width: '120',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 3,
        // },
        // /* Loại vay */
        // {
        //     dataField: "category",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "100",
        //     width: '120',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 4,
        // },
        // /* Món vay*/
        // {
        //     dataField: "ftype_name",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "100",
        //     width: '120',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 5,
        // },
        /* Số tiểu khoản */
        {
            dataField: "afacctno_ext",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Tên khách hàng*/
        {
            dataField: "fullname",
            dataType: "string",
            allowReordering: true,
            minWidth: "180",
            //width: '500',
            visible: true,
            alignment: "left",
            visibleIndex: 3,
        },
        /* Mã món vay */
        {
            dataField: "autoid",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '110',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        // /* Số tiểu khoản vay*/
        // {
        //     dataField: "lnacctno",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     width: '170',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 8,
        // },
        /* Ngày giải ngân*/
        {
            dataField: "rlsdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 9,
        },
        // /* Ngày ĐH kỳ hạn 1*/
        // {
        //     dataField: "prinfrq",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "120",
        //     width: '140',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 10,
        // },
        /* Ngày ĐH*/
        {
            dataField: "prinperiod",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 11,
        },
        /* Dư nợ giải ngân*/
        {
            dataField: "orgnml",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        /* Mức lãi suất trong hạn 1*/
        // {
        //     dataField: "rate1",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     width: '150',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 12,
        // },
        /* Mức lãi suất trong hạn 2*/
        // {
        //     dataField: "rate2",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     width: '150',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 13,
        // },
        /* Mức lãi suất quá hạn*/
        // {
        //     dataField: "rate3",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "160",
        //     width: '160',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 14,
        // },
        /* Mức phí trong hạn 1*/
        // {
        //     dataField: "cfrate1",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "160",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 15,
        // },
        /* Mức phí trong hạn 1*/
        // {
        //     dataField: "cfrate2",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "160",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        /* Mức phí quá hạn*/
        //  {
        //     dataField: "cfrate3",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "160",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        /* Dư nợ đã hoàn trả*/
        {
            dataField: "prinpaid",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        /* Dư nợ trong hạn*/
        {
            dataField: "prinnml",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        /* Dư nợ đến hạn*/
        {
            dataField: "prindue",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        /* Dư nợ quá hạn*/
        {
            dataField: "prinovd",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        /* Lãi trong hạn*/
        {
            dataField: "intnmlacr",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        /* Lãi đến hạn*/
        {
            dataField: "intdue",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        /* Lãi quá hạn*/
        {
            dataField: "intovd",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        },
        // /* Lãi trên gốc quá hạn*/
        // {
        //     dataField: "intovdprin",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "200",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Lãi đã trả*/
        // {
        //     dataField: "intpaid",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "200",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí dịch vụ trong hạn*/
        // {
        //     dataField: "feeintnmlacr",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí dịch vụ đến hạn*/
        // {
        //     dataField: "feeintdue",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí và lãi quá hạn*/
        // {
        //     dataField: "feeintovdacr",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "200",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí dịch vụ quá hạn*/
        // {
        //     dataField: "feeintnmlovd",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí dịch vụ đã trả*/
        // {
        //     dataField: "feeintpaid",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí đảo nợ*/
        // {
        //     dataField: "feeamt",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí đảo nợ đã trả*/
        // {
        //     dataField: "feepaid",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí đảo nợ trong hạn*/
        // {
        //     dataField: "fee",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí đảo nợ đến hạn*/
        // {
        //     dataField: "feedue",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Phí đảo nợ quá hạn*/
        // {
        //     dataField: "feeovd",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        // /* Đã tất toán*/
        // {
        //     dataField: "settled",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     // width: '500',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 16,
        // },
        /* Cán bộ QLTK*/
        {
            dataField: "refullname",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            // width: '500',
            visible: true,
            alignment: "center",
            visibleIndex: 16,
        },
    ]


const totalDefault =
    [
        {
            column: "orgnml",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "prinpaid",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "prinnml",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "prindue",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "prinovd",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "intnmlacr",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "intdue",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "intovd",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]
class CustomerDebtMargin extends Component {

    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        customers: [],
        custodycd: PREFIX_CUSTODYCD,
        loading: false,
        custodycdParam: '002C',
        listAccounts: [],
        currentAccount: null,
        listCareBy: [
            {
                tlid: "ALL",
                tlname: "ALL"
            }
        ],
        brokerAccountSelected: 'ALL',
    };


    constructor(props) {
        super(props);
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    genReport = (exptype) => {
        if (!this.applyInput) {
            this.setReloadData(); // do gen báo cáo có sử dụng đầu vào nên cần gọi lại api  nếu input chưa được tìm kiếm
        }
        const { userInfo } = this.props;
        const { currentAccount, brokerAccountSelected } = this.state;
        const { curCustomer } = this.props;
        let { custodycd, custodycdParam } = this.state;
        let tlid = userInfo && userInfo.custid;
        let _custodycd = custodycdParam ? custodycdParam : custodycd
        let fullname = "ALL"
        let idcode = "ALL"
        let mobile = "ALL"
        let email = "ALL"

        let custid = curCustomer && curCustomer.custid ? curCustomer.custid : 'ALL'


        let rptId = '';
        let inpArr = [];

        rptId = 'LN0001';
        inpArr = [brokerAccountSelected ? brokerAccountSelected : "ALL", custid, currentAccount.accounttype === 'ALL' ? 'ALL' : currentAccount.id, tlid, 0, 9999999, reportParams.OUT, reportParams.OUT];
        for (let i = 0; i < inpArr.length; i++) {
            let str = CommonUtils.stringifyInputExcel(inpArr[i]);
            inpArr[i] = str;
        };
        inquiryService.genReport({
            rptId: rptId,
            rptParams: _.join(inpArr, ','),
            exptype: exptype
        }).then(data => {
            ToastUtil.success('common.request-gen-report-success');
        }).catch(error => {
            ToastUtil.errorApi(error, 'common.fail-to-gen-report')
        })
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd);
            case 'actype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.actype);
            case 'autoid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.autoid);
            case 'bankname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.bankname);
            case 'category':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.category);
            case 'ftype_name':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.ftype_name);
            case 'afacctno_ext':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno_ext);
            case 'fullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.fullname);
            case 'lnacctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.lnacctno);
            case 'rlsdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.rlsdate);
            case 'prinfrq':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.prinfrq);
            case 'prinperiod':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.prinperiod);
            case 'rate1':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rate1);
            case 'rate2':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rate2);
            case 'rate3':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rate3);
            case 'cfrate1':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.cfrate1, "PRICE");
            case 'cfrate2':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.cfrate2, "PRICE");
            case 'cfrate3':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.cfrate3, "PRICE");
            case 'prinnml':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.prinnml, "PRICE");
            case 'orgnml':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.orgnml, "PRICE");
            case 'prindue':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.prindue, "PRICE");
            case 'prinovd':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.prinovd, "PRICE");
            case 'prinpaid':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.prinpaid, "PRICE");
            case 'intnmlacr':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intnmlacr, "PRICE");
            case 'intdue':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intdue, "PRICE");
            case 'intovd':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intovd, "PRICE");
            case 'intovdprin':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intovdprin, "PRICE");
            case 'intpaid':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intpaid, "PRICE");
            case 'feeintnmlacr':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeintnmlacr, "PRICE");
            case 'feeintdue':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeintdue, "PRICE");
            case 'feeintovdacr':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeintovdacr, "PRICE");
            case 'feeintnmlovd':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeintnmlovd, "PRICE");
            case 'feeintpaid':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeintpaid, "PRICE");
            case 'feeamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeamt, "PRICE");
            case 'feepaid':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feepaid, "PRICE");
            case 'fee':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.fee, "PRICE");
            case 'feedue':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feedue, "PRICE");
            case 'feeovd':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeovd, "PRICE");
            case 'settled':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.settled);
            case 'refullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.refullname);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'custodycd':
                return "customer-list-debt-margin.table.account";
            case 'actype':
                return "customer-list-debt-margin.table.type-saving";
            case 'autoid':
                return "customer-list-debt-margin.table.autoid";
            case 'bankname':
                return "customer-list-debt-margin.table.bankname";
            case 'category':
                return "customer-list-debt-margin.table.loan-type";
            case 'ftype_name':
                return "customer-list-debt-margin.table.loan";
            case 'afacctno_ext':
                return "customer-list-debt-margin.table.sub-account";
            case 'fullname':
                return "customer-list-debt-margin.table.full-name";
            case 'lnacctno':
                return "customer-list-debt-margin.table.loan-account";
            case 'rlsdate':
                return "customer-list-debt-margin.table.disbursement-date";
            case 'prinfrq':
                return "customer-list-debt-margin.table.due-date-1";
            case 'prinperiod':
                return "customer-list-debt-margin.table.due-date-2";
            case 'rate1':
                return "customer-list-debt-margin.table.interest-rate-within-term-1";
            case 'rate2':
                return "customer-list-debt-margin.table.interest-rate-within-term-2";
            case 'rate3':
                return "customer-list-debt-margin.table.overdue-interest-rate";
            case 'cfrate1':
                return "customer-list-debt-margin.table.term-fee-1";
            case 'cfrate2':
                return "customer-list-debt-margin.table.term-fee-2";
            case 'cfrate3':
                return "customer-list-debt-margin.table.overdue-fee";
            case 'prinnml':
                return "customer-list-debt-margin.table.principal-in-due";
            case 'orgnml':
                return "customer-list-debt-margin.table.orgnml";
            case 'prindue':
                return "customer-list-debt-margin.table.principal-to-due";
            case 'prinovd':
                return "customer-list-debt-margin.table.principal-over-due";
            case 'prinpaid':
                return "customer-list-debt-margin.table.paid-principal";
            case 'intnmlacr':
                return "customer-list-debt-margin.table.interest-in-due";
            case 'intdue':
                return "customer-list-debt-margin.table.interest-to-due";
            case 'intovd':
                return "customer-list-debt-margin.table.interest-over-due";
            case 'intovdprin':
                return "customer-list-debt-margin.table.interest-on-overdue-principal";
            case 'intpaid':
                return "customer-list-debt-margin.table.paid-interest";
            case 'feeintnmlacr':
                return "customer-list-debt-margin.table.service-fee-in-due";
            case 'feeintdue':
                return "customer-list-debt-margin.table.service-fee-to-due";
            case 'feeintovdacr':
                return "customer-list-debt-margin.table.fee-interest-overdue";
            case 'feeintnmlovd':
                return "customer-list-debt-margin.table.service-fee-over-due";
            case 'feeintpaid':
                return "customer-list-debt-margin.table.paid-service-fee";
            case 'feeamt':
                return "customer-list-debt-margin.table.debt-reversal-fee";
            case 'feepaid':
                return "customer-list-debt-margin.table.paid-debt-reversal-fee";
            case 'fee':
                return "customer-list-debt-margin.table.debt-reversal-fee-in-due";
            case 'feedue':
                return "customer-list-debt-margin.table.debt-reversal-fee-to-due";
            case 'feeovd':
                return "customer-list-debt-margin.table.debt-reversal-fee-overdue";
            case 'settled':
                return "customer-list-debt-margin.table.fully-paid";
            case 'refullname':
                return "customer-list-debt-margin.table.account-manager";
            default:
                return null;
        }
    }

    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    // run only once when component is mounted
    loadInitData = (check) => {
        // Lấy danh sách trạng thái để tìm kiếm
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;

        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found });
    };

    getCareByInGroup = async () => {
        await inquiryService.getCareByInGroup().then(data => {
            if (data && data.length !== 0) {
                this._setState({
                    listCareBy: [{
                        tlid: "ALL",
                        tlname: "ALL"
                    }, ...data]
                })
            } else {
                this._setState({
                    listCareBy: [{
                        tlid: "ALL",
                        tlname: "ALL"
                    }]
                })
                ToastUtil.error('confirm-order-online.fail-to-load-list-broker');
            }
        }).catch(error => {
            ToastUtil.errorApi(error, 'confirm-order-online.fail-to-load-list-broker');
        })
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.getCareByInGroup();
        this.loadInitData();
        this.setReloadData();
        CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const { language, currentAccountId } = this.props;
        const { language: prevlanguage, currentAccountId: prevCurrentAccountId } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
            this.applyInput = false;
        }

        if (!_.isEqual(currentAccount, prevCurrentAccount)) {
            this.applyInput = false;
        }
    }

    applyInput = true; // bằng true vì vừa vào màn đã gọi api

    convertToUnixTime(dateString) {
        // Split the date string into day, month, and year
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript Date object
        const year = parseInt(parts[2], 10);
        // Create a new Date object with the parsed components
        let date = new Date(year, month, day);
        date.setHours(7, 0, 0, 0)

        // Get the Unix timestamp (milliseconds since January 1, 1970)
        const unixTime = date.getTime();


        // Return the Unix timestamp
        return unixTime;
    }

    processOrderData = (data) => {
        let records = data.d;
        let newRecords = _.cloneDeep(records)
        if (newRecords && newRecords.length > 0) {
            _.forEach(newRecords, (record) => {
                record["rlsdate"] = this.convertToUnixTime(record["rlsdate"]);
                record["prinperiod"] = this.convertToUnixTime(record["prinperiod"]);
            })
            return { ...data, d: newRecords }
        } else {
            return { ...data, d: [] }
        }
    };

    apiPaging = async (obj) => {
        this.applyInput = true;
        const { currentAccount, brokerAccountSelected } = this.state;
        const { curCustomer } = this.props;
        try {
            let _obj = {
                page: obj.page,
                limitRow: obj.limitRow ? obj.limitRow : 100, //hard nếu ko tìm được limitRow thì 
            }
            if (currentAccount) {
                return inquiryService.getCustListDebtMargin(_obj.page, _obj.limitRow, curCustomer.custid, currentAccount.accounttype === 'ALL' ? 'ALL' : currentAccount.id, brokerAccountSelected ? brokerAccountSelected : 'ALL')
                    .then((data) => {
                        // if (data && data.d) {
                        //     data = data.d
                        // }
                        data = this.processOrderData(data)
                        return data
                    })
                    .catch((error) => {
                        console.log('LAOJAHACKGAME====>ERROR',error)
                        ToastUtil.errorApi(error, 'common.fail-to-load-customers-list-debt-margin');
                        return []
                    });
            } else {
                return [];
            }
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    search = () => {
        this.setReloadData();
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    setBrokerAccountSelected = (e) => {
        this.applyInput = false;
        this._setState({
            brokerAccountSelected: e.target.value
        })
    }

    render() {
        const { curCustomer, language } = this.props;
        const { onReloadData, listAccounts, currentAccount, listCareBy, brokerAccountSelected } = this.state;
        const isBroker = this.isBroker();
        // let fullName = isBroker ? (curCustomer.fullName || '') : this.props.customerFullName;
        // fullName = CommonUtils.getDisplayName(fullName);
        return (
            <RetryableContent className="customer-list-debt-margin report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="customer-list-debt-margin-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            <div className="custom-form-group-n padding-top-0">
                                <SelectorAccountCustomer
                                    ref={this.selectAccountCustomerRef}
                                    curCustomer={curCustomer}
                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                    disabled={false}
                                    className="custom-form-control txt---400-14-20"
                                />
                            </div>

                            <div className="custom-form-group-n">
                                {/* <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.fillter-sub-account" /></div> */}
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                            (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        )
                                    })}
                                </select>
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="account-lookup-by-symbol.table.broker" /></div>
                                <select value={brokerAccountSelected ? brokerAccountSelected : ''} onChange={this.setBrokerAccountSelected} className="custom-form-control" autoFocus={true} style={{ marginLeft: '5px' }}>
                                    {listCareBy && listCareBy.map((broker) => {
                                        return (
                                            <option key={broker.tlid} value={broker.tlid}>{broker.tlid} - {broker.tlname !== "ALL" ? broker.tlname : LanguageUtils.getMessageByKey("common.ALL", language)}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>
                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="custodycd" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="customer-list-debt-margin-paging" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            apiLoadAllByPagingRes={this.apiPaging}
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        userInfo: state.user.userInfo,
        //currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        //currentAccountId: currentAccountId,
        defaultTheme: defaultTheme,
        curCustomer: curCustomer,
        customerFullName: state.user.customerFullName,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerDebtMargin));

import React, { Component } from "react";
import { connect } from 'react-redux';
import { CommonWidgetUtils } from "utils";

import DraggableModal from '../../../../components/Modal/DraggableModal';
import * as actions from "../../../../store/actions";

import { ORDER_TYPE_USE } from '../../../../utils/constants';
import TradeAuthBlb from "./TradeAuthBlb";

import './TradeAuthBlbModal.scss';
class TradeAuthBlbModal extends Component {
    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.isOpen !== nextProps.isOpen
            || props.toggle !== nextProps.toggle
            || props.tradeOrderInputMsg !== nextProps.tradeOrderInputMsg
            || JSON.stringify(props.placeBlbOrderCurrent) !== JSON.stringify(nextProps.placeBlbOrderCurrent);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen != prevProps.isOpen && !this.props.isOpen) {
            let isMobileScreen = window.innerWidth <= 769 && window.innerWidth != 0;
            isMobileScreen && window.scrollTo(0, 0);
        }
    }

    focusFirstElement = () => {
        document.getElementById("prevent-outside-tab").focus();
    }

    render() {
        const { isOpen, toggle, onClose, placeBlbOrderCurrent, accountQuickOrderInfo, orderTypeUse } = this.props;
        const listReconfirmOrder = { ...placeBlbOrderCurrent };
        const { titleId, useBtnClose, handleClose } = { ...placeBlbOrderCurrent };


        let _className = "trade-auth-putthrough-order-modal"
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={useBtnClose ? handleClose : onClose}
                className={_className}
                titleId={titleId ? titleId : 'trade.auth.title'}
            >
                <div className="body">
                    <TradeAuthBlb
                        onFinish={onClose}
                        listReconfirmOrder={listReconfirmOrder} //
                        accountQuickOrderInfo={accountQuickOrderInfo}
                        quote={this.props.quote}
                        orderTypeUse={orderTypeUse}
                        toggle={toggle}
                    />
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps;
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null
    let _placeBlbOrderCurrent = null;
    let _placeChildBlbOrderCurrent = null;


    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _placeOrderData = null;
    if (orderTypeUse === ORDER_TYPE_USE.BLB_TRADE_ORDER) {
        let _placeBlbOrder = state.orderNew.placeBlbOrder
        currentSymbol = _currentLayoutPageActive && _placeBlbOrder[_currentLayoutPageActive] && _placeBlbOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]
        _placeBlbOrderCurrent = _currentLayoutPageActive && _placeBlbOrder[_currentLayoutPageActive];
        _placeOrderData = _placeBlbOrderCurrent;
    } else if (orderTypeUse === ORDER_TYPE_USE.BLB_CHILD_TRADE_ORDER) {
        let _placeChildBlbOrder = state.orderNew.placeChildBlbOrder;
        currentSymbol = _currentLayoutPageActive && _placeChildBlbOrder[_currentLayoutPageActive] && _placeChildBlbOrder[_currentLayoutPageActive]["symbolCurrent"]
        quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]];
        _placeChildBlbOrderCurrent = _currentLayoutPageActive && _placeChildBlbOrder[_currentLayoutPageActive];
        _placeOrderData = _placeChildBlbOrderCurrent;
    }

    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,

        placeBlbOrderCurrent: _placeOrderData,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        clearCustodycdCustomerScreen: (typeScreen) => dispatch(actions.clearCustodycdCustomerScreen(typeScreen)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeAuthBlbModal);
import React, { Component } from 'react';
import { FormattedAmount, FormattedPercentage, FormattedPrice } from 'components/Formating';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { CustomInput } from 'reactstrap';

import RetryableContent from "../../../components/RetryableContent";
import TransAuth2 from '../../../components/TransAuth2';
import { tradeApiService } from "../../../services";
import { TransferType, KeyCodeUtils } from '../../../utils';
import { LoadStatus, ModalConfirmType, BLB_ACK_OPTIONS } from '../../../utils/constants';
import * as actions from "../../../store/actions";
import CustomRowItem from './../../../components/CustomRowItem/CustomRowItem';
import CommonUtils from './../../../utils/CommonUtils';
import CustomScrollbars from 'components/CustomScrollbars';
import { TYPE_CHANGE } from 'containers/Settings/PersonalInfo/Information/Information';
import { ReactComponent as WarningIcon_Dark } from '../../../assets/icons/screenmodal/WarningIcon_Dark.svg';

import './TranAuthScreenConfirm.scss';
import moment from 'moment';

class TranAuthScreenConfirm extends Component {
    state = {
        loadStatus: LoadStatus.NONE
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        }
        this.confirmBtnRef = React.createRef();
        this.onCloseBtnRef = React.createRef();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { verified, isSaveTradeAuthenticated, dataInfo, clearTradeAuthenticated } = this.props;
        const { showOrderSwitch } = dataInfo;
        // Nếu có tích lưu xác thực nhưng lần xác thực trước đó không thành công hoặc không lưu xác thực ==> Clear trạng thái lưu xác thực
        if (showOrderSwitch !== false && !verified && isSaveTradeAuthenticated) {
            clearTradeAuthenticated();
        }
    }

    //Chuyển tiền ra ngoài
    renderCashTransferExternal = () => {
        const { dataInfo } = this.props
        const {
            selectedBenefitAccount, feeAmount, feeType,
            transferAmount, accountSend, transferType, description, custodycd
        } = { ...dataInfo };
        //Số tiền chuyển
        let realAmountTranfers = feeType === "inFee" ? transferAmount : transferAmount + feeAmount
        //Số tiền thực nhận
        let realAmount = feeType === "inFee" ? transferAmount - feeAmount : transferAmount

        let selectedBenefitAccountDisplay = selectedBenefitAccount || {};

        let descriptionConvert = CommonUtils.returnWithoutAccents(description)
        return (
            <div>
                {/* Hình thức giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={transferType} id={'account.utilities.cash-transfer.transfer-type.' + transferType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {custodycd}
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.cdcontent}
                    </div>
                </div>
                {/* Ngoài - Số tài khoản ngân hàng */}
                {transferType === TransferType.EXTERNAL &&
                    <div className="row-item">
                        <div className="label txt---400-14-20">
                            <FormattedMessage id="account.utilities.cash-transfer.number-bank" />
                        </div>

                        <div className='txt---400-14-20'>
                            {selectedBenefitAccountDisplay.benefitaccount}
                        </div>

                    </div>
                }
                {/* Ngoài - Tên người thụ hưởng */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.benefit-name" />
                    </div>
                    <div className='txt---400-14-20'>
                        {selectedBenefitAccountDisplay.benefitname || ''}
                    </div>
                </div>
                {/* Ngoài - Ngân hàng thụ hưởng */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.bank-beneficiary" />
                    </div>
                    <div className='txt---400-14-20'>
                        {selectedBenefitAccountDisplay.benefitbankname}
                    </div>
                </div>
                {/* Ngoài - Số tiền chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="account.utilities.cash-transfer.transfer-amount" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={realAmountTranfers} />
                    </div>
                </div>
                {/* Ngoài - Phí chuyển tiền */}
                {/*<div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.transfer-fee" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={feeAmount} />
                    </div>
                </div>*/}
                {/* Ngoài - Số tiền thực nhận */}
                {/*<div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.the-amount-actually-received" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={realAmount} />
                    </div>
                </div>*/}
                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.content" />
                    </div>
                    <div className='txt---400-14-20'>{descriptionConvert}</div>
                </div>
            </div>
        )
    }

    // Chuyển tiền nội bộ
    renderCashTransferInternal = () => {
        const { dataInfo } = this.props
        const {
            transferAmount, accountSend, accountReceived, transferType, description, custodycd
        } = { ...dataInfo };
        let descriptionConvert = CommonUtils.returnWithoutAccents(description)
        return (
            <div>
                {/* Hình thức giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={transferType} id={'account.utilities.cash-transfer.transfer-type.' + transferType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {custodycd}
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.cdcontent}
                    </div>
                </div>
                {/* Nội bộ - Tiểu khoản nhận */}
                {transferType === TransferType.INTERNAL &&
                    <div className="row-item">
                        <div className="label txt---400-14-20">
                            <FormattedMessage id="account.utilities.cash-transfer.sub-account-received" />
                        </div>

                        <div className='txt---400-14-20'>
                            {accountReceived.cdcontent}
                        </div>

                    </div>
                }
                {/* Ngoài - Số tiền chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="account.utilities.cash-transfer.transfer-amount" />
                    </div>
                    <div className='text-secondary-1 txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>
                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.content" />
                    </div>
                    <div className='txt---400-14-20'>
                        {descriptionConvert}</div>
                </div>
            </div>
        )
    }


    // Chuyển tiền nội bộ
    renderUnholdCoreBank = () => {
        const { dataInfo } = this.props
        const {
            unholdAmount, currentCorebankAccount, corebankAccountInfo, custodycd
        } = { ...dataInfo };
        return (
            <div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {custodycd}
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc" />
                    </div>

                    <div className='txt---400-14-20'>
                        {currentCorebankAccount.cdcontent}
                    </div>
                </div>
                {/* Số tiền tối đa có thể giải tỏa*/}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="account.utilities.unhold-corebank.max-unhold" />
                    </div>
                    <div className='text-secondary-1 txt---400-14-20'>
                        <FormattedPrice value={corebankAccountInfo.holdbalance} />
                    </div>
                </div>
                {/* Số tiền giải tỏa */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="account.utilities.unhold-corebank.unhold-amount" />
                    </div>
                    <div className='text-secondary-1 txt---400-14-20'>
                        <FormattedPrice value={unholdAmount} />
                    </div>
                </div>
            </div>
        )
    }

    //Gia hạn nợ
    renderExtendDebtManagement = () => {
        const { dataInfo } = this.props
        const {
            row, currentAccount, fromDate, extenddays
        } = { ...dataInfo }
        return (
            <div>
                {/* Mã món vay  */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.loan-schedule-code" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        {row.autoid}
                    </div>
                </div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.custodycd" />
                    </div>
                    <div className='txt---400-14-20'>
                        {currentAccount.custodycd}
                    </div>
                </div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.sub" />
                    </div>
                    <div className='txt---400-14-20'>
                        {currentAccount.cdcontent}
                    </div>
                </div>

                {/* Ngày giải ngân */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.disbursement-date" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        <div >
                            {row.rlsdate}
                        </div>
                    </div>
                </div>
                {/* Ngày đến hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.date-due" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedDate value={row.overduedate_val} />
                    </div>
                </div>
                {/* Ngày bắt đầu gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.renewal-start-date" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.overduedate}
                    </div>
                </div>
                {/* Gốc còn lại */}
                {/* <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.remaining-original" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.prinamt} />
                    </div>
                </div> */}
                {/* Ngày đến hạn mới */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.new-due-date" />
                    </div>
                    <div className='txt---400-14-20'>
                        {fromDate}
                    </div>
                </div>
                {/* Số lần đã gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.renewed-number-of-times" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.extimes}
                        {/* <FormattedMessage id="debt-management.year" /> */}
                    </div>
                </div>
                {/* Số ngày gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.number-of-extension-days" />
                    </div>
                    <div className='txt---400-14-20'>
                        {this.dateConverter(row.overduedate, row.nextoverduedate)}
                    </div>
                </div>
                {/* Gốc còn lại */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.remaining-original" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.prinamt} />
                    </div>
                </div>
                {/* Lãi tạm tính */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.unpaid-interest" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.intamt} />
                    </div>
                </div>
                {/* Số dư tiền mặt */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.cash-balance" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.baldefovd} />
                    </div>
                </div>
                {/* Phí gia hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.renewal-fee" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.feeamt} />
                    </div>
                </div>
                {/* Lãi suất khoản vay */}
                {/* <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.loan-interest-rate" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.interestrate}%
                    </div>
                </div> */}
                {/* Tổng phải trả */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.extend.total-pay" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.sumpaid} />
                    </div>
                </div>
            </div>
        )
    }

    //Nộp tiền ký quỹ CCP
    renderPayCCP = () => {
        const { dataInfo } = this.props
        const {
            totalFee,
            feeType,
            transferAmount, accountSend, tradingType, description
        } = { ...dataInfo };


        let cutMoney = 0;
        let totalCashDeposit = 0;
        if (feeType === "inFee") {
            cutMoney = transferAmount;
            totalCashDeposit = transferAmount - totalFee;
        } else {
            cutMoney = transferAmount + totalFee;
            totalCashDeposit = transferAmount;
        }
        let descriptionConvert = CommonUtils.returnWithoutAccents(description)
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={tradingType} id={'trading-ccp.trading-type.' + tradingType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.sub-account-transfer" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {CommonUtils.formatFDS(accountSend.id)}
                    </div>
                </div>
                {/* Số tiền chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.transfer-amount" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>

                {/* Số tiền thực cắt */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.cut-money" />
                    </div>

                    <div className='txt---400-14-20'>
                        <FormattedPrice value={cutMoney} />
                    </div>
                </div>
                {/* Phí giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.fee-transfer" />
                    </div>

                    <div className='txt---400-14-20'>
                        <FormattedPrice value={totalFee} />
                    </div>
                </div>
                {/* Tổng tiền nộp */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.total-money" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={totalCashDeposit} />
                    </div>
                </div>

                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.content" />
                    </div>
                    <div className='txt---400-14-20'>{descriptionConvert}</div>
                </div>
            </div>
        )
    }

    renderHoldMoneyBidv = () => {
        const { dataInfo } = this.props
        const {
            transferAmount, accountSend, tradingType
        } = { ...dataInfo };

        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trade-money-bidv.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={tradingType} id={'trade-money-bidv.trade-bidv-type.' + tradingType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trade-money-bidv.sub-account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {accountSend.id}
                    </div>
                </div>
                {/* Số tiền phong tỏa */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trade-money-bidv.blocked-amount" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>
            </div>
        )
    }

    renderUnholdMoneyBidv = () => {
        const { dataInfo } = this.props
        const {
            transferAmount, accountSend, tradingType
        } = { ...dataInfo };

        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trade-money-bidv.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={tradingType} id={'trade-money-bidv.trade-bidv-type.' + tradingType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trade-money-bidv.sub-account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {accountSend.id}
                    </div>
                </div>
                {/* Số tiền giải tỏa */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trade-money-bidv.amount-of-relief" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>
            </div>
        )
    }

    renderWithdrawalCCP = () => {
        const { dataInfo } = this.props
        const {
            totalFee, vat,
            transferAmount, accountSend, tradingType, description
        } = { ...dataInfo };


        let cutMoney = transferAmount;
        let totalCashDeposit = transferAmount - totalFee - vat;
        let descriptionConvert = CommonUtils.returnWithoutAccents(description)
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={tradingType} id={'trading-ccp.trading-type.' + tradingType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.sub-account-withdraw" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {CommonUtils.formatFDS(accountSend.id)}
                    </div>
                </div>
                {/* Số tiền rút */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.withdraw-amount" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>

                {/* Số tiền thực cắt */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.cut-money" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={cutMoney} />
                    </div>
                </div>
                {/* Phí giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.fee-transfer" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={totalFee} />
                    </div>
                </div>
                {/* VAT */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.vat" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={vat} />
                    </div>
                </div>
                {/* Tổng tiền thực nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.total-money" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={totalCashDeposit} />
                    </div>
                </div>

                {/* Nội dung */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="trading-ccp.content" />
                    </div>
                    <div className='txt---400-14-20'>{descriptionConvert}</div>
                </div>
            </div>
        )
    }

    renderCashInAdvance = () => {
        const { dataInfo } = this.props
        const {
            advanceAmount, accountSend, cashAdvanceType, feeAmount, totalAmount, custodycd
        } = { ...dataInfo };
        let sotienungtruoc = 0
        let tongsotien = 0
        if (Number(advanceAmount) + Number(feeAmount) < Number(totalAmount)) {
            sotienungtruoc = advanceAmount
            tongsotien = Number(advanceAmount) - Number(feeAmount)
        } else {
            sotienungtruoc = Number(totalAmount) - Number(feeAmount)
            tongsotien = Number(totalAmount)
        }
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="cash-in-advance.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={cashAdvanceType} id={'cash-in-advance.cash-advance-type.' + cashAdvanceType}> </FormattedMessage>
                    </div>
                </div>

                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {custodycd}
                    </div>
                </div>

                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="cash-in-advance.sub-account" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountSend.cdcontent}
                    </div>
                </div>

                {/* Tổng tiền ứng */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="cash-in-advance.total-advance" />
                    </div>

                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={tongsotien} />
                    </div>
                </div>
                {/* Phí ứng */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="cash-in-advance.fee-amount" />
                    </div>

                    <div className='txt---400-14-20'>
                        <FormattedPrice value={feeAmount} />
                    </div>
                </div>
                {/* Số tiền thực nhận*/}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="cash-in-advance.amount-of-advance" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={sotienungtruoc} />
                    </div>
                </div>
            </div>
        )
    }

    renderRegisterProduct = () => {
        const { dataInfo } = this.props
        const {
            custodycd,
            cdcontent,
            prmname
        } = { ...dataInfo };

        return (
            <div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="common.username" />
                    </div>
                    <div className='txt---400-14-20'>
                        {custodycd}
                    </div>
                </div>

                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.sub-accounts" />
                    </div>
                    <div className='txt---400-14-20'>
                        {cdcontent}
                    </div>
                </div>

                {/* Sản phẩm */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="product-register.product" />
                    </div>
                    <div className='txt---400-14-20'>
                        {prmname}
                    </div>
                </div>
            </div>
        )
    }

    renderIFlexUnAuto = () => {
        const { dataInfo } = this.props
        const {
            transferAmount, accountSend, flexType, typeDeposit
        } = { ...dataInfo };
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={flexType} id={'iflex.iflex-type.' + flexType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.sub-account" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend.entypename} - {accountSend.id}
                    </div>
                </div>
                {/* Thời hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.duration" />
                    </div>
                    <div className='txt---400-14-20'>
                        {typeDeposit.en_display}
                    </div>
                </div>

                {/* Lãi suất */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.interest-rate" />
                    </div>

                    <div className="custom-form-group txt---400-14-20">
                        <FormattedPercentage value={typeDeposit ? typeDeposit.intrate : 0} />
                        %
                        <FormattedMessage id="iflex.year" />
                    </div>
                </div>
                {/* Ngày hiệu lực*/}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.currdate" />
                    </div>

                    <div className='txt---400-14-20'>
                        <FormattedDate value={typeDeposit.currdate} />
                    </div>
                </div>
                {/* Ngày đáo hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.newduedate" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedDate value={typeDeposit.newduedate} />
                    </div>
                </div>
                {/* Tiền cho vay */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.money-back" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>
            </div>
        )
    }

    renderIFlexAuto = () => {
        const { dataInfo } = this.props
        const {
            accountSend, autotypeDeposit, isRegistedAuto
        } = { ...dataInfo }
        return (
            <div>
                {/* Tên tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.account-name" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend ? accountSend.name : ''}
                    </div>
                </div>
                {/* Số tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.account-number" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend ? accountSend.custodycd : ''}
                    </div>
                </div>
                {/* Sản phẩm */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.duration" />
                    </div>
                    <div className='txt---400-14-20'>
                        {autotypeDeposit ? autotypeDeposit.typename : ''}
                    </div>
                </div>

                {/* Lãi suất */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.interest-rate" />
                    </div>

                    <div className='d-flex txt---400-14-20'>
                        <FormattedPercentage value={autotypeDeposit ? autotypeDeposit.intrate : 0} />
                        %
                        <FormattedMessage id="iflex.year" />
                    </div>
                </div>
                {/* Số tiền tối thiểu */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.minimum-amount" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={autotypeDeposit ? autotypeDeposit.minamt : 0} />
                    </div>
                </div>
                {/* Số tiền tối đa */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.the-maximum-amount" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={autotypeDeposit ? autotypeDeposit.maxamt : 0} />
                    </div>
                </div>
            </div>
        )
    }


    renderToggleIFlex = () => {
        const { dataInfo } = this.props
        const {
            accountSend, autotypeDeposit, isOnAuto
        } = { ...dataInfo }
        return (
            <div>
                {/* Tên tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.account-name" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend ? accountSend.name : ''}
                    </div>
                </div>
                {/* Số tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.account-number" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend ? accountSend.custodycd : ''}
                    </div>
                </div>
                {/* Sản phẩm */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.product" />
                    </div>
                    <div className='txt---400-14-20'>
                        {autotypeDeposit ? autotypeDeposit.typename : ''}
                    </div>
                </div>

                {/* Lãi suất */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.interest-rate" />
                    </div>

                    <div className='d-flex txt---400-14-20'>
                        <FormattedPercentage value={autotypeDeposit ? autotypeDeposit.intrate : 0} />
                        %
                        <FormattedMessage id="iflex.year" />
                    </div>
                </div>
                {/* Số tiền tối thiểu */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.minimum-amount" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={autotypeDeposit ? autotypeDeposit.minamt : 0} />
                    </div>
                </div>
                {/* Số tiền tối đa */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.the-maximum-amount" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={autotypeDeposit ? autotypeDeposit.maxamt : 0} />
                    </div>
                </div>
                {/* Bật/Tắt iFlex tự động */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.enable-disable-iflex" />
                    </div>
                    <div>
                        <CustomInput
                            type="switch"
                            id="switch-theme-input"
                            checked={!isOnAuto}
                            disable={true}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderDepositWithdraw = () => {
        const { dataInfo } = this.props
        const {
            row, typename
        } = { ...dataInfo }
        return (
            <div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.sub-accounts" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        {typename}.
                        {row.afacctno}
                    </div>
                </div>
                {/* Giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.transaction" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage id="iflex.savings-settlement" />
                    </div>
                </div>
                {/* Mã khoản gửi */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.deposit-code" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.acctno}
                    </div>
                </div>

                {/* Loại hình */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.type" />
                    </div>

                    <div className='d-flex txt---400-14-20'>
                        {row.typename}
                    </div>
                </div>
                {/* Ngày đáo hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.date-due" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedDate value={row.todate} />
                    </div>
                </div>
                {/* Số tiền tất toán */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.amount-of-settlement" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.orgamt} />
                    </div>
                </div>
                {/* Lãi tất toán trước hạn */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.interest-paid-off-before-maturity" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.intavlamt} />
                    </div>
                </div>
                {/* Tổng nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.total-receipt" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={row.orgamt + row.intavlamt} />
                    </div>
                </div>
            </div>
        )
    }

    renderPayDebtManagement = () => {
        const { dataInfo } = this.props
        const {
            row, currentAccount, transferAmount
        } = { ...dataInfo }
        return (
            <div>
                {/* Tiểu khoản vay */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.sub-loan" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        {currentAccount.entypename} - {currentAccount.id}
                    </div>
                </div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.type-trade" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage id="debt-management.loan-repayment" />
                    </div>
                </div>
                {/* Mã lịch vay */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.loan-schedule-code" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.autoid}
                    </div>
                </div>

                {/* Gốc còn lại */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.remaining-original" />
                    </div>

                    <div className='d-flex txt---400-14-20'>
                        <FormattedPrice value={row.prinamt} />
                    </div>
                </div>
                {/* Lãi chưa trả */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.unpaid-interest" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={row.intamt} />
                    </div>
                </div>
                {/* Lãi suất khoản vay */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.loan-interest-rate" />
                    </div>
                    <div className='txt---400-14-20'>
                        {row.interestrate}%
                    </div>
                </div>
                {/* Số tiền trả nợ */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.amount-of-debt-payment" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={transferAmount} />
                    </div>
                </div>
                {/* Số tiền trả lãi */}
                {/* <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.amount-of-interest-payment" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={0} />
                    </div>
                </div> */}
                {/* Số tiền trả gốc */}
                {/* <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="debt-management.pay.principal-payment-amount" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={0} />
                    </div>
                </div> */}
            </div>
        )
    }

    renderModalRegisterVoucher = () => {
        const { dataInfo } = this.props
        const {
            dataVoucher, registerVoucherAmount, accountSend
        } = { ...dataInfo }
        return (
            <div>
                {/* Giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.voucher-register" />
                    </div>
                </div>
                {/* Tiểu khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="iflex.withdraw.sub-accounts" />
                    </div>
                    <div className='d-flex txt---400-14-20'>
                        {accountSend.entypename} - {CommonUtils.formatFDS(accountSend.id)}
                    </div>
                </div>
                {/* Gói ưu đãi*/}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.name-voucher" />
                    </div>
                    <div className='txt---400-14-20'>
                        {dataVoucher.vouchername}
                    </div>
                </div>

                {/* Ngày bắt đầu */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.ticket.start-date" />
                    </div>

                    <div className='d-flex txt---400-14-20'>
                        <FormattedDate value={dataVoucher.valdate} />
                    </div>
                </div>
                {/* Ngày kết thúc */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.ticket.end-date" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedDate value={dataVoucher.expdate} />
                    </div>
                </div>
                {/* Tỷ lệ hoàn phí */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.ticket.rate-refund" />
                    </div>
                    <div className='txt---400-14-20'>
                        {dataVoucher.vcrate}%
                    </div>
                </div>
                {/* Giá trị gói ưu đãi tối thiểu*/}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.ticket.min-voucher" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={dataVoucher.minamt} />
                    </div>
                </div>
                {/* Tổng tiền nộp */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.total" />
                    </div>
                    <div className=' txt---400-14-20'>
                        <FormattedPrice value={registerVoucherAmount - (parseFloat(registerVoucherAmount) * parseFloat(dataVoucher.vcrate) / 100)} />
                    </div>
                </div>
            </div>
        )
    }

    renderModalRegisterIPO = () => {
        const { dataInfo } = this.props
        const { currentAccount, registerAmountValid, registerQtty, dataFill, registerAmount, accountSummary } = dataInfo

        return (
            <div>
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Loại giao dịch"
                    showValue="Đăng ký mua CW IPO"
                    classNameValue={"txt---400-14-20"}
                />
                {/* Tiểu khoản */}
                <CustomRowItem
                    showLabel="Tiểu khoản"
                    showValue={`${currentAccount.entypename} - ${currentAccount.id}`}
                />
                {/* Mã chứng khoán */}
                <CustomRowItem
                    showLabel="Mã chứng quyền"
                    showValue={dataFill.symbol}
                />
                {/* Số dư khả dụng */}
                <CustomRowItem
                    showLabel="Số dư khả dụng"
                    showValue={accountSummary.baldefovd}
                    isPrice={true}
                />
                {/* Giá */}
                <CustomRowItem
                    showLabel="Giá"
                    showValue={dataFill.purchaseprice}
                    isPrice={true}
                />
                {/* Số lượng tối đa */}
                {/* <CustomRowItem
                    showLabel="Số lượng tối đa"
                    showValue={dataFill.rightbuyavailable}
                    isQtty={true}
                /> */}
                {/* Số lượng đăng ký */}
                <CustomRowItem
                    showLabel="Số lượng đăng ký"
                    showValue={registerQtty}
                    isQtty={true}
                />
                {/* Số tiền thanh toán */}
                <CustomRowItem
                    showLabel="Số tiền thanh toán"
                    showValue={registerAmount}
                    isPrice={true}
                    classNameValue={""}
                />
            </div>
        )
    }

    renderModalChangeInfo = () => {
        const { dataInfo } = this.props
        const { emailNew, addressNew, emailOld, addressOld, typeChange } = dataInfo
        return (
            <div>
                {typeChange == TYPE_CHANGE.ALL && (
                    <div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.old-email" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {emailOld}
                            </div>
                        </div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.new-email" />
                            </div>
                            <div className='txt---400-14-20 text-right break-word'>
                                {emailNew}
                            </div>
                        </div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.old-address" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {addressOld}
                            </div>
                        </div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.new-address" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {addressNew}
                            </div>
                        </div>
                    </div>
                )}

                {typeChange == TYPE_CHANGE.EMAIL && (
                    <div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.old-email" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {emailOld}
                            </div>
                        </div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.new-email" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {emailNew}
                            </div>
                        </div>
                    </div>
                )}
                {typeChange == TYPE_CHANGE.ADDRESS && (
                    <div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.old-address" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {addressOld}
                            </div>
                        </div>
                        <div className="row-item row-item-custom">
                            <div className="col-3 label txt---400-14-20">
                                <FormattedMessage id="settings.personal.new-address" />
                            </div>
                            <div className='col-9 txt---400-14-20 text-right break-word'>
                                {addressNew}
                            </div>
                        </div>
                    </div>
                )}

            </div>
        )
    }

    renderModalBankAddition = () => {
        const { dataInfo } = this.props
        const { bankId, customerBankName, customerBankNumber, language, citybank } = dataInfo

        return (
            <div>
                {/* Chủ TK */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.accountBankName"
                    showValue={customerBankName}
                />

                {/* Số Tài Khoản */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.accountBankNumber"
                    showValue={customerBankNumber}
                />

                {/*  Ngân hàng */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.bank"
                    showValue={bankId}
                />

                {/*  Chi nhánh */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.bank-branch"
                    showValue={citybank}
                />

            </div>
        )
    }

    renderModalBankRemove = () => {
        const { dataInfo } = this.props
        const { dataFill } = dataInfo
        return (
            <div>
                {/* Chủ TK */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.accountBankName"
                    showValue={dataFill.bankacname}
                />

                {/* Số Tài Khoản */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.accountBankNumber"
                    showValue={dataFill.bankacc}
                />

                {/*  Ngân hàng */}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.bank"
                    showValue={dataFill.bankname}
                />

                {/*  Chi nhánh*/}
                <CustomRowItem
                    showLabel="settings.personal.bank-addition.bank-branch"
                    showValue={dataFill.citybank}
                />

            </div>
        )
    }

    renderSignUp2FactorAuth = () => {
        const { dataInfo } = this.props
        const { dataFill } = dataInfo
        return (
            <div>
                {/* PTGD cũ */}
                <CustomRowItem
                    showLabel="sign-up-factor-auth.current-authentication-method"
                    showValue={dataFill && dataFill.current ? dataFill.current : ''}
                    hiddenLine={true}
                />
                {/* PTGD mới*/}
                <CustomRowItem
                    showLabel="sign-up-factor-auth.new-authentication-method"
                    showValue={dataFill && dataFill.new ? dataFill.new : ''}
                />
            </div>
        )
    }

    renderForgetPassword = () => {
        return (
            <div>
            </div>
        )
    }


    renderChangePassword = () => {
        return (
            <div>
            </div>
        )
    }

    renderTokenLifetime = () => {
        return (
            <div>
                {/* PTGD cũ */}
                <CustomRowItem
                    showLabel="sign-up-factor-auth.current-authentication-method"
                    showValue="SMS-OTP"
                    hiddenLine={true}
                />
                {/* PTGD mới*/}
                <CustomRowItem
                    showLabel="sign-up-factor-auth.new-authentication-method"
                    showValue="SMS-OTP"
                />
            </div>
        )
    }

    renderRegisterMarginSubAccount = () => {
        const { dataInfo } = this.props
        const { dataFill } = dataInfo
        const { custodycd } = dataFill
        return (
            <div>
                {/* Tài khoản */}
                <CustomRowItem
                    showLabel="common.username"
                    showValue={`${custodycd}`}
                />
                {/* ND */}
                <CustomRowItem
                    showLabel="account.utilities.cash-transfer.content"
                    showValue="service-register.open-margin-account"
                    isValueText={true}
                />
            </div>
        )
    }

    renderRegisterSMS = () => {
        return (<div></div>)
    }

    renderRightOff = () => {
        const { dataInfo } = this.props
        const { currentAccount, registerAmountValid, registerQtty, dataFill, registerAmout, accountSummary, custodycd } = dataInfo

        return (
            <div>
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Loại giao dịch"
                    showValue="Đăng ký quyền mua"
                />
                {/* Tài khoản */}
                <CustomRowItem
                    showLabel="Tài khoản"
                    showValue={`${currentAccount.custodycd}`}
                />
                {/* Tiểu khoản */}
                <CustomRowItem
                    showLabel="Tiểu khoản cắt tiền"
                    showValue={`${dataFill.ciaccount_af}`}
                />
                {/* Mã chứng khoán */}
                <CustomRowItem
                    showLabel="Mã chứng khoán"
                    showValue={dataFill.symbol}
                />
                {/* Số dư khả dụng */}
                <CustomRowItem
                    showLabel="Tiền mặt khả dụng"
                    showValue={accountSummary.baldefovd_not_adv}
                    isPrice={true}
                />
                {/* Giá (x1000) */}
                <CustomRowItem
                    showLabel="Giá đặt mua"
                    showValue={dataFill.exprice}
                    isPrice={true}
                />
                {/* Số lượng tối đa */}
                <CustomRowItem
                    showLabel="KL còn được mua"
                    showValue={dataFill.avlqtty}
                    isQtty={true}
                />
                {/* Số lượng đăng ký */}
                <CustomRowItem
                    showLabel="Khối lượng đăng ký"
                    showValue={registerQtty}
                    isQtty={true}
                />
                {/* Số tiền thanh toán */}
                <CustomRowItem
                    showLabel="Số tiền thanh toán"
                    showValue={registerAmout}
                    isPrice={true}
                    classNameValue={""}
                />
            </div>
        )
    }

    renderCancelRightOff = () => {
        const { dataInfo } = this.props
        const { currentAccount, registerAmountValid, registerQtty, dataFill, registerAmout, accountSummary, custodycd } = dataInfo

        return (
            <div>
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Loại giao dịch"
                    showValue="Hủy đăng ký quyền mua"
                />
                {/* Tài khoản */}
                <CustomRowItem
                    showLabel="Tài khoản"
                    showValue={`${custodycd}`}
                />
                {/* Tiểu khoản */}
                <CustomRowItem
                    showLabel="Tiểu khoản"
                    showValue={`${currentAccount.cdcontent}`}
                />
                {/* Mã chứng khoán */}
                <CustomRowItem
                    showLabel="Mã chứng khoán"
                    showValue={dataFill.symbol_org}
                />
                {/* Tỷ lệ */}
                <CustomRowItem
                    showLabel="Tỷ lệ"
                    showValue={dataFill.rightoffrate}
                />
                {/* Ngày ĐK cuối cùng */}
                <CustomRowItem
                    showLabel="Ngày ĐK cuối cùng"
                    showValue={dataFill.reportdate}
                    isDate={true}
                />
                {/* KHối lượng đã đăng ký */}
                <CustomRowItem
                    showLabel="KL CK đã mua"
                    showValue={dataFill.qtty}
                    isQtty={true}
                />
                {/* Số tiền đã đăng ký*/}
                <CustomRowItem
                    showLabel="Số tiền đã thanh toán"
                    showValue={dataFill.aamt}
                    isPrice={true}
                />
            </div>
        )
    }

    renderConfirmOrderOnline = () => {
        const { dataInfo } = this.props
        const {
            listOrderConfirm, language
        } = { ...dataInfo }
        // return (
        //     <CustomScrollbars
        //         className="list-symbol"
        //         autoHeight={true}
        //         autoHeightMin={100}
        //         autoHeightMax={300}
        //     >
        //         <table className="basic-table">
        //             <tbody>
        //                 {listOrderConfirm && listOrderConfirm.map((item, index) => {
        //                     return (
        //                         <tr key={index} >
        //                             <td className="text-center">{item.symbol}</td>
        //                             <td className={(item.exectype === "Mua") ? "text-green text-center" : "text-red text-center"} >{item.exectype === "Mua" ? (language === "vi" ? "Mua" : "Buy") : (language === "vi" ? "Bán" : "Sell")}</td>
        //                             <td className="text-center"> <FormattedAmount value={item.orderqtty} /></td>
        //                             <td className="text-center"><FormattedPrice value={item.quoteprice} shortForm={true} decimalScale={null} /></td>
        //                         </tr>
        //                     )
        //                 })}
        //             </tbody>
        //         </table>
        //     </CustomScrollbars>
        // )
        return (
            <div>
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    <div className='text-left col-12'>
                        <FormattedMessage id="confirm-order-online.confirm-auth" />
                    </div>
                </div>
            </div>
        )
    }

    renderBlbAckConfirm = () => {
        const { dataInfo } = this.props
        const {
            selectedCheckType
        } = { ...dataInfo }
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={100}
                autoHeightMax={300}
            >
                <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                    <div className="col-1 custom-form-group text-right" style={{ border: 'none' }}>
                        <div className="custom-form-control">
                            <input className='' type="radio" name="select-ack-option"
                                readOnly
                                checked={selectedCheckType === BLB_ACK_OPTIONS.CHILD_ORD_PRICE}
                            />
                        </div>

                    </div>
                    <div className="col-11 text-left">
                        <label>Giá lệnh con không vượt quá chỉ thị</label>
                    </div>

                    <div className="col-1 custom-form-group text-right" style={{ border: 'none' }}>
                        <div className="custom-form-control">
                            <input className='' type="radio" name="select-ack-option"
                                readOnly
                                checked={selectedCheckType === BLB_ACK_OPTIONS.AVG_PRICE}
                            />
                        </div>

                    </div>
                    <div className="col-11 text-left">
                        <label>Giá trung bình không vượt quá chỉ thị</label>
                    </div>

                    <div className="col-1 custom-form-group text-right " style={{ border: 'none' }}>
                        <div className="custom-form-control">
                            <input className='' type="radio" name="select-ack-option"
                                readOnly
                                checked={selectedCheckType === BLB_ACK_OPTIONS.NOT_CHILD_ORD_PRICE}
                            />
                        </div>

                    </div>
                    <div className="col-11 text-left">
                        <label>Không check giá lệnh con</label>
                    </div>
                </div>
            </CustomScrollbars>
        )
    }


    renderBlbAckSellConditionConfirm = () => {
        const { dataInfo } = this.props
        const {
            custodycd, afacctno, calQttyInfo
        } = { ...dataInfo }

        // Tính Số cấp thêm
        let addQttyMore = 0;
        if (calQttyInfo) {
            let setupQtty = calQttyInfo['setupQtty'] || 0;
            let currSetupQtty = calQttyInfo['currSetupQtty'] || 0
            addQttyMore = parseFloat(setupQtty - currSetupQtty);
        }
        return (
            <div>
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="TK Thiết lập"
                    showValue={`${custodycd || ''} - ${afacctno || ''}`}
                />
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Số cấp tổng"
                    showValue={calQttyInfo && calQttyInfo['setupQtty'] ? calQttyInfo['setupQtty'] : ''}
                    isQtty={true}
                />
                {/* Loại giao dịch */}
                <CustomRowItem
                    showLabel="Số cấp thêm"
                    showValue={addQttyMore}
                    isQtty={true}
                />
            </div>
        )
    }

    renderBlbAckEditCancelOrder = () => {
        const { dataInfo } = this.props
        const {
            blorderid,
            newquantity,
            newprice
        } = { ...dataInfo }
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={50}
                autoHeightMax={50}
            >
                <div className="text-center">
                    {`Số hiệu lệnh ${blorderid} KL mới : ${newquantity} - giá mới ${newprice}`}
                </div>
            </CustomScrollbars>
        )
    }

    renderDone4DayConfirm = () => {
        const { dataInfo } = this.props
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={50}
                autoHeightMax={50}
            >
                <div className="text-center">
                    Bạn có muốn thực thi Done 4 day đối với lệnh này
                </div>
            </CustomScrollbars>
        )
    }


    renderBlbRejectConfirm = () => {
        const { dataInfo } = this.props
        const {
            orderId
        } = { ...dataInfo }
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={100}
                autoHeightMax={100}
            >
                <div className="col-12 text-center">
                    <WarningIcon_Dark />
                </div>
                <div className="col-12 text-center">
                    {`Từ chối lệnh ${orderId}`}
                </div>
            </CustomScrollbars>
        )
    }

    renderBlbCancelFromFixNet = () => {
        const { dataInfo } = this.props
        const {
            exectype, symbol, quantity, price, blorderid
        } = { ...dataInfo }
        let orderInfoDisplay = (symbol || '') + ' - ' + (exectype || '') + ' - ' + (quantity || '');
        return (
            <CustomScrollbars
                className="list-symbol"
                autoHeight={true}
                autoHeightMin={150}
                autoHeightMax={150}
            >


                <div>
                    <CustomRowItem
                        showLabel="Lệnh tổng"
                        showValue={orderInfoDisplay}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Giá"
                        showValue={price || 0}
                        isPrice={true}
                        classNameValue={""}
                    />
                </div>
                <div>
                    <CustomRowItem
                        showLabel="Số hiệu lệnh"
                        showValue={blorderid || ''}
                    />
                </div>

            </CustomScrollbars>
        )
    }

    renderStockTransferInternal = () => {
        const { dataInfo } = this.props
        const {
            username, selectedSymbol, quantityTransfer,
            accountSend, accountReceived, transferType
        } = { ...dataInfo };
        return (
            <div>
                {/* Loại giao dịch */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.transaction-type" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedMessage key={transferType} id={'stock-transfer.transfer-type.' + transferType}> </FormattedMessage>
                    </div>
                </div>
                {/* Tài khoản */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="stock-transfer.account" />
                    </div>
                    <div className='txt---400-14-20'>
                        <FormattedPrice value={username} />
                    </div>
                </div>
                {/* Tiểu khoản chuyển */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.sub-account-transfer" />
                    </div>
                    <div className='txt---400-14-20'>
                        {accountSend.cdcontent}
                    </div>
                </div>
                {/* Tiểu khoản nhận */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.sub-account-received" />
                    </div>

                    <div className='txt---400-14-20'>
                        {accountReceived.cdcontent}
                    </div>

                </div>
                {/* Mã chứng khoán */}
                <div className="row-item">
                    <div className="label txt---400-14-20 ">
                        <FormattedMessage id="stock-transfer.stock-code" />
                    </div>
                    <div className='txt---400-14-20'>
                        {selectedSymbol}
                    </div>
                </div>
                {/* Khối lượng */}
                <div className="row-item">
                    <div className="label txt---400-14-20">
                        <FormattedMessage id="stock-transfer.mass" />
                    </div>
                    <div className='text-secondary-1 txt---400-14-20'>
                        <FormattedAmount value={quantityTransfer} />
                    </div>
                </div>
            </div>
        )
    }

    renderSaveVerify2fauth = () => {
        return (
            <div>
                <p className='n_text_near_active txt---400-14-20'><FormattedMessage id="save2fauth.note" /></p>
            </div>
        )
    }

    dateConverter = (startDate, timeEnd) => {
        let start = moment(startDate, 'DD/MM/YYYY');
        let end = moment(timeEnd, 'DD/MM/YYYY');
        let daysDiff = end.diff(start, 'days');
        return daysDiff
    }

    //Xác thực 2 lớp
    renderBody = () => {
        const { typeInfo } = this.props;
        switch (typeInfo) {
            case ModalConfirmType.CASH_TRANSFER_INTERNAL:           //Chuyển tiền nội bộ
                return this.renderCashTransferInternal();
            case ModalConfirmType.CASH_TRANSFER_EXTERNAL:                   //Chuyển tiền ngoài
                return this.renderCashTransferExternal();
            case ModalConfirmType.PAY_CCP:                                  //Nạp tiền ký quỹ CCP
                return this.renderPayCCP();
            case ModalConfirmType.WITHDRAWAL_CCP:                           //Rút tiền ký quỹ CCP
                return this.renderWithdrawalCCP();
            case ModalConfirmType.CASH_IN_ADVANCE:                          //Rút tiền ký quỹ CCP
                return this.renderCashInAdvance();
            case ModalConfirmType.UNHOLD_COREBANK:                          //Giải tỏa tiền CoreBank
                return this.renderUnholdCoreBank();
            case ModalConfirmType.HOLD_MONEY_BIDV:                      //Phong tỏa tiền @BIDV
                return this.renderHoldMoneyBidv();
            case ModalConfirmType.UNHOLD_MONEY_BIDV:                    //Giải tỏa tiền @BIDV
                return this.renderUnholdMoneyBidv();
            case ModalConfirmType.IFLEX_UN_AUTO:                    //Giải tỏa tiền @BIDV
                return this.renderIFlexUnAuto();
            case ModalConfirmType.IFLEX_AUTO:                    //Giải tỏa tiền @BIDV
                return this.renderIFlexAuto();
            case ModalConfirmType.TOGGLE_IFLEX:                    //Giải tỏa tiền @BIDV
                return this.renderToggleIFlex();
            case ModalConfirmType.DEPOSIT_WITHDRAW:                    //Giải tỏa tiền @BIDV
                return this.renderDepositWithdraw();

            case ModalConfirmType.REGISTER_VOUCHER: //Đăng ký gói Voucher
                return this.renderModalRegisterVoucher();
            case ModalConfirmType.IPO_REGISTER: //Đăng ký IPO
                return this.renderModalRegisterIPO();
            case ModalConfirmType.CHANGE_INFO: // Đổi thông tin
                return this.renderModalChangeInfo();
            case ModalConfirmType.BANK_ADDITION: // Thêm ngân hàng thụ hưởng
                return this.renderModalBankAddition();
            case ModalConfirmType.BANK_REMOVE: // Xóa ngân hàng thụ hưởng
                return this.renderModalBankRemove();
            case ModalConfirmType.REGISTER_PRODUCT:                          //Đăng ký sản phẩm
                return this.renderRegisterProduct();

            case ModalConfirmType.RIGHT_OFF:                    // Đăng ký quyền mua
                return this.renderRightOff();
            case ModalConfirmType.RIGHT_OFF_CANCEL:                   // Hủy Đăng ký quyền mua
                return this.renderCancelRightOff();
            case ModalConfirmType.DEBT_MANAGEMENT_PAY:                    // Quản lý dư nợ - Trả nợ
                return this.renderPayDebtManagement();
            case ModalConfirmType.CONFIRM_ORDER_ONLINE:                 // Quản lý dư nợ - Gia hạn nợ
                return this.renderConfirmOrderOnline()
            case ModalConfirmType.SIGN_UP_2_FACTOR_AUTH:                 // Quản lý dư nợ - Gia hạn nợ
                return this.renderSignUp2FactorAuth()
            case ModalConfirmType.FORGET_PASSWORD:                 // Quên mật khẩu
                return this.renderForgetPassword()
            case ModalConfirmType.CHANGE_PASSWORD:                 // Đổi mk đăng nhập / mk giao dịch / mật khẩu giao dịch thiết bị di động
                return this.renderChangePassword()
            case ModalConfirmType.TOKEN_LIFETIME:                 // Quản lý dư nợ - Gia hạn nợ
                return this.renderTokenLifetime()
            case ModalConfirmType.REGISTER_MARGIN_SUB_ACCOUNT:                 // Đăng ký tiểiu khoản Margin
                return this.renderRegisterMarginSubAccount()
            case ModalConfirmType.REGISTER_SMS:                 // Đăng ký tiểiu khoản Margin
                return this.renderRegisterSMS()

            case ModalConfirmType.BLB_ACK_CONFIRM:                    //ACK của lệnh Bloomberg cho lệnh mua và lệnh bán không cần cấp thêm chứng khoán
                return this.renderBlbAckConfirm();
            case ModalConfirmType.BLB_ACK_CONFIRM_SELL_CONDITION:     //ACK của lệnh Bloomberg cho lệnh Bán có điều kiện (cấp thêm số dư do tk lưu ký nơi khác)
                return this.renderBlbAckSellConditionConfirm();
            case ModalConfirmType.BLB_ACK_EDIT_CANCEL_ORDER:
                return this.renderBlbAckEditCancelOrder();
            case ModalConfirmType.BLB_DONE_4_DAY:                    //Done4Day của lệnh Bloomberg
                return this.renderDone4DayConfirm();
            case ModalConfirmType.BLB_REJECT:                    //Reject của lệnh Bloomberg
                return this.renderBlbRejectConfirm();
            case ModalConfirmType.BLB_CANCEL_FROM_FIXNET:                    //Cancel from Fixnet của lệnh Bloomberg
                return this.renderBlbCancelFromFixNet();

            case ModalConfirmType.STOCK_TRANSFER_INTERNAL:          //Chuyển chứng khoán nội bộ
                return this.renderStockTransferInternal();

            case ModalConfirmType.DEBT_MANAGEMENT_EXTEND:                 // Quản lý dư nợ - Gia hạn nợ
                return this.renderExtendDebtManagement(); //Dư nợ ký quỹ - gia hạn nợ

            case ModalConfirmType.SAVE_2_FACTOR_AUTH:           //Lưu xác thực 2 lớp theo phiên đăng nhập
                return this.renderSaveVerify2fauth();

            default:
                return <div></div>
                break;
        }
    }

    onSubmit = () => {
        const { dataInfo, clearInfoCheckAuth, toggle } = this.props;
        const { processTransfer } = { ...dataInfo };
        processTransfer()
    };


    onCloseModal = () => {
        const { toggle, dataInfo, currentAccountId, clearInfoCheckAuth } = this.props;
        const {
            authRef, backStep,
            initFuncData, onFinish, processAuthFunc, transactionCb, transactionFailCb
        } = { ...dataInfo };
        if (backStep) {
            backStep()
        } else {
            toggle()
        }
        clearInfoCheckAuth()
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setLoadStatus(nextProps.loadStatusScreenConfirm ? nextProps.loadStatusScreenConfirm : this.state.loadStatus)
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    twoFactorAuthCb = () => {
        const { toggle, dataInfo, currentAccountId } = this.props;
        const {
            authRef, backStep,
            initFuncData, onFinish, processAuthFunc, transactionCb, transactionFailCb
        } = { ...dataInfo };
        CommonUtils.middlewareOrder()
        transactionCb && transactionCb()
    }

    onHandleKeyDownCloseModal = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            if (!this.onCloseBtnRef.current || this.onCloseBtnRef.current.disabled) return;
            event.preventDefault();
            this.onCloseBtnRef.current.click();
        }
    }

    onHandleKeyDownSubmit = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            event.preventDefault();
            this.confirmBtnRef.current.click();
        }
    }

    render() {
        const { loadStatus } = this.state
        const { toggle, dataInfo, currentAccountId } = this.props;
        const {
            authRef, backStep,
            initFuncData, onFinish, processAuthFunc, transactionCb, transactionFailCb, showOrderSwitch, titleErrorApi, closeBtnIdMsg
        } = { ...dataInfo };

        const genAuthFunc = tradeApiService.resendTwoFactorCode;    // gen lại mã xác thực ở tradeApi
        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="tran-auth-screen-confirm">
                {this.renderBody()}
                <TransAuth2
                    containerClass="row gutters-5 align-items-center"
                    labelClass="col-6"
                    inputClass="col-6 custom-form-group"
                    inputClassAuth="col-6 custom-form-group-effect"
                    btnContainerClass="row gutters-5 justify-content-center"
                    autoFocus={true}
                    ref={authRef}
                    noButton={true}
                    confirmBtnRefProp={this.confirmBtnRef}
                    onFinish={onFinish}

                    doProcessData={initFuncData}
                    genAuthFunc={genAuthFunc}

                    twoFactorAuthFunc={processAuthFunc}
                    twoFactorAuthCb={this.twoFactorAuthCb}
                    twoFactorAuthFailCb={transactionFailCb}
                    // setLoadStatus={() => this.setLoadStatus}
                    setLoadStatus={status => this.setLoadStatus(status)}
                    isDisableConfirmBtn={this.state.loadStatus == LoadStatus.LOADING ? true : false}
                    showOrderSwitch={showOrderSwitch !== undefined ? showOrderSwitch : true} // true/false :Có cho phép tích lưu xác thực hay không (Tùy từng giao dịch, mặc định là có nếu không truyền)
                    fillPassword={true}
                    titleErrorApi={titleErrorApi}
                />
                <div className="row-step container-btn">
                    <button ref={this.onCloseBtnRef} className="btn-refresh txt---400-16-24 btn" onClick={() => this.onCloseModal()} onKeyDown={this.onHandleKeyDownCloseModal}>
                        <FormattedMessage id={closeBtnIdMsg ? closeBtnIdMsg : "common.btn-back"} />
                    </button>

                    <button ref={this.confirmBtnRef} className="btn-confirm txt---400-16-24 btn" onClick={() => this.onSubmit()} onKeyDown={this.onHandleKeyDownSubmit} autoFocus={this.props.verified}
                    >
                        <FormattedMessage id="common.btn-confirm" />
                    </button>
                </div>
            </RetryableContent >
        );
    }
}

const mapStateToProps = state => {
    let listIsOpenModal = state.layout.listIsOpenModal["C&B"]
    let _layoutPage = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    let _accountInfo = _layoutPage && _layoutPage.accountInfo;
    let { currentAccountId } = { ..._accountInfo }
    let _infoAuth = state.checkAuth.infoAuth["C&B"];
    let _authKey = _infoAuth.authKey;
    let _verified = _infoAuth[_authKey].verified;
    return {
        loadStatusScreenConfirm: listIsOpenModal["ScreenConfirm"].loadStatusScreenConfirm,
        currentAccountId: currentAccountId,
        verified: _verified,
        isSaveTradeAuthenticated: listIsOpenModal.isSaveTradeAuthenticated,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearInfoCheckAuth: (authKey) => dispatch(actions.clearInfoCheckAuth(authKey)),
        clearTradeAuthenticated: () => dispatch(actions.clearTradeAuthenticated()),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TranAuthScreenConfirm));
import { push } from "connected-react-router";
import $ from 'jquery';
import React, { Component } from 'react';
// import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from 'react-intl';
import { batch, connect } from 'react-redux';
import { withRouter } from 'react-router';

import AdvertisingModal from 'components/Modal/AdvertisingModal';
import SelectorAccountId from 'components/SelectorAccount/SelectorAccountId';
import CustomerSelectorModal from 'containers/CustomerSelector/CustomerSelectorModal';
// import vn from 'containers/Ekyc/HeaderEkyc';
// import RegisterAccount from 'containers/Ekyc/RegisterAccount';
import TradeAuthBlbModal from 'containers/PlaceOrder/CustomerOrganization/TradeAuthBlb/TradeAuthBlbModal';
import TradeAuthPutthroughModal from 'containers/PlaceOrder/PlaceOrderPuthrough/TradeAuthPutthrough/TradeAuthPutthroughModal';
import TradeAuthQuickOrderModal from 'containers/QuickOrder/PlaceOrderQuickOrder/TradeAuthQuickOrder/TradeAuthQuickOrderModal';
import ContactMobileModal from 'containers/Settings/ContactInfo/ContactMobileModal';
import Timer from 'containers/Trade/Footer/Timer';
import ChartMatchedIndayPopUp from 'containers/Trade/SymbolSelector/ChartMatchedIndayPopUp'
import SymbolDetailModal from 'containers/Trade/SymbolSelector/SymbolDetailModal';
import { authService } from 'services';
import { emitter, IS_SHOW_POPUP_LOGIN } from 'utils/EventEmitter';
import version from 'version';

import { ReactComponent as IconBtnMenuDark } from '../../assets/icons/header/btn_menu_dark.svg'
// import { ReactComponent as IconBtnMenuLight } from '../../assets/icons/header/btn_menu_light.svg'
// import { ReactComponent as DropSelectIcon_Dark } from '../../assets/icons/header/DropSelectIcon_Dark.svg'
// import { ReactComponent as DropSelectIcon_Light } from '../../assets/icons/header/DropSelectIcon_Light.svg'
import { ReactComponent as SaveVerify_Dark } from '../../assets/icons/header/SaveVerify_Dark.svg'
import { ReactComponent as SaveVerify_Light } from '../../assets/icons/header/SaveVerify_Dark.svg'
// Chua co icon light
// import { ReactComponent as HeaderLogo } from '../../assets/images/headerLogo.svg';
// import HeaderLogoDark from '../../assets/images/headerLogo_dark.svg';
import HeaderLogoDark from '../../assets/images/headerLogo_dark.png';
// import HeaderLogoLight from '../../assets/images/headerLogo_light.svg';
import Line from '../../assets/images/line.png';
import { buildAuthorizeUrl, buildAuthorizeUrlLogedIn } from '../../auth';
// import config from '../../combineConfig';
import LayoutPage from '../../components/LayoutPage/LayoutPage';
import TextToolTip from "../../components/TextToolTip";
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { menuBroker, menuCustomer } from '../../containers/MenuSideBar/MenuSideBarConfig';
// import reduxStore from '../../redux';
import { dispatch } from '../../redux';
import * as actions from "../../store/actions";
import { CommonOrderUtils, CommonUtils, CommonWidgetUtils, FOCUS_KEYS, ModalConfirmType, OPENNING_SCREEN_TYPE, ORDER_TYPE_USE, orderActions, path, Role, SELECTOR_ACCOUNT_SCREEN, SocketStatus, ToastUtil } from "../../utils";
import ModalActionMethodCondition from '../Account/Utilities/ModalActionMethodCondition/ModalActionMethodCondition';
import LoginModal from "../LoginModal/LoginModal"
import MenuSideBar from '../MenuSideBar/MenuSideBar';
// import MenuTool from '../MenuSideBar/MenuTool';
import QuickOrder from './../QuickOrder/QuickOrder';
import LoginAccountSelector from './LoginAccountSelector';
import ModalHeaderCreateLayout from './ModalHeaderCreateLayout';
import NotificationDropdownNew from './NotificationDropdownNew';
// import NotificationDropdown from "./NotificationDropdown";
import QuickSetting from "./QuickSetting";
import SearchDropdown from './SearchDropdown';
import SettingDisplay from './SettingDisplay/SettingDisplay';
import TranAuthScreenConfirmModal from './TranAuthScreenConfirm/TranAuthScreenConfirmModal';
import WaringQuoteChangeModal from './WaringQuoteChangeModal/WaringQuoteChangeModal';
import CorpbondDetailModal from 'containers/Trade/SymbolSelector/CorpbondDetailModal';
import { history } from '../../redux';

import './Header.scss';
const globalVar = window._env_

// class _TradeLayoutSettingButton extends Component {
//     render() {
//         const { toogleTradeLayoutSettingModal } = this.props;
//         return (
//             <button className="btn-widget-customize d-none d-md-inline-block" onClick={toogleTradeLayoutSettingModal}>
//                 <i className="fal fa-tools" />
//             </button>
//         );
//     }
// }

// const mapDispatchToPropsTradeLayout = dispatch => {
//     return {
//         toogleTradeLayoutSettingModal: () => dispatch(actions.toogleTradeLayoutSettingModal())
//     };
// };

// const TradeLayoutSettingButton = connect(null, mapDispatchToPropsTradeLayout)(_TradeLayoutSettingButton);


/*
 * See https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md for more information
 */
let isFistCheckUrl = true
class Header extends Component {
    initialState = {
        isSearchDetailModalOpen: false,
        isShowLoginAccountSelector: false,
        isShowSelectCustomer: false,
        isVerifiedSession: false,
        isResize: false,
    };
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        };
        this.listenToTheEmitter();
        this.LoginAccountSelectorRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.transAuthRef = React.createRef();
    }

    callback = (data) => {
        this.loginHandler(data ? data : '');
    }
    callback2 = (data) => {
        this.onCallbackUpdate(data ? data : '');
    }
    callback3 = (data) => {
        this._setState({ isVerifiedSession: data === 'true' ? true : false });
    }

    listenToTheEmitter() {
        emitter.on(IS_SHOW_POPUP_LOGIN, this.callback);

        emitter.on("SHOW_SAVE_LAYOUT", this.callback2);

        emitter.on("IS_SAVE_VERIFIED_CHANGE", this.callback3);
    }
    logoutHandler = () => {
        this.props.history.push(path.LOG_OUT);
    };

    setCurrentAccount = (event) => {
        this.props.setCurrentAccount(event.target.value);
    };

    toggleCustomerSelectorModalOpen = () => {
        this.props.setIsOpenModalByKey(
            "customerSelectorModalOpen",
            !this.props.customerSelectorModalOpen
        )
    };

    onClose = () => {
        this.props.setIsOpenModalByKey(
            "customerSelectorModalOpen",
            false
        )
    };

    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    onChangeConfigLayout = (event) => {
        this.props.setSelectedTradeLayout(event);
        this._setState(this.state);

        const {
            currentSymbol, defaultSymbol, navigate
        } = this.props;
        const tradeSymbol = currentSymbol ? currentSymbol.id : defaultSymbol;
        navigate(path.TRADE + (tradeSymbol ? '/' + tradeSymbol : ''))
    };

    onChangeMobileLayout = (event) => {
        this.props.setSelectedMobileLayout(event);
        this._setState(this.state);

        const {
            currentSymbol, defaultSymbol, navigate
        } = this.props;
        const tradeSymbol = currentSymbol ? currentSymbol.id : defaultSymbol;
        navigate(path.TRADE + (tradeSymbol ? '/' + tradeSymbol : ''))
    };
    // getUrlActiveTab = (e) => {
    //     e.preventDefault();
    //     // //console.log('Haki==getUrlActiveTab')
    //     if (this.props.isLoggedIn) {
    //         let newWindow = window.open(config.app.PRICE_BOARD_URL, '_blank', 'noopener noreferrer');
    //     }
    //     else {
    //         const { navigate } = this.props;
    //         const redirectPath = path.ERROR;
    //         navigate(`${redirectPath}`);
    //     }
    // }
    getUrlActiveTab = () => {
        const {
            currentSymbol, defaultSymbol } = this.props;
        const tradeSymbol = currentSymbol ? currentSymbol.id : defaultSymbol;
        return '/priceboard' + (tradeSymbol ? '/' + tradeSymbol : '')
        // navigate('/priceboard' + (tradeSymbol ? '/' + tradeSymbol : ''))
    }


    onChangeMenuActive = (keyName) => {
        let { menuHeader, navigate } = this.props
        let activeURLSideBar = menuHeader.menuSideBar[keyName].activeURLSideBar;
        let activeMenuHeader = menuHeader.activeMenuHeader
        navigate(activeURLSideBar)
        // this.props.onChangeMenuActive(keyName)
        // this.props.onChangeStatusMenuSideBar(1)
    }

    checkActiveMenu = (activeMenu, keyName) => {
        if (activeMenu === keyName) {
            return true
        }
        return false
    }

    onCloseModalLogin = () => {
        this._setState({ isOpenModalLogin: false })
    }

    onCloseModalDetail = () => {
        this.props.setSymbolDetailModalOpenState(false)
    }


    componentDidUpdate(prevProps, prevState) {
        const { isOpenLoginPopUp, userInfo, setKeyboardVisible, pathname } = this.props;
        const { isOpenModalLogin } = this.state
        const { isOpenLoginPopUp: prevIsOpenLoginPopUp, currentAccount: prevCurrentAccount } = prevProps;
        if (isOpenLoginPopUp !== prevIsOpenLoginPopUp && isOpenLoginPopUp) {
            this.loginHandler(this.props.custodycdOnOpenPopup);
            //reset redux
            this.props.setLoginPopupOnstart();
        }

        const openningScreenInfo = sessionStorage.getItem('openningScreenInfo') ? JSON.parse(sessionStorage.getItem('openningScreenInfo')) : null;

        if (this.props.isLoggedIn !== prevProps.isLoggedIn && this.props.isLoggedIn && openningScreenInfo) {
            this.onChangeMenuActiveNoEvent();
        }

        // Mở modal lưu xác thực 2 lớp lúc mới đăng nhập (trừ trường hợp modal reset pinpass phải mở  và trường hợp modal đổi core mở)
        if (this.props.isLoggedIn !== prevProps.isLoggedIn && this.props.isLoggedIn
            && !(userInfo.isresetpin === 'Y' || userInfo.isresetpass === 'Y')
            && !(userInfo.isChangePass === 'Y' || userInfo.isChangePass === 'W')
            && userInfo.isfirstlogin === 'N'
        ) {
            this.toggleSave2fauth();
        }

        if (this.props.isLoggedIn !== prevProps.isLoggedIn && this.props.isLoggedIn) {
            let isBroker = this.isBroker();
            if (isBroker) {
                if( userInfo.isChangePass !== 'Y'){
                    this.toggleSave2fauth();
                }

            }
        }

        if (isOpenModalLogin !== prevState.isOpenModalLogin) {
            setKeyboardVisible(isOpenModalLogin)
        }

        if (isFistCheckUrl && pathname !== prevProps.pathname) {
            isFistCheckUrl = false
            this.checkTabActiveUrl()
        }
    }


    checkTabActiveUrl = () => {
        // Chỉ nên gọi 1 lần lúc mới truy cập vào web
        const { layoutPage, changeLayoutPageActive, updatedLayoutPage } = this.props
        // let activeHeader = menuHeader.activeMenuHeader
        let currentUrl = history.location.pathname
        // //console.log("checkTabActiveUrl", currentUrl, layoutPage)
        Object.keys(layoutPage).forEach((item, i) => {
            if (item && item.indexOf('$TC_TEMP') == -1) {
                // //console.log("checkMenuActiveUrl_2", item, CommonUtils.isBroker())
                let activeURL = path.PRICEBOARD
                if (layoutPage && layoutPage[item] && layoutPage[item].activeURL) {
                    activeURL = layoutPage[item].activeURL
                }
                if (activeURL == currentUrl) {
                    if ((CommonUtils.isBroker() && item.startsWith("B")) || (!CommonUtils.isBroker() && item.startsWith("C"))) {
                        this.props.history.push(activeURL);
                        // //console.log("checkTabActiveUrl====222", currentUrl, activeURL, item, layoutPage[item])
                        batch(() => {
                            changeLayoutPageActive(item)
                            updatedLayoutPage({ showMenu: true })
                        })
                        return
                    }
                }
            }
        })
    }

    onChangeMenuActiveNoEvent = () => {
        // const { openningScreenInfo } = this.props;
        const openningScreenInfo = sessionStorage.getItem('openningScreenInfo') ? JSON.parse(sessionStorage.getItem('openningScreenInfo')) : null;
        if (!openningScreenInfo) return;
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData, updatePlaceQuickOrder, userInfo } = this.props;
        //console.log("openningScreenInfo---: ", openningScreenInfo)
        let screenType = openningScreenInfo['screenType'];
        let keyName = openningScreenInfo['keyName'];
        let isBroker = this.isBroker();
        let alowBroker = openningScreenInfo.data['allowBroker']
        let isPermissionSearch = openningScreenInfo.data['isPermissionSearch']
        let menuDisableBroker = openningScreenInfo.data['menuDisableBroker']
        let menuDisableCustomer = openningScreenInfo.data['menuDisableCustomer']
        let fun = openningScreenInfo.data['fun']
        if (isPermissionSearch) {
            //console.log("log loccoc ---: ", isPermissionSearch)
            if (isBroker) {
                //console.log("log loccoc 1---: ", isPermissionSearch)
                let checkExist = menuDisableBroker.includes(fun)
                if (checkExist) return
            } else {
                //console.log("log loccoc 2---: ", isPermissionSearch)
                let checkExist = menuDisableCustomer.includes(fun)
                if (checkExist) return
            }
        }
        if (isBroker && alowBroker === false) {
            //console.log("log loccoc 3---: ", isPermissionSearch)
            ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker');
            this.props.clearOpenningScreenInfo();
            return;
        }
        switch (screenType) {
            case OPENNING_SCREEN_TYPE.LAYOUT:
                let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + keyName) // Mặc định theo role
                batch(() => {
                    dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
                    dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
                });
                if (this.isBroker()) {
                    if (keyName === menuBroker['TRADE#01'].key) {
                        this.openNormalTrade()
                    } else if (keyName === menuBroker['TRADE#02'].key) {
                        this.openProTrade()
                    } else if (keyName === menuBroker['TRADE#03'].key) {
                        this.openPutthroughTrade()
                    } else if (keyName === menuBroker['TRADE#04'].key) {
                        this.openTPRLTradeNM()
                    } else if (keyName === menuBroker['ASSET_TOTAL'].key) {
                        this.openAssetTotal()
                    } else if (keyName === menuBroker['MARKET_WATCH'].key) {
                        this.openMarketWatch()
                    } else if (keyName === menuBroker['AI_TRADING'].key) {
                        this.openAiTrading()
                    } else if (keyName === menuBroker['IBROKER'].key) {
                        this.openIBroker()
                    }
                } else {
                    if (keyName === menuCustomer['TRADE#01'].key) {
                        this.openNormalTrade()
                    } else if (keyName === menuCustomer['TRADE#02'].key) {
                        this.openProTrade()
                    } else if (keyName === menuBroker['TRADE#03'].key) {
                        this.openPutthroughTrade()
                    } else if (keyName === menuCustomer['TRADE#04'].key) {
                        this.openTPRLTradeNM()
                    } else if (keyName === menuCustomer['ASSET_TOTAL'].key) {
                        this.openAssetTotal()
                    } else if (keyName === menuCustomer['MARKET_WATCH'].key) {
                        this.openMarketWatch()
                    } else if (keyName === menuCustomer['AI_TRADING'].key) {
                        this.openAiTrading()
                    } else if (keyName === menuBroker['IBROKER'].key) {
                        this.openIBroker()
                    }
                }

                if (!this.props.isDeskTop) {
                    this.props.onChangeStatusMenuSideBar(0)
                }
                break;
            case OPENNING_SCREEN_TYPE.SCREEN_MODAL:
                let { accountInfo, isDeskTop } = this.props
                //console.log("log---: ", accountInfo)
                if (openningScreenInfo['keyName'] === "menu-sidebar.title-5.4" && isBroker) {
                    ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker');
                    this.props.clearOpenningScreenInfo();
                    return;
                }

                if (openningScreenInfo['keyName'] === "menu-sidebar.title-3.8" && !isBroker && !userInfo.isCorebank) {
                    ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.no-corebank-permission');
                    this.props.clearOpenningScreenInfo();
                    return;
                }
                this.props.setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: true,
                    curScreenModalActive: openningScreenInfo['keyName'],
                    accountInfo: accountInfo,
                    data: openningScreenInfo['data'] || {}
                })
                if (!isDeskTop) {
                    this.props.onChangeStatusMenuSideBar(0)
                }
                break;
            case OPENNING_SCREEN_TYPE.MODAL:
                if (keyName === "menu-sidebar.title-3.4") { // Modal Phong tỏa/Giải tỏa tiền @BIDV ==> Cần case cho các modal mở từ menu
                    this.props.setIsOpenTradeMoneyBIDV(true);
                }
                if (keyName === "menu-tool.title-05") { // Modal Phong tỏa/Giải tỏa tiền @BIDV ==> Cần case cho các modal mở từ menu
                    if (this.isBroker()) {
                        ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker')
                    } else {
                        this.props.setIsOpenPayment(true);
                    }
                }
            case OPENNING_SCREEN_TYPE.ORDER: // Mở đặt lệnh
                clearDataPlaceQuickOrder()
                setIsOpenModalHaveData(keyName, {
                    isOpenQuickOrder: true
                });
                let dataQuickOrder = openningScreenInfo['data'] || {};
                updatePlaceQuickOrder({
                    ...dataQuickOrder
                })
                break;
            default:
                return;
        }
        this.props.clearOpenningScreenInfo();
    }

    openAssetTotal = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#ASSET_TOTAL" : "C#CS#ASSET_TOTAL"
        CommonUtils.openLayoutByKey(key)
    }

    openIBroker = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#IBROKER" : "C#CS#IBROKER"
        CommonUtils.openLayoutByKey(key)
    }


    openPutthroughTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = "B#CS#TRADE#TT"
        CommonUtils.openLayoutByKey(key)
    }

    openAiTrading = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#AI_TRADING" : "C#CS#AI_TRADING"
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        CommonUtils.openLayoutByKey(key)
    }

    openMarketWatch = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#MARKET_WATCH" : "C#CS#MARKET_WATCH"
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        CommonUtils.openLayoutByKey(key)
    }

    openProTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#NC" : "C#CS#TRADE#NC"
        CommonUtils.openLayoutByKey(key)
    }

    openNormalTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#CB" : "C#CS#TRADE#CB"
        CommonUtils.openLayoutByKey(key)
    }

    openTPRLTradeNM = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#TPRL_NM" : "C#CS#TRADE#TPRL_NM"
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
        let keyLayout = CommonWidgetUtils.getReplaceLayoutPageActive()
        if (CommonUtils.getOrderTypeUseLayout(keyLayout) == ORDER_TYPE_USE.TPRL_OR_ORDER || CommonUtils.getOrderTypeUseLayout(keyLayout) == ORDER_TYPE_USE.TPRL_NM_ORDER) {
            this.props.updatedLayoutPage({
                "showMenu": false,
            }, CommonWidgetUtils.getReplaceLayoutPageActive())
        }
        CommonUtils.openLayoutByKey(key)
    }


    handleBodyKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        const isAltKey = event.altKey;

        if ((isAltKey && keyCode === 50) || (isAltKey && keyCode === 98)) { // alt + 2 // mở layout đặt lệnh mua + focus ô nhập mã
            this.openNormalTradeFocus(orderActions.BUY);
            event.preventDefault();
        }

        if ((isAltKey && keyCode === 51) || (isAltKey && keyCode === 99)) { // alt + 3 // mở layout đặt lệnh bán + focus ô nhập mã
            this.openNormalTradeFocus(orderActions.SELL);
            event.preventDefault();
        }

        if ((isAltKey && keyCode === 52) || (isAltKey && keyCode === 100)) { // alt + 4 // focus vào danh mục đầu tư
            this.openTabOnNormalTrade('openPositions');
            event.preventDefault();
        }

        if ((isAltKey && keyCode === 53) || (isAltKey && keyCode === 101)) { // alt + 5 // Focus vào sổ lệnh
            this.openTabOnNormalTrade('normalOrderBook');
            event.preventDefault();
        }

    };

    // focus vào tab trên màn đặt lệnh thường
    openTabOnNormalTrade = (focuskey) => {
        if (!focuskey) return;
        let key = this.isBroker() ? "B#CS#TRADE#CB" : "C#CS#TRADE#CB"
        if (!this.onCheckLogined()) {
            return;
        }

        this.props.setIsOpenModalHaveData("ScreenModal", { // Đóng screenModal
            isOpenScreenModal: false,
        })
        let tabItem = {}
        if (focuskey === 'openPositions') {
            tabItem = {
                "id": "openPositions",
                "nameId": "trade.sections.titles.openPositions"
            }
        } else if (focuskey === 'normalOrderBook') {
            tabItem = {
                "id": "normalOrderBook",
                "nameId": "trade.sections.titles.normalOrderBook"
            }
        } else if (focuskey === 'orderActive') {
            tabItem = {
                "id": "orderActive",
                "nameId": "trade.sections.titles.orderActive"
            }
        }

        this.props.onChangeStatusMenuSideBar(0) // đóng menusidebar
        // window.scrollTo(0, document.body.scrollHeight);
        if (this.props.currentLayoutPageActive !== key) {
            CommonUtils.openLayoutByKey(key);
            let timer = setTimeout(() => {
                emitter.emit("SCROLL_TO_BOTTOM", tabItem);
                clearTimeout(timer)
            }, 300)
        } else {
            emitter.emit("SCROLL_TO_BOTTOM", tabItem);
        }
    }

    openNormalTradeFocus = (action) => {
        if (!action) return;
        const isBroker = this.isBroker();
        let key = isBroker ? "B#CS#TRADE#CB" : "C#CS#TRADE#CB"
        if (!this.onCheckLogined()) {
            return;
        }
        // this.props.setIsOpenModalHaveData("ScreenModal", { // Đóng screenModal
        //     isOpenScreenModal: false,
        // })
        this.props.onChangeStatusMenuSideBar(0) // đóng menusidebar

        let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + menuBroker['TRADE#01'].key) // Mặc định theo role
        batch(() => {
            dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
        });

        if (this.props.currentLayoutPageActive !== key) {
            CommonUtils.openLayoutByKey(key)
            this.props.clearDataPlaceQuickOrder(ORDER_TYPE_USE.NORMAL_ORDER, []);
            this.props.setFocusOnRenderOrder(isBroker ? FOCUS_KEYS.CUSTOMER_SELECT : FOCUS_KEYS.SYMBOL);
            let timer = setTimeout(() => {
                this.props.updatePlaceQuickOrder({
                    action: action,
                    symbolCurrent: null,
                }, ORDER_TYPE_USE.NORMAL_ORDER, 'HEADER');
                clearTimeout(timer)
            }, 300)
        } else {
            if (isBroker) {
                emitter.emit("onTabSymbolTrade_Broker") // Dùng trong trường hợp layout đặt lệnh thường đang mở
            } else {
                emitter.emit("FOCUS_SYMBOL") // Dùng trong trường hợp layout đặt lệnh thường đang mở
            }
            this.props.updatePlaceQuickOrder({
                action: action,
            }, ORDER_TYPE_USE.NORMAL_ORDER, 'HEADER');
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        this.checkTabActiveUrl()
        if (this.props.isOpenLoginPopUp) {
            this.loginHandler(this.props.custodycdOnOpenPopup);
            //reset redux
            this.props.setLoginPopupOnstart();
        }
        const openningScreenInfo = sessionStorage.getItem('openningScreenInfo') ? JSON.parse(sessionStorage.getItem('openningScreenInfo')) : null;
        if (openningScreenInfo && this.props.isLoggedIn) {
            this.onChangeMenuActiveNoEvent();
        }
        let isVerifiedSession = CommonUtils.getSaveVerifiedSession();
        this._setState({ isVerifiedSession: isVerifiedSession });
        document.addEventListener("mousedown", this.handleClickOutside); //auto close menu
        document.addEventListener('keydown', this.handleBodyKeyDown);

        // Mở modal quảng cáo
        // let notshowAd = localStorage.getItem('notshowAd'); // Đã tích không hiển thị lần sau
        // let showedAd = sessionStorage.getItem('showedAd') // Đã hiển thị modal cảnh báo chưa (theo tab)
        // if ((!notshowAd || notshowAd === 'false')) {
        //     if ((!showedAd || showedAd === 'false')) {
        //         this.setOpenAdvertisingModal(true);
        //     }
        // }

        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this._setState({ isResize: !this.state.isResize })
    }

    toggleSearchDetailModal = () => {
        this._setState({ isSearchDetailModalOpen: true })
    }

    onCloseSearchDetailModal = () => {
        this._setState({ isSearchDetailModalOpen: false })
    }

    onChangeStatusMenuSideBar = (e) => {
        //console.log("e menu ---: ", e)
        e.preventDefault()
        // e.stopPropogation()
        const { menuHeader } = this.props
        let statusMenuSideBar = menuHeader.menuSideBar.statusMenuSideBar
        if (statusMenuSideBar === 0) {
            emitter.emit("CLOSE_MENU_SUPPORT", "")
            this.props.onChangeStatusMenuSideBar(1)
            //console.log("onClickMenu 1")
        }
        else {
            this.props.onChangeStatusMenuSideBar(0)
            //console.log("onClickMenu 2")
        }

        // VIX : Không đóng đặt lệnh nhanh khi mở menu
        // this.props.setIsOpenModalHaveData("QuickOrder", {
        //     isOpenQuickOrder: false
        // })
    }

    setOpenQuickOrder = () => {
        const { setIsOpenModalHaveData, listIsOpenModal } = this.props
        // Đóng menusidebar trước khi mở lệnh nhanh
        this.props.onChangeStatusMenuSideBar(0);
        // Clear dữ liệu đặt lệnh trước khi mở // VIX yêu cầu clear , không cache
        this.props.clearDataPlaceQuickOrder(ORDER_TYPE_USE.QUICK_ORDER);
        setIsOpenModalHaveData("QuickOrder", {
            isOpenQuickOrder: !listIsOpenModal["QuickOrder"].isOpenQuickOrder
        })
    }


    closeScreenConfirmModal = () => {
        const { setIsOpenModalHaveData, listIsOpenModal, infoConfirmAuth, clearInfoCheckAuth } = this.props
        const { typeInfo, dataInfo } = { ...infoConfirmAuth }
        if (typeInfo === ModalConfirmType.HOLD_MONEY_BIDV || ModalConfirmType.UNHOLD_MONEY_BIDV) {
            this.props.setIsOpenTradeMoneyBIDV(false)
        }
        setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,

        })
        clearInfoCheckAuth()
    }

    onCallbackUpdate = (isCancel = true) => {

        const { isCheckExistLayoutSave, callBackSaveLayout } = CommonWidgetUtils.onCheckSaveLayout()
        if (isCheckExistLayoutSave) {
            callBackSaveLayout()
            return
        } else {
            this.toggleModalCreateLayout()
        }
    }

    toggleModalCreateLayout = (from) => {
        const { setIsOpenModalHaveData, listIsOpenModal, layoutsCustom } = this.props
        //console.log("binh_toggleModalCreateLayout", layoutsCustom)
        setIsOpenModalHaveData("CreatLayout", {
            isOpenModalCreatLayout: false,
            data: {},
        })

        if (from && from.includes("btn_logout")) {
            // const { isCheckExistLayoutSave, callBackSaveLayout } = this.onCallbackUpdate()
            // if (isCheckExistLayoutSave) {
            //     // callBackSaveLayout()
            //     return
            // }
            emitter.emit("SHOW_SAVE_LAYOUT", true)
        }
    }


    loginHandler = (custodycd = '') => {
        if (globalVar.app.IS_LOGIN_POPUP == true) {
            const { setCurrentAccount, setCurrentCustomer } = this.props;
            // build lại url Login ====== > Xóa list account và current account hiện tại 
            // Trường hợp bị đá ra do token hết hạn (chỉ redirect lại login do xử lý logout trên server ===> thông tin tài khoản và tiểu khoản cũ không bị xóa khi đăng nhập môi giới)
            //setCurrentAccount(null);
            setCurrentCustomer('');
            let data = {};
            if (this.props.location.search) {
                data.queryString = this.props.location.search;
            }
            let loginUrl = '';
            if (globalVar.switch.enableSaveLoginAccount) {
                loginUrl = buildAuthorizeUrlLogedIn(custodycd);
            } else {
                loginUrl = buildAuthorizeUrl(data);
            }
            this._setState({ isOpenModalLogin: true, urlSSO: loginUrl })

            // CommonUtils.createPopupWin(loginUrl, 'myWindow', 320, 570)
        }
        else {
            if (globalVar.app.IS_LOGIN_POPUP == 'local') {
                const { setCurrentAccount, setCurrentCustomer } = this.props;
                // build lại url Login ====== > Xóa list account và current account hiện tại 
                // Trường hợp bị đá ra do token hết hạn (chỉ redirect lại login do xử lý logout trên server ===> thông tin tài khoản và tiểu khoản cũ không bị xóa khi đăng nhập môi giới)
                setCurrentCustomer('');
                //setCurrentAccount(null);
                let data = {};
                if (this.props.location.search) {
                    data.queryString = this.props.location.search;
                }
                let loginUrl = '';
                if (globalVar.switch.enableSaveLoginAccount) {
                    loginUrl = buildAuthorizeUrlLogedIn(custodycd);
                } else {
                    loginUrl = buildAuthorizeUrl(data);
                }
                CommonUtils.createPopupWin(loginUrl, 'myWindow', 366, 633)
            } else {
                this.props.history.push(path.LOGIN);
            }
        }
    };



    onCloseTradeAuthPutthroughOrder = (event) => {
        const { setIsOpenModalHaveData, listIsOpenModal, clearInfoCheckAuth } = this.props
        setIsOpenModalHaveData("Putthrough", {
            isOpenModalPutthrough: false
        })
        clearInfoCheckAuth()
    }


    onCloseTradeAuthBlbOrder = (event) => {
        const { setIsOpenModalHaveData, listIsOpenModal, clearInfoCheckAuth } = this.props
        setIsOpenModalHaveData("BlbOrder", {
            isOpenModalBlb: false
        })
        clearInfoCheckAuth()
    }

    onCloseTradeAuthQuickOrder = (event) => {
        const { setIsOpenModalHaveData, listIsOpenModal, clearInfoCheckAuth } = this.props
        setIsOpenModalHaveData("QuickOrder", {
            isOpenModalTradeQuickOrder: false,
            dataShow: {},
        })
        clearInfoCheckAuth()
    }

    toggleSelectLoginAccount = () => {
        const { isShowLoginAccountSelector } = this.state;
        if (this.LoginAccountSelectorRef && this.LoginAccountSelectorRef.current) {
            this.LoginAccountSelectorRef.current.setOpenQuickSetting(!isShowLoginAccountSelector);
        }
    }

    updateSettingOpenState = (isOpen) => {
        this._setState({ isShowLoginAccountSelector: isOpen });
    }


    onCloseModalActionMethod = () => {
        const { setIsOpenModalHaveData, listIsOpenModal } = this.props

        this.props.setIsOpenModalByKey(
            "isOpenModalActionMethodCondition",
            !listIsOpenModal.isOpenModalActionMethodCondition
        )
    };

    returnLengthLayoutPage = () => {
        const { layoutPage } = this.props;
        const isBroker = this.isBroker();
        let lengthLayoutPage = 0
        Object.keys(layoutPage).map((item, i) => {
            if (isBroker && item.startsWith("B")) {
                if (layoutPage[item].showMenu) {
                    lengthLayoutPage += 1;
                    //console.log("returnLengthLayoutPage C")
                }
            } else if (!isBroker && item.startsWith("C")) {
                if (layoutPage[item].showMenu) {
                    lengthLayoutPage += 1;
                    //console.log("returnLengthLayoutPage B")
                }
            }
        })
        return lengthLayoutPage
    };

    componentWillUnmount() {
        emitter.removeListener(IS_SHOW_POPUP_LOGIN, this.callback);
        emitter.removeListener("SHOW_SAVE_LAYOUT", this.callback2);
        emitter.removeListener("IS_SAVE_VERIFIED_CHANGE", this.callback3);
        document.removeEventListener("mousedown", this.handleClickOutside);  //auto close menu
        document.removeEventListener('keydown', this.handleBodyKeyDown);
        window.removeEventListener('resize', this.handleResize);
        // this._setState({
        //     ...this.initialState
        // })
        // // this.LoginAccountSelectorRef = undefined;
        // this.setWrapperRef = undefined;
        // this.transAuthRef = undefined;
        this.mounted = false
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside = (e) => {
        // //console.log("e menu outSide --- : ", e.MouseEvent)
        let { isOpenScreenModal } = this.props
        let btnBars = $("#btn-bars");
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)
            && (!btnBars.is(e.target) && btnBars.has(e.target).length === 0) && !isOpenScreenModal
        ) {
            this.props.onChangeStatusMenuSideBar(0)
            // //console.log("onClickMenu outSide 1: ", e)
        }
    }

    setOpenAdvertisingModal = (isOpen) => {
        this.props.setIsOpenModalByKey(
            "isOpenAdvertisingModal",
            isOpen || false,
        )
    };

    setOpenChartIndayModal = (isOpen) => {
        this.props.setIsOpenModalByKey(
            "isOpenChartIndayModal",
            isOpen || false,
        )
    };

    setOpenContactModal = (isOpen) => {
        this.props.setIsOpenModalByKey(
            "isOpenContactModal",
            isOpen || false,
        )
    };

    setOpenSymbolDetailModal = (isOpen) => {
        this.props.setIsOpenModalByKey(
            "isOpenSymbolDetailModal",
            isOpen || false,
        )
    };

    setOpenCorpbondDetailModal = (isOpen) => {
        this.props.setIsOpenModalByKey(
            "isOpenCorpBondDetailModal",
            isOpen || false,
        )
    };


    onCheckLogined = () => {
        const { currentLayoutPageActive, isLoggedIn } = this.props
        if (!isLoggedIn) {
            if (currentLayoutPageActive &&
                (currentLayoutPageActive.includes("PRICEBOARD") ||
                    currentLayoutPageActive.includes("SYMBOL_DETAIL") ||
                    currentLayoutPageActive.includes("MARKET_WATCH") ||
                    currentLayoutPageActive.includes("AI_TRADING"))
            ) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    onCheckShowQuickOrder = () => {

        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            return false
        }
        return true
    }

    toggleSave2fauth = () => {
        authService.checkIsverifyAccessToken()
            .then(data => {
                if (Object.keys(data).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    // Chỉ mở màn lưu khi chưa được lưu xác thực
                    if (!verified) {
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })

                        let dataInfo = {
                            initFuncData: {},
                            authRef: this.transAuthRef,
                            processTransfer: () => { this.processTransfer() },
                            processAuthFunc: authService.processSave2FactorAuth,
                            transactionCb: () => {
                                ToastUtil.success('save2fauth.success');
                                CommonUtils.setIsStep2Authenticated('true');
                                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                    isOpenScreenConfirm: false,
                                    isCheckAuth2: true,
                                })
                            },
                            title: "save2fauth.title",
                            showOrderSwitch: false,
                            closeBtnIdMsg: 'common.skip'
                        }

                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.SAVE_2_FACTOR_AUTH,
                            dataInfo: dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })

                    }
                    // else {
                    //     ToastUtil.error('common.fail-to-precheck-transactions');
                    // }
                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions');
                }
            }).catch(err => {
                ToastUtil.errorApi(err, 'common.bad-request');
            })
    }

    processTransfer = () => {
        this.transAuthRef.current.processAuth();
    };

    classNameTimerByStatusSocket = (status, isCheckLogin) => {
        let { isLoggedIn } = this.props
        if (isCheckLogin && !isLoggedIn) return "text-green";
        // console.debug("classNameTimerByStatusSocket.:", status, isCheckLogin)
        switch (status) {
            case SocketStatus.CONNECTED: {
                return "text-green"
            }
            case SocketStatus.CONNECTING: {
                return "text-yellow-warning"
            }
            case SocketStatus.WARNING: {
                return "text-yellow-warning"
            }
            case SocketStatus.ERROR: {
                return "text-red"
            }
            case SocketStatus.DISCONNECTED: {
                return "text-red"
            }
            default: {
                return "text-red"
            }
        }
    }

    render() {
        // const { customerFullName,
        //     accounts, currentSymbol, currentAccount, currentCustomer, userInfo, defaultSymbol, isDerivativeAccount,
        //     isAccountRouteVisible, setAccountSidebarMobileVisible, isDeskTop,
        //     isSettingsRouteVisible, setSettingsSidebarMobileVisible,
        //     isAccountSidebarMobileVisible, isSettingsSidebarMobileVisible, menuHeader,
        //     currentLayoutPageActive,
        //     isLoggedIn, isFirstLogin, layoutPage, listIsOpenModal, infoConfirmAuth, layoutsCustom, isLayoutChange, defaultTheme, activeMenu, statusMenuSideBar
        // } = this.props;
        // const { isOpenModalLogin, urlSSO, isSearchDetailModalOpen, isVerifiedSession } = this.state



        const { customerFullName, userInfo,
            isAccountRouteVisible, setAccountSidebarMobileVisible, isDeskTop,
            isSettingsRouteVisible, setSettingsSidebarMobileVisible,
            isAccountSidebarMobileVisible, isSettingsSidebarMobileVisible,
            isLoggedIn, layoutPage, listIsOpenModal, infoConfirmAuth, defaultTheme, activeMenu, socketMarketStatus, socketStatus
        } = this.props;
        const { isOpenModalLogin, urlSSO, isVerifiedSession } = this.state
        const role = userInfo ? userInfo.role : '';


        // const tradeSymbol = currentSymbol ? currentSymbol.id : defaultSymbol;
        const isBroker = this.isBroker();
        const isShowQuickOrder = this.onCheckShowQuickOrder()
        // alert(currentLayoutPageActive)
        // let _isShowSaveLayout = false
        // let indexLayoutsCustomTemp = layoutsCustom && (layoutsCustom.findIndex((e) => e.id === currentLayoutPageActive) > -1)
        // let indexLayoutsDefault = layoutPage && (Object.keys(layoutPage).findIndex((e) => (e == currentLayoutPageActive && layoutPage[e].typeLayout != 'D')) > -1)
        //console.log('layoutsCustomTemp====', layoutPage, Object.keys(layoutPage), layoutsCustom, currentLayoutPageActive, indexLayoutsCustomTemp, indexLayoutsDefault)
        // if (indexLayoutsCustomTemp || indexLayoutsDefault) {
        //     _isShowSaveLayout = true
        // }
        //console.log('layoutsCustomTemp', layoutPage)
        // let isShowSaveLayout = (isLoggedIn && _isShowSaveLayout) ? true : false
        // let isShowSaveLayout = (isLoggedIn && _isShowSaveLayout && this.onCheckShowSaveLayout()) ? true : false
        // isShowSaveLayout = true
        // let isShowSaveLayout = (indexLayoutsCustomTemp > -1) ? true : false
        let lengthLayoutPage = this.returnLengthLayoutPage();

        const widthStyle = {
            width: (100 / lengthLayoutPage) + "%"
        }
        let isCheckMoblieLogout = !isLoggedIn && !isDeskTop
        // const isAccountRegisterPath = history.location.pathname.indexOf(path.ACCOUNT_REGISTER) > -1;
        // console.debug("Header.:render====", layoutPage)

        let classNameTimer = this.classNameTimerByStatusSocket(socketMarketStatus) // socket
        let classNameAlert = this.classNameTimerByStatusSocket(socketStatus, true) // socket_pushacc
        return (
            // isAccountRegisterPath ? <HeaderEkyc /> : 

            <header className="header-menu">
                {/* {isLoggedIn && */}
                {
                    listIsOpenModal["CreatLayout"].isOpenModalCreatLayout &&
                    <ModalHeaderCreateLayout
                        toggle={this.toggleModalCreateLayout}
                        isOpen={listIsOpenModal["CreatLayout"].isOpenModalCreatLayout}
                        data={listIsOpenModal["CreatLayout"].data}
                    ></ModalHeaderCreateLayout>
                }
                <div
                    id="btn-bars"
                    className="btn-menu"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.onChangeStatusMenuSideBar(e)}
                >
                    {/* <i class="fas fa-bars btn-menu"></i> */}
                    <IconBtnMenuDark />
                    {/* {defaultTheme === "dark" ? <IconBtnMenuDark /> : <IconBtnMenuLight />} */}
                </div>
                {/* {statusMenuSideBar ? <MenuSideBar setWrapperRef={this.setWrapperRef} activeMenu={activeMenu} /> : null} */}
                <MenuSideBar setWrapperRef={this.setWrapperRef} activeMenu={activeMenu} />
                {/* <MenuTool /> */}
                {/* } */}
                <div className="container-logo">
                    <img src={CommonUtils.renderIconTheme(HeaderLogoDark, HeaderLogoDark)} alt="LOGO" />
                    <span className='version-fs2pro'>{version}</span>
                </div>
                {isLoggedIn &&
                    <button className="btn-login txt---400-14-20 item-center text-custom" onClick={this.setOpenQuickOrder}
                        disabled={!isShowQuickOrder}
                    >
                        <FormattedMessage id="priceboard.analytic.placeOrder" />
                    </button>}

                {isAccountRouteVisible && (
                    <button className="sidebar-toggle" onClick={() => { setAccountSidebarMobileVisible(isAccountSidebarMobileVisible ? false : true) }}>
                        <i className="far fa-bars" />
                    </button>
                )}

                {isSettingsRouteVisible && (
                    <button className="sidebar-toggle" onClick={() => { setSettingsSidebarMobileVisible(isSettingsSidebarMobileVisible ? false : true) }}>
                        <i className="far fa-bars" />
                    </button>
                )}
                <img style={{ paddingTop: "10px", paddingBottom: "10px" }} src={Line} alt="LOGO" />
                <div className={"content-header " + (isLoggedIn ? " content-header-login " : " ")}>
                    <div id="layout-top" className={"navLinks" + (isCheckMoblieLogout ? " top-navLinks-moblie-logout" : "")}
                        style={{
                            maxWidth: isLoggedIn ? `calc(100% - ${CommonUtils.getWidthId("content-setting-header")}px)` : "none",
                        }}
                    >
                        {
                            Object.keys(layoutPage).map((item, i) => {
                                if (isBroker && item.startsWith("C")) {
                                    return
                                } else if (!isBroker && item.startsWith("B")) {
                                    return
                                }
                                // if (layoutPage[item].showMenu) {
                                //     lengthLayoutPage += 1;
                                // }

                                const layoutTop = CommonUtils.getWidthId("layout-top")
                                //console.log("binh_layoutTop", layoutTop, lengthLayoutPage * 210)
                                let styleDiv = (layoutTop !== 0 && layoutTop < lengthLayoutPage * 210) ? widthStyle : {}
                                // //console.log("item---: ", item)
                                return (
                                    layoutPage[item].showMenu && <LayoutPage
                                        isRenderTabActive={true}
                                        item={item}
                                        data={layoutPage[item]}
                                        isBroker={isBroker}
                                        widthStyle={styleDiv}
                                        lengthLayoutPage={lengthLayoutPage}
                                    ></LayoutPage>
                                )
                            })
                        }
                    </div>
                    <div id="content-setting-header" className="content-setting-header">
                        {/* {!isMobile && isShowSaveLayout &&
                            <button type="button"
                                className="btn-save-layout txt---400-14-20 item-center"
                                onClick={() => this.saveActiveLayout()}
                            >
                                <FormattedMessage id="layout.save" />
                            </button>} */}

                        <Timer format='HH:mm:ss' type='TIME' className={'time bg-title txt---500-14-20 ' + classNameTimer} />
                        {(isLoggedIn && role === Role.BROKER) && (
                            <div className={"customer-container select-accountId " + (isCheckMoblieLogout ? "off-customer-container" : "")} >
                                <div className='full-name d-flex select-accountId txt---400-14-20'>
                                    <div className='custodycd-container'>{this.props.userInfo.username || ''}</div>
                                </div>
                                <div className="d-flex h-100 select-accountId fullname txt---500-14-20" dir="rtl">
                                    {customerFullName}
                                </div>
                            </div>
                        )}
                        {(isLoggedIn && role === Role.CUSTOMER) && (
                            <div className={"customer-container select-accountId txt---500-14-20 " + (isCheckMoblieLogout ? "off-customer-container" : "")}>
                                <div className='full-name d-flex select-accountId txt---400-14-20'>
                                    <div className='custodycd-container'>{this.props.userInfo.username || ''}</div>
                                    <img style={{ marginRight: "8px", marginLeft: "10px", height: "16px" }} src={Line} alt="LOGO1" />
                                    <SelectorAccountId
                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                        accountInfo={this.props.accountInfo}
                                        disabled={false}
                                        isHeader={true}
                                    />
                                </div>
                                <div className="d-flex h-100 select-accountId fullname" dir="rtl">
                                    {customerFullName}
                                </div>
                            </div>
                        )}
                        {(isLoggedIn &&
                            <img style={{ paddingTop: "10px", paddingBottom: "10px" }} src={Line} alt="LOGO" />
                        )}
                        <div className={"actions" + (role === Role.BROKER ? " broker" : " customer")}>
                            {(role === Role.BROKER) && this.props.customerSelectorModalOpen && (
                                <CustomerSelectorModal
                                    isOpen={this.props.customerSelectorModalOpen}
                                    // toggle={this.toggleCustomerSelectorModalOpen}
                                    onClose={this.onClose}
                                    onCustomerDidSelected={this.toggleCustomerSelectorModalOpen}
                                />
                            )}
                            <div className='btn-container'>
                                {isLoggedIn &&
                                    <div className="txt---500-14-20">
                                        {!isVerifiedSession ?
                                            <button
                                                id='button-save-verify'
                                                className="btn-icon"
                                                onClick={() => { this.toggleSave2fauth() }}
                                            >
                                                {defaultTheme === 'dark' ? <SaveVerify_Dark /> : <SaveVerify_Light />}
                                            </button>
                                            :
                                            <button
                                                id='button-save-verify'
                                                className="btn-icon save-verified"
                                                disabled
                                            >
                                                {defaultTheme === 'dark' ? <SaveVerify_Dark /> : <SaveVerify_Light />}
                                            </button>
                                        }
                                        <TextToolTip
                                            // placement='bottom-end'
                                            tooltipText={"Lưu xác thực"}
                                            targetID={`button-save-verify`}
                                        ></TextToolTip>
                                    </div>}
                                {globalVar.switch.enableSaveLoginAccount
                                    ?
                                    (<LoginAccountSelector
                                        wrappedComponentRef={ref => this.LoginAccountSelectorRef.current = ref} // Thêm current để giống format của ref (dùng nếu component có lớp bao ngoài connect , withRouter ,...)
                                        loginHandler={this.loginHandler}
                                        isBroker={isBroker}
                                        className="btn-icon"
                                        isLoggedIn={isLoggedIn}
                                        updateSettingOpenState={this.updateSettingOpenState}
                                    />)
                                    :
                                    (<QuickSetting isBroker={isBroker} className="btn-icon" />)
                                }
                                <SearchDropdown className="btn-icon" />
                                <NotificationDropdownNew className={"btn-icon " + classNameAlert} />
                                <div className='btn-icon'><SettingDisplay className="btn-icon" /></div>
                                {/* {isFirstLogin && <FirstLoginModal />} */}
                            </div>
                        </div>
                        {!isLoggedIn && <button onClick={() => { this.loginHandler('') }} className="btn-login txt---400-14-20 text-custom" >
                            <FormattedMessage id="login.title" />
                        </button>}
                    </div>
                </div>
                {isLoggedIn && isShowQuickOrder &&
                    <QuickOrder
                        isOpen={listIsOpenModal["QuickOrder"].isOpenQuickOrder}
                        orderTypeUse={ORDER_TYPE_USE.QUICK_ORDER}
                    // isOpen={true}
                    />
                }
                {isOpenModalLogin && <LoginModal isOpenModalLogin={isOpenModalLogin}
                    onCloseModalLogin={this.onCloseModalLogin}
                    urlSSO={urlSSO}
                />}


                {listIsOpenModal["ScreenConfirm"].isOpenScreenConfirm && <TranAuthScreenConfirmModal
                    isOpen={listIsOpenModal["ScreenConfirm"].isOpenScreenConfirm}
                    isCheckAuth2={listIsOpenModal["ScreenConfirm"].isCheckAuth2}
                    toggle={this.closeScreenConfirmModal}
                    onClose={this.closeScreenConfirmModal}
                    infoConfirmAuth={infoConfirmAuth}
                />}
                {listIsOpenModal["QuickOrder"].isOpenModalTradeQuickOrder && <TradeAuthQuickOrderModal
                    isOpen={listIsOpenModal["QuickOrder"].isOpenModalTradeQuickOrder}
                    toggle={this.onCloseTradeAuthQuickOrder}
                    onClose={this.onCloseTradeAuthQuickOrder}
                    orderTypeUse={isShowQuickOrder ? ORDER_TYPE_USE.QUICK_ORDER : ORDER_TYPE_USE.NORMAL_ORDER}
                />}
                {listIsOpenModal["Putthrough"].isOpenModalPutthrough && <TradeAuthPutthroughModal
                    isOpen={listIsOpenModal["Putthrough"].isOpenModalPutthrough}
                    toggle={this.onCloseTradeAuthPutthroughOrder}
                    onClose={this.onCloseTradeAuthPutthroughOrder}
                    orderTypeUse={ORDER_TYPE_USE.PUTTHROUGH_TRADE_ORDER}
                />}
                {listIsOpenModal["BlbOrder"].isOpenModalBlb && <TradeAuthBlbModal
                    isOpen={listIsOpenModal["BlbOrder"].isOpenModalBlb}
                    toggle={this.onCloseTradeAuthBlbOrder}
                    onClose={this.onCloseTradeAuthBlbOrder}
                    orderTypeUse={listIsOpenModal["BlbOrder"].orderTypeUse}
                />}
                {listIsOpenModal.isOpenAdvertisingModal && <AdvertisingModal
                    isOpen={listIsOpenModal.isOpenAdvertisingModal}
                    toggle={() => this.setOpenAdvertisingModal(!listIsOpenModal.isOpenAdvertisingModal)}
                    onClose={() => this.setOpenAdvertisingModal(false)}
                />}
                {listIsOpenModal.isOpenChartIndayModal && <ChartMatchedIndayPopUp
                    isOpen={listIsOpenModal.isOpenChartIndayModal}
                    toggle={() => this.setOpenChartIndayModal(!listIsOpenModal.isOpenChartIndayModal)}
                    onClose={() => this.setOpenChartIndayModal(false)}
                />}
                {listIsOpenModal.isOpenContactModal && <ContactMobileModal
                    isOpen={listIsOpenModal.isOpenContactModal}
                    toggle={() => this.setOpenContactModal(!listIsOpenModal.isOpenContactModal)}
                    onClose={() => this.setOpenContactModal(false)}
                />}
                {listIsOpenModal.isOpenModalActionMethodCondition &&
                    <ModalActionMethodCondition
                        isOpen={listIsOpenModal.isOpenModalActionMethodCondition}
                        toggle={this.onCloseModalActionMethod}
                        onClose={this.onCloseModalActionMethod}
                    />}
                {listIsOpenModal["WaringQuoteChange"].isOpenWaringQuoteChange &&
                    <WaringQuoteChangeModal
                        isOpen={listIsOpenModal["WaringQuoteChange"].isOpenWaringQuoteChange}
                        toggle={() => {
                            this.props.setIsOpenModalHaveData("WaringQuoteChange", {
                                isOpenWaringQuoteChange: !listIsOpenModal["WaringQuoteChange"].isOpenWaringQuoteChange
                            })
                        }}
                    />}

                {listIsOpenModal.isOpenSymbolDetailModal && <SymbolDetailModal
                    isOpen={listIsOpenModal.isOpenSymbolDetailModal}
                    toggle={() => this.setOpenSymbolDetailModal(!listIsOpenModal.isOpenSymbolDetailModal)}
                    onClose={() => this.setOpenSymbolDetailModal(false)}
                />}

                {listIsOpenModal.isOpenCorpBondDetailModal && <CorpbondDetailModal
                    isOpen={listIsOpenModal.isOpenCorpBondDetailModal}
                    toggle={() => this.setOpenCorpbondDetailModal(!listIsOpenModal.isOpenCorpBondDetailModal)}
                    onClose={() => this.setOpenCorpbondDetailModal(false)}
                />}
            </header >
        );
    }
}

const mapStateToProps = state => {
    // console.log("binh--header", state)
    const userInfo = state.user.userInfo;
    const isDerivativeAccount = CommonUtils.isDerivativeAccount(state.account.currentAccount);
    const defaultSymbol = userInfo ? (isDerivativeAccount ? userInfo.defaultSymbolFds : userInfo.defaultSymbol) : null;
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const activeMenu = (state.user.userInfo && state.user.userInfo.menuHeader) && state.user.userInfo.menuHeader.activeMenuHeader
    const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    // const layoutPage = state.layout.layoutPage
    let layoutPage = state.layout.layoutPage
    // Object.keys(layoutPage)
    //     .filter(key => key.substring(0, 2).indexOf(userInfo.role + "#") < 0)
    //     .forEach(key => delete layoutPage[key]);
    const currentCustomer = (layoutPage[currentLayoutPageActive] && layoutPage[currentLayoutPageActive].accountInfo) && layoutPage[currentLayoutPageActive].accountInfo.curCustomer
    let _accountInfo = layoutPage && layoutPage[currentLayoutPageActive] && layoutPage[currentLayoutPageActive]["accountInfo"]
    let { accounts } = { ..._accountInfo }
    let _infoConfirmAuth = state.checkAuth.infoAuth["C&B"]["infoConfirmAuth"]
    const layoutsCustom = state.layout.layoutsCustom
    // const isLayoutChange = state.layout.isLayoutChange
    let isDeskTop = state.app.isDeskTop
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    // let statusMenuSideBar = state.user.userInfo.menuHeader.menuSideBar.statusMenuSideBar
    // //console.log("loc---state ", activeMenu)

    return {
        // statusMenuSideBar: statusMenuSideBar,
        menuHeader: menuHeader,
        accounts: accounts,
        accountInfo: _accountInfo,
        currentSymbol: state.app.tradeCurrentSymbol,
        currentCustomer: currentCustomer,
        currentAccount: state.account.currentAccount,
        isLoggedIn: state.user.isLoggedIn,
        // isFirstLogin: state.user.isLoggedIn && userInfo.isFirstLogin,
        isAccountRouteVisible: state.app.isAccountRouteVisible,
        isSettingsRouteVisible: state.app.isSettingsRouteVisible,
        userInfo: userInfo,
        defaultSymbol: defaultSymbol,
        isDerivativeAccount: isDerivativeAccount.currentAccount,
        // tradeLayoutId: state.app.currentTradeLayoutId,
        // currentMobileLayoutId: state.app.currentMobileLayoutId,
        isAccountSidebarMobileVisible: state.app.isAccountSidebarMobileVisible,
        isSettingsSidebarMobileVisible: state.app.isSettingsSidebarMobileVisible,
        // accountMenuPath: state.app.accountMenuPath,
        // settingMenuPath: state.app.settingMenuPath,
        location: state.router.location,
        activeMenu: activeMenu,
        layoutPage: layoutPage,
        listIsOpenModal: state.layout.listIsOpenModal["C&B"],
        infoConfirmAuth: _infoConfirmAuth,
        currentLayoutPageActive: currentLayoutPageActive,
        layoutsCustom: layoutsCustom,
        // isLayoutChange,
        isDeskTop: isDeskTop,
        isOpenLoginPopUp: state.user.isOpenLoginPopUp,
        custodycdOnOpenPopup: state.user.custodycdOnOpenPopup,
        defaultTheme: defaultTheme,
        customerSelectorModalOpen: state.layout.listIsOpenModal["C&B"].customerSelectorModalOpen,
        openningScreenInfo: state.layout.openningScreenInfo,
        customerFullName: state.user.customerFullName,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        pathname: state.router.location.pathname,
        socketMarketStatus: state.app.socketMarketStatus,
        socketStatus: state.app.socketStatus
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentAccount: (accountId) => dispatch(actions.setCurrentAccount(accountId)),
        setAccountSidebarMobileVisible: (visible) => dispatch(actions.setAccountSidebarMobileVisible(visible)),
        setSettingsSidebarMobileVisible: (visible) => dispatch(actions.setSettingsSidebarMobileVisible(visible)),
        toogleTradeLayoutSettingModal: () => dispatch(actions.toogleTradeLayoutSettingModal()),
        setSelectedTradeLayout: (tradeLayoutId) => dispatch(actions.setSelectedTradeLayout(tradeLayoutId)),
        setSelectedMobileLayout: (tradeLayoutId) => dispatch(actions.setSelectedMobileLayout(tradeLayoutId)),
        navigate: (path) => dispatch(push(path)),
        setSymbolDetailModalOpenState: (isOpen) => dispatch(actions.setSymbolDetailModalOpenState(isOpen)),
        // fetchCustomerAccounts: () => dispatch(actions.fetchCustomerAccounts()),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
        onChangeStatusMenuSideBar: (statusMenuSideBar) => dispatch(actions.onChangeStatusMenuSideBar(statusMenuSideBar)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setLoginPopupOnstart: (isOpen, custodycd) => dispatch(actions.setLoginPopupOnstart(isOpen, custodycd)),
        setIsOpenModalByKey: (key, status) => dispatch(actions.setIsOpenModalByKey(key, status)),
        clearOpenningScreenInfo: () => dispatch(actions.clearOpenningScreenInfo()),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        changeLayoutPageActive: (currentTabActive) => dispatch(actions.changeLayoutPageActive(currentTabActive)),
        setIsOpenTradeMoneyBIDV: (isOpen) => dispatch(actions.setIsOpenTradeMoneyBIDV(isOpen)),
        // onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),
        // onChangeStatusMenuSideBar: (statusMenuSideBar) => dispatch(actions.onChangeStatusMenuSideBar(statusMenuSideBar)),
        setIsOpenPayment: (isOpen) => dispatch(actions.setIsOpenPayment(isOpen)),
        //QuickOrder
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        clearInfoCheckAuth: (authKey) => dispatch(actions.clearInfoCheckAuth(authKey)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setFocusOnRenderOrder: (focusKey) => dispatch(actions.setFocusOnRenderOrder(focusKey)),
    };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));

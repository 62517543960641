import { push } from "connected-react-router";
import $ from 'jquery'
// https://codesandbox.io/s/react-pro-sidebar-sample-forked-dpx15?file=/src/MenuSideBar.js
// https://www.npmjs.com/package/react-pro-sidebar


import React, { Component } from 'react';
import { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Menu, MenuItem, ProSidebar, SidebarContent, SubMenu } from "react-pro-sidebar";
import { batch, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { checkBrokerRule } from 'components/BrockerScreenRule/BrokerScreenRuleConfig';
import CustomScrollbars from 'components/CustomScrollbars';
import NetModal from 'containers/Settings/Net/NetModal';
import ShortCutListModal from 'containers/Settings/ShortCutList/ShortCutListModal';
import { emitter } from 'utils/EventEmitter';

import { ReactComponent as AccountManagementIconLight } from '../../assets/icons/menu/AccountManagementIcon_Light.svg'
// import { ReactComponent as AccountManagementIconActive } from "react-device-detect";

import { ReactComponent as AccountManagementIconActive } from '../../assets/icons/menu/AccountManagementIconActive.svg'
import { ReactComponent as AccountManagementIconDark } from '../../assets/icons/menu/AnalysisSectionIcon_Dark.svg'
import { ReactComponent as MoneyTransactionIconDark } from '../../assets/icons/menu/MoneyTransactionIcon_Dark.svg'
import { ReactComponent as MoneyTransactionIconLight } from '../../assets/icons/menu/MoneyTransactionIcon_Light.svg'
import { ReactComponent as MoneyTransactionIconActive } from '../../assets/icons/menu/MoneyTransactionIconActive.svg'
import { ReactComponent as PlaceOrderIconDark } from '../../assets/icons/menu/PlaceOrderIcon_Dark.svg'
import { ReactComponent as PlaceOrderIconLight } from '../../assets/icons/menu/PlaceOrderIcon_Light.svg'
import { ReactComponent as PlaceOrderIconActive } from '../../assets/icons/menu/PlaceOrderIconActive.svg'
import { ReactComponent as PriceBoardIconDark } from '../../assets/icons/menu/PriceBoardIcon_Dark.svg'
import { ReactComponent as PriceBoardIconLight } from '../../assets/icons/menu/PriceBoardIcon_Light.svg'
//menu
import { ReactComponent as PriceBoardIconActive } from '../../assets/icons/menu/PriceBoardIconActive.svg'
import { ReactComponent as ReportIconDark } from '../../assets/icons/menu/ReportIcon_Dark.svg'
import { ReactComponent as ReportIconLight } from '../../assets/icons/menu/ReportIcon_Light.svg'
import { ReactComponent as ReportIconActive } from '../../assets/icons/menu/ReportIconActive.svg'
import { ReactComponent as SecurityIconDark } from '../../assets/icons/menu/SecurityIcon_Dark.svg'
import { ReactComponent as SecurityIconLight } from '../../assets/icons/menu/SecurityIcon_Light.svg'
import { ReactComponent as SecurityIconActive } from '../../assets/icons/menu/SecurityIconActive.svg'
import { ReactComponent as SettingIconDark } from '../../assets/icons/menu/SettingIcon_Dark.svg'
import { ReactComponent as SettingIconLight } from '../../assets/icons/menu/SettingIcon_Light.svg'
import { ReactComponent as SettingIconActive } from '../../assets/icons/menu/SettingIconActive.svg'
import { ReactComponent as StockTradingIconDark } from '../../assets/icons/menu/StockTradingIcon_Dark.svg'
import { ReactComponent as StockTradingIconLight } from '../../assets/icons/menu/StockTradingIcon_Light.svg'
import { ReactComponent as StockIcon } from '../../assets/icons/menu/StockIcon.svg'
import { ReactComponent as StockIconInActive } from '../../assets/icons/menu/StockIconInActive.svg'

// import { ReactComponent as StockTradingIconActive } from '../../assets/icons/menu/SettingIcon_Light.svg'

import { ReactComponent as StockTradingIconActive } from '../../assets/icons/menu/StockTradingIconActive.svg'
import { ReactComponent as SupportIconDark } from '../../assets/icons/menu/SupportIcon_Dark.svg'
import { ReactComponent as SupportIconLight } from '../../assets/icons/menu/SupportIcon_Light.svg'
import { ReactComponent as SupportIconActive } from '../../assets/icons/menu/SupportIconActive.svg'
import { ReactComponent as UtilitiesIconDark } from '../../assets/icons/menu/UtilitiesIcon_Dark.svg'
import { ReactComponent as UtilitiesIconLight } from '../../assets/icons/menu/UtilitiesIcon_Light.svg'
import { ReactComponent as UtilitiesIconActive } from '../../assets/icons/menu/UtilitiesIconActive.svg'
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { LanguageLayout } from '../../containers/MenuSideBar/listMapMenuSideBar';
import { dispatch } from '../../redux';
import * as actions from "../../store/actions";
import { BROKER_VIEW_RULE, CommonUtils, CommonWidgetUtils, OPENNING_SCREEN_TYPE, Role } from "../../utils";
import CustomIcon from './../../components/CustomIcon';
import { getMenuActiveByPath, menuBroker, menuCustomer } from './MenuSideBarConfig'
import { history } from '../../redux';
import _ from 'lodash'

import "./MenuSideBar.scss"
import "react-pro-sidebar/dist/css/styles.css";

class Aside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenSupport: false,
            isOpenShortCut: false
        }
        this.listenToTheEmitter();
    }
    callback = (data) => {
        this.toggleOpenSupport(true)
    }

    callback1 = (data) => {
        this._setState({ isOpenSupport: false });
    }

    listenToTheEmitter = () => {
        emitter.on("OPEN_MENU_SUPPORT", this.callback);
        emitter.on("CLOSE_MENU_SUPPORT", this.callback1);
    }

    toggleOpenSupport = (open) => {
        let check = open ? open : !this.state.isOpenSupport
        this._setState({ isOpenSupport: check });
    }

    componentWillUnmount() {
        this.mounted = false
        emitter.removeListener("OPEN_MENU_SUPPORT", this.callback);
        emitter.removeListener("CLOSE_MENU_SUPPORT", this.callback1);
    }


    componentDidUpdate(prevProps, prevState) {
        const { setPopUpTitle } = this.props;
        const { isContactMobileModalOpen } = this.state;
        const { isContactMobileModalOpen: prevIsContactMobileModalOpen } = prevState;
        if (isContactMobileModalOpen !== prevIsContactMobileModalOpen && !isContactMobileModalOpen) {
            setPopUpTitle('');
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        let pathname = history.location.pathname
        if (pathname) {
            let currentMenuActive = getMenuActiveByPath(pathname)
            // //console.log('componentDidMount.:currentMenuActive=' + currentMenuActive)
            if (currentMenuActive && currentMenuActive.key) {
                // Haki.: Set lại layout khi theo pathname
                let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + currentMenuActive.key) // Mặc định theo role
                if (this.onCheckLogined(_OBJLAYOUT)) {
                    return;
                }
                const { updatedLayoutPage } = this.props
                batch(() => {
                    updatedLayoutPage({
                        "showMenu": true
                    }, _OBJLAYOUT)
                    dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
                    dispatch(actions.changeLayoutPageActive(_OBJLAYOUT))
                });
            }
        }
    }

    onCheckLogined = (keyName) => {
        if (CommonWidgetUtils.onCheckPermistionKeyLayout(keyName)) {
            // if (keyName.indexOf("PRICEBOARD#CB") < 0) {
            // Check Login với menu khác bảng giá
            let isLoggedIn = CommonUtils.checkLogined()
            if (!isLoggedIn) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    updateOpenningScreenInfo = (keyName, screenType, data = {}) => {
        // Chưa login ===> Lưu lại thông tin màn hình muốn mở => sau khi login mở lại màn hình với thông tin được lưu
        const { setOpenningScreenInfo } = this.props;
        setOpenningScreenInfo(keyName, screenType, data);
    }

    onChangeMenuActive = (e, keyName, activeURL, allowBroker = true) => {
        // Haki.: set currentMenuActive
        if (!this.onCheckLogined(keyName)) {
            this.updateOpenningScreenInfo(keyName, OPENNING_SCREEN_TYPE.LAYOUT, { allowBroker: allowBroker, isPermissionSearch: false });
            return;
        }
        e.stopPropagation();
        let { navigate } = this.props;
        activeURL && navigate(activeURL);
        let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + keyName) // Mặc định theo role
        batch(() => {
            // VIX : Không đóng đặt lệnh nhanh khi mở menu
            // dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
            // dispatch(actions.changeMenuActive(keyName))
            dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
            // dispatch(actions.onChangeActiveURLSideBar(activeURL))
            // dispatch(actions.changeLayoutPageActive(_OBJLAYOUT))
            // dispatch(actions.setCurrentCustomer((data && data.accountInfo) && data.accountInfo.curCustomer))
        });
        if (this.isBroker()) {
            if (keyName === menuBroker['TRADE#01'].key) {
                this.openNormalTrade()
            } else if (keyName === menuBroker['TRADE#02'].key) {
                this.openProTrade()
            } else if (keyName === menuBroker['TRADE#03'].key) {
                this.openPutthroughTrade()
            } else if (keyName === menuBroker['ASSET_TOTAL'].key) {
                this.openAssetTotal()
            } else if (keyName === menuBroker['CUSTOMER_ORGANIZATION'].key) {
                this.openCustomerOrganization()
            } else if (keyName === menuBroker['PRICEBOARD'].key) {
                this.openPriceboard()
            } else if (keyName === menuBroker['SYMBOL_DETAIL'].key) {
                this.openSymbolDetail()
            }
        } else {
            if (keyName === menuCustomer['TRADE#01'].key) {
                this.openNormalTrade()
            } else if (keyName === menuCustomer['TRADE#02'].key) {
                this.openProTrade()
            } else if (keyName === menuCustomer['ASSET_TOTAL'].key) {
                this.openAssetTotal()
            } else if (keyName === menuCustomer['PRICEBOARD'].key) {
                this.openPriceboard()
            } else if (keyName === menuBroker['SYMBOL_DETAIL'].key) {
                this.openSymbolDetail()
            }
        }
        this.closeMenuSideBar(0)
        let { isDeskTop } = this.props
        if (!isDeskTop) {
            this.closeMenuSideBar(0)
        }
    }

    openFuncionalModal = (titleId) => {
        const { intl } = this.props;
        // Tắt screenModal nếu đang mở
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ''
        })

        switch (titleId) {
            case 'menu-sidebar.title-3.4': // Phong tỏa / Giải tỏa tiền
                if (!this.onCheckLogined('menu_sidebar_title_3_4')) {
                    this.updateOpenningScreenInfo('menu_sidebar_title_3_4', OPENNING_SCREEN_TYPE.MODAL, { allowBroker: false, isPermissionSearch: false });
                    return;
                }
                // e.stopPropagation(); // gọi ở onClick
                this.props.setIsOpenTradeMoneyBIDV(true);
                this.props.setPopUpTitle(intl.formatMessage({ id: 'trade-money-bidv.trade-money-bidv' }))
                break;
            case 'menu-sidebar.title-8.1': // Tổng đài
                this.setIsOpenContactMobile(true);
                break;
            case 'menu-sidebar.title-8.4': // mạng lưới
                this.setIsOpenNetwork(true)
                break;
            case 'menu-sidebar.title-8.5': // danh mục phím tắt
                this.setIsOpenShortCutList(true)
                break;
            default:
                return null;
        }
    }

    setIsOpenShortCutList = (isOpen) => {
        const { setPopUpTitle, intl } = this.props;
        this._setState({ isOpenShortCut: isOpen });
        setPopUpTitle(intl.formatMessage({ id: 'menu-sidebar.title-8.5' }));
    }


    closeMenuSideBar = (status) => {
        //console.log("moblie --- ", status)
        this.props.onChangeStatusMenuSideBar(status)
    }

    openPutthroughTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = "B#CS#TRADE#TT"
        CommonUtils.openLayoutByKey(key)
    }

    openCustomerOrganization = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = "B#CS#CUSTOMER_ORGANIZATION"
        CommonUtils.openLayoutByKey(key)
    }

    openAssetTotal = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#ASSET_TOTAL" : "C#CS#ASSET_TOTAL"
        CommonUtils.openLayoutByKey(key)
    }

    openProTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#NC" : "C#CS#TRADE#NC"
        CommonUtils.openLayoutByKey(key)
    }

    openNormalTrade = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#TRADE#CB" : "C#CS#TRADE#CB"
        //console.log("binh_openNormalTrade")
        CommonUtils.openLayoutByKey(key)
    }

    openPriceboard = () => {
        const { layoutPage, updatedLayoutPage, changeLayoutPageActive } = this.props
        let key = this.isBroker() ? "B#CS#PRICEBOARD#CB" : "C#CS#PRICEBOARD#CB"
        CommonUtils.openLayoutByKey(key)
    }

    openSymbolDetail = () => {
        let key = this.isBroker() ? "B#CS#SYMBOL_DETAIL" : "C#CS#SYMBOL_DETAIL";
        CommonUtils.openLayoutByKey(key)
    }


    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    // addNewLayoutPage = (e, keyName, activeURL, name) => {
    //     e.stopPropagation();
    //     // this.props.onChangeMenuActive(keyName)
    //     // this.props.onChangeActiveURLSideBar(activeURL)
    //     let { layoutPage, currentLayoutPageActive } = this.props
    //     let key = ""
    //     while (true) {
    //         key = uuid();
    //         const checkKeyExists = Object.keys(layoutPage).some(item => item === key)

    //         if (!checkKeyExists) {
    //             break
    //         }
    //     }

    //     // Thiếu lấy keyName theo cur layout active
    //     let newData = {}
    //     newData[key] = {
    //         "keyName": keyName,
    //         "activeURL": activeURL,
    //         "accountInfo": layoutPage[currentLayoutPageActive].accountInfo,
    //         "symbols": ""
    //     }
    //     this.props.addLayoutPage(newData)
    // }

    checkMenuActive = (menuActive) => {
        // Haki.: check menu active css
        let pathname = history.location.pathname
        if (pathname) {
            let currentMenuActive = getMenuActiveByPath(pathname)
            // //console.log('checkMenuActive.:', menuActive + '===' + currentMenuActive)
            if (currentMenuActive && currentMenuActive.key && currentMenuActive.key.indexOf(menuActive) > -1) {
                return true
            }
        }
        return false
    }

    onChangeMenuActiveModal = (keyName, allowBroker = true) => {
        // Haki.: set CurrentMenuActiveModal
        if (!this.onCheckLogined(keyName)) {
            this.updateOpenningScreenInfo(keyName, OPENNING_SCREEN_TYPE.SCREEN_MODAL, { allowBroker: allowBroker, isPermissionSearch: false });
            return;
        }
        let { accountInfo, isDeskTop } = this.props
        //console.log("log---: ", accountInfo)
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: true,
            curScreenModalActive: keyName,
            accountInfo: accountInfo,
        })
        if (!isDeskTop) {
            this.closeMenuSideBar(0)
        }
    }

    checkMenuActiveModal = (keyName) => {
        // Haki.: check menu modal active css
        // //console.log('checkMenuActiveModal', keyName, this.props.curScreenModalActive)
        if (this.props.curScreenModalActive.indexOf(keyName) > -1) {
            return true
        }
        return false
    }

    onOpenModalActionMethod = () => {
        const { setPopUpTitle, intl } = this.props;
        if (!this.onCheckLogined('menu_sidebar_title_9_4')) {
            this.updateOpenningScreenInfo('menu_sidebar_title_9_4', OPENNING_SCREEN_TYPE.MODAL, { allowBroker: false, isPermissionSearch: false });
            return;
        }
        this.props.setIsOpenModalByKey(
            "isOpenModalActionMethodCondition",
            true,
        )
        setPopUpTitle(intl.formatMessage({ id: 'menu-sidebar.title-9.4' }));
    };

    genderIcon = (keyIcon) => {
        const { defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < PriceBoardIconDark /> : < PriceBoardIconLight />
                break;
            case 2:
                return defaultTheme === 'dark' ? < PlaceOrderIconDark /> : < PlaceOrderIconLight />
                break;
            case 3:
                return defaultTheme === 'dark' ? < MoneyTransactionIconDark /> : < MoneyTransactionIconLight />
                break;
            case 4:
                return defaultTheme === 'dark' ? < StockTradingIconDark /> : < StockTradingIconLight />
                break;
            case 5:
                return defaultTheme === 'dark' ? < UtilitiesIconDark /> : < UtilitiesIconLight />
                break;
            case 6:
                return defaultTheme === 'dark' ? < AccountManagementIconDark /> : < AccountManagementIconLight />
                break;
            case 7:
                return defaultTheme === 'dark' ? < SupportIconDark /> : < SupportIconLight />
                break;
            case 8:
                return defaultTheme === 'dark' ? < SettingIconDark /> : < SettingIconLight />
                break;
            case 9:
                return defaultTheme === 'dark' ? < ReportIconDark /> : < ReportIconLight />
                break;
            case 10:
                return defaultTheme === 'dark' ? < SecurityIconDark /> : < SecurityIconLight />
            case 11:
                return <StockIconInActive />
                break;
            default:
                break;
        }
    }

    setIsOpenNetwork = () => {
        const { setPopUpTitle, setIsOpenNetwork, intl } = this.props;
        setIsOpenNetwork(true)
        setPopUpTitle(intl.formatMessage({ id: 'menu-sidebar.title-8.4' }))
    }

    getActiveClassName = (keyName, type) => {
        let isActive = false;
        if (type === OPENNING_SCREEN_TYPE.MODAL) {
            return 'txt---400-14-20'
        } else if (type === OPENNING_SCREEN_TYPE.SCREEN_MODAL) {
            isActive = this.checkMenuActiveModal(keyName);
        } else if (type === OPENNING_SCREEN_TYPE.LAYOUT) {
            isActive = this.checkMenuActive(keyName);
        }

        return isActive ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar txt---400-14-20 '
    }


    renderBrokerMenuItem = (keyName, msgId, type = OPENNING_SCREEN_TYPE.SCREEN_MODAL, idAttr, displayIcon, activeIcon, iconId, dataOpen = {}) => {
        let checkVisible = checkBrokerRule(keyName);
        let style = displayIcon ? { paddingLeft: "16px" } : {}
        let classNameActive = this.getActiveClassName(keyName, type);

        if (checkVisible !== BROKER_VIEW_RULE.HIDE) {
            return (
                <Menu iconShape="circle" style={style} >
                    <MenuItem
                        id={idAttr || ''}
                        onClick={(e) => {
                            if (type === OPENNING_SCREEN_TYPE.SCREEN_MODAL) {
                                this.onChangeMenuActiveModal(keyName)
                            } else if (type === OPENNING_SCREEN_TYPE.LAYOUT) {
                                this.onChangeMenuActive(e, dataOpen.key, dataOpen.path);
                            } else if (type === OPENNING_SCREEN_TYPE.MODAL) {
                                this.openFuncionalModal(keyName)
                            }
                        }}
                        icon={displayIcon && (<CustomIcon isSidebar={true}>{this.checkMenuActive(keyName) ? activeIcon && activeIcon() : this.genderIcon(iconId || 1)} </ CustomIcon >)}
                        className={classNameActive}
                    >
                        {<FormattedMessage id={msgId} />}
                    </MenuItem>
                </Menu>)
        }
        else return null
    }

    getRender_Menu_Broker = () => {
        let { setWrapperRef, menuHeader, rtl, handleToggleSidebar, isOpenScreenModal, accountInfo, isDeskTop, contactInfos, clientConfig } = this.props
        let widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
        let collapsed = widthMenuSideBar === "75px" ? true : false
        if (!isDeskTop) {
            collapsed = false
        }
        let position = isOpenScreenModal ? "" : "absolute"
        return (
            <div ref={setWrapperRef} className="ProSidebar" style={{ height: "100vh" }}>
                <ProSidebar
                    image={false}
                    rtl={rtl}
                    collapsed={collapsed}
                    toggled={true}
                    breakPoint="lg"
                    // onToggle={handleToggleSidebar}
                    style={{ width: widthMenuSideBar, position: position }}
                >
                    <CustomScrollbars>
                        <SidebarContent style={{ textAlign: "center", width: widthMenuSideBar }}>
                            {/*====================================================================================*/}
                            {/* 1. Bảng giá  */}
                            {/*<Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <MenuItem
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActive(menuBroker['PRICEBOARD'].key) ? <PriceBoardIconActive /> : this.genderIcon(1)} </ CustomIcon >}
                                    className={this.checkMenuActive(menuBroker['PRICEBOARD'].key) ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar txt---400-14-20 '}
                                >
                                    {<FormattedMessage id="menu-sidebar.title-1" />}
                                    <Link to={menuBroker['PRICEBOARD'].path} onClick={(e) => this.onChangeMenuActive(e, menuBroker['PRICEBOARD'].key, menuBroker['PRICEBOARD'].path)} />
                                </MenuItem>
                            </Menu>*/}

                            {this.renderBrokerMenuItem(menuBroker['PRICEBOARD'].key, "menu-sidebar.title-1", OPENNING_SCREEN_TYPE.LAYOUT, '', true, () => { return (<PriceBoardIconActive />) }, 1, { key: menuBroker['PRICEBOARD'].key, path: menuBroker['PRICEBOARD'].path })}
                            {this.renderBrokerMenuItem(menuBroker['SYMBOL_DETAIL'].key, "menu-sidebar.title-2.5", OPENNING_SCREEN_TYPE.LAYOUT, '', true, () => { return (<StockIcon />) }, 11, { key: menuBroker['SYMBOL_DETAIL'].key, path: menuBroker['SYMBOL_DETAIL'].path })}

                            {/*====================================================================================*/}
                            {/* 2. Đặt lệnh */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}
                            >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-2" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActive("#TRADE") ? <PlaceOrderIconActive /> : this.genderIcon(2)} </ CustomIcon >}
                                    className={this.checkMenuActive("#TRADE") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 2.1 Đặt lệnh thông thường*/}
                                    {/*<Menu iconShape="circle">
                                        < MenuItem
                                            id='menu_sidebar_title_2_1'
                                            onClick={(e) => this.onChangeMenuActive(e, menuCustomer['TRADE#01'].key, menuCustomer['TRADE#01'].path)}
                                            className={this.checkMenuActive(menuCustomer['TRADE#01'].key) ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-2.1" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem(menuCustomer['TRADE#01'].key, 'menu-sidebar.title-2.1', OPENNING_SCREEN_TYPE.LAYOUT, 'menu-sidebar.title-2.1', false, null, 0, { key: menuCustomer['TRADE#01'].key, path: menuCustomer['TRADE#01'].path })}
                                    {/* 2.2 Đặt lệnh Pro*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => this.onChangeMenuActive(e, menuBroker['TRADE#02'].key, menuBroker['TRADE#02'].path)}
                                            className={this.checkMenuActive(menuBroker['TRADE#02'].key) ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-2.2" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {/* 2.3 Đặt lệnh thỏa thuận*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => this.onChangeMenuActive(e, menuBroker['TRADE#03'].key, menuBroker['TRADE#03'].path)}
                                            className={this.checkMenuActive(menuBroker['TRADE#03'].key) ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-2.3" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {/* 2.4 Đặt lệnh cho KHTC*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => this.onChangeMenuActive(e, menuBroker['CUSTOMER_ORGANIZATION'].key, menuBroker['CUSTOMER_ORGANIZATION'].path)}
                                        className={this.checkMenuActive(menuBroker['CUSTOMER_ORGANIZATION'].key) ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        {/*<Link to={menuBroker['TRADE#03'].path} onClick={(e) => this.onChangeMenuActive(e, menuBroker['CUSTOMER_ORGANIZATION'].key, menuBroker['CUSTOMER_ORGANIZATION'].path)} />
                                        <FormattedMessage id="menu-sidebar.title-2.4" />
                                    </MenuItem>
                                </Menu>*/}
                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 3. Giao dịch tiền */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-3" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-3") ? <MoneyTransactionIconActive /> : this.genderIcon(3)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 3.1 Chuyển tiền nội bộ*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.1") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.1" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-3.1', 'menu-sidebar.title-3.1', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-3.1', false, null, 0, {})}

                                    {/* 3.1 Chuyển tiền ra ngoài*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.7") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.7") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.7" />
                                    </MenuItem>
                                </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-3.7', 'menu-sidebar.title-3.7', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-3.7', false, null, 0, {})}
                                    {/* 3.2 Nộp/Rút tiền tài khoản phái sinh*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_3_2'
                                        onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.2" />
                                        <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-3.2" />}
                                            targetID='menu_sidebar_title_3_2'
                                        ></TextTooltip>
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.3 Nộp/Rút tiền ký quỹ CCP     */}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_3_3'
                                        onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.3" />
                                        {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-3.3" />}
                                            targetID='menu_sidebar_title_3_3'
                                        ></TextTooltip>
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.4 Phong tỏa/Giải tỏa*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_3_4'
                                        onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.4" />
                                        <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-3.4" />}
                                            targetID='menu_sidebar_title_3_4'
                                        ></TextTooltip>
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.5 Ứng trước tiền bán*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.5") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.5" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-3.5', 'menu-sidebar.title-3.5', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-3.5', false, null, 0, {})}
                                    {/* 3.6 iFlex*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.6") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.6" />
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.8 Giải tỏa tiền tài khoản @*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.8", false) }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.8") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.8" />
                                        </MenuItem>
                                    </Menu>
                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 4. Giao dịch chứng khoán  */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    title={(<span id='menu_sidebar_title_4'><FormattedMessage id="menu-sidebar.title-4" /></span>)}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-4") ? <StockTradingIconActive /> : this.genderIcon(4)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* <TextTooltip
                                    placement='top-start'
                                    tooltipText={<FormattedMessage id="menu-sidebar.title-4" />}
                                    targetID='menu_sidebar_title_4'
                                ></TextTooltip> */}
                                    {/* 4.1 Chuyển chứng khoán*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.1") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-4.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-4.1" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-4.1', 'menu-sidebar.title-4.1', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-4.1', false, null, 0, {})}
                                    {/* 4.2 Đăng ký quyền mua*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_4_2'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.2") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-4.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-4.2" />
                                        </MenuItem>
                                    </Menu>*/}

                                    {this.renderBrokerMenuItem('menu-sidebar.title-4.2', 'menu-sidebar.title-4.2', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu_sidebar_title_4_2', false, null, 0, {})}
                                    {/* 4.3 Đăng ký mua IPO*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_4_3'
                                        onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-4.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        {/* <Link to="/" onClick={(e) => this.onChangeLayoutTrade(e, TABS_ON_FOOTER.BASEORDER)} />
                                        <FormattedMessage id="menu-sidebar.title-4.3" />
                                        {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-4.3" />}
                                            targetID='menu_sidebar_title_4_3'
                                        ></TextTooltip> 
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 4.4 Tra cứu Thực hiện quyền*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_4_4'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.4") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-4.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-4.4" />

                                        </MenuItem>
                                    </Menu>*/}

                                    {this.renderBrokerMenuItem('menu-sidebar.title-4.4', 'menu-sidebar.title-4.4', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu_sidebar_title_4_4', false, null, 0, {})}
                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 6. Quản lý tài khoản*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}
                            >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-6" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-6") ? <AccountManagementIconActive /> : this.genderIcon(6)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-6") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 6.1 Tài sản*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => this.onChangeMenuActive(e, menuBroker['ASSET_TOTAL'].key, menuBroker['ASSET_TOTAL'].path)}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}     >
                                            <FormattedMessage id="menu-sidebar.title-6.1" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.1', 'menu-sidebar.title-6.1', OPENNING_SCREEN_TYPE.LAYOUT, 'menu-sidebar.title-6.1', false, null, 0, { key: menuBroker['ASSET_TOTAL'].key, path: menuBroker['ASSET_TOTAL'].path })}

                                    {/* 6.2 Quản lý dư nợ*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.2") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.2" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.2', 'menu-sidebar.title-6.2', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.2', false, null, 0, {})}

                                    {/* 6.3 Sao kê tiền*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.3") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.3" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.3', 'menu-sidebar.title-6.3', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.3', false, null, 0, {})}

                                    {/* 6.4 Sao kê chứng khoán*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.4") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.4" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.4', 'menu-sidebar.title-6.4', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.4', false, null, 0, {})}

                                    {/* 6.5 Lịch sử đặt lệnh*/}
                                    {/* <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.5") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.5") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.5" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.5', 'menu-sidebar.title-6.5', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.5', false, null, 0, {})}

                                    {/* 6.10 Tổng hợp lệnh khớp*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.10") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.10") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.10" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.10', 'menu-sidebar.title-6.10', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.10', false, null, 0, {})}

                                    {/* 6.6 Lịch sử lệnh điều kiện*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.6") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-6.6") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-6.6" />
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 6.7 Lịch sử khớp lệnh*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.7") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-6.7") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-6.7" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 6.8 Lãi/Lỗ đã thực hiện*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.8") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.8") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.8" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.8', 'menu-sidebar.title-6.8', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.8', false, null, 0, {})}

                                    {/* 6.9 Báo cáo tổng hợp lãi lỗ*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_6_8'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.9") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.9") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.9" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-6.9', 'menu-sidebar.title-6.9', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-6.9', false, null, 0, {})}

                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/*   5. Tiện ích*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}
                            >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-5" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-5") ? <UtilitiesIconActive /> : this.genderIcon(5)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-5") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 5.1 Đăng ký  gói Voucher*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        className={this.checkMenuActiveModal("menu-sidebar.title-5.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-5.1" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 5.2 Xác nhận lệnh*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-5.2") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-5.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-5.2" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-5.2', 'menu-sidebar.title-5.2', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-5.2', false, null, 0, {})}

                                    {/* 5.3 Tra cứu danh mục Margin*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_5_3'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-5.3") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-5.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-5.3" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-5.3', 'menu-sidebar.title-5.3', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-5.3', false, null, 0, {})}

                                    {/* 5.4 Cảnh báo giá*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker') }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-5.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-5.4" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-5.4', 'menu-sidebar.title-5.4', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-5.4', false, null, 0, {})}

                                </SubMenu>
                            </Menu>


                            {/*====================================================================================*/}
                            {/* 7. Báo cáo môi giới*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-7" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-7") ? <ReportIconActive /> : this.genderIcon(9)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-7") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 7.1 Tra cứu tài khoản khách hàng*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_7_1'
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.1") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-7.1") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                    >
                                        
                                        <FormattedMessage id="menu-sidebar.title-7.1" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 7.2 Tra cứu tài sản khách hàng*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_7_2'
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.2") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-7.2") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                    >
                                        
                                        <FormattedMessage id="menu-sidebar.title-7.2" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 7.3 Quản trị khách hàng*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.3") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-7.3") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-7.3" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 7.4 Tra cứu doanh số*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.4") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-7.4") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-7.4" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 7.5 Tra cứu trạng thái xác nhận lệnh*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.5") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-7.5") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-7.5" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 7.6 Báo cáo tra cứu lệnh */}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.6") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-7.6") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-7.6" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 7.7 DSKH cần bổ sung kỹ quỹ*/}
                                    {/* <Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_7_7'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.7") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-7.7") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-7.7" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-7.7', 'menu-sidebar.title-7.7', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-7.7', false, null, 0, {})}

                                    {/* 7.8 DSKH còn dư nợ ký quỹ*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_7_8'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.8") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-7.8") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-7.8" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-7.8', 'menu-sidebar.title-7.8', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-7.8', false, null, 0, {})}

                                    {/* 7.9 DSKH bị xử lý bán*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_7_9'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.9") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-7.9") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-7.9" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-7.9', 'menu-sidebar.title-7.9', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-7.9', false, null, 0, {})}

                                    {/* 7.11 Báo cáo tra cứu thông tin tài sản theo chứng khoán */}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-7.11', 'menu-sidebar.title-7.11', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-7.11', false, null, 0, {})}
                                    {/* 7.10 Báo cáo số liệu giao dịch*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_7_10'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-7.10") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-7.10") ? 'activeMenuSideBar txt---400-14-20' : 'notActiveMenuSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-7.10" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-7.10', 'menu-sidebar.title-7.10', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-7.10', false, null, 0, {})}

                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 10. Bảo mật*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}>
                                <MenuItem
                                    onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-10", false) }}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-10") ? <SecurityIconActive /> : this.genderIcon(10)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-10") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {<FormattedMessage id="menu-sidebar.title-10" />}
                                </MenuItem>
                            </Menu>

                            {/*this.renderBrokerMenuItem('menu-sidebar.title-10', 'menu-sidebar.title-10', OPENNING_SCREEN_TYPE.SCREEN_MODAL, 'menu-sidebar.title-10', true, () => { return <SecurityIconActive /> }, 10, {})*/}

                            {/*====================================================================================*/}
                            {/* 8. Hỗ trợ*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    open={this.state.isOpenSupport}
                                    onOpenChange={() => this.toggleOpenSupport()}
                                    title={<FormattedMessage id="menu-sidebar.title-8" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActive("menu-sidebar.title-8") ? <SupportIconActive /> : this.genderIcon(7)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-8") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 8.1 Tổng đài*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            // onClick={() => this.setIsOpenContactMobile(true)}
                                            onClick={() => this.openFuncionalModal('menu-sidebar.title-8.1')}
                                            className="txt---400-14-20"
                                        >
                                            <FormattedMessage id="menu-sidebar.title-8.1" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-8.1', 'menu-sidebar.title-8.1', OPENNING_SCREEN_TYPE.MODAL, 'menu-sidebar.title-8.1', false, null, 0, {})}

                                    {/* 8.2 Chat với tư vấn viên*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        className="txt---400-14-20"
                                    >
                                        {contactInfos.chat_link ? (<a href={contactInfos.chat_link} target='_blank'>
                                            <FormattedMessage id="menu-sidebar.title-8.2" />
                                        </a>) : (<FormattedMessage id="menu-sidebar.title-8.2" />)}
                                    </MenuItem>
                                </Menu> */}
                                    {/* 8.3 Sổ tài giao dịch*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            className="txt---400-14-20"
                                        >
                                            {clientConfig.configData && clientConfig.configData.handbook_web ? (<a href={clientConfig.configData.handbook_web} target='_blank'>
                                                <FormattedMessage id="menu-sidebar.title-8.3" />
                                            </a>) : (<FormattedMessage id="menu-sidebar.title-8.3" />)}
                                        </MenuItem>
                                    </Menu>
                                    {/* 8.4 Mạng lưới*/}
                                    {/*<Menu iconShape="circle">
                                        <MenuItem
                                            className="txt---400-14-20"
                                            onClick={(e) => {
                                                this.openFuncionalModal('menu-sidebar.title-8.4')
                                            }}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-8.4" />
                                        </MenuItem>
                                    </Menu>*/}
                                    {this.renderBrokerMenuItem('menu-sidebar.title-8.4', 'menu-sidebar.title-8.4', OPENNING_SCREEN_TYPE.MODAL, 'menu-sidebar.title-8.4', false, null, 0, {})}
                                    {/* 8.5 Danh mục phím tắt*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            className="txt---400-14-20"
                                            onClick={(e) => {
                                                // if (!this.onCheckLogined('menu_sidebar_title_3_4')) {
                                                //     this.updateOpenningScreenInfo('menu_sidebar_title_3_4', OPENNING_SCREEN_TYPE.MODAL, { allowBroker: false });
                                                //     return;
                                                // }
                                                // e.stopPropagation();
                                                // this.props.setIsOpenModalHaveData("ScreenModal", {
                                                //     isOpenScreenModal: false,
                                                //     curScreenModalActive: ''
                                                // })

                                                // this.setIsOpenNetwork()

                                                this.openFuncionalModal('menu-sidebar.title-8.5')
                                            }}
                                        >
                                            {/* <Link to="/trade" onClick={(e) => this.onChangeLayoutTrade(e, 'advance', 'MSB_GDCP.MSB_DLN')} />  */}
                                            <FormattedMessage id="menu-sidebar.title-8.5" />
                                        </MenuItem>
                                    </Menu>
                                </SubMenu>
                            </Menu>

                        </SidebarContent >
                    </CustomScrollbars>
                </ProSidebar >
            </div >
        )
    }

    getRender_Menu_Customer = () => {
        let { setWrapperRef, menuHeader, rtl, handleToggleSidebar, isOpenScreenModal, isDeskTop, contactInfos, intl, clientConfig, userInfo, isLoggedIn } = this.props;
        let widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
        let collapsed = widthMenuSideBar === "75px" ? true : false
        if (!isDeskTop) {
            collapsed = false;
            // collapsed = "100vw"
        }
        let position = isOpenScreenModal ? "" : "absolute"

        return (
            <div ref={setWrapperRef} className="ProSidebar" style={{ height: "100vh" }}>
                <ProSidebar
                    image={false}
                    rtl={rtl}
                    collapsed={collapsed}
                    toggled={true}
                    breakPoint="lg"
                    // onToggle={handleToggleSidebar}
                    style={{ width: widthMenuSideBar, position: position }}
                >
                    <CustomScrollbars>
                        <SidebarContent style={{ textAlign: "center", width: widthMenuSideBar }}>
                            {/*====================================================================================*/}
                            {/* 1. Bảng giá  */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}>
                                <MenuItem
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActive(menuCustomer['PRICEBOARD'].key) ? <PriceBoardIconActive /> : this.genderIcon(1)} </ CustomIcon >}
                                    className={this.checkMenuActive(menuCustomer['PRICEBOARD'].key) ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar  txt---400-14-20'}
                                >
                                    {<FormattedMessage id="menu-sidebar.title-1" />}
                                    <Link to={menuCustomer['PRICEBOARD'].path} onClick={(e) => this.onChangeMenuActive(e, menuCustomer['PRICEBOARD'].key, menuCustomer['PRICEBOARD'].path)} />
                                </MenuItem>
                            </Menu>

                            {/* 5. Chứng khoán  */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}>
                                <MenuItem
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActive(menuCustomer['SYMBOL_DETAIL'].key) ? <StockIcon /> : this.genderIcon(11)} </ CustomIcon >}
                                    className={this.checkMenuActive(menuCustomer['SYMBOL_DETAIL'].key) ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar  txt---400-14-20'}
                                >
                                    {<FormattedMessage id="menu-sidebar.title-2.5" />}
                                    <Link to={menuCustomer['SYMBOL_DETAIL'].path} onClick={(e) => this.onChangeMenuActive(e, menuCustomer['SYMBOL_DETAIL'].key, menuCustomer['SYMBOL_DETAIL'].path)} />
                                </MenuItem>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 2. Đặt lệnh */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}
                            >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-2" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActive("#TRADE") ? <PlaceOrderIconActive /> : this.genderIcon(2)} </ CustomIcon >}
                                    className={this.checkMenuActive("#TRADE") ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar txt---400-14-20 '}
                                >
                                    {/* 2.1 Đặt lệnh thông thường*/}
                                    <Menu iconShape="circle">
                                        < MenuItem
                                            id='menu_sidebar_title_2_1'
                                            onClick={(e) => this.onChangeMenuActive(e, menuCustomer['TRADE#01'].key, menuCustomer['TRADE#01'].path)}
                                            className={this.checkMenuActive(menuCustomer['TRADE#01'].key) ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-2.1" />
                                            {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-2.1" />}
                                            targetID='menu_sidebar_title_2_1'
                                        ></TextTooltip> */}
                                            {/*<Link to={menuCustomer['TRADE#01'].path} onClick={(e) => this.onChangeMenuActive(e, menuCustomer['TRADE#01'].key, menuCustomer['TRADE#01'].path)} />*/}
                                        </MenuItem>
                                    </Menu>
                                    {/* 2.2 Đặt lệnh Pro*/}
                                    {/*<Menu iconShape="circle">
                                        < MenuItem
                                            onClick={(e) => this.onChangeMenuActive(e, menuBroker['TRADE#02'].key, menuBroker['TRADE#02'].path)}
                                            className={this.checkMenuActive(menuCustomer['TRADE#02'].key) ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar  txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-2.2" />
                                        </MenuItem>
                                    </Menu>*/}
                                </SubMenu>
                            </Menu>


                            {/*====================================================================================*/}
                            {/* 3. Giao dịch tiền */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-3" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-3") ? <MoneyTransactionIconActive /> : this.genderIcon(3)} </ CustomIcon >}
                                    className={
                                        (this.checkMenuActive("#CASHTRANS") || this.checkMenuActiveModal("menu-sidebar.title-3"))
                                            ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar txt---400-14-20 '}
                                >
                                    {/* 3.1 Chuyển tiền nội bộ*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.1") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.1") ? 'activeMenuSideBar txt---400-14-20 ' : 'notMenuActiveSideBar txt---400-14-20 '}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.1" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 3.7 Chuyển tiền ra ngoài*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.7") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.7") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.7" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 3.2 Nộp/Rút tiền tài khoản phái sinh*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_3_2'
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.2", false) }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.2") ? 'activeMenuSideBar ' : 'notMenuActiveSideBar '}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.2" />
                                        <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-3.2" />}
                                            targetID='menu_sidebar_title_3_2'
                                        ></TextTooltip>
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.3 Nộp/Rút tiền ký quỹ CCP     */}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_3_3'
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.3", false) }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.3" />
                                        {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-3.3" />}
                                            targetID='menu_sidebar_title_3_3'
                                        ></TextTooltip> 
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.4 Phong tỏa/Giải tỏa*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_3_4'
                                        onClick={(e) => {
                                            // if (!this.onCheckLogined('menu_sidebar_title_3_4')) {
                                            //     this.updateOpenningScreenInfo('menu_sidebar_title_3_4', OPENNING_SCREEN_TYPE.MODAL, { allowBroker: false, isPermissionSearch: false });
                                            //     return;
                                            // }
                                            e.stopPropagation();
                                            // this.props.setIsOpenTradeMoneyBIDV(true);
                                            // this.props.setPopUpTitle(intl.formatMessage({ id: 'trade-money-bidv.trade-money-bidv' }))
                                            // this.props.setIsOpenModalHaveData("ScreenModal", {
                                            //     isOpenScreenModal: false,
                                            //     curScreenModalActive: ''
                                            // })
                                            this.openFuncionalModal('menu-sidebar.title-3.4');
                                        }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.4" />
                                        <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-3.4" />}
                                            targetID='menu_sidebar_title_3_4'
                                        ></TextTooltip>
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.5 Ứng trước tiền bán*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.5", false) }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.5") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.5" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 3.6 iFlex*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.6", false) }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-3.6") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-3.6" />
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 3.8 Giải tỏa tiền tài khoản @*/}
                                    {((isLoggedIn && userInfo.isCorebank) || !isLoggedIn) && <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-3.8", false) }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-3.8") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-3.8" />
                                        </MenuItem>
                                    </Menu>}
                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 4. Giao dịch chứng khoán  */}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    // id='menu_sidebar_title_4'
                                    title={(<span id='menu_sidebar_title_4'><FormattedMessage id="menu-sidebar.title-4" /></span>)}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-4") ? <StockTradingIconActive /> : this.genderIcon(4)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}

                                >
                                    {/* <TextTooltip
                                    placement='top-start'
                                    tooltipText={<FormattedMessage id="menu-sidebar.title-4" />}
                                    targetID='menu_sidebar_title_4'
                                ></TextTooltip> */}
                                    {/* 4.1 Chuyển chứng khoán*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.1") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-4.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-4.1" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 4.2 Đăng ký quyền mua*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_4_2'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.2") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-4.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-4.2" />
                                            {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-4.2" />}
                                            targetID='menu_sidebar_title_4_2'
                                        ></TextTooltip> */}
                                        </MenuItem>
                                    </Menu>
                                    {/* 4.3 Đăng ký mua IPO*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_4_3'
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.3") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-4.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        {/* <Link to="/" onClick={(e) => this.onChangeLayoutTrade(e, TABS_ON_FOOTER.BASEORDER)} />
                                        <FormattedMessage id="menu-sidebar.title-4.3" />
                                        {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-4.3" />}
                                            targetID='menu_sidebar_title_4_3'
                                        ></TextTooltip>
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 4.4 Tra cứu quyền mua*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_4_4'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-4.4") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-4.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-4.4" />

                                        </MenuItem>
                                    </Menu>
                                </SubMenu>
                            </Menu>


                            {/*====================================================================================*/}
                            {/* 6. Quản lý tài khoản*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}
                            >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-6" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-6") ? <AccountManagementIconActive /> : this.genderIcon(6)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-6") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 6.1 Tài sản*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => this.onChangeMenuActive(e, menuCustomer.ASSET_TOTAL.key, menuCustomer.ASSET_TOTAL.path)}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}     >
                                            <FormattedMessage id="menu-sidebar.title-6.1" />
                                            {/*<Link to={menuCustomer.ASSET_TOTAL.path} onClick={(e) => this.onChangeMenuActive(e, menuCustomer.ASSET_TOTAL.key, menuCustomer.ASSET_TOTAL.path)} />*/}
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.2 Quản lý dư nợ*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.2", true) }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}                                    >
                                            <FormattedMessage id="menu-sidebar.title-6.2" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.3 Sao kê tiền*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.3") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.3" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.4 Sao kê chứng khoán*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.4") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.4" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.5 Lịch sử lệnh*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.5") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.5") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.5" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.10 Tổng hợp lệnh khớp*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.10") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.10") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.10" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.6 Lịch sử lệnh điều kiện*/}
                                    {/*<Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.6") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-6.6") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-6.6" />
                                    </MenuItem>
                                </Menu>*/}
                                    {/* 6.7 Lịch sử khớp lệnh*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.7") }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-6.7") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-6.7" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 6.8 Lãi/Lỗ đã thực hiện*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.8") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.8") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.8" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 6.9 Báo cáo tổng hợp lãi lỗ*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_6_8'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-6.9") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-6.9") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-6.9" />
                                        </MenuItem>
                                    </Menu>
                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/*   5. Tiện ích*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}
                            >
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-5" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-5") ? <UtilitiesIconActive /> : this.genderIcon(5)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-5") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 5.1 Đăng ký  gói Voucher*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        className={this.checkMenuActiveModal("menu-sidebar.title-5.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-5.1", false) }}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-5.1" />
                                    </MenuItem>
                                </Menu> */}
                                    {/* 5.2 Xác nhận lệnh*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-5.2") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-5.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            {/* <Link to="/" onClick={(e) => this.onChangeLayoutTrade(e, TABS_ON_FOOTER.BASEORDER)} /> */}
                                            <FormattedMessage id="menu-sidebar.title-5.2" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 5.3 Tra cứu danh mục Margin*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            id='menu_sidebar_title_5_3'
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-5.3") }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-5.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            {/* <Link to="/" onClick={(e) => this.onChangeLayoutTrade(e, TABS_ON_FOOTER.BASEORDER)} /> */}
                                            <FormattedMessage id="menu-sidebar.title-5.3" />
                                            {/* <TextTooltip
                                            placement='top-start'
                                            tooltipText={<FormattedMessage id="menu-sidebar.title-5.3" />}
                                            targetID='menu_sidebar_title_5_3'
                                        ></TextTooltip> */}
                                        </MenuItem>
                                    </Menu>
                                    {/* 5.4 Cảnh báo giá*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            className={this.checkMenuActiveModal("menu-sidebar.title-5.4") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-5.4", false) }}
                                        >
                                            {/* <Link to="/" onClick={(e) => this.onChangeLayoutTrade(e, TABS_ON_FOOTER.BASEORDER)} /> */}
                                            <FormattedMessage id="menu-sidebar.title-5.4" />
                                        </MenuItem>
                                    </Menu>
                                </SubMenu>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 9. Cài đặt*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}>
                                <SubMenu
                                    title={<FormattedMessage id="menu-sidebar.title-9" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-9") ? <SettingIconActive /> : this.genderIcon(8)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-9") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 9.1 Thông tin cá nhân*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-9.1", false) }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-9.1") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-9.1" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 9.2 Đăng ký dịch vụ*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-9.2", false) }}
                                            className={this.checkMenuActiveModal("menu-sidebar.title-9.2") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                        >
                                            <FormattedMessage id="menu-sidebar.title-9.2" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 9.3 Đăng ký xác thực 2 yếu tố*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_9_3'
                                        onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-9.3", false) }}
                                        className={this.checkMenuActiveModal("menu-sidebar.title-9.3") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-9.3" />
                                      
                                    </MenuItem>
                                </Menu> */}
                                    {/* 9.4 Đăng ký phương thức kích hoạt*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        id='menu_sidebar_title_9_4'
                                        className="txt---400-14-20"
                                        onClick={(e) => { this.onOpenModalActionMethod() }}
                                    >
                                        <FormattedMessage id="menu-sidebar.title-9.4" />
                                      
                                    </MenuItem>
                                </Menu> */}
                                </SubMenu>
                            </Menu>


                            {/*====================================================================================*/}
                            {/* 10. Bảo mật*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }}>
                                <MenuItem
                                    onClick={(e) => { this.onChangeMenuActiveModal("menu-sidebar.title-10", false) }}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-10") ? <SecurityIconActive /> : this.genderIcon(10)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-10") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {<FormattedMessage id="menu-sidebar.title-10" />}
                                </MenuItem>
                            </Menu>

                            {/*====================================================================================*/}
                            {/* 8. Hỗ trợ*/}
                            <Menu iconShape="circle" style={{ paddingLeft: "16px" }} >
                                <SubMenu
                                    open={this.state.isOpenSupport}
                                    onOpenChange={() => this.toggleOpenSupport()}
                                    title={<FormattedMessage id="menu-sidebar.title-8" />}
                                    icon={<CustomIcon isSidebar={true}>{this.checkMenuActiveModal("menu-sidebar.title-8") ? <SupportIconActive /> : this.genderIcon(7)} </ CustomIcon >}
                                    className={this.checkMenuActiveModal("menu-sidebar.title-8") ? 'activeMenuSideBar txt---400-14-20' : 'notMenuActiveSideBar txt---400-14-20'}
                                >
                                    {/* 8.1 Tổng đài*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            // onClick={() => this.setIsOpenContactMobile(true)}
                                            onClick={() => this.openFuncionalModal('menu-sidebar.title-8.1')}
                                            className="txt---400-14-20"
                                        >
                                            {/* <Link to="/" onClick={(e) => this.onChangeLayoutTrade(e, TABS_ON_FOOTER.BASEORDER)} /> */}
                                            <FormattedMessage id="menu-sidebar.title-8.1" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 8.2 Chat với tư vấn viên*/}
                                    {/* <Menu iconShape="circle">
                                    <MenuItem
                                        className="txt---400-14-20"
                                    >
                                        {contactInfos.chat_link ? (<a href={contactInfos.chat_link} target='_blank'>
                                            <FormattedMessage id="menu-sidebar.title-8.2" />
                                        </a>) : (<FormattedMessage id="menu-sidebar.title-8.2" />)}
                                    </MenuItem>
                                </Menu> */}
                                    {/* 8.3 Sổ tài giao dịch*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            className="txt---400-14-20"
                                        >
                                            {clientConfig.configData && clientConfig.configData.handbook_web ? (<a href={clientConfig.configData.handbook_web} target='_blank'>
                                                <FormattedMessage id="menu-sidebar.title-8.3" />
                                            </a>) : (<FormattedMessage id="menu-sidebar.title-8.3" />)}
                                        </MenuItem>
                                    </Menu>
                                    {/* 8.4 Mạng lưới*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            className="txt---400-14-20"
                                            onClick={(e) => {
                                                // if (!this.onCheckLogined('menu_sidebar_title_3_4')) {
                                                //     this.updateOpenningScreenInfo('menu_sidebar_title_3_4', OPENNING_SCREEN_TYPE.MODAL, { allowBroker: false });
                                                //     return;
                                                // }
                                                // e.stopPropagation();
                                                // this.props.setIsOpenModalHaveData("ScreenModal", {
                                                //     isOpenScreenModal: false,
                                                //     curScreenModalActive: ''
                                                // })

                                                // this.setIsOpenNetwork()

                                                this.openFuncionalModal('menu-sidebar.title-8.4')
                                            }}
                                        >
                                            {/* <Link to="/trade" onClick={(e) => this.onChangeLayoutTrade(e, 'advance', 'MSB_GDCP.MSB_DLN')} />  */}
                                            <FormattedMessage id="menu-sidebar.title-8.4" />
                                        </MenuItem>
                                    </Menu>
                                    {/* 8.5 Danh mục phím tắt*/}
                                    <Menu iconShape="circle">
                                        <MenuItem
                                            className="txt---400-14-20"
                                            onClick={(e) => {
                                                // if (!this.onCheckLogined('menu_sidebar_title_3_4')) {
                                                //     this.updateOpenningScreenInfo('menu_sidebar_title_3_4', OPENNING_SCREEN_TYPE.MODAL, { allowBroker: false });
                                                //     return;
                                                // }
                                                // e.stopPropagation();
                                                // this.props.setIsOpenModalHaveData("ScreenModal", {
                                                //     isOpenScreenModal: false,
                                                //     curScreenModalActive: ''
                                                // })

                                                // this.setIsOpenNetwork()

                                                this.openFuncionalModal('menu-sidebar.title-8.5')
                                            }}
                                        >
                                            {/* <Link to="/trade" onClick={(e) => this.onChangeLayoutTrade(e, 'advance', 'MSB_GDCP.MSB_DLN')} />  */}
                                            <FormattedMessage id="menu-sidebar.title-8.5" />
                                        </MenuItem>
                                    </Menu>
                                </SubMenu>
                            </Menu>

                        </SidebarContent >
                    </CustomScrollbars>
                </ProSidebar >
            </div >
        )
    }

    setIsOpenContactMobile = (isOpen) => {
        // this._setState({ isContactMobileModalOpen: isOpen });
        // if (isOpen) {
        //     const { intl, setPopUpTitle } = this.props;
        //     setPopUpTitle(intl.formatMessage({ id: 'menu-sidebar.title-8.1' }));
        // }
        this.props.setIsOpenModalByKey(
            "isOpenContactModal",
            isOpen || false,
        )
    }

    getRender = () => {
        let isBroker = CommonUtils.isBroker();
        if (isBroker) {
            return this.getRender_Menu_Broker()
        } else {
            return this.getRender_Menu_Customer()
        }

    }

    render() {
        const { isContactMobileModalOpen, isOpenShortCut } = this.state;
        const { isOpenNetwork, setIsOpenNetwork, statusMenuSideBar, isOpenScreenModal } = this.props;
        let key = "AIzaSyC7c7kxXv85stvqlCY7pbzc-SS49E134_w"
        let isEnbleOverlap = statusMenuSideBar === 1 && !isOpenScreenModal ? true : false;
        let isBroker = this.isBroker();
        return (
            <Fragment>
                {
                    isOpenShortCut &&
                    <ShortCutListModal
                        isOpen={isOpenShortCut}
                        setIsOpenShortCutList={this.setIsOpenShortCutList}
                        isBroker={isBroker}
                    />
                }
                {
                    isOpenNetwork &&
                    <NetModal
                        isOpen={isOpenNetwork}
                        setIsOpenContactMobile={setIsOpenNetwork}
                        isMarkerShown={true}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        latitude={-34.397}
                        longitude={150.644}
                    />
                }
                {/*
                    <ContactMobileModal
                        isOpen={isContactMobileModalOpen}
                        setIsOpenContactMobile={this.setIsOpenContactMobile}
                        toggle={() => { this._setState({ isContactMobileModalOpen: false }); }}
                    />
                */}
                {statusMenuSideBar ? this.getRender() : ''}
                {isEnbleOverlap && <div className="fake-overlay">

                </div>}
            </Fragment>
        );

    }

}

class MenuSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popUptitle: ''
        }
    }
    onChangeMenuActiveBar = (keyName) => {

    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    setPopUpTitle = (title) => {
        this._setState({ popUptitle: title });
    }

    componentDidMount() {
        this.mounted = true
        $(".overlay").removeClass("overlay")
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidUpdate(prevProps, prevState) {
        $(".overlay").removeClass("overlay")
        const { isOpenTradeMoneyBIDV, isOpenPayment, isOpenNetwork, isOpenModalActionMethodCondition, intl } = this.props;
        const { isOpenTradeMoneyBIDV: prevIsOpenTradeMoneyBIDV, isOpenPayment: previsOpenPayment, isOpenNetwork: prevIsOpenNetwork, isOpenModalActionMethodCondition: prevIsOpenModalActionMethodCondition } = prevProps;
        if ((isOpenTradeMoneyBIDV !== prevIsOpenTradeMoneyBIDV && !isOpenTradeMoneyBIDV)
            || isOpenPayment !== previsOpenPayment && !isOpenPayment
            || isOpenNetwork !== prevIsOpenNetwork && !isOpenNetwork
            || isOpenModalActionMethodCondition !== prevIsOpenModalActionMethodCondition && !isOpenModalActionMethodCondition
        ) {
            this.setPopUpTitle('');
        }

        // Nộp tiền không mở từ menusideBar nên phải case với trạng thái đóng popup để set title
        if (isOpenPayment !== previsOpenPayment && isOpenPayment) {
            this.setPopUpTitle(intl.formatMessage({ id: 'menu-tool.title-04' }));
        }
    }

    setTitleApp = (curScreenModalActive, currentLayoutPageActive) => {
        let { intl, currentSymbol, currentMarketDetail, layoutsCustom, isOpenTradeMoneyBIDV, curSymbolTrade, userInfo } = this.props;
        const { popUptitle } = this.state;
        // Các modal cần lấy tên để để lên title (Chỉ những modal mở từ menu)
        // let OpenningModalState = [isOpenTradeMoneyBIDV];

        let title = '';
        let username = userInfo && userInfo.username ? (userInfo.username || '') : '';
        if (curScreenModalActive) {
            if (curScreenModalActive.indexOf("menu-sidebar.title-1.1") > -1) {
                title = currentSymbol ? (currentSymbol.id + ' - ') : '';
            }
            if (curScreenModalActive.indexOf("menu-sidebar.title-1.2") > -1) {
                // currentMarketDetail
                title = currentMarketDetail ? (currentMarketDetail['name'] + ' - ') : '';
            }
            title = title + intl.formatMessage({ id: curScreenModalActive })
            CommonUtils.onChangeTitleApp(title, username)
        }
        else {

            if (currentLayoutPageActive) {
                const find = LanguageLayout.find(i => i.keyLayout === currentLayoutPageActive)
                if (find) {
                    if (currentLayoutPageActive.indexOf("CS#MARKET_WATCH") > -1) {
                        title = currentSymbol ? (currentSymbol.id + ' - ') : ''
                    }
                    if (currentLayoutPageActive.indexOf("CS#AI_TRADING") > -1) {
                        title = currentSymbol ? (currentSymbol.id + ' - ') : ''
                    }
                    if (currentLayoutPageActive.indexOf("CS#SYMBOL_DETAIL") > -1) {
                        if (curSymbolTrade && curSymbolTrade['id']) {
                            title = curSymbolTrade['id'] + ' - ' + title
                        }
                    }
                    title = title + intl.formatMessage({ id: find.keyLanguage })
                }

                // TÌm kiếm trong custom layout nếu có
                if (!title && layoutsCustom) {
                    const customFound = layoutsCustom.find(i => i.id === currentLayoutPageActive);
                    if (customFound) {
                        title = customFound['layoutName'] || '';
                    }
                }

            }

            // Set title cho các chức năng mở popup (Cần fix lại về cùng format mở popup dùng state.layout.listIsOpenModal như Phương thức kích hoạt)
            if (popUptitle) {
                title = popUptitle;
            }
        }
        //console.log('setTitleApp.:', currentSymbol, curScreenModalActive, currentLayoutPageActive, title)
        CommonUtils.onChangeTitleApp(title, username)
    }


    shouldComponentUpdate(nextProps, nextState, snapshot) {
        const { curScreenModalActive, statusMenuSideBar, isDeskTop } = this.props;
        const { curScreenModalActive: nextCurScreenModalActive, statusMenuSideBar: nextStatusMenuSideBar, isDeskTop: nextIsDeskTop } = nextProps;

        // //console.log('laojahackgame========> RENDER MENUSIDEBAR AAAAAA SHOULD RENDER', {
        //     val: nextState.popUptitle,
        //     check: curScreenModalActive !== nextCurScreenModalActive
        //         || statusMenuSideBar !== nextStatusMenuSideBar
        //         || isDeskTop !== nextIsDeskTop
        //         || !_.isEqual(this.props.menuHeader, nextProps.menuHeader)
        //         || this.props.widthMenuSideBar !== nextProps.widthMenuSideBar
        //         || !_.isEqual(this.props.currentSymbol, nextProps.currentSymbol)
        //         || this.props.curSymbolTrade && nextProps.curSymbolTrade && !_.isEqual(this.props.curSymbolTrade.id, nextProps.curSymbolTrade.id)
        //         || !_.isEqual(this.props.userInfo, nextProps.userInfo)
        //         || this.props.language !== nextProps.language
        //         || this.props.currentLayoutPageActive !== nextProps.currentLayoutPageActive
        //         || this.props.isOpenScreenModal !== nextProps.isOpenScreenModal
        //         || this.props.curScreenModalActive !== nextProps.curScreenModalActive
        //         || !_.isEqual(this.props.accountInfo, nextProps.accountInfo)
        //         || !_.isEqual(this.props.clientConfig, nextProps.clientConfig)
        //         || this.props.defaultTheme !== nextProps.defaultTheme
        //         || this.props.isOpenNetwork !== nextProps.isOpenNetwork
        //         || !_.isEqual(this.props.layoutsCustom, nextProps.layoutsCustom)
        //         || this.props.isOpenTradeMoneyBIDV !== nextProps.isOpenTradeMoneyBIDV
        //         || this.props.isOpenPayment !== nextProps.isOpenPayment
        //         || this.props.isOpenModalActionMethodCondition !== nextProps.isOpenModalActionMethodCondition
        //         || this.state.popUptitle !== nextState.popUptitle
        // });

        return curScreenModalActive !== nextCurScreenModalActive
            || statusMenuSideBar !== nextStatusMenuSideBar
            || isDeskTop !== nextIsDeskTop
            || !_.isEqual(this.props.menuHeader, nextProps.menuHeader)
            || this.props.widthMenuSideBar !== nextProps.widthMenuSideBar
            || !_.isEqual(this.props.currentSymbol, nextProps.currentSymbol)
            || this.props.curSymbolTrade && nextProps.curSymbolTrade && !_.isEqual(this.props.curSymbolTrade.id, nextProps.curSymbolTrade.id)
            || !_.isEqual(this.props.userInfo, nextProps.userInfo)
            || this.props.language !== nextProps.language
            || this.props.currentLayoutPageActive !== nextProps.currentLayoutPageActive
            || this.props.isOpenScreenModal !== nextProps.isOpenScreenModal
            || this.props.curScreenModalActive !== nextProps.curScreenModalActive
            || !_.isEqual(this.props.accountInfo, nextProps.accountInfo)
            || !_.isEqual(this.props.clientConfig, nextProps.clientConfig)
            || this.props.defaultTheme !== nextProps.defaultTheme
            || this.props.isOpenNetwork !== nextProps.isOpenNetwork
            || !_.isEqual(this.props.layoutsCustom, nextProps.layoutsCustom)
            || this.props.isOpenTradeMoneyBIDV !== nextProps.isOpenTradeMoneyBIDV
            || this.props.isOpenPayment !== nextProps.isOpenPayment
            || this.props.isOpenModalActionMethodCondition !== nextProps.isOpenModalActionMethodCondition
            || this.state.popUptitle !== nextState.popUptitle

        // //console.log('laojahackgame=======> RENDER SHOULD', shallowCompare({ props: otherProps, state: this.state }, nextOtherProps, nextState)
        //     || JSON.stringify(currentInstrument) != JSON.stringify(nextInstrumentCompare));

        // return shallowCompare({ props: otherProps, state: this.state }, nextOtherProps, nextState)
        //     || JSON.stringify(currentInstrument) != JSON.stringify(nextInstrumentCompare);
    }

    render() {
        const { setWrapperRef, menuHeader, popupFooterPage, activeMenu, menuActive, defaultTheme,
            onChangeMenuActive, onChangeActiveURLSideBar, role,
            setSelectedTradeLayout, currentSymbol, defaultSymbol, curScreenModalActive,
            isOpenScreenModal, setIsOpenTradeMoneyBIDV, accountInfo, setIsOpenModalHaveData,
            userInfo, navigate, isLoggedIn, onChangeOpenListMenuSideBar,
            onchangeStatusPopupFooterPage, setFooterCurrentPage, language,
            addLayoutPage, layoutPage, currentAccount, currentLayoutPageActive, changeLayoutPageActive,
            updatedLayoutPage, setIsOpenScreenModal, onChangeStatusMenuSideBar, isDeskTop, setOpenningScreenInfo, openningScreenInfo, contactInfos, intl,
            setIsOpenNetwork, isOpenNetwork, statusMenuSideBar, clientConfig } = this.props;
        this.setTitleApp(curScreenModalActive, currentLayoutPageActive)

        //console.log('laojahackgame========> RENDER MENUSIDEBAR AAAAAA', menuHeader);

        return (
            <Aside
                rtl={false}
                activeMenu={activeMenu}
                menuHeader={menuHeader}
                onChangeMenuActive={onChangeMenuActive}
                onChangeActiveURLSideBar={onChangeActiveURLSideBar}
                setSelectedTradeLayout={setSelectedTradeLayout}
                currentSymbol={currentSymbol}
                // defaultSymbol={defaultSymbol}
                navigate={navigate}
                onChangeMenuActiveBar={() => this.onChangeMenuActiveBar}
                isLoggedIn={isLoggedIn}
                onChangeOpenListMenuSideBar={onChangeOpenListMenuSideBar}
                // onchangeStatusPopupFooterPage={onchangeStatusPopupFooterPage}
                setFooterCurrentPage={setFooterCurrentPage}
                // popupFooterPage={popupFooterPage}
                language={language}
                // currentAccount={currentAccount}
                //////////
                addLayoutPage={addLayoutPage}
                // layoutPage={layoutPage}
                currentLayoutPageActive={currentLayoutPageActive}
                changeLayoutPageActive={changeLayoutPageActive}
                updatedLayoutPage={updatedLayoutPage}
                setIsOpenScreenModal={setIsOpenScreenModal}
                isOpenScreenModal={isOpenScreenModal}
                setIsOpenTradeMoneyBIDV={setIsOpenTradeMoneyBIDV}
                accountInfo={accountInfo}
                setIsOpenModalHaveData={setIsOpenModalHaveData}
                curScreenModalActive={curScreenModalActive}
                menuActive={menuActive}
                defaultTheme={defaultTheme}
                userInfo={userInfo}
                onChangeStatusMenuSideBar={onChangeStatusMenuSideBar}
                isDeskTop={isDeskTop}
                setOpenningScreenInfo={setOpenningScreenInfo}
                // openningScreenInfo={openningScreenInfo}
                setIsOpenModalByKey={this.props.setIsOpenModalByKey}
                setWrapperRef={setWrapperRef}
                // contactInfos={contactInfos}
                intl={intl}
                isOpenNetwork={isOpenNetwork}
                setIsOpenNetwork={setIsOpenNetwork}
                statusMenuSideBar={statusMenuSideBar}
                setPopUpTitle={this.setPopUpTitle}
                clientConfig={clientConfig}
            />
        );
    }
}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    // const isDerivativeAccount = CommonUtils.isDerivativeAccount(state.account.currentAccount);
    // const defaultSymbol = userInfo ? (isDerivativeAccount ? userInfo.defaultSymbolFds : userInfo.defaultSymbol) : null;
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
    // const popupFooterPage = userInfo.popupFooterPage
    //new
    const _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    const _accountInfo = _layoutPage && _layoutPage["accountInfo"]

    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}

    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let isDeskTop = state.app.isDeskTop;
    // const contactInfos = state.app.contactInfos ? state.app.contactInfos : {};
    const clientConfig = state.app.config ? state.app.config : {};
    let statusMenuSideBar = state.user.userInfo.menuHeader.menuSideBar.statusMenuSideBar;
    //console.log('laojahackgame========> RENDER MENUSIDEBAR', state.layout.listIsOpenModal["C&B"]["ScreenModal"].curScreenModalActive);
    return {
        isDeskTop: isDeskTop,
        menuHeader: menuHeader,
        // accounts: state.account.accounts,
        currentSymbol: state.app.tradeCurrentSymbol,
        curSymbolTrade: _curSymbolTrade,
        // currentCustomer: state.customer.currentCustomer,
        // currentAccount: state.account.currentAccount,
        isLoggedIn: state.user.isLoggedIn,
        // isAccountRouteVisible: state.app.isAccountRouteVisible,
        // isSettingsRouteVisible: state.app.isSettingsRouteVisible,
        userInfo: userInfo,
        // defaultSymbol: defaultSymbol,
        // isDerivativeAccount: isDerivativeAccount.currentAccount,
        // tradeLayoutId: state.app.currentTradeLayoutId,
        // currentMobileLayoutId: state.app.currentMobileLayoutId,
        // isAccountSidebarMobileVisible: state.app.isAccountSidebarMobileVisible,
        // isSettingsSidebarMobileVisible: state.app.isSettingsSidebarMobileVisible,
        // accountMenuPath: state.app.accountMenuPath,
        // settingMenuPath: state.app.settingMenuPath,
        widthMenuSideBar: widthMenuSideBar,
        // popupFooterPage: popupFooterPage,
        language: state.app.language,
        // location: state.router.location,
        // layoutPage: state.layout.layoutPage,
        currentLayoutPageActive: state.layout.currentLayoutPageActive,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        curScreenModalActive: state.layout.listIsOpenModal["C&B"]["ScreenModal"].curScreenModalActive,
        //new
        // accountInfo: accountDefault,
        accountInfo: _accountInfo,
        clientConfig: clientConfig,
        defaultTheme: defaultTheme,

        // openningScreenInfo: state.layout.openningScreenInfo,
        // contactInfos: contactInfos,
        isOpenNetwork: state.layout.isOpenNetwork,
        currentMarketDetail: state.marketInfoStore.currentMarketDetail,
        statusMenuSideBar: statusMenuSideBar,
        layoutsCustom: state.layout.layoutsCustom,
        isOpenTradeMoneyBIDV: state.layout.isOpenTradeMoneyBIDV,
        isOpenPayment: state.layout.isOpenPayment,
        isOpenModalActionMethodCondition: state.layout.listIsOpenModal["C&B"].isOpenModalActionMethodCondition,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeOpenListMenuSideBar: (keyName) => dispatch(actions.onChangeOpenListMenuSideBar(keyName)),
        onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),
        onChangeActiveURLSideBar: (activeURL) => dispatch(actions.onChangeActiveURLSideBar(activeURL)),

        setCurrentAccount: (accountId) => dispatch(actions.setCurrentAccount(accountId)),
        setAccountSidebarMobileVisible: (visible) => dispatch(actions.setAccountSidebarMobileVisible(visible)),
        setSettingsSidebarMobileVisible: (visible) => dispatch(actions.setSettingsSidebarMobileVisible(visible)),
        toogleTradeLayoutSettingModal: () => dispatch(actions.toogleTradeLayoutSettingModal()),
        setSelectedTradeLayout: (tradeLayoutId) => dispatch(actions.setSelectedTradeLayout(tradeLayoutId)),
        setSelectedMobileLayout: (tradeLayoutId) => dispatch(actions.setSelectedMobileLayout(tradeLayoutId)),
        navigate: (path) => dispatch(push(path)),
        onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),

        onchangeStatusPopupFooterPage: (status, typeStatus, tabActivated) => dispatch(actions.onchangeStatusPopupFooterPage(status, typeStatus, tabActivated)),
        setFooterCurrentPage: (currentPage) => dispatch(actions.setFooterCurrentPage(currentPage)),

        addLayoutPage: (newLayout) => dispatch(actions.addLayoutPage(newLayout)),
        changeLayoutPageActive: (currentTabActive) => dispatch(actions.changeLayoutPageActive(currentTabActive)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        setIsOpenScreenModal: (isOpenScreenModal, curScreenModalActive) => dispatch(actions.setIsOpenScreenModal(isOpenScreenModal, curScreenModalActive)),
        setIsOpenTradeMoneyBIDV: (isOpen) => dispatch(actions.setIsOpenTradeMoneyBIDV(isOpen)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        onChangeStatusMenuSideBar: (statusMenuSideBar) => dispatch(actions.onChangeStatusMenuSideBar(statusMenuSideBar)),
        setOpenningScreenInfo: (keyName, type, data) => dispatch(actions.setOpenningScreenInfo(keyName, type, data)),
        setIsOpenModalByKey: (key, status) => dispatch(actions.setIsOpenModalByKey(key, status)),
        setIsOpenNetwork: (isOpen) => dispatch(actions.setIsOpenNetwork(isOpen)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MenuSideBar));

import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";

import CustomScrollbars from "../../components/CustomScrollbars";
import { FormattedUtcDateTime } from "../../components/Formating";
import * as actions from "../../store/actions";

const NotificationItem = (data) => {
    let message = data.message;
    return (
        <div className="notification-item" tabIndex="0">
            <div className="inner">
                <div className="title title-analytic"
                >
                    <div>{message.subject}</div>
                    <div className="date"><FormattedUtcDateTime value={message.createdAt} /></div>
                </div>
                <div className="content"
                    onClick={data.onTitleClick.bind(null, message)}
                >{message.contentShort} <span className='read-detail'>...{<FormattedMessage id="common.notifications.read-detail" />}</span></div>
                <div className="press-link">
                    {message.link && <a href={message.link} target="_blank" rel="noopener noreferrer">{message.filename ? message.filename : <FormattedMessage id={"common.notifications.press-link"} />}</a>}
                </div>
                {/*<div className="date">
                    <FormattedUtcDateTime value={message.createdAt} />
                </div>*/}
            </div>
        </div>
    );
};

class AnalyticNotificationsNew extends Component {

    state = {
        fetchResult: false
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    render() {
        const { analyticMessages, onAnalyticNewsClick, onAnalyticBackClick, viewModes, analyticScreenMode,
            selectedAnalyticNew, nextIndexNoti, getNotiAnalyticRecommedDiff } = this.props;
        return (
            <div className="notification-tab-content">
                <div className="flex-scroll-content-account">
                    <CustomScrollbars className="notifications">
                        {analyticScreenMode === viewModes.MAIN_SCREEN && analyticMessages && analyticMessages.map(message => {
                            return <NotificationItem
                                message={message}
                                onTitleClick={onAnalyticNewsClick}
                            />
                        })}
                        {/* Nút Quay lại + tiêu đề của trang*/}
                        {(analyticScreenMode === viewModes.DETAIL_SCREEN) && (
                            <Fragment>
                                <div className='back-to text-left'>
                                    <button className="sidebar-hider" onClick={onAnalyticBackClick}>
                                        <i className="fas fa-chevron-circle-left"></i><span> </span>
                                        <FormattedMessage id="common.btn-back" />
                                    </button>
                                </div>
                                <div className='view-mode-name text-center'>
                                    {selectedAnalyticNew ? selectedAnalyticNew.subject : ""}
                                </div>
                            </Fragment>
                        )
                        }

                        {analyticScreenMode === viewModes.DETAIL_SCREEN && selectedAnalyticNew &&
                            <div className="notification-item" tabIndex="0">
                                <div className="inner">
                                    <div className="date">
                                        <FormattedUtcDateTime value={selectedAnalyticNew.createdAt} />
                                    </div>
                                    <div className="content"

                                        dangerouslySetInnerHTML={{ __html: selectedAnalyticNew.content }}
                                    >
                                        {/* Nội dung chi tiết */}
                                    </div>
                                    <div className="press-link">
                                        {selectedAnalyticNew.link && <a href={selectedAnalyticNew.link} target="_blank" rel="noopener noreferrer">{selectedAnalyticNew.filename ? selectedAnalyticNew.filename : <FormattedMessage id={"common.notifications.press-link"} />}</a>}
                                    </div>
                                </div>
                            </div>
                        }

                        {/*!this.state.fetchResult && (
                            <div className="notification-item" tabIndex="0">
                                <div className="inner">
                                    <div className="date">
                                        <FormattedMessage id="common.fail-to-load-analytic-notification-list" />
                                    </div>
                                </div>
                            </div>
                        )*/}

                    </CustomScrollbars>
                </div>
                {nextIndexNoti > 0 && (
                    <div className='load-more col-12 text-center txt---500-14-20' onClick={() => getNotiAnalyticRecommedDiff({ isLoadMore: true })}>
                        <FormattedMessage id="common.load-more-detail" />
                    </div>
                )}
            </div>
        );
    }

}
const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticNotificationsNew);
import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import './HeaderEkyc.scss'
import { CommonUtils } from "../../utils";
import HeaderLogoDark from '../../assets/images/headerLogo_dark.png';
import { ReactComponent as ManualDocumentIcon } from '../../assets/icons/icon_ekyc/manual-docment.svg'
//import { ReactComponent as VideoSupportIcon } from '../../assets/icons/icon_ekyc/video_header.svg'
import { ReactComponent as SupportAgentIcon } from '../../assets/icons/icon_ekyc/support_agent.svg'
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import TextToolTip from "../../components/TextToolTip";
function HeaderEkyc() {
    const clientConfig = useSelector(state => state.app.config);
    const [hotline, setHotline] = useStateIfMounted(['']);
    const onClickContentHeaderHeadle = (url) => {
        window.open(url, "_blank");
    }

    let contactRef = React.createRef();

    function getHotline() {
        let hotline = clientConfig && clientConfig.configData && clientConfig.configData.mainHotline ? clientConfig.configData.mainHotline.split("|")[1] : '';
        if (hotline) {
            // return hotline.replace(/ /g,'')
            return hotline.trim();
        }
        return hotline;
    }

    useEffect(() => {
        setHotline(getHotline())
    }, [clientConfig]); // Only re-run the effect if changes


    return (
        <div className="open-account-ekyc-header">
            <header className="header-menu-ekyc">
                {/* <HeaderLogo className="header-logo"></HeaderLogo> */}
                <div className="container-logo">
                    <img src={CommonUtils.renderIconTheme(HeaderLogoDark, HeaderLogoDark)} alt="LOGO" />
                </div>

                <div className="navLinks">
                    <a className="content-header" href={`tel:${hotline}`}>
                        <SupportAgentIcon className="icon-content-header"></SupportAgentIcon>
                        <a
                            ref={contactRef}
                            className="btn-menu"
                        >
                            <FormattedMessage id="ekyc.call-center" />
                        </a>
                        <TextToolTip
                            tooltipText={hotline}
                            targetID={contactRef}
                            placement='bottom'
                        ></TextToolTip>
                    </a>
                    {/* <div className="content-header" onClick={() => onClickContentHeaderHeadle("https://www.vixs.vn/")}>
                        <VideoSupportIcon className="icon-content-header"></VideoSupportIcon>
                        <a
                            className="btn-menu"
                        >
                            <FormattedMessage id="ekyc.video-tutorials" />
                        </a>
                    </div> */}
                    <div className="content-header" onClick={() => onClickContentHeaderHeadle(clientConfig && clientConfig.configData.ekyc_usage && clientConfig.configData.ekyc_usage)}>
                        <ManualDocumentIcon className="icon-content-header"></ManualDocumentIcon>
                        <a
                            className="btn-menu"
                        >
                            <FormattedMessage id="ekyc.manual-document" />
                        </a>
                    </div>
                </div>
            </header>
        </div>
    );
}
export default HeaderEkyc;
import React, { Component } from "react";
import { connect } from 'react-redux';
import { ACTION_USER_ORDER, CommonWidgetUtils, SELECTOR_ACCOUNT_SCREEN, PUTTHROUGH_ORDER_ACTION } from "utils";

import DraggableModal from '../../../../components/Modal/DraggableModal';
import * as actions from "../../../../store/actions";

import TradeAuthPutthrough from "./TradeAuthPutthrough";

import './TradeAuthPutthroughModal.scss';
class TradeAuthPutthroughModal extends Component {
    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.isOpen !== nextProps.isOpen
            || props.toggle !== nextProps.toggle
            || props.tradeOrderInputMsg !== nextProps.tradeOrderInputMsg
            || JSON.stringify(props.placePutthroughOrderCurrent) !== JSON.stringify(nextProps.placePutthroughOrderCurrent);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen != prevProps.isOpen && !this.props.isOpen) {
            let isMobileScreen = window.innerWidth <= 769 && window.innerWidth != 0;
            isMobileScreen && window.scrollTo(0, 0);
        }

        const { placePutthroughOrderCurrent } = this.props;
        const { orderType, actionUserOrder } = { ...placePutthroughOrderCurrent }
        // Chỉ clear dữ liệu khách hàng khi mở xác nhận đặt tab danh sách lệnh thỏa thuận và xác nhận lệnh thỏa thuận
        if (this.props.isOpen != prevProps.isOpen && this.props.isOpen && (orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_NOTI || orderType === PUTTHROUGH_ORDER_ACTION.CONFIRM_PLACE_PUTTHROUGH_ORDER) && actionUserOrder === ACTION_USER_ORDER.PLACE_ORDER) {
            this.props.clearCustodycdCustomerScreen(SELECTOR_ACCOUNT_SCREEN.LAYOUT);
        }
    }

    focusFirstElement = () => {
        document.getElementById("prevent-outside-tab").focus();
    }

    render() {
        const { isOpen, toggle, onClose, placePutthroughOrderCurrent, accountQuickOrderInfo, orderTypeUse } = this.props;
        const listReconfirmOrder = { ...placePutthroughOrderCurrent }
        const { titleId } = { ...placePutthroughOrderCurrent }

        let _className = "trade-auth-putthrough-order-modal"
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={onClose}
                className={_className}
                titleId={titleId ? titleId : 'trade.auth.title'}
            >
                <div className="body">
                    <TradeAuthPutthrough
                        onFinish={onClose}
                        listReconfirmOrder={listReconfirmOrder} //
                        accountQuickOrderInfo={accountQuickOrderInfo}
                        quote={this.props.quote}
                    />
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    let currentSymbol = null
    let quote = null
    let _accountQuickOrderInfo = null
    let _currentLayoutPageActive = null
    let _placePutthroughOrderCurrent = null;


    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _placePutthroughOrder = state.orderNew.placePutthroughOrder
    currentSymbol = _currentLayoutPageActive && _placePutthroughOrder[_currentLayoutPageActive] && _placePutthroughOrder[_currentLayoutPageActive]["symbolCurrent"]
    quote = currentSymbol && state.symbol.quotes[currentSymbol["id"]]

    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    _accountQuickOrderInfo = _layoutPage && _layoutPage["accountInfo"]
    _placePutthroughOrderCurrent = _currentLayoutPageActive && _placePutthroughOrder[_currentLayoutPageActive]

    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        currentSymbol: currentSymbol,

        placePutthroughOrderCurrent: _placePutthroughOrderCurrent,
        accountQuickOrderInfo: _accountQuickOrderInfo,

        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        clearCustodycdCustomerScreen: (typeScreen) => dispatch(actions.clearCustodycdCustomerScreen(typeScreen)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeAuthPutthroughModal);
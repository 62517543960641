import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { ReactComponent as IconMoonDark } from '../../../assets/icons_new/setting_display/icon_moon_dark.svg'
import { ReactComponent as IconMoonLight } from '../../../assets/icons_new/setting_display/icon_moon_light.svg'
import { ReactComponent as IconSunDark } from '../../../assets/icons_new/setting_display/icon_sun_dark.svg'
import { ReactComponent as IconSunLight } from '../../../assets/icons_new/setting_display/icon_sun_light.svg'
import { ReactComponent as IconFlagVIDark } from '../../../assets/icons_new/setting_display/icon_flag_vi_dark.svg'
import { ReactComponent as IconFlagENDark } from '../../../assets/icons_new/setting_display/icon_flag_en_dark.svg'
import { ReactComponent as IconFlagKRDark } from '../../../assets/icons_new/setting_display/icon_flag_kr_dark.svg'
import { ReactComponent as IconFlagJPDark } from '../../../assets/icons_new/setting_display/icon_flag_jp_dark.svg'
import IconArrowDark from '../../../assets/icons_new/setting_display/icon_arrow_dark.svg'
import IconArrowLight from '../../../assets/icons_new/setting_display/icon_arrow_light.svg'
import IconCheckActive from '../../../assets/icons_new/setting_display/icon_check_active.svg'
import { ReactComponent as IconSettingDark } from '../../../assets/icons/header/setting_dark.svg'
import { ReactComponent as IconSettingLight } from '../../../assets/icons/header/setting_light.svg'
import { ReactComponent as IconSettingActive } from '../../../assets/icons/header/SettingIconActive.svg'
import { userService } from "../../../services";
import * as actions from "../../../store/actions";
import { ToastUtil } from "../../../utils";

import './SettingDisplay.scss';


const TYPE_SELECT = {
    THEME: 'THEME',
    LANGUAGE: 'LANGUAGE'
}
class SettingDisplay extends Component {

    initstate = {
        isShowSetting: false,
        accountInfo: null,
        isShowChangeSetting: false,
        typeSelect: TYPE_SELECT.LANGUAGE
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initstate
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener("storage", this.onStorageUpdate);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener("storage", this.onStorageUpdate);
        this.mounted = false
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this._setState({ ...this.initstate })
        }
    }

    toggleQuickSetting = () => {
        this._setState({ isShowSetting: !this.state.isShowSetting })
    }

    backStep = () => {
        this._setState({ isShowChangeSetting: false })
    }

    onHandelChangeSettings = (type) => {
        this._setState({ typeSelect: type, isShowChangeSetting: true })
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoggedIn } = this.props;
        const { isLoggedIn: previsLoggedIn } = prevProps;
        const { isShowSetting } = this.state;
        const { isShowSetting: prevIsShowSetting } = prevState;
    }

    switchTheme = (themeCode) => {
        // const { defaultTheme } = this.props;
        // let newTheme = defaultTheme === 'dark' ? 'light' : 'dark';
        // this.state.theme = newTheme;
        const { isLoggedIn } = this.props;
        if (isLoggedIn) {
            userService.changeTheme(themeCode)
                .catch((error) => {
                    ToastUtil.errorApi(error);
                });
        }
        this.props.changeDefaultTheme(themeCode);
    };

    switchLanguage = (langCode) => {
        const { isLoggedIn } = this.props;
        if (langCode) {
            if (isLoggedIn) {
                userService.changeLanguage(langCode)
                    .catch((error) => {
                        ToastUtil.errorApi(error);
                    });
            }
            this.props.changeLanguage(langCode);
            this._setState({ isShowSetting: false })
        }
    }

    languagesInfo = {
        VIETNAM: {
            code: 'vi',
            name: 'settings.config.general-config.languages.vi',
        },
        ENGLISH: {
            code: 'en',
            name: 'settings.config.general-config.languages.en',
        },
        KOREA: {
            code: 'kr',
            name: 'settings.config.general-config.languages.kr',
        },
        JAPAN: {
            code: 'jp',
            name: 'settings.config.general-config.languages.jp',
        }
    };

    themeInfo = {
        LIGHT: {
            code: "light"
        },
        DARK: {
            code: "dark"
        }
    }

    genderIcon = (keyIcon) => {
        const { defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < IconSettingDark /> : < IconSettingLight />
                break;
            case 2:
                return defaultTheme === 'dark' ? <IconMoonDark /> : <IconMoonLight />
                break;
            case 3:
                return defaultTheme === 'dark' ? <IconSunDark /> : <IconSunLight />
                break;
            case 4:
                return defaultTheme === 'dark' ? <IconFlagVIDark /> : <IconFlagVIDark />
                break;
            case 5:
                return defaultTheme === 'dark' ? <IconFlagENDark /> : <IconFlagENDark />
                break;
            case 6:
                return defaultTheme === 'dark' ? <IconFlagKRDark /> : <IconFlagKRDark />
                break;
            case 7:
                return defaultTheme === 'dark' ? <IconFlagJPDark /> : <IconFlagJPDark />
                break;
            default:
                break;
        }
    }

    genderIconHeader = () => {
        const { language, defaultTheme } = this.props
        return this.props.defaultTheme === 'dark' ? this.state.isShowSetting ? <IconSettingActive /> : <IconSettingDark /> : this.state.isShowSetting ? <IconSettingActive /> : < IconSettingDark />

        // switch (language) {
        //     case "vi":
        //         return <IconFlagVIDark />
        //     case "en":
        //         return <IconFlagENDark />
        //     case "kr":
        //         return <IconFlagKRDark />
        //     case "jp":
        //         return <IconFlagJPDark />
        //     default:
        //         return <IconFlagVIDark />
        //         break;
        // }
    }


    render() {
        const { className, defaultTheme, language, isLoggedIn } = this.props;
        const { isShowSetting, isShowChangeSetting, typeSelect } = this.state;
        let theme = defaultTheme;
        // let _style = isLoggedIn ? { right: "190px" } : { right: "155px" }
        let _style = { right: "100px" }
        return (
            <div className="setting-display txt---500-14-20" ref={this.setWrapperRef}>
                <button id="btn-setting-id" className={className} onClick={this.toggleQuickSetting}>
                    {this.genderIconHeader()}
                </button>
                <div className="setting-container" hidden={isShowSetting ? '' : 'hidden'} style={_style}>
                    {!isShowChangeSetting && <div className="block-select-setting">
                        <div className="action-select" onClick={() => this.onHandelChangeSettings(TYPE_SELECT.THEME)}>
                            <div className="left">
                                <FormattedMessage id="setting-display.display" />
                            </div>
                            <div className="right">
                                <span>
                                    {defaultTheme === this.themeInfo.DARK.code && <IconMoonDark />}
                                    {defaultTheme === this.themeInfo.LIGHT.code && <IconSunLight />}
                                </span>
                                <span>
                                    <img src={defaultTheme === this.themeInfo.LIGHT.code ? IconArrowLight : IconArrowDark} alt="" />
                                </span>
                            </div>
                        </div>
                        <div className="action-select" onClick={() => this.onHandelChangeSettings(TYPE_SELECT.LANGUAGE)}>
                            <div className="left">
                                <FormattedMessage id="setting-display.language" />
                            </div>
                            <div className="right">
                                <span>
                                    {language === 'vi' && <IconFlagVIDark />}
                                    {language === 'en' && <IconFlagENDark />}
                                </span>
                                <span>
                                    <img src={defaultTheme === this.themeInfo.LIGHT.code ? IconArrowLight : IconArrowDark} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>}
                    {isShowChangeSetting && typeSelect === TYPE_SELECT.THEME && <div className="block-change-setting">
                        <div className="back-step" onClick={this.backStep}>
                            <img className="icon-arrow" src={defaultTheme === this.themeInfo.LIGHT.code ? IconArrowLight : IconArrowDark} alt="" />
                            <span className="item-center">
                                <FormattedMessage id="setting-display.interface-settings" />
                            </span>
                        </div>
                        <div className={"switch-theme " + (defaultTheme === this.themeInfo.DARK.code ? "active " : "")} onClick={() => this.switchTheme(this.themeInfo.DARK.code)}>
                            <div className="custom-row item-center">
                                <div className="left">
                                    <span style={{ marginRight: "5px" }}>
                                        {this.genderIcon(2)}
                                    </span>
                                    <FormattedMessage id="setting-display.dark-interface" />
                                </div>
                                <div className="right">
                                    <input type="radio" name="mode-select"
                                        checked={defaultTheme === this.themeInfo.DARK.code}
                                        onChange={() => this.switchTheme(this.themeInfo.DARK.code)}
                                    >
                                    </input>
                                </div>
                            </div>
                        </div>
                        {window._env_.THEME_LIGHT && <div className={"switch-theme " + (defaultTheme === this.themeInfo.LIGHT.code ? "active " : "")} onClick={() => this.switchTheme(this.themeInfo.LIGHT.code)}>
                            <div className="custom-row item-center">
                                <div className="left">
                                    <span style={{ marginRight: "5px" }}>
                                        {this.genderIcon(3)}
                                    </span>
                                    <FormattedMessage id="setting-display.bright-interface" />
                                </div>
                                <div className="right">
                                    <input type="radio" name="mode-select"
                                        checked={defaultTheme === this.themeInfo.LIGHT.code}
                                        onChange={() => this.switchTheme(this.themeInfo.LIGHT.code)}
                                    >
                                    </input>
                                </div>
                            </div>
                        </div>}
                    </div>}
                    {isShowChangeSetting && typeSelect === TYPE_SELECT.LANGUAGE &&
                        <div className="block-change-setting">
                            <div className="back-step" onClick={this.backStep}>
                                <img className="icon-arrow" src={defaultTheme === this.themeInfo.LIGHT.code ? IconArrowLight : IconArrowDark} alt="" />
                                <span className="item-center">
                                    <FormattedMessage id="setting-display.language-setting" />
                                </span>
                            </div>
                            <div className={"switch-langue " + (language === 'vi' ? "active " : "")} onClick={() => this.switchLanguage(this.languagesInfo.VIETNAM.code)}>
                                <div className="custom-row item-center">
                                    <div className="left">
                                        <span className="flag-language item-center" style={{ marginRight: "5px" }}>
                                            {this.genderIcon(4)}
                                        </span>
                                        <div className="text-language item-center">
                                            <div className='text-left'>Tiếng Việt</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {language === 'vi' && <img src={IconCheckActive} alt="" />}
                                    </div>
                                </div>
                            </div>
                            <div className={"switch-langue " + (language === 'en' ? "active " : "")} onClick={() => this.switchLanguage(this.languagesInfo.ENGLISH.code)}>
                                <div className="custom-row item-center">
                                    <div className="left">
                                        <span className="flag-language item-center" style={{ marginRight: "5px" }}>
                                            {this.genderIcon(5)}
                                        </span>
                                        <div className="text-language item-center">
                                            <div className='text-left'>English</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {language === 'en' && <img src={IconCheckActive} alt="" />}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={"switch-langue " + (language === 'kr' ? "active " : "")} onClick={() => this.switchLanguage(this.languagesInfo.KOREA.code)}>
                                <div className="custom-row item-center">
                                    <div className="left">
                                        <span className="flag-language item-center" style={{ marginRight: "5px" }}>
                                            {this.genderIcon(6)}
                                        </span>
                                        <div className="text-language item-center">
                                            <div className='text-left'>한국어</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {language === 'kr' && <img src={IconCheckActive} alt="" />}
                                    </div>
                                </div>
                            </div>
                            <div className={"switch-langue " + (language === 'jp' ? "active " : "")} onClick={() => this.switchLanguage(this.languagesInfo.JAPAN.code)}>
                                <div className="custom-row item-center">
                                    <div className="left">
                                        <span className="flag-language item-center" style={{ marginRight: "5px" }}>
                                            {this.genderIcon(7)}
                                        </span>
                                        <div className="text-language item-center">
                                            <div className='text-left'>日本語</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {language === 'jp' && <img src={IconCheckActive} alt="" />}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    }
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    // const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        language: state.app.language,
        // accounts: state.account.accounts,
        lang: state.app.language,
        defaultTheme: defaultTheme,
        // userInfo: userInfo,
        // currentAccount: state.account.currentAccount,
        // currentCustomer: state.customer.currentCustomer,
        isLoggedIn: state.user.isLoggedIn,
        // usersToken: state.user.usersToken
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeDefaultTheme: (theme) => dispatch(actions.changeDefaultTheme(theme)),
        changeLanguage: (language) => dispatch(actions.changeLanguage(language)),
        logout: () => dispatch(actions.logout()),
        // setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
        // logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
        // updateTokenStore: (usersToken) => dispatch(actions.updateTokenStore(usersToken)),
        // loginByTokenData: (tokenData) => dispatch(actions.loginByTokenData(tokenData)),
    };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingDisplay)));


import { path } from "utils"

export const DataListMenuSideBar = {
    "MH_CKCS": {
        "CS#PRICEBOARD#CB": "/priceboard",
        "CS#TRADE#CB": "/webtrade",
        "CS#TRADE#NC": "/protrade",
        "CS#TRADE#TT": "/putthroughtrade",
        "CS#TRADE#TPRL_NM": path.TPRLTRADE_NM,
        "CS#TRADE#TPRL_OR": path.TPRLTRADE_OR,
        "CS#TRADE#TPRL_RP": path.TPRLTRADE_RP,
        "CS#MARKET_WATCH": "/market-watch",
        "CS#AI_TRADING": "/ai-trading",
        "MSB_GDCP.MSB_LDK.MSB_DLDK": "/webtrade/condition-order",
        "MSB_GDCP.MSB_LDK.MSB_LSL": "/condition-order/condition-order-history",

        "MSB_GDCP.MSB_XNL": "/stock-trade/confirm-order",
        "MSB_GDCP.MSB_LSL.MSB_LSKL": "/stock-trade/command-history/matching-history",
        "MSB_GDCP.MSB_LSL.MSB_LSDL": "/stock-trade/command-history/order-history",
        "MSB_GDCP.MSB_LSL.MSB_PLTT": "/stock-trade/command-history/agreement-order",

        "MSB_QLTS.MSB_QLTS.MSB_THTS": "/asset-management/balance",
        "MSB_QLTS.MSB_DMDT.MSB_SDCK": "/asset-management/open-positions/all-portfolios",
        "MSB_QLTS.MSB_DMDT.MSB_DMDT": "/asset-management/open-positions/invest-portfolios",
        "MSB_QLTS.MSB_DMDT.MSB_CKDB": "/asset-management/open-positions/sold-portfolios",

        "MSB_QLTS.MSB_LLTH.MSB_BC": "/asset-management/npl-executed/securities-over-time",
        "MSB_QLTS.MSB_LLTH.MSB_LLTDT": "/asset-management/npl-executed/npl-executed-by-amount",
        "MSB_QLTS.MSB_NVKQ.MSB_CKN": "/asset-management/margin-loan/debts",
        "MSB_QLTS.MSB_NVKQ.MSB_TTTN": "/asset-management/margin-loan/payment-information",
        "MSB_QLTS.MSB_SKTK.MSB_SKT": "/asset-management/account-statement/cash-transaction-report",
        "MSB_QLTS.MSB_SKTK.MSB_SKCK": "/asset-management/account-statement/securities-transaction-report",
        "MSB_QLTS.MSB_TTSKQ": "/asset-management/account-statement/right-info",

        "MSB_TI.MSB_UTTB.MSB_DK": "/utilities/factoring/register",
        "MSB_TI.MSB_UTTB.MSB_TH": "/utilities/factoring/perform",
        "MSB_TI.MSB_UTTB.MSB_LS": "/utilities/factoring/history",
        "MSB_TI.MSB_DKQM.MSB_TH": "/utilities/right-off-register/perform",
        "MSB_TI.MSB_DKQM.MSB_LS": "/utilities/right-off-register/history",
        "MSB_TI.MSB_BLL.MSB_TH": "/utilities/sell-oddlot/perform",
        "MSB_TI.MSB_BLL.MSB_HB": "/utilities/sell-oddlot/cancel",
        "MSB_TI.MSB_BLL.MSB_LS": "/utilities/sell-oddlot/history",
        "MSB_TI.MSB_CKCKNB": "/utilities/stock-transfer-internal"
    },
    "MH_TP": {
        'MSB_TP.MSB_DL': "bonds/place-order",
        'MSB_TP.MSB_DMTP': "/bonds/bond-portfolio",
        'MSB_TP.MSB_TVSDT': "/bonds/cash-bond-query",
        'MSB_TP.MSB_DSYCTT': "/bonds/list-payment-requests",
        'MSB_TP.MSB_SLTP': "/bonds/bond-order-book",
        'MSB_TP.MSB_SKTP': "/bonds/bond-statement"
    },
    "MH_TKTM": {
        "MSB_GTK": "/smart-save/deposit-save",
        "MSB_DMTK": "/smart-save/deposit-withdraw",
        "MSB_LSGDGT": "/smart-save/deposit-transaction-history"
    },
    "MH_GDTK": {
        'MSB_GDTK.MSB_THTS': "/transaction-accounts/asset-total",
        "MSB_CT.MSB_CTRNDKT": "/transaction-accounts/transfer/cash-tranfer-external",
        "MSB_CT.MSB_CTRNCT": "/transaction-accounts/transfer/cash-tranfer-with-id-code",
        "MSB_CT.MSB_CTNB": "/transaction-accounts/transfer/cash-tranfer-internal",
        'MSB_GDTK.MSB_LSCT': "/transaction-accounts/cash-transfer-history",
        'MSB_GDTK.MSB_NT': "/transaction-accounts/recharge",
        'MSB_GDTK.MSB_SKT': "/transaction-accounts/cash-transaction-report",
    },
    "MH_MG": {
        'MSB_MG.MSB_TCVSK.MSB_TTTK': "/agency/margin-acctno-status",
        'MSB_MG.MSB_TCVSK.MSB_DTBCK': "/agency/expect-security-sell",
        'MSB_MG.MSB_TCVSK.MSB_DSKHCBSKQ': "/agency/cust-list-need-additional-margin",
        'MSB_MG.MSB_TCVSK.MSB_DSKHBBXY': "/agency/cust-list-dealt-sell",
        'MSB_MG.MSB_TCVSK.MSB_DSKHDNMR': "/agency/cust-list-debt-margin",
        'MSB_MG.MSB_TCVSK.MSB_THDNMG': "/agency/loan-account",
        'MSB_MG.MSB_TCVSK.MSB_SKMV': "/agency/loan-contract-hist",
        'MSB_MG.MSB_BC.MSB_KQGDM': "/agency/buy-result",
        'MSB_MG.MSB_BC.MSB_KQGDB': "/agency/sell-result",
        'MSB_MG.MSB_BC.MSB_CTTSCTK': "/agency/account-asset",
        'MSB_MG.MSB_BC.MSB_BCSLGTGD': "/agency/trading-value-report",
    },
    "MH_SETTINGS": {
        "MSB_CN.MSB_TTCN": "/setting/personal-info",
        "MSB_CN.MSB_DMK": "/setting/change-password",
        "MSB_CN.MSB_DMP": "/setting/change-pin",
        "MSB_CN.MSB_DSDT": "/setting/change-customer-mobile",
        "MSB_TC.MSB_TKTTTD": "/setting/info-change-log",
        "MSB_TC.MSB_LSDN": "/setting/login-hist",
        "MSB_TC.MSB_BCDX": "/setting/exported-report",
        "MSB_CH.MSB_CHC": "/setting/general-config",
        "MSB_CH.MSB_DKDV": "/setting/register-service",
        "MSB_CH.MSB_QLNL": "/setting/group-order-manage",
        "MSB_CH.MSB_CBTM.MSB_CBCK": "/setting/smart-warning/stock-alert",
        "MSB_CH.MSB_CBTM.MSB_CBTT": "/setting/smart-warning/index-alert",
        "MSB_CH.MSB_CBTM.MSB_CBINDEX": "/setting/smart-warning/market-index-alert",
        "MSB_CH.MSB_CHNTBTK": "/setting/notify-account",
        "MSB_LH.MSB_LHASC": "/setting/contact"
    }
}

export const LanguageLayout = [
    // 1. Bảng giá
    { keyLayout: "C#CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar.title-1" },
    { keyLayout: "B#CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar.title-1" },
    { keyLayout: "CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar.title-1" },
    { keyLayout: "CS#SYMBOL_DETAIL", keyLanguage: "menu-sidebar.title-1.1" },
    { keyLayout: "CS#SYMBOL_DETAIL", keyLanguage: "menu-sidebar.title-1.1" },
    { keyLayout: "C#CS#SYMBOL_DETAIL", keyLanguage: "menu-sidebar.title-1.1" },
    { keyLayout: "B#CS#SYMBOL_DETAIL", keyLanguage: "menu-sidebar.title-1.1" },
    // 2. Đặt lệnh
    { keyLayout: "C#CS#TRADE#CB", keyLanguage: "menu-sidebar.title-2.1" },
    { keyLayout: "C#CS#TRADE#NC", keyLanguage: "menu-sidebar.title-2.2" },
    { keyLayout: "B#CS#TRADE#CB", keyLanguage: "menu-sidebar.title-2.1" },
    { keyLayout: "B#CS#TRADE#NC", keyLanguage: "menu-sidebar.title-2.2" },
    { keyLayout: "C#CS#TRADE#TPRL_NM", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "B#CS#TRADE#TPRL_NM", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "C#CS#TRADE#TPRL_OR", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "B#CS#TRADE#TPRL_OR", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "C#CS#TRADE#TPRL_RP", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "B#CS#TRADE#TPRL_RP", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "C#CS#TRADE#TT", keyLanguage: "menu-sidebar.title-2.3" },
    { keyLayout: "B#CS#TRADE#TT", keyLanguage: "menu-sidebar.title-2.3" },

    { keyLayout: "C#CS#MARKET_WATCH", keyLanguage: "menu-tool.title-02" },
    { keyLayout: "B#CS#MARKET_WATCH", keyLanguage: "menu-tool.title-02" },
    { keyLayout: "CS#MARKET_WATCH", keyLanguage: "menu-tool.title-02" },

    { keyLayout: "C#CS#AI_TRADING", keyLanguage: "menu-tool.title-13" },
    { keyLayout: "B#CS#AI_TRADING", keyLanguage: "menu-tool.title-13" },
    { keyLayout: "CS#AI_TRADING", keyLanguage: "menu-tool.title-13" },

    { keyLayout: "C#CS#ASSET_TOTAL", keyLanguage: "menu-sidebar.title-6.1" },
    { keyLayout: "B#CS#ASSET_TOTAL", keyLanguage: "menu-sidebar.title-6.1" },
    { keyLayout: "MENU_2.1", keyLanguage: "menu-sidebar.title-2.1" },
    { keyLayout: "MENU_2.2", keyLanguage: "menu-sidebar.title-2.2" },
    { keyLayout: "MENU_2.3", keyLanguage: "menu-sidebar.title-2.3" },
    { keyLayout: "MENU_2.4", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "CS#CUSTOMER_ORGANIZATION", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "B#CS#CUSTOMER_ORGANIZATION", keyLanguage: "menu-sidebar.title-2.4" },
    { keyLayout: "CS#IBROKER", keyLanguage: "menu-tool.title-06" },
    { keyLayout: "C#CS#IBROKER", keyLanguage: "menu-tool.title-06" },
    { keyLayout: "B#CS#IBROKER", keyLanguage: "menu-tool.title-06" },
    // 3. Giao dịch tiền
    { keyLayout: "MENU_3", keyLanguage: "menu-sidebar.title-3" },
    { keyLayout: "MENU_3.1", keyLanguage: "menu-sidebar.title-3.1" },
    { keyLayout: "MENU_3.2", keyLanguage: "menu-sidebar.title-3.2" },
    { keyLayout: "MENU_3.3", keyLanguage: "menu-sidebar.title-3.3" },
    { keyLayout: "MENU_3.4", keyLanguage: "menu-sidebar.title-3.4" },
    { keyLayout: "MENU_3.5", keyLanguage: "menu-sidebar.title-3.5" },
    { keyLayout: "MENU_3.6", keyLanguage: "menu-sidebar.title-3.6" },
    { keyLayout: "MENU_3.7", keyLanguage: "menu-sidebar.title-3.7" },
    { keyLayout: "MENU_3.8", keyLanguage: "menu-sidebar.title-3.8" },
    // 4. Giao dịch CK
    { keyLayout: "MENU_4", keyLanguage: "menu-sidebar.title-4" },
    { keyLayout: "MENU_4.1", keyLanguage: "menu-sidebar.title-4.1" },
    { keyLayout: "MENU_4.2", keyLanguage: "menu-sidebar.title-4.2" },
    { keyLayout: "MENU_4.3", keyLanguage: "menu-sidebar.title-4.3" },
    { keyLayout: "MENU_4.4", keyLanguage: "menu-sidebar.title-4.4" },
    // 5. Tài sản
    { keyLayout: "MENU_5", keyLanguage: "menu-sidebar.title-5" },
    // 6. Tiện ích
    { keyLayout: "MENU_6", keyLanguage: "menu-sidebar.title-6" },
    { keyLayout: "MENU_6.1", keyLanguage: "menu-sidebar.title-6.1" },
    { keyLayout: "MENU_6.2", keyLanguage: "menu-sidebar.title-6.2" },
    { keyLayout: "MENU_6.3", keyLanguage: "menu-sidebar.title-6.3" },
    { keyLayout: "CS#ASSET_TOTAL", keyLanguage: "menu-sidebar.title-6.1" },
    // 7. Quản lý tài khoản
    { keyLayout: "MENU_7", keyLanguage: "menu-sidebar.title-7" },
    { keyLayout: "MENU_7.1", keyLanguage: "menu-sidebar.title-7.1" },
    // 8. Báo cáo
    { keyLayout: "MENU_8", keyLanguage: "menu-sidebar.title-8" },
    { keyLayout: "MENU_8.1", keyLanguage: "menu-sidebar.title-8.1" },
    { keyLayout: "MENU_8.2", keyLanguage: "menu-sidebar.title-8.2" },
    { keyLayout: "MENU_8.3", keyLanguage: "menu-sidebar.title-8.3" },
    { keyLayout: "MENU_8.4", keyLanguage: "menu-sidebar.title-8.4" },
    { keyLayout: "MENU_8.5", keyLanguage: "menu-sidebar.title-8.5" },
    // 9. Hỗ trợ
    { keyLayout: "MENU_9", keyLanguage: "menu-sidebar.title-9" },
    { keyLayout: "MENU_9.1", keyLanguage: "menu-sidebar.title-9.1" },
    { keyLayout: "MENU_9.2", keyLanguage: "menu-sidebar.title-9.2" },
    // 10. Cài đặt
    { keyLayout: "MENU_10", keyLanguage: "menu-sidebar.title-10" },
    { keyLayout: "MENU_10.1", keyLanguage: "menu-sidebar.title-10.1" },
    { keyLayout: "MENU_10.2", keyLanguage: "menu-sidebar.title-10.2" },
    { keyLayout: "MENU_10.3", keyLanguage: "menu-sidebar.title-10.3" },
    { keyLayout: "MENU_10.4", keyLanguage: "menu-sidebar.title-10.4" },



    //ASC
    { keyLayout: "MSB_CN.MSB_TTCN", keyLanguage: "menu-sidebar-05.title.title-1.1" },
    { keyLayout: "MSB_CN.MSB_DMK", keyLanguage: "menu-sidebar-05.title.title-1.2" },
    { keyLayout: "MSB_CN.MSB_DMP", keyLanguage: "menu-sidebar-05.title.title-1.3" },
    { keyLayout: "MSB_CN.MSB_DSDT", keyLanguage: "menu-sidebar-05.title.title-1.4" },

    { keyLayout: "CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar-01.title.title-1" },
    { keyLayout: "CS#TRADE#CB", keyLanguage: "menu-sidebar-01.title.normal-trade" },
    { keyLayout: "CS#TRADE#TT", keyLanguage: "menu-sidebar.title-2.3" },
    { keyLayout: "CS#TRADE#NC", keyLanguage: "menu-sidebar-01.title.pro-trade" },
    { keyLayout: "CS#TRADE#TPRL_NM", keyLanguage: "menu-sidebar-01.title.tprl-trade" },
    { keyLayout: "CS#TRADE#TPRL_OR", keyLanguage: "menu-sidebar-01.title.tprl-trade" },
    { keyLayout: "CS#TRADE#TPRL_RP", keyLanguage: "menu-sidebar-01.title.tprl-trade" },
    { keyLayout: "MSB_TC", keyLanguage: "menu-sidebar-05.title.title-2" },
    { keyLayout: "MSB_QLTS.MSB_DMDT", keyLanguage: "menu-sidebar-01.title.title-3.2" },

    { keyLayout: "MSB_QLTS.MSB_THTS", keyLanguage: "menu-sidebar.title-10.1" },
    { keyLayout: "MSB_QLTS.MSB_NVKQ", keyLanguage: "menu-sidebar-01.title.title-3.4" },
    { keyLayout: "MSB_CN.MSB_TTCN", keyLanguage: "menu-sidebar-05.title.title-1.1" },
    { keyLayout: "MSB_CN.MSB_DMK", keyLanguage: "menu-sidebar-05.title.title-1.2" },
    { keyLayout: "MSB_CN.MSB_DMP", keyLanguage: "menu-sidebar-05.title.title-1.3" },
    { keyLayout: "MSB_CN.MSB_DSDT", keyLanguage: "menu-sidebar-05.title.title-1.4" },
    { keyLayout: "CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar-01.title.title-1" },
    { keyLayout: "MSB_TC", keyLanguage: "menu-sidebar-05.title.title-2" },
    { keyLayout: "MSB_QLTS.MSB_DMDT", keyLanguage: "menu-sidebar-01.title.title-3.2" },

    { keyLayout: "MSB_QLTS.MSB_THTS", keyLanguage: "menu-sidebar.title-10.1" },
    { keyLayout: "MSB_QLTS.MSB_NVKQ", keyLanguage: "menu-sidebar-01.title.title-3.4" },
    { keyLayout: "MSB_CN.MSB_TTCN", keyLanguage: "menu-sidebar-05.title.title-1.1" },
    { keyLayout: "MSB_CN.MSB_DMK", keyLanguage: "menu-sidebar-05.title.title-1.2" },
    { keyLayout: "MSB_CN.MSB_DMP", keyLanguage: "menu-sidebar-05.title.title-1.3" },
    { keyLayout: "MSB_CN.MSB_DSDT", keyLanguage: "menu-sidebar-05.title.title-1.4" },
    { keyLayout: "CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar-01.title.title-1" },
    { keyLayout: "MSB_TC", keyLanguage: "menu-sidebar-05.title.title-2" },
    { keyLayout: "MSB_QLTS.MSB_DMDT", keyLanguage: "menu-sidebar-01.title.title-3.2" },

    { keyLayout: "MSB_QLTS.MSB_THTS", keyLanguage: "menu-sidebar.title-10.1" },
    { keyLayout: "MSB_QLTS.MSB_NVKQ", keyLanguage: "menu-sidebar-01.title.title-3.4" },
    { keyLayout: "MSB_CN.MSB_TTCN", keyLanguage: "menu-sidebar-05.title.title-1.1" },
    { keyLayout: "MSB_CN.MSB_DMK", keyLanguage: "menu-sidebar-05.title.title-1.2" },
    { keyLayout: "MSB_CN.MSB_DMP", keyLanguage: "menu-sidebar-05.title.title-1.3" },
    { keyLayout: "MSB_CN.MSB_DSDT", keyLanguage: "menu-sidebar-05.title.title-1.4" },
    { keyLayout: "CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar-01.title.title-1" },
    { keyLayout: "MSB_TC", keyLanguage: "menu-sidebar-05.title.title-2" },
    { keyLayout: "MSB_QLTS.MSB_DMDT", keyLanguage: "menu-sidebar-01.title.title-3.2" },

    { keyLayout: "MSB_QLTS.MSB_THTS", keyLanguage: "menu-sidebar.title-10.1" },
    { keyLayout: "MSB_QLTS.MSB_NVKQ", keyLanguage: "menu-sidebar-01.title.title-3.4" },
    { keyLayout: "MSB_CN.MSB_TTCN", keyLanguage: "menu-sidebar-05.title.title-1.1" },
    { keyLayout: "MSB_CN.MSB_DMK", keyLanguage: "menu-sidebar-05.title.title-1.2" },
    { keyLayout: "MSB_CN.MSB_DMP", keyLanguage: "menu-sidebar-05.title.title-1.3" },
    { keyLayout: "MSB_CN.MSB_DSDT", keyLanguage: "menu-sidebar-05.title.title-1.4" },
    { keyLayout: "CS#PRICEBOARD#CB", keyLanguage: "menu-sidebar-01.title.title-1" },
    { keyLayout: "MSB_TC", keyLanguage: "menu-sidebar-05.title.title-2" },
    { keyLayout: "MSB_QLTS.MSB_DMDT", keyLanguage: "menu-sidebar-01.title.title-3.2" },

    { keyLayout: "MSB_QLTS.MSB_THTS", keyLanguage: "menu-sidebar.title-10.1" },
    { keyLayout: "MSB_QLTS.MSB_NVKQ", keyLanguage: "menu-sidebar-01.title.title-3.4" },
]
import React, { Component } from 'react';
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import _ from 'lodash';
import { destroySessionStorage, getSessionStorage } from '../common/utils'
import { history } from '../redux';
import { userService, } from "../services";
import * as actions from "../store/actions";
import { KeyCodeUtils, LoadStatus, path, ToastUtil, SESSION_STORAGE_LIST_MARKET_CHART, } from "../utils";

import './ChangeCoreModal.scss';
import RetryableContent from 'components/RetryableContent';

class ChangeCoreModal extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE
    };

    state = {
        ...this.initialState
    };

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) { }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }
    }

    handleChangeLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        })
    }

    onLogout = () => {
        let listMarketChartFromSessionStorage = getSessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        if (listMarketChartFromSessionStorage && listMarketChartFromSessionStorage.length > 0) {
            destroySessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        }

        history.push(path.LOG_OUT);
    }

    blockNextStep = () => {
        return new Promise((resolve) => {
            let timer = setTimeout(() => { resolve(true); clearTimeout(timer) }, 1000);
        })
    }

    handleSubmit = async () => {
        const { loadStatus } = this.state
        if (loadStatus === LoadStatus.LOADING) {
            return
        }

        this.handleChangeLoadStatus(LoadStatus.LOADING)
        await userService.resetPassForFirstLogin().then(async result => {
            if (_.isObject(result)) {
                const res = await this.blockNextStep()
                if (res) {
                    this.handleChangeLoadStatus(LoadStatus.LOADED)
                    this.props.toggleModalChangeCore()
                    this.props.loadUserInfo()
                }
            } else {
                this.handleChangeLoadStatus(LoadStatus.LOADED)
                ToastUtil.error("common.unknown-error");
            }
        }).catch((error) => {
            this.handleChangeLoadStatus(LoadStatus.LOADED)
            ToastUtil.errorApi(error, 'common.bad-request');
        })
    }

    render() {
        const { isOpen } = this.props;
        const { loadStatus } = this.state

        return (
            <Modal
                isOpen={isOpen}
                zIndex={9999}
                className='layout-setting-modal change-core-modal'
                centered={true}>
                <RetryableContent className="change-core-modal-retry" status={loadStatus} disableRetry={true}>
                    <div className="modal-header headB---700-16-24">
                        <div className="modal-title">
                            <FormattedMessage id="common.noti" />
                        </div>
                        <div disabled={loadStatus === LoadStatus.LOADING} className="btn-icon-fm" onClick={this.onLogout}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="title title-16-700 line-height-32 mb-2">
                            <FormattedMessage id="change-core.title" />
                        </div>

                        <div className="body txt---500-14 line-height-32">
                            <div className="main-section">
                                <FormattedMessage id="change-core.section"
                                    values={{ span: chunks => <span className='txt---700-14-20'>{chunks}</span>, }}
                                />
                            </div>

                            <div className="sub-section">
                                <FormattedMessage id="change-core.sub-section1" />
                            </div>

                            <div className="sub-section">
                                <FormattedMessage id="change-core.sub-section2" />
                            </div>

                            <div className="sub-section">
                                <FormattedMessage
                                    id="change-core.sub-section3"
                                    values={{ span: chunks => <span className='txt---700-14-20'>{chunks}</span>, }}
                                />
                            </div>

                            <div className="sub-section">
                                <FormattedMessage
                                    id="change-core.sub-section4"
                                    values={{ span: chunks => <span className='txt---700-14-20'>{chunks}</span>, }}
                                />
                            </div>

                            <div className="sub-section">
                                <FormattedMessage id="change-core.sub-section5" />
                            </div>

                            <div className="sub-section">
                                <FormattedMessage
                                    id="change-core.sub-section6"
                                    values={{ span: chunks => <span className='txt---700-14-20'>{chunks}</span>, }}
                                />
                            </div>

                        </div>

                        <div className="col-12 btn-container headB---700-16-24">
                            <button
                                ref={this.confirmBtnRef}
                                onClick={this.handleSubmit}
                                disabled={loadStatus === LoadStatus.LOADING}
                            ><FormattedMessage id={"common.btn-continue"} /></button>
                        </div>

                    </div>
                </RetryableContent>
            </Modal >
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        logout: () => dispatch(actions.logout()),
        loadUserInfo: () => dispatch(actions.loadUserInfo()),
        toggleModalChangeCore: () => dispatch(actions.toggleModalChangeCore()),
    };
};

const mapStateToProps = (state, props) => {
    return {
        lang: state.app.language,
        isLoggedIn: state.user.isLoggedIn,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCoreModal);

import React, { Component } from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import { LoadStatus, Role, ToastUtil, LanguageUtils, KeyCodeUtils, reportParams, reportTypes, CommonUtils, mDate, Random, OUTPUT_REPORT, execTypesSeach1 } from "../../../utils";
import RetryableContent from "../../../components/RetryableContent";
import moment from "moment/moment";
import { inquiryService } from "../../../services";
import { connect } from "react-redux";
import * as actions from '../../../store/actions'
import TextToolTip from "../../../components/TextToolTip";
import { ReactComponent as DownloadIconLight } from '../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../assets/icons/new/DownloadIcon_Dark.svg'
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

import './AccountLookupBySymbol.scss';
import SymbolSuggestTrans from 'components/SymbolSuggestTrans';

const rowPerPage = 200;

let columnsDefault =
    [
        /* Tài khoản */
        {
            dataField: "custodycd",
            fixed: false,
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Tiểu khoản */
        {
            dataField: "al_afacctno",
            fixed: false,
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Tên KH */
        {
            dataField: "fullname",
            fixed: false,
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "60",
            width: '80',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Số lượng sở hữu */
        {
            dataField: "totalpnl",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Số lượng giao dịch */
        {
            dataField: "trade",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },

        /* Tổng chờ về */
        {
            dataField: "receiving",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "90",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        /* Tổng chờ giao */
        {
            dataField: "sending",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        /* MGQL */
        {
            dataField: "refullname",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 14,
        },
        /* temp column - Thêm để đỡ lỗi VIXEXT-930*/
        {
            dataField: "makername",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: false,
            alignment: "center",
            visibleIndex: 9,
        },
    ]

const totalDefault =
    [
        {
            column: "totalpnl",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "trade",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "receiving",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "sending",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]


class AccountLookupBySymbol extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        fromDate: null,
        toDate: null,
        symbol: '',
        execType: execTypesSeach1[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        orderHistoryStatus: [],
        onReloadData: true,
        listAccounts: [],
        listCareBy: [
            {
                tlid: "ALL",
                tlname: "ALL"
            }
        ],
        brokerAccountSelected: 'ALL',
        displayBroker: 'ALL'
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "GRODSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        //this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();
        this.state.toDate = currentDate;
        this.state.fromDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
        this.inputRef = React.createRef();
    }


    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd);
            case 'al_afacctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.al_afacctno);
            case 'totalpnl':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.totalpnl, 'PRICE');
            case 'fullname':
                return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.fullname);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.symbol);
            case 'trade':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.trade, 'PRICE');
            case 'receiving':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.receiving, 'PRICE');
            case 'sending':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.sending, 'PRICE');
            case 'refullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.refullname);
            default:
                return null;
        }

    }

    returnCaption = (dataField) => {

        switch (dataField) {
            case 'custodycd':
                return "account-lookup-by-symbol.table.account";
            case 'al_afacctno':
                return "account-lookup-by-symbol.table.sub-account";
            case 'fullname':
                return "account-lookup-by-symbol.table.customer-name";
            case 'symbol':
                return "order-history.symbol";
            case 'totalpnl':
                return "account-lookup-by-symbol.table.own-amount";
            case 'trade':
                return "account-lookup-by-symbol.table.trade-amount";
            case 'receiving':
                return "account-lookup-by-symbol.table.wait-sum";
            case 'sending':
                return "account-lookup-by-symbol.table.wait-for-trade";
            case 'refullname':
                return "account-lookup-by-symbol.table.broker";
            default:
                return null;
        }
    }

    search = () => {
        this.setReloadData();
    }

    renderConfirmsts = (row) => {
        if (row === "Không") return "No"
        return "Yes"
    }


    applyInput = false;

    apiPaging = async (obj) => {
        this.applyInput = true;
        const { symbol, brokerAccountSelected } = this.state;
        // const momentFromDate = moment(fromDate);
        // const momentToDate = moment(toDate);
        const lang = this.props.language;

        if (!symbol) {
            ToastUtil.error('common.fail-to-load-data', 'account-lookup-by-symbol.please-set-symbol');
            return [];
        }
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return [];
        // }

        try {
            let self = this
            const { page, limitRow } = obj
            return inquiryService.getAccountBySymbol(symbol ? symbol : 'ALL', brokerAccountSelected ? brokerAccountSelected : 'ALL', page, limitRow).then((data) => {
                // if (data && data.d) {
                //     data = data.d
                // }
                return data
            })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'ORDER_HISTORY');
                    return []
                });
        } catch (e) {
            alert(e);
            console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    genReport = (exptype) => {
        if(!this.applyInput){
            this.setReloadData(); // do gen báo cáo có sử dụng đầu vào nên cần gọi lại api  nếu input chưa được tìm kiếm
        }
        let { symbol, brokerAccountSelected } = this.state;
        const { userInfo } = this.props;
        if (symbol) {
            let tlid = userInfo && userInfo.custid;
            let rptId = '';
            let inpArr = [];
            rptId = 'STOCKACCOUNT';
            inpArr = ['ALL', brokerAccountSelected ? brokerAccountSelected : 'ALL', symbol, tlid, OUTPUT_REPORT.OFFSET, OUTPUT_REPORT.LIMIT, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('account-lookup-by-symbol.please-set-symbol');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };


    getCareByInGroup = async () => {
        await inquiryService.getCareByInGroup().then(data => {
            if (data && data.length !== 0) {
                this._setState({
                    listCareBy: [{
                        tlid: "ALL",
                        tlname: "ALL"
                    }, ...data]
                })
            } else {
                this._setState({
                    listCareBy: [{
                        tlid: "ALL",
                        tlname: "ALL"
                    }]
                })
                ToastUtil.error('confirm-order-online.fail-to-load-list-broker');
            }
        }).catch(error => {
            ToastUtil.errorApi(error, 'confirm-order-online.fail-to-load-list-broker');
        })
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.getCareByInGroup();
        this.setReloadData('NOT_CALL_API');
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.inputRef);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }


    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const { language, currentAccountId } = this.props;
        const { language: prevlanguage, currentAccountId: prevCurrentAccountId } = prevProps;

    }


    onSymbolChanged = (symbol) => {
        this.applyInput = false;
        this._setState({ symbol: symbol });
    };

    setBrokerAccountSelected = (e) => {
        this.applyInput = false;
        this._setState({
            brokerAccountSelected: e.target.value
        })
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    onBrokerChange = (symbol, valid, symbolObj) => {
        if (valid) {
            this._setState({ brokerAccountSelected: symbolObj.brokerId, displayBroker: symbolObj.id });
        } else {
            this._setState({ brokerAccountSelected: "", displayBroker: '' });
        }
    }

    // onSymbolChange = (symbol, valid, symbolObj) => {
    //     let _symbolToDisplay = this.getSymbolToDisplay(symbolObj);
    //     this._setState({ currentSymbol: symbol, symbolToDisplay: _symbolToDisplay, isValidSymbol: valid, symbolObj: symbolObj }, () => {
    //         this.checkValidSave();
    //         if (valid) {
    //             this.onSetShowSearch(false);
    //             this.registerSymbol(symbol, symbolObj);
    //         }
    //         else {
    //             this.onSetShowSearch(true);
    //         }
    //     })
    // };
    getAccountToSearch = () => {
        const { listCareBy } = this.state;
        let listToSet = {};
        if (listCareBy && listCareBy.length > 0) {
            listCareBy.forEach(item => {
                listToSet[item.tlname] = {
                    FullName: item.tlname || '',
                    desc: item.tlname || '',
                    id: item.tlname,
                    brokerId: item.tlid
                }
            })
        }

        return listToSet;
    }

    render() {
        const { language, accountInfo } = this.props;
        const { currentAccount, records, listAccounts, onReloadData, listCareBy, brokerAccountSelected, displayBroker } = this.state;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false;
        let accountsToSearch = this.getAccountToSearch();
        return (
            <RetryableContent className="account-lookup-by-symbol report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="account-lookup-by-symbol-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            <SymbolSuggestTrans
                                ref={this.inputRef}
                                handleInputChange={this.onSymbolChanged}
                                currentSymbol={this.state.symbol}
                                symbolToDisplay={this.state.symbol}
                                suggestId="account-lookup-report "
                                isRedirect={false}
                                isDisplayError={false}
                                isDisplayLabel={true}
                                placeholder={LanguageUtils.getMessageByKey("order-history.symbol", this.props.language)}
                            />

                            {/*<div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="account-lookup-by-symbol.table.broker" /></div>
                                <SymbolSuggestPlaceOrderQuick
                                    ref={this.symbolSearchRef}
                                    symbolValid={true}
                                    currentSymbol={displayBroker}
                                    symbolToDisplay={displayBroker}
                                    isDisplayLabel={false}
                                    handleInputChange={this.onBrokerChange}
                                    isRedirect={false}
                                    suggestId="symbol-search-market-alert"
                                    placeholder={LanguageUtils.getMessageByKey("account-lookup-by-symbol.all", this.props.language)}
                                    symbolWithIndex={accountsToSearch}
                                    disabled={false}
                                />
                            </div>*/}

                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="account-lookup-by-symbol.table.broker" /></div>
                                <select value={brokerAccountSelected ? brokerAccountSelected : ''} onChange={this.setBrokerAccountSelected} className="custom-form-control" autoFocus={true} style={{ marginLeft: '5px' }}>
                                    {listCareBy && listCareBy.map((broker) => {
                                        return (
                                            <option key={broker.tlid} value={broker.tlid}>{broker.tlid} - {broker.tlname !== "ALL" ? broker.tlname : LanguageUtils.getMessageByKey("common.ALL", language)}</option>
                                        )
                                    })}
                                </select>
                            </div>


                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="symbol" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="account-lookup-paging" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            apiLoadAllByPagingRes={this.apiPaging}
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={200}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ...accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    // let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    // let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // let accountInfo = _layoutPage && _layoutPage["accountInfo"]
    // let { accounts, currentAccountId, curCustomer } = { ...accountInfo }
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: accountInfo,
        userInfo: state.user.userInfo,
        defaultTheme: defaultTheme
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountLookupBySymbol));

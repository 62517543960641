import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import CustomScrollbars from "../../../components/CustomScrollbars";

import NotificationItem from "./NotificationItem";
import { accountNotifyType } from "../../../utils";

class AccountNotifications extends Component {

    loadMoreNoti = () => {
        // //console.log("binh_loadMoreNoti", this.props)
        const { getAccountMessageData } = this.props
        getAccountMessageData && getAccountMessageData(false, { isLoadMore: true })
    }

    render() {
        const { accountmessages, lang, allCodeMessage, confirm_orders_infor, fetchAccountNotiResult, deleteAllNotify, userInfo, notifyType, nextIndexNoti, accountInfo, } = this.props;
        var messsages = [];
        if (accountmessages && accountmessages.length > 0) {
            messsages = [...messsages, ...accountmessages];
        }

        // Lọc message cảnh báo theo templateid
        if (notifyType === accountNotifyType.TRANSACTION) {
            let transactionMesssages = messsages.filter(item => { return item.templateid !== "1001" });
            messsages = confirm_orders_infor ? [...confirm_orders_infor, ...transactionMesssages] : [...transactionMesssages]; // Bỏ thông báo xác nhận lệnh
            // messsages = transactionMesssages;
        } else if (notifyType === accountNotifyType.MARKET_ALERT) {
            messsages = messsages.filter(item => { return item.templateid === "1001" });
        }

        return (
            <div className="notification-tab-content">
                <div className={userInfo.role === "C" ? "flex-scroll-content-account" : "flex-scroll-content"}>
                    <CustomScrollbars className="notifications">
                        {messsages && messsages.map((message, index) => {
                            return <NotificationItem
                                key={index}
                                message={message}
                                allCodeMessage={allCodeMessage}
                                lang={lang}

                                accountInfo={accountInfo}
                            />
                        })}
                        {fetchAccountNotiResult === false && (
                            <div className="notification-item" tabIndex="0">
                                <div className="inner">
                                    <div className="date">
                                        <FormattedMessage id="common.fail-to-load-account-notification-list" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </CustomScrollbars>
                    {nextIndexNoti > 0 && (
                        <div className='load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreNoti()}>
                            <FormattedMessage id="common.load-more-detail" />
                        </div>
                    )}
                </div>
                {/*<div className="col-12">
                    <div className="action-delete">
                        {userInfo.role === "C" && (
                            <Fragment>
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.notifications.deleteTooltip" />}
                                    targetID="deleteNotify"
                                ></TextToolTip>
                                <button
                                    id="deleteNotify"
                                    className="btn-delete"
                                    onClick={deleteAllNotify}
                                >
                                    <FormattedMessage id="common.notifications.delete-notify" />
                                </button>
                            </Fragment>
                        )}
                    </div>
                </div>*/}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        userInfo: state.user.userInfo,
    }
}

export default connect(mapStateToProps)(AccountNotifications);
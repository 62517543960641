import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';

import { LoadStatus, KeyCodeUtils, CommonWidgetUtils, path, ToastUtil } from "../../utils";
import { connect } from "react-redux";
import './ModalHeaderCreateLayout.scss';
import { batch } from 'react-redux';
import _ from "lodash";
import * as actions from "../../store/actions";
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { ReactComponent as WarningIcon_Dark } from '../../assets/icons/screenmodal/WarningIcon_Dark.svg'
const globalVar = window._env_

class ModalHeaderCreateLayout extends Component {

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
    }

    initialState = {
        loadStatus: LoadStatus.NONE,
        nameLayout: ''
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        this.onSetSuggestNameLayout()
        document.addEventListener('keydown', this.handlerKeyDown);
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }

    }

    onSetSuggestNameLayout = () => {
        let { layoutsCustom, language } = this.props
        let newWidget = "Tùy chỉnh "
        if (language !== "vi") {
            newWidget = "Workspace "
        }
        if (layoutsCustom) {
            layoutsCustom = layoutsCustom.filter((e) => e.typeLayout == "C")
            newWidget = newWidget + (layoutsCustom.length + 1)
        }
        this._setState({ nameLayout: newWidget })
    }


    onChangeInputName = (e) => {
        this._setState({
            nameLayout: e.target.value
        })
    }

    refreshInputName = () => {
        this._setState({
            ...this.initialState
        })
    }

    componentWillUnmount() {
        this.mounted = false
        document.removeEventListener('keydown', this.handlerKeyDown);
    }

    isCheckCreateNewLayoutCustom = (currentLayoutPageActive) => {
        //console.log("isCheckCreateNewLayoutCustom 1=", currentLayoutPageActive)

        let OBJLAYOUT = currentLayoutPageActive && currentLayoutPageActive.split('$TC_TEMP')
        OBJLAYOUT = (OBJLAYOUT && OBJLAYOUT.length > 0) && OBJLAYOUT[0]
        let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
        //console.log("isCheckCreateNewLayoutCustom 2=", OBJLAYOUT_CUSTOM, OBJLAYOUT)
        if (!OBJLAYOUT_CUSTOM) {
            ToastUtil.warn('WR000001')
            return false
        }
        return true
    }

    subCreateNewLayout = (actionType, nameLayoutCustom) => {
        // alert(actionType + '_' + nameLayoutCustom)
        let { isDeskTop, userInfo } = this.props

        if (actionType == "C_C_C") {

            // Tạo mới layout tùy chỉnh
            let OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#CS#TRADE#NC') // Mặc định theo role
            let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
            // nameLayoutCustom = window.prompt('Nhập tên layout tùy chỉnh cần khởi tạo:..', nameLayoutCustomSuggest)
            if (nameLayoutCustom) {
                // check trùng tên so với database. nếu chưa tồn tại thì cho phép khởi tạo.
                let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
                let layoutPageCurrent = getLayoutPageCurrent.LayoutPageCurrent;
                if (!this.isCheckCreateNewLayoutCustom(layoutPageCurrent.keyName)) {
                    return null;
                }
                // let keyName = 'CS#TRADE#CB'
                // let activeURL = "/trade/"
                let activeURL = path.CUSTOMETRADE + '/' + CommonWidgetUtils.getPathLayoutCustom(OBJLAYOUT_CUSTOM)
                let newData = {}
                newData[OBJLAYOUT_CUSTOM] = {
                    "keyName": OBJLAYOUT_CUSTOM,
                    "activeURL": activeURL,
                    "accountInfo": layoutPageCurrent && layoutPageCurrent.accountInfo,
                    "symbols": "",
                    "typeLayout": "C",
                    "showMenu": true,
                    "typeUser": userInfo.role || "",
                    "isEditLayout": true,
                    // "layoutCurrent": layoutCurrent
                }
                let layoutCurrent = {
                    id: OBJLAYOUT_CUSTOM,
                    layoutName: nameLayoutCustom,
                    typeLayout: "C",
                }
                let isSaveByBtn = true
                let isOverRidesDf = false
                let from = 'Header.:activeNewLayoutPageTemp_addLayout'
                this.props.addNewLayoutCustom(newData, OBJLAYOUT_CUSTOM, layoutCurrent, isSaveByBtn, isOverRidesDf, from);
                this.props.setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: false,
                    curScreenModalActive: ""
                })
                // const { navigate } = this.props;
                // navigate(activeURL)
            } else {
                // alert('Vui lòng nhập tên layout tùy chỉnh!')
            }
        }

        if (actionType == "C_O_C") {
            // Ghi đè lên layout tùy chỉnh
            let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
            let currentLayoutPageActive = getLayoutPageCurrent.currentLayoutPageActive
            let OBJLAYOUT = currentLayoutPageActive.split('$TC_TEMP')
            OBJLAYOUT = OBJLAYOUT[0]
            // let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
            // if (!nameLayoutCustom) {
            //     // this.activeNewLayoutPageTemp(nameLayoutCustom, OBJLAYOUT_CUSTOM)
            //     // this._setState({
            //     //     isOpenModalCreatLayout: false
            //     // })
            //     return null;
            //     // nameLayoutCustom = getLayoutPageCurrent.layoutName
            // }
            // let isOverRidesDf = true
            this.activeNewLayoutPageTemp(undefined, OBJLAYOUT, actionType)

        }
        if (actionType == "DC_CO_C") {

            // Tạo mới layout tùy chỉnh từ Deault
            let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
            let layoutPageCurrent = getLayoutPageCurrent.LayoutPageCurrent;
            let currentLayoutPageActive = getLayoutPageCurrent.currentLayoutPageActive
            if (!this.isCheckCreateNewLayoutCustom(layoutPageCurrent.keyName)) {
                return null;
            }
            let OBJLAYOUT = currentLayoutPageActive.split('$TC_TEMP')
            OBJLAYOUT = OBJLAYOUT[0]
            let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
            if (!nameLayoutCustom) {
                // this.activeNewLayoutPageTemp(nameLayoutCustom, OBJLAYOUT_CUSTOM)
                // this._setState({
                //     isOpenModalCreatLayout: false
                // })
                return null;
                // nameLayoutCustom = getLayoutPageCurrent.layoutName
            }
            // let isOverRidesDf = true
            this.activeNewLayoutPageTemp(nameLayoutCustom, OBJLAYOUT_CUSTOM, actionType)
        }
        if (actionType == "DC_O_DC") {
            // Ghi đè lên layout mặc định
            let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
            let currentLayoutPageActive = getLayoutPageCurrent.currentLayoutPageActive
            let OBJLAYOUT = currentLayoutPageActive.split('$TC_TEMP')
            OBJLAYOUT = OBJLAYOUT[0]
            // Ghi đè
            // //console.log('subOverRideLayoutDefault.:', this.props.layoutsCustom)
            this.activeNewLayoutPageTemp(OBJLAYOUT, OBJLAYOUT, actionType)
        }
        // this.props.toggle()
        // let self = this
        // setTimeout(function () {
        this.onCloseModal(false)
        // }, 0)
    }
    activeNewLayoutPageTemp = (nameLayoutCustom, OBJLAYOUT_CUSTOM, actionType) => {
        // alert('activeNewLayoutPageTemp' + nameLayoutCustom + '_' + OBJLAYOUT_CUSTOM + '_' + actionType)
        // alert('addNewLayoutPageTemp.:OBJLAYOUT_CUSTOM' + OBJLAYOUT_CUSTOM)
        let { isDeskTop, userInfo } = this.props
        let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
        let LayoutPageCurrent = getLayoutPageCurrent.LayoutPageCurrent;
        let layoutCurrent = getLayoutPageCurrent.layoutCurrent
        //console.log('activeNewLayoutPageTemp.:showMenu.:', nameLayoutCustom, getLayoutPageCurrent, LayoutPageCurrent, layoutCurrent)
        let newData = {}
        let activeURL = path.CUSTOMETRADE + '/' + CommonWidgetUtils.getPathLayoutCustom(OBJLAYOUT_CUSTOM)
        newData[OBJLAYOUT_CUSTOM] = _.cloneDeep(LayoutPageCurrent)
        newData[OBJLAYOUT_CUSTOM].showMenu = true;
        newData[OBJLAYOUT_CUSTOM].keyName = OBJLAYOUT_CUSTOM;
        let newLayoutCurrent = {}
        newLayoutCurrent = _.cloneDeep(layoutCurrent)
        newLayoutCurrent.id = OBJLAYOUT_CUSTOM;
        newLayoutCurrent.sourceLayoutId = OBJLAYOUT_CUSTOM;
        newLayoutCurrent.layoutName = nameLayoutCustom ? nameLayoutCustom : layoutCurrent.layoutName
        if (actionType == "DC_O_DC") {
            // //console.log('activeNewLayoutPageTemp.:haki check.:1', LayoutPageCurrent)
            // //console.log('activeNewLayoutPageTemp.:haki check.:2', layoutCurrent)
            // //console.log('activeNewLayoutPageTemp.:haki check.:3', getLayoutPageCurrent)
            // alert(OBJLAYOUT_CUSTOM)
            let _getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent(false, OBJLAYOUT_CUSTOM);
            let _LayoutPageCurrent = _getLayoutPageCurrent.LayoutPageCurrent;
            newData[OBJLAYOUT_CUSTOM].activeURL = _LayoutPageCurrent && _LayoutPageCurrent.activeURL;
            newLayoutCurrent.typeLayout = "DC"
            newData[OBJLAYOUT_CUSTOM].typeLayout = "DC";

            // newData[OBJLAYOUT_CUSTOM].activeURL = '/protrade'
        }
        if (actionType == "DC_CO_C" || actionType == "C_O_C" || actionType == "C_C_C") {
            newData[OBJLAYOUT_CUSTOM].activeURL = activeURL;
            newLayoutCurrent.typeLayout = "C"
            newData[OBJLAYOUT_CUSTOM].typeLayout = "C";
        }
        if (actionType == "DC_O_DC" || actionType == "DC_CO_C") {
            let breakpoint = getLayoutPageCurrent.breakpoint
            let _getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent(OBJLAYOUT_CUSTOM);
            //console.log('Haki check = getLayoutPageCurrent.: 1', OBJLAYOUT_CUSTOM, _getLayoutPageCurrent.layoutCurrent, newLayoutCurrent.grid)
            if (!_getLayoutPageCurrent.layoutCurrent || _getLayoutPageCurrent.layoutCurrent.id !== OBJLAYOUT_CUSTOM) {
                //console.log('Haki check = getLayoutPageCurrent.: 2', newLayoutCurrent.grid)
                let keys = Object.keys(newLayoutCurrent.grid)
                keys.map((key) => {
                    if (key !== breakpoint) {
                        // lần đầu khởi tạo => Xóa các kích cỡ != breakpoint
                        delete newLayoutCurrent.grid[key];
                        // break;
                    }
                })
                //console.log('Haki check = getLayoutPageCurrent.: 3', newLayoutCurrent.grid)
            }
        }
        // alert(getLayoutPageCurrent.activeURL)
        newData[OBJLAYOUT_CUSTOM].typeUser = userInfo && userInfo.role;

        let isSaveByBtn = true
        let isOverRidesDf = true
        //console.log('activeNewLayoutPageTemp.:showMenu.: 2=', newData, OBJLAYOUT_CUSTOM, newLayoutCurrent, isSaveByBtn, isOverRidesDf)
        let from = 'Header.:activeNewLayoutPageTemp_saveLayout'
        this.props.addNewLayoutCustom(newData, OBJLAYOUT_CUSTOM, newLayoutCurrent, isSaveByBtn, isOverRidesDf, from);


        // const { navigate } = this.props;
        // navigate(activeURL)

    }


    renderCreateNewCustom = () => { // Tạo mới  layout
        return (
            <div className="create-new-custom" >
                <div className="text-center div-input-layout">
                    <input type='text' value={this.state.nameLayout} className='input-edit-name text-active '
                        onChange={(e) => this.onChangeInputName(e)}
                    >
                    </input>
                    {/* <button className="btn-not-outline"><i onClick={this.refreshInputName} style={{ lineHeight: '28px', fontSize: 22, cursor: 'pointer' }} className="fal fa-times"></i></button> */}
                </div>
                <div className="container-btn">
                    <button className="btn btn-confirm txt---400-14-20"
                        ref={this.confirmBtnRef}
                        disabled={this.state.nameLayout.length === 0}
                        onClick={() => this.subCreateNewLayout("C_C_C", this.state.nameLayout)}
                    >
                        <FormattedMessage id="common.btn-confirm" />
                    </button>
                </div>
            </div>
        )
    }

    renderCreateOrOverrideByBtnSave = (typeLayout) => {
        let { isCreateNewCustom } = this.props
        const isShowOverRide = globalVar.app.IS_OVERRIDE_LAYOUT_DEFAULT
        return (
            <div className="create-override-data">

                {
                    (!isCreateNewCustom && typeLayout == "C") && <div className="create-override-data-dc-o-dc">
                        <div className='headB---700-20-30 title'>
                            <WarningIcon_Dark />
                        </div>
                        <div className='txt---400-16-24 content'>
                            {/* Lưu thành giao diện tùy chỉnh? */}
                            <FormattedMessage id="edit-layout.save-to-custom" />
                        </div>
                        {/* Thao tác */}
                        <div className="container-btn">
                            {/* Làm mới */}
                            <button className="btn btn-refresh txt---400-14-20"
                                onClick={this.onCloseModal}
                            >
                                <FormattedMessage id="common.btn-cancel" />
                            </button>
                            {/* Xác nhận */}
                            <button className="btn btn-confirm txt---400-14-20"
                                ref={this.confirmBtnRef}
                                disabled={this.state.nameLayout.length === 0}
                                onClick={() => this.subCreateNewLayout("C_O_C", this.state.nameLayout)}
                            >
                                <FormattedMessage id="common.btn-confirm" />
                            </button>
                        </div>
                    </div>
                }
                {
                    (!isCreateNewCustom && typeLayout == "DC") && <div className="create-override-data-dc-o-dc">
                        <div className='headB---700-20-30 title'>
                            <WarningIcon_Dark />
                        </div>
                        <div className='txt---400-16-24 content'>
                            <FormattedMessage id="edit-layout.save-to-default" />
                            {/* Lưu thành giao diện đặt lệnh mặc định? */}
                        </div>
                        {/* Thao tác */}
                        <div className="container-btn">
                            {/* Làm mới */}
                            <button className="btn btn-refresh txt---400-14-20"
                                onClick={this.onCloseModal}
                            >
                                <FormattedMessage id="common.btn-cancel" />
                            </button>
                            {/* Xác nhận */}
                            <button className="btn btn-confirm txt---400-14-20"
                                ref={this.confirmBtnRef}
                                disabled={this.state.nameLayout.length === 0}
                                onClick={() => this.subCreateNewLayout("DC_O_DC")}
                            >
                                <FormattedMessage id="common.btn-confirm" />
                            </button>
                        </div>
                    </div>
                }
                {/* {
                    (!isCreateNewCustom && typeLayout == "C") && <button className="btn txt---400-14-20"
                        ref={this.confirmBtnRef}
                        disabled={this.state.nameLayout.length === 0}
                        onClick={() => this.subCreateNewLayout("C_O_C", this.state.nameLayout)}
                    >
                        Custom save override Custom
                    </button>
                }

                {
                    (!isCreateNewCustom && typeLayout == "DC") && <button className="btn txt---400-14-20"
                        ref={this.confirmBtnRef}
                        disabled={this.state.nameLayout.length === 0}
                        onClick={() => this.subCreateNewLayout("DC_CO_C", this.state.nameLayout)}
                    >
                        Default save new Custom
                    </button>
                }
                {
                    (!isCreateNewCustom && typeLayout == "DC") && isShowOverRide && <button
                        ref={this.confirmBtnRef}
                        className="btn txt---400-14-20"
                        disabled={this.state.nameLayout.length === 0}
                        onClick={() => this.subCreateNewLayout("DC_O_DC")}
                    >
                        Default save override Default
                    </button>
                } */}
            </div>
        )
    }

    onCloseModal = (isCancel = true) => { // isCancel === true là action hủy thì thay thế layoutCustom => layoutConfig, = false là lưu k cần thay thế chỉ ẩn đi
        const { data, toggle, layoutsCustom } = this.props
        if (data) {
            const { from, onCallbackUpdateLayoutCustom, onCallbackHideLayoutPage } = data
            if (from) {
                if (from.includes("btn_save_delete")) {
                    batch(() => {
                        isCancel && onCallbackUpdateLayoutCustom && onCallbackUpdateLayoutCustom()
                        onCallbackHideLayoutPage && onCallbackHideLayoutPage()
                    })
                }
                if (from.includes("btn_logout")) {

                    // setTimeout(function () {
                    batch(() => {
                        isCancel && onCallbackUpdateLayoutCustom && onCallbackUpdateLayoutCustom()
                        onCallbackHideLayoutPage && onCallbackHideLayoutPage()
                    })
                    // }, 2000)
                    toggle(from)
                    return
                }
            }
            toggle()
            return
        }
        toggle()
    }

    render() {
        const { isOpen, subCreateNewLayout, subOverRideLayoutDefault,
            toggle, currentLayoutPageActive, layoutPage,
            isCreateNewCustom
        } = this.props;

        let typeLayout = CommonWidgetUtils.getTypeLayoutAcive()
        let titleId = "edit-layout.input-layout-new-header"
        if (typeLayout == "DC") {
            titleId = "Thông báo tạo mới layout"
        }
        if (typeLayout == "C") {
            titleId = "Thông báo lưu layout"
        }

        // 'modal-create-layout-header'
        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="modal-create-layout-header"
            >
                {isCreateNewCustom
                    && <div className="custom-header">
                        <div style={{ fontSize: 16 }}>
                            <FormattedMessage id="edit-layout.input-layout-new" />
                        </div>
                        <div className="btn-icon-fm" onClick={toggle}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                }
                <ModalBody className="custom-body txt---500-16-24">
                    <div className="body txt---400-14-20">
                        <div className="wrap-body">
                            {isCreateNewCustom ? this.renderCreateNewCustom() : this.renderCreateOrOverrideByBtnSave(typeLayout)}
                        </div>
                    </div >
                </ModalBody>
            </Modal >
        );
    }

}


const mapStateToProps = (state) => {
    const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const userInfo = state.user.userInfo;
    return {
        userInfo,
        layoutsCustom: state.layout.layoutsCustom,
        layoutsConfig: state.layout.layoutsConfig,
        // layoutPage: state.layout.layoutPage,
        language: state.app.language,
        currentLayoutPageActive: currentLayoutPageActive,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addNewLayoutCustom: (newLayout, OBJLAYOUT_CUSTOM, layoutCustom, isSaveByBtn, isOverRidesDf, from) => dispatch(actions.addNewLayoutCustom(newLayout, OBJLAYOUT_CUSTOM, layoutCustom, isSaveByBtn, isOverRidesDf, from)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeaderCreateLayout);

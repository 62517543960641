import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from "../utils";

// isLoggedIn, isLogginFail, isLoggingIn
const defaultPath = path.PRICEBOARD
// isSkipPrivate : Bỏ qua việc check Private đối với các layout Widget
const PrivateRoute = ({ component: Component, isLoggedIn, isLogginFail, isSkipPrivate, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            (!isSkipPrivate && !isLoggedIn && !isLogginFail) ?
                (<Redirect to={defaultPath} />) :
                (<Component {...props} />)}
    />
);

PrivateRoute.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    isLogginFail: state.user.isLogginFail,
    // isLoggingIn: state.user.isLoggingIn
});

export default connect(mapStateToProps)(PrivateRoute);
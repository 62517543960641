import React, { Component } from 'react';
import moment from 'moment';

import DraggableModal from '../../components/Modal/DraggableModal';

class ModalDetailNews extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
    };

    state = {
        ...this.initialState
    };

    render() {
        const { isOpen, toggle, paramNew } = this.props;
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={toggle}
                // className='confirm-modal'
                titleId={paramNew.TITLE}
            >
                <div className="body">
                    <div className="seperate-line"></div>
                    <div >
                        <div dangerouslySetInnerHTML={{ __html: paramNew.DETAIL }}></div>
                        <div className='text-near-active d-flex' style={{ gap: "5px" }}>
                            <div>{paramNew.SOURCE_NEWS}</div>
                            <div>|</div>
                            <div>{moment(paramNew.POST_DATE).format('DD-MM-YYYY HH:mm')}</div>
                        </div>
                        {paramNew.URL !== "" &&
                            <div style={{ textAlign: "center" }}>
                                <a className='text-center bold ' style={{ cursor: "pointer" }} target="_blank" href={paramNew.URL} rel="noreferrer">
                                    TẢI VỀ
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </DraggableModal>
        );
    }

}



export default ModalDetailNews;
// Haki_lt.: LayoutUtils: Khai báo các loại layout theo OBJLAYOUT
import _ from "lodash";
import memoizeOne from 'memoize-one';

import config from '../../combineConfig';
import { COLS_WIDGET, CommonUtils, CommonWidgetUtils, TYPE_CONFIG } from "../../utils";
import { WIDGET_WIDTH } from "../../utils/constants";
import * as LayoutDesktop from "./LayoutDesktop";
import * as LayoutMobile from "./LayoutMobile";

const staticFalse = config.switch.isWidgetStatic ? true : false;

const DF_LIST_FEATURE = {
    isFullScreenable: true, // cho phép phóng to màn hình
    isShowHidden: true, // Cho phép ẩn hiện
    isAllowDragDrop: true, // cho phép kéo thả
    isAllowClose: true, // Cho phép đóng
    isPermission: true, // mở layout phải đăng nhập
    isFullScreenCustom: false, //cho phép phóng to màn hình và mở 1 màn hình khác
}


export const layoutsCustomID = ['$TC003', '$TC002', '$TC001']

export const layoutConfigKey = {
    // Haki_lt.: List layout theo OBJLAYOUT lưu trên localstorage
    'C#CS#PRICEBOARD#CB': {
        configKey: 'PRICEBOARD-layout-cf', // Mặc định
        layoutVersion: 'PRICEBOARD-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'C#CS#TRADE#CB': {
        configKey: 'TRADE-layout-cf', // Mặc định
        layoutVersion: 'TRADE-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'C#CS#TRADE#NC': {
        configKey: 'PRO-TRADE-layout-cf', // Mặc định
        layoutVersion: 'PRO-TRADE-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'C#CS#TRADE#TPRL_NM': {
        configKey: 'TPRLNM-TRADE-layout-cf', // Mặc định
        layoutVersion: 'TPRLNM-TRADE-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'B#CS#TRADE#TPRL_OR': {
        configKey: 'TPRLOR-TRADE-layout-cf', // Mặc định
        layoutVersion: 'TPRLOR-TRADE-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'B#CS#TRADE#TT': {
        configKey: 'AGREMENT-TRADE-layout-cf', // Mặc định
        layoutVersion: 'AGREMENT-TRADE-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'C#CS#MARKET_WATCH': {
        configKey: 'MARKET-WATCH-layout-cf', // Mặc định
        layoutVersion: 'MARKET-WATCH-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
    'C#CS#AI_TRADING': {
        configKey: 'AI-TRADING-layout-cf', // Mặc định
        layoutVersion: 'AI-TRADING-layout-ver', // Mặc định
        newVersion: '1.0', // Thay đổi khi nâng cấp mới
    },
}

// export const getKeyLayout = (role) => {
//     // Haki_lt.: Tạm thời mặc định là Cơ sở
//     let account = ACCOUNT_TYPE.CS;
//     let key = `${role}.${account}`;

//     key = 'C#CS#TRADE#CB'
//     return key;
// };

export const createWidgetConfig = (OBJLAYOUT, id, desktopVisible, mobileVisible, collapsible, base, listFeature) => {
    // Haki_lt.: Khởi tạo các config về widget theo các property hỗ trợ
    const _listFeature = {
        ...DF_LIST_FEATURE,
        ...listFeature
    }
    // const { isShowHidden, isAllowDragDrop, isFullScreenable, isAllowClose } = _listFeature

    const config = {
        id: id,
        desktopVisible: desktopVisible,
        mobileVisible: mobileVisible,
        collapsible: collapsible,
        ..._listFeature
    };
    config[OBJLAYOUT] = base;
    // config[getKeyLayout(Role.CUSTOMER)] = baseCustomer;
    // config[getKeyLayout(Role.BROKER)] = baseBroker;
    return config;
};

const allSections = {
    // Haki_lt.: Danh sách tất cả Widget theo OBJLAYOUT cho KH
    "C#CS#PRICEBOARD#CB": [
        createWidgetConfig('C#CS#PRICEBOARD#CB', 'priceboard', true, true, true, true, DF_LIST_FEATURE),
    ],
    "C#CS#TRADE#CB": [
        createWidgetConfig('C#CS#TRADE#CB', 'infoSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'infoPortfolioFavorites', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'priceChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'chartMatchedInfo', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'recentTrades', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'conditionOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'draftOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'analysisSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'newsSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'assetTotalDetail', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#TRADE#CB', 'brokerOrderBook', true, true, true, true,true, true),

        // createWidgetConfig('C#CS#TRADE#CB', 'placeOrderPro', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#CB', 'symbolSelector', true, true, true, true, DF_LIST_FEATURE),

        //Chuyên mục thị trường
        // createWidgetConfig('C#CS#TRADE#CB', 'marketBreadth', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'topStockUp', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'topStockDown', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'mutationLiquidity', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'recommendedList', true, true, true, true, { isPermission: false, isFullScreenCustom: true, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'liquidityChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'distributionStockCapitalization', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#CB', 'foreignBlockChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
    ],
    "C#CS#TRADE#NC": [
        createWidgetConfig('C#CS#TRADE#NC', 'infoSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'infoPortfolioFavorites', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'recentTrades', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'priceChart', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#TRADE#NC', 'placeOrderPro', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'depthChart', true, true, true, true, DF_LIST_FEATURE),

        createWidgetConfig('C#CS#TRADE#NC', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#TRADE#NC', 'conditionOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'draftOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#TRADE#NC', 'brokerOrderBook', true, true, true, true,true, true),
        createWidgetConfig('C#CS#TRADE#NC', 'analysisSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'newsSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'assetTotalDetail', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#NC', 'symbolSelector', true, true, true, true, DF_LIST_FEATURE),

        //Chuyên mục thị trường
        // createWidgetConfig('C#CS#TRADE#NC', 'marketBreadth', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'topStockUp', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'topStockDown', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'mutationLiquidity', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'recommendedList', true, true, true, true, { isPermission: false, isFullScreenCustom: true, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'liquidityChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'distributionStockCapitalization', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#TRADE#NC', 'foreignBlockChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
    ],
    // Haki_lt.: Danh sách tất cả Widget theo OBJLAYOUT cho MG
    "B#CS#PRICEBOARD#CB": [
        createWidgetConfig('B#CS#PRICEBOARD#CB', 'priceboard', true, true, true, true, DF_LIST_FEATURE),
    ],
    "B#CS#TRADE#CB": [
        // createWidgetConfig('B#CS#TRADE#CB', 'putthroughtOrder', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#TRADE#CB', 'putthroughConfirmOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'putthroughConfirmCancelOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'putthroughOrderNoti', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#TRADE#CB', 'advertisingOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        createWidgetConfig('B#CS#TRADE#CB', 'infoSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'infoPortfolioFavorites', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'priceChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'chartMatchedInfo', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'recentTrades', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'conditionOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'brokerOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'draftOrderBook', true, true, true, true, DF_LIST_FEATURE),

        // createWidgetConfig('B#CS#TRADE#CB', 'placeOrderPro', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'analysisSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'newsSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'assetTotalDetail', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#CB', 'symbolSelector', true, true, true, true, DF_LIST_FEATURE),

        //Chuyên mục thị trường
        // createWidgetConfig('B#CS#TRADE#CB', 'marketBreadth', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'topStockUp', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'topStockDown', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'mutationLiquidity', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'recommendedList', true, true, true, true, { isPermission: false, isFullScreenCustom: true, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'liquidityChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'distributionStockCapitalization', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#CB', 'foreignBlockChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
    ],
    "B#CS#TRADE#NC": [
        // createWidgetConfig('B#CS#TRADE#NC', 'putthroughtOrder', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#TRADE#NC', 'putthroughConfirmOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'putthroughConfirmCancelOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'putthroughOrderNoti', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#TRADE#NC', 'advertisingOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        createWidgetConfig('B#CS#TRADE#NC', 'infoSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'infoPortfolioFavorites', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'priceChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'recentTrades', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#TRADE#NC', 'conditionOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'brokerOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'draftOrderBook', true, true, true, true, DF_LIST_FEATURE),

        // createWidgetConfig('B#CS#TRADE#NC', 'placeOrderPro', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'analysisSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'newsSymbol', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'assetTotalDetail', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#NC', 'symbolSelector', true, true, true, true, DF_LIST_FEATURE),

        //Chuyên mục thị trường
        // createWidgetConfig('B#CS#TRADE#NC', 'marketBreadth', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'topStockUp', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'topStockDown', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'mutationLiquidity', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'recommendedList', true, true, true, true, { isPermission: false, isFullScreenCustom: true, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'liquidityChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'distributionStockCapitalization', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#TRADE#NC', 'foreignBlockChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
    ],
    "B#CS#TRADE#TT": [
        createWidgetConfig('B#CS#TRADE#TT', 'putthroughtOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        createWidgetConfig('B#CS#TRADE#TT', 'putthroughConfirmOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        createWidgetConfig('B#CS#TRADE#TT', 'putthroughConfirmCancelOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        createWidgetConfig('B#CS#TRADE#TT', 'putthroughOrderNoti', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'advertisingOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'infoSymbol', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'placeOrderNormal', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'infoPortfolioFavorites', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'priceChart', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'depthChart', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'matchedOrder', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'recentTrades', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        createWidgetConfig('B#CS#TRADE#TT', 'openPositions', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        createWidgetConfig('B#CS#TRADE#TT', 'orderActive', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        createWidgetConfig('B#CS#TRADE#TT', 'normalOrderBook', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false, isAllowClose: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'conditionOrderBook', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),
        // createWidgetConfig('B#CS#TRADE#TT', 'brokerOrderBook', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),

        // createWidgetConfig('B#CS#TRADE#TT', 'placeOrderPro', true, true, true, true, { isShowHidden: false, isAllowDragDrop: false, isFullScreenable: false }),

    ],

    "C#CS#MARKET_WATCH": [
        // createWidgetConfig('C#CS#MARKET_WATCH', 'infoSymbol', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'infoPortfolioFavorites', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'priceChart', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'recentTrades', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'conditionOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'draftOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'analysisSymbol', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'newsSymbol', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'assetTotalDetail', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'placeOrderPro', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'symbolSelector', true, true, true, true, DF_LIST_FEATURE),

        //Chuyên mục thị trường
        createWidgetConfig('C#CS#MARKET_WATCH', 'listStatusMarketIndex', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('C#CS#MARKET_WATCH', 'topStockUp', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('C#CS#MARKET_WATCH', 'topStockDown', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('C#CS#MARKET_WATCH', 'mutationLiquidity', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'recommendedList', true, true, true, true, { isPermission: false, isFullScreenCustom: true, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'liquidityChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'distributionStockCapitalization', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'foreignBlockChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'newsMarket', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('C#CS#MARKET_WATCH', 'analysisMarket', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
    ],
    "B#CS#MARKET_WATCH": [
        // createWidgetConfig('B#CS#MARKET_WATCH', 'infoSymbol', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'infoPortfolioFavorites', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'priceChart', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'recentTrades', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'conditionOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'draftOrderBook', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'analysisSymbol', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'newsSymbol', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'assetTotalDetail', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'placeOrderPro', true, true, true, true, DF_LIST_FEATURE),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'symbolSelector', true, true, true, true, DF_LIST_FEATURE),


        //Chuyên mục thị trường
        createWidgetConfig('B#CS#MARKET_WATCH', 'listStatusMarketIndex', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('B#CS#MARKET_WATCH', 'topStockUp', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('B#CS#MARKET_WATCH', 'topStockDown', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('B#CS#MARKET_WATCH', 'mutationLiquidity', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        createWidgetConfig('B#CS#MARKET_WATCH', 'listTranslog', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'recommendedList', true, true, true, true, { isPermission: false, isFullScreenCustom: true, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'liquidityChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'distributionStockCapitalization', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'foreignBlockChart', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'newsMarket', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
        // createWidgetConfig('B#CS#MARKET_WATCH', 'analysisMarket', true, true, true, true, { isPermission: false, isAllowDragDrop: false, isAllowClose: false, isShowHidden: false }),
    ],
    "C#CS#AI_TRADING": [
        //Ai trading
        createWidgetConfig('C#CS#AI_TRADING', 'marketStatus', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
        createWidgetConfig('C#CS#AI_TRADING', 'ratingSector', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
        createWidgetConfig('C#CS#AI_TRADING', 'ratingStock', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
        createWidgetConfig('C#CS#AI_TRADING', 'marketSignal', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
    ],
    "B#CS#AI_TRADING": [
        //Ai trading
        createWidgetConfig('B#CS#AI_TRADING', 'marketStatus', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
        createWidgetConfig('B#CS#AI_TRADING', 'ratingSector', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
        createWidgetConfig('B#CS#AI_TRADING', 'ratingStock', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
        createWidgetConfig('B#CS#AI_TRADING', 'marketSignal', true, true, true, true, { ...DF_LIST_FEATURE, isFullScreenCustom: true }),
    ],
    "C#CS#TRADE#TPRL_NM": [
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_NM', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
    ],
    "B#CS#TRADE#TPRL_NM": [
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_NM', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
    ],
    "C#CS#TRADE#TPRL_OR": [
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'bondDetailTPRL', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'transactionDetailTPRL', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_OR', 'orderBookTPRL', true, true, true, true, DF_LIST_FEATURE),
    ],
    "B#CS#TRADE#TPRL_OR": [
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'bondDetailTPRL', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'transactionDetailTPRL', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_OR', 'orderBookTPRL', true, true, true, true, DF_LIST_FEATURE),
    ],
    "C#CS#TRADE#TPRL_RP": [
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('C#CS#TRADE#TPRL_RP', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
    ],
    "B#CS#TRADE#TPRL_RP": [
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'placeOrderNormal', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'depthChart', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'matchedOrder', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'openPositions', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'normalOrderBook', true, true, true, true, DF_LIST_FEATURE),
        createWidgetConfig('B#CS#TRADE#TPRL_RP', 'orderActive', true, true, true, true, DF_LIST_FEATURE),
    ],
};

const layoutDesktopRegistry = {
    // KH
    [`C#CS#PRICEBOARD#CB`]: [LayoutDesktop.C_CS_PRICEBOARD_CB],
    [`C#CS#TRADE#CB`]: [LayoutDesktop.C_CS_TRADE_CB],
    [`C#CS#TRADE#NC`]: [LayoutDesktop.C_CS_TRADE_NC],
    [`C#CS#TRADE#TPRL_NM`]: [LayoutDesktop.C_CS_TRADE_TPRL_NM],
    [`C#CS#TRADE#TPRL_OR`]: [LayoutDesktop.C_CS_TRADE_TPRL_OR],
    [`C#CS#TRADE#TPRL_RP`]: [LayoutDesktop.C_CS_TRADE_TPRL_RP],
    [`C#CS#MARKET_WATCH`]: [LayoutDesktop.C_CS_MARKET_WATCH],
    [`C#CS#AI_TRADING`]: [LayoutDesktop.C_CS_AI_TRADING],
    // MG
    [`B#CS#PRICEBOARD#CB`]: [LayoutDesktop.B_CS_PRICEBOARD_CB],
    [`B#CS#TRADE#CB`]: [LayoutDesktop.B_CS_TRADE_CB],
    [`B#CS#TRADE#NC`]: [LayoutDesktop.B_CS_TRADE_NC],
    [`B#CS#TRADE#TPRL_NM`]: [LayoutDesktop.B_CS_TRADE_TPRL_NM],
    [`B#CS#TRADE#TPRL_OR`]: [LayoutDesktop.B_CS_TRADE_TPRL_OR],
    [`B#CS#TRADE#TPRL_RP`]: [LayoutDesktop.B_CS_TRADE_TPRL_RP],
    [`B#CS#TRADE#TT`]: [LayoutDesktop.B_CS_TRADE_TT], // Thỏa thuận
    [`B#CS#MARKET_WATCH`]: [LayoutDesktop.B_CS_MARKET_WATCH],
    [`B#CS#AI_TRADING`]: [LayoutDesktop.B_CS_AI_TRADING],
};

const layoutMobileRegistry = {
    // KH
    [`C#CS#PRICEBOARD#CB`]: [LayoutMobile.C_CS_PRICEBOARD_CB],
    [`C#CS#TRADE#CB`]: [LayoutMobile.C_CS_TRADE_CB],
    [`C#CS#TRADE#NC`]: [LayoutMobile.C_CS_TRADE_NC],
    [`C#CS#TRADE#TPRL_NM`]: [LayoutDesktop.C_CS_TRADE_TPRL_NM],
    [`C#CS#TRADE#TPRL_OR`]: [LayoutDesktop.C_CS_TRADE_TPRL_OR],
    [`C#CS#TRADE#TPRL_RP`]: [LayoutDesktop.C_CS_TRADE_TPRL_RP],
    [`C#CS#MARKET_WATCH`]: [LayoutMobile.C_CS_MARKET_WATCH],
    [`C#CS#AI_TRADING`]: [LayoutMobile.C_CS_AI_TRADING],
    // MG
    [`B#CS#PRICEBOARD#CB`]: [LayoutMobile.B_CS_PRICEBOARD_CB],
    [`B#CS#TRADE#CB`]: [LayoutMobile.B_CS_TRADE_CB],
    [`B#CS#TRADE#NC`]: [LayoutMobile.B_CS_TRADE_NC],
    [`B#CS#TRADE#TPRL_NM`]: [LayoutDesktop.B_CS_TRADE_TPRL_NM],
    [`B#CS#TRADE#TPRL_OR`]: [LayoutDesktop.B_CS_TRADE_TPRL_OR],
    [`B#CS#TRADE#TPRL_RP`]: [LayoutDesktop.B_CS_TRADE_TPRL_RP],
    [`B#CS#TRADE#TT`]: [LayoutMobile.B_CS_TRADE_TT], // Thỏa thuận
    [`B#CS#MARKET_WATCH`]: [LayoutMobile.B_CS_MARKET_WATCH],
    [`B#CS#AI_TRADING`]: [LayoutMobile.B_CS_AI_TRADING],
};

export const getFunctionMenu = [
    {
        vi: "Bảng giá",
        en: "Price list",
        value: "menu-sidebar.title-1",
        type: "newtab",
        keyName: "CS#PRICEBOARD#CB",
        activeURL: "/priceboard"
    },
    {
        vi: "Đặt lệnh thông thường",
        value: "menu-sidebar.title-2.1",
        type: "newtab",
        keyName: "CS#TRADE#CB",
        en: "Regular order placement",
        activeURL: "/webtrade"
    },
    // {
    //     vi: "Đặt lệnh Pro",
    //     en: "Place Pro Order",
    //     value: "menu-sidebar.title-2.2",
    //     type: "newtab",
    //     keyName: "CS#TRADE#NC",
    //     activeURL: "/protrade"
    // },
    // {
    //     vi: "Đặt lệnh thỏa thuận",
    //     en: "Place a deal order",
    //     value: "menu-sidebar.title-2.3",
    //     type: "newtab",
    //     keyName: "CS#TRADE#TT",
    //     activeURL: "/putthroughtrade"
    // },
    // {
    //     vi: "Đặt lệnh cho KHTC",
    //     en: "Place orders for KHTC",
    //     value: "menu-sidebar.title-2.4",
    //     type: "newtab",
    //     activeURL: "/customer-organization"
    // },
    {
        vi: "Chuyển tiền",
        en: "Cash transfer",
        value: "menu-sidebar.title-3.1",
        type: "screenmodal"
    },
    // {
    //     vi: "Nộp/Rút tiền tài khoản phái sinh",
    //     en: "Cash transfer to/from Derivative account",
    //     value: "menu-sidebar.title-3.2",
    //     type: "screenmodal"
    // },
    // {
    //     vi: "Nộp/Rút tiền ký quỹ CCP",
    //     en: "Cash transfer to/from VSD",
    //     value: "menu-sidebar.title-3.3",
    //     type: "screenmodal"
    // },
    // {
    //     vi: "Phong tỏa/Giải tỏa tiền @BIDV",
    //     en: "Hold/Unhold connect bank account",
    //     value: "menu-sidebar.title-3.4",
    //     type: "modal"
    // },
    {
        vi: "Ứng trước tiền bán",
        en: "Cash advance",
        value: "menu-sidebar.title-3.5",
        type: "screenmodal"
    },
    // {
    //     vi: "iFlex",
    //     en: "iFlex",
    //     value: "menu-sidebar.title-3.6",
    //     type: "screenmodal"
    // },
    {
        vi: "Chuyển chứng khoán",
        en: "Stock transfer",
        value: "menu-sidebar.title-4.1",
        type: "screenmodal"
    },
    {
        vi: "Đăng ký quyền mua",
        en: "Stock purchase right subscription",
        value: "menu-sidebar.title-4.2",
        type: "screenmodal"
    },
    // {
    //     vi: "Đăng ký mua chứng quyền IPO",
    //     en: "Register to buy IPO warrants",
    //     value: "menu-sidebar.title-4.3",
    //     type: "screenmodal"
    // },
    // {
    //     vi: "Đăng ký gói Voucher",
    //     en: "Voucher registration",
    //     value: "menu-sidebar.title-5.1",
    //     type: "screenmodal"
    // },
    {
        vi: "Xác nhận lệnh Online",
        en: "Order confirmation",
        value: "menu-sidebar.title-5.2",
        type: "screenmodal"
    },
    {
        vi: "Tra cứu danh mục Margin",
        en: "Marginable stock list",
        value: "menu-sidebar.title-5.3",
        type: "screenmodal"
    },
    {
        vi: "Cảnh báo giá",
        en: "Price alert",
        value: "menu-sidebar.title-5.4",
        type: "screenmodal"
    },
    {
        vi: "Tài sản",
        en: "Property",
        value: "menu-sidebar.title-6.1",
        type: "screenmodal"
    },
    {
        vi: "Dư nợ ký quỹ",
        en: "Margin loans",
        value: "menu-sidebar.title-6.2",
        type: "screenmodal"
    },
    {
        vi: "Sao kê tiền",
        en: "Cash transaction statement",
        value: "menu-sidebar.title-6.3",
        type: "screenmodal"
    },
    {
        vi: "Sao kê chứng khoán",
        en: "Stock transaction statement",
        value: "menu-sidebar.title-6.4",
        type: "screenmodal"
    },
    {
        vi: "Lịch sử lệnh",
        en: "Placed order history",
        value: "menu-sidebar.title-6.5",
        type: "screenmodal"
    },
    // {
    //     vi: "Lịch sử lệnh điều kiện",
    //     en: "Condition order history",
    //     value: "menu-sidebar.title-6.6",
    //     type: "screenmodal"
    // },
    {
        vi: "Tổng hợp lệnh khớp",
        en: "Summary of order matching",
        value: "menu-sidebar.title-6.10",
        type: "screenmodal"
    },
    // {
    //     vi: "Lịch sử khớp lệnh",
    //     en: "Matched order history",
    //     value: "menu-sidebar.title-6.7",
    //     type: "screenmodal"
    // },
    {
        vi: "Lãi/Lỗ đã thực hiện",
        en: "Realized Profit/Loss",
        value: "menu-sidebar.title-6.8",
        type: "screenmodal"
    },
    {
        vi: "Báo cáo tổng hợp",
        en: "Profit and loss summary report",
        value: "menu-sidebar.title-6.9",
        type: "screenmodal"
    },
    // {
    //     vi: "Tra cứu tài khoản khách hàng",
    //     value: "menu-sidebar.title-7.1",
    //     type: "screenmodal",
    //     en: "Lookup customer account",
    // },
    // {
    //     vi: "Tra cứu tài sản khách hàng",
    //     value: "menu-sidebar.title-7.2",
    //     type: "screenmodal",
    //     en: "Lookup customer property",
    // },
    // {
    //     vi: "Quản trị khách hàng",
    //     value: "menu-sidebar.title-7.3",
    //     type: "screenmodal",
    //     en: "Customer Administration",
    // },
    // {
    //     vi: "Tra cứu doanh số",
    //     value: "menu-sidebar.title-7.4",
    //     type: "screenmodal",
    //     en: "Lookup Sales",
    // },
    // {
    //     vi: "Tra cứu trạng thái xác nhận lệnh",
    //     value: "menu-sidebar.title-7.5",
    //     type: "screenmodal",
    //     en: "Look up order confirmation status",
    // },
    // {
    //     vi: "Báo cáo tra cứu lệnh",
    //     value: "menu-sidebar.title-7.6",
    //     type: "screenmodal",
    //     en: "Command lookup report",
    // },
    {
        vi: "DSKH cần bổ sung ký quỹ",
        value: "menu-sidebar.title-7.7",
        type: "screenmodal",
        en: "List of customers needing additional margin",
    },
    {
        vi: "DSKH còn dư nợ ký quỹ",
        value: "menu-sidebar.title-7.8",
        type: "screenmodal",
        en: "List of customers with outstanding margin debt",
    },
    {
        vi: "DSKH bị xử lý bán",
        value: "menu-sidebar.title-7.9",
        type: "screenmodal",
        en: "List of customers with being dealt sell",
    },
    {
        vi: "Tra cứu TK theo mã CK",
        value: "menu-sidebar.title-7.11",
        type: "screenmodal",
        en: "Account infor by stock",
    },
    {
        vi: "Báo cáo số liệu giao dịch",
        value: "menu-sidebar.title-7.10",
        type: "screenmodal",
        en: "Report transaction data",
    },
    {
        vi: "Liên hệ", // chưa được
        en: "VIX call center",
        value: "menu-sidebar.title-8.1",
        type: "modal"
    },
    // {
    //     vi: "Chat với tư vấn viên",
    //     en: "Chat with a broker",
    //     value: "menu-sidebar.title-8.2",
    //     type: ""
    // },
    {
        vi: "Sổ tay giao dịch", // chưa được
        en: "Transaction manual",
        value: "menu-sidebar.title-8.3",
        type: "link"
    },
    {
        vi: "Địa chỉ",
        en: "Network",
        value: "menu-sidebar.title-8.4",
        type: "modal"
    },
    {
        vi: "Thông tin cá nhân",
        en: "Personal Information",
        value: "menu-sidebar.title-9.1",
        type: "screenmodal"
    },
    {
        vi: "Đăng ký dịch vụ",
        en: "Register service",
        value: "menu-sidebar.title-9.2",
        type: "screenmodal"
    },
    {
        vi: "Bảo mật",
        en: "Security",
        value: "menu-sidebar.title-10",
        type: "screenmodal"
    },
]

const _filterSections = (OBJLAYOUT) => {
    let sections = allSections[OBJLAYOUT]
    // //console.log('Haki_lt.:_filterSections().:sections=', OBJLAYOUT, sections)
    if (sections && sections.length > 0) {
        return sections.filter((section) => {
            return section[OBJLAYOUT];
        });
    }
    return null
};
///////////////////
const throwLayoutConfigError = (key, sectionId) => {
    throw new Error(`Layout config for ${key} is invalid. Section ${sectionId} is not valid.`);
};

const checkVisibleSectionIdsConfig = (key, sectionsMap, visibleSectionIds) => {
    _.forEach(visibleSectionIds, (sectionId) => {
        if (sectionsMap[sectionId] == null) {
            throwLayoutConfigError(key, sectionId);
        }
    });
};

const checkMergeSectionConfig = (key, sectionsMap, mergeSections) => {
    _.forEach(mergeSections, (subTabs, mainSectionId) => {
        if (sectionsMap[mainSectionId] == null) {
            throwLayoutConfigError(key, mainSectionId);
        }

        _.forEach(subTabs, (subTabId) => {
            if (sectionsMap[subTabId] == null) {
                throwLayoutConfigError(key, subTabId);
            }
        });
    });
};
// Haki_lt: Danh sách các layout đã đăng ký theo danh sách của FS2PRO
// Đồng bộ với allSections
const listLayoutDefault = [
    { OBJLAYOUT: "C#CS#PRICEBOARD#CB" },
    { OBJLAYOUT: "C#CS#TRADE#CB" },
    { OBJLAYOUT: "C#CS#TRADE#NC" },
    { OBJLAYOUT: "B#CS#TRADE#TT" },
    { OBJLAYOUT: "B#CS#PRICEBOARD#CB" },
    { OBJLAYOUT: "B#CS#TRADE#CB" },
    { OBJLAYOUT: "B#CS#TRADE#NC" },

    { OBJLAYOUT: "C#CS#TRADE#TPRL_NM" },
    { OBJLAYOUT: "B#CS#TRADE#TPRL_NM" },

    { OBJLAYOUT: "C#CS#MARKET_WATCH" },
    { OBJLAYOUT: "B#CS#MARKET_WATCH" },
];
const checkLayoutRegistry = (isDeskTop) => {
    // Haki_lt: kiểm tra các layout đã đăng ký theo danh sách của FS2PRO
    // Đồng bộ với allSections
    _.forEach(listLayoutDefault, (listLayout) => {
        const { OBJLAYOUT } = listLayout;
        // //console.log("Haki_lt.:checkLayoutRegistry().:OBJLAYOUT=", OBJLAYOUT, listLayoutDefault)
        const sections = _filterSections(OBJLAYOUT);
        const sectionsMap = _.mapKeys(sections, (section) => (section.id));
        if (isDeskTop) {
            // Desktop
            let layout = layoutDesktopRegistry[OBJLAYOUT];
            // //console.log("Haki_lt.:checkLayoutRegistry().:layout=", layout)
            if (layout) {
                checkVisibleSectionIdsConfig(OBJLAYOUT, sectionsMap, layout.visibleSectionIds);
                checkMergeSectionConfig(OBJLAYOUT, sectionsMap, layout.mergeSections);
            }
        } else {
            // Mobile
            const mobileLayout = layoutMobileRegistry[OBJLAYOUT];
            checkVisibleSectionIdsConfig(OBJLAYOUT, sectionsMap, mobileLayout.visibleSectionIds);
            checkMergeSectionConfig(OBJLAYOUT, sectionsMap, mobileLayout.mergeSections);
        }
    });
};

// Haki_lt: kiểm tra các layout Desktop đã đăng ký theo danh sách của FS2PRO
checkLayoutRegistry(true);
// Haki_lt: kiểm tra các layout Mobile đã đăng ký theo danh sách của FS2PRO
// checkLayoutRegistry(false);
///////////////////

// export const getLastLayoutsCustom = (OBJLAYOUT) => {
//     let key = getKeyLayoutsCustomSave(OBJLAYOUT) // keyLayoutsCustomSave
//     // //console.log('Haki_lt_local.:getConfigFromLocalStorage().:OBJLAYOUT.:key=', OBJLAYOUT, key)
//     if (localStorage) {
//         try {
//             let version = localStorage.getItem(layoutConfigKey[key].layoutVersion);
//             // //console.log('Haki_lt_local.:getConfigFromLocalStorage().:version=', version)

//             if (!version || version !== layoutConfigKey[key].newVersion) {
//                 return undefined;
//             }
//             // //console.log('Haki_lt_local.:getConfigFromLocalStorage()', JSON.parse(localStorage.getItem(layoutConfigKey[key].configKey)))
//             return JSON.parse(localStorage.getItem(layoutConfigKey[key].configKey)) || undefined;
//         } catch (e) {
//             /*Ignore*/
//             // //console.log('Haki_lt_local.:getConfigFromLocalStorage().:Error=', e)
//         }
//     }
//     return []
// }

// Haki_lt.:
export const getLayoutCustomCurrent = (OBJLAYOUT, layoutsMerge) => {
    // //console.log('Haki_lt_local.:getLayoutCustomCurrent().:', OBJLAYOUT, layoutsMerge)
    try {
        let layoutCurrent = layoutsMerge.filter(e => e.id === OBJLAYOUT)
        if (layoutCurrent && layoutCurrent.length > 0) {
            return layoutCurrent[0]
        }
        return undefined
    }
    catch (e) {
        /*Ignore*/
        // //console.log('Haki_lt_local.:saveCustomLayoutToLocalStorage().:Error=', e)
        return undefined
    }
};

// // Haki_lt.:
// export const saveCustomLayoutToLocalStorage = (OBJLAYOUT, newLayoutsCustom) => {
//     try {
//         let key = getKeyLayoutsCustomSave(OBJLAYOUT) // keyLayoutsCustomSave
//         if (localStorage) {
//             // //console.log('Haki_lt.:saveConfigToLocalStorage().:OBJLAYOUT=', OBJLAYOUT, layoutConfigKey[key])
//             localStorage.setItem(layoutConfigKey[key].layoutVersion, layoutConfigKey[key].newVersion);
//             localStorage.setItem(layoutConfigKey[key].configKey, JSON.stringify(newLayoutsCustom));
//         }
//     }
//     catch (e) {
//         /*Ignore*/
//         // //console.log('Haki_lt_local.:saveCustomLayoutToLocalStorage().:Error=', e)
//     }
// };









export const getConfigFromLocalStorage = (OBJLAYOUT) => {
    //Haki_lt.: load config layout from LocalStorage
    // //console.log('Haki_lt_local.:getConfigFromLocalStorage()OBJLAYOUT=', OBJLAYOUT)
    if (localStorage) {
        try {
            let version = localStorage.getItem(layoutConfigKey[OBJLAYOUT].layoutVersion);
            // //console.log('Haki_lt_local.:getConfigFromLocalStorage().:version=', version)

            if (!version || version !== layoutConfigKey[OBJLAYOUT].newVersion) {
                return null;
            }
            // //console.log('Haki_lt_local.:getConfigFromLocalStorage()', JSON.parse(localStorage.getItem(layoutConfigKey[OBJLAYOUT].configKey)))
            return JSON.parse(localStorage.getItem(layoutConfigKey[OBJLAYOUT].configKey)) || null;
        } catch (e) {
            /*Ignore*/
            // //console.log('Haki_lt_local.:getConfigFromLocalStorage().:Error=', e)
        }
    }
    return null;
};


export const isLayoutDefautl = (OBJLAYOUT) => {
    // Haki_lt: Kiểm tra OBJLAYOUT thuộc list Mặc định hay list tùy chỉnh
    // if ((OBJLAYOUT in allSections)) {
    //     alert(123)
    // }
    // alert(OBJLAYOUT)

    return allSections[OBJLAYOUT] ? true : false
};
// const allSectionsCustom = { 'C#CS#TRADE#CB$TC001': 'OK' } // hardcode. Cần lấy từ dữ liệu được lưu ở db postgres/localtorage
export const isLayoutCustom = (OBJLAYOUT, allSectionsCustom) => {
    // Haki_lt: Kiểm tra OBJLAYOUT thuộc list Mặc định hay list tùy chỉnh
    // if ((OBJLAYOUT in allSections)) {
    //     alert(123)
    // }
    // alert(OBJLAYOUT)
    // //console.log('Haki_lt.:isLayoutCustom.:OBJLAYOUT=', OBJLAYOUT, allSectionsCustom)

    if (!allSectionsCustom || (allSectionsCustom && allSectionsCustom.length == 0)) {
        return false;
    }
    let isLayoutCustom = allSectionsCustom.filter((e) => e.id === OBJLAYOUT)
    return ((isLayoutCustom && isLayoutCustom.length > 0) ? true : false);
};


export const saveConfigToLocalStorage = (OBJLAYOUT, config) => {
    if (localStorage) {
        let key = getKeyLayoutsCustomSave(OBJLAYOUT)
        // update newVersion 
        // //console.log('Haki_lt.:saveConfigToLocalStorage().:OBJLAYOUT=', OBJLAYOUT, layoutConfigKey[key])
        localStorage.setItem(layoutConfigKey[key].layoutVersion, layoutConfigKey[key].newVersion);
        localStorage.setItem(layoutConfigKey[key].configKey, JSON.stringify(config));
    }
};

export const getDesktopLayouts = (OBJLAYOUT) => {
    let layout = _.cloneDeep(layoutDesktopRegistry[OBJLAYOUT]);
    return layout;
};

export const getMobileLayouts = (OBJLAYOUT) => {
    let layout = _.cloneDeep(layoutMobileRegistry[OBJLAYOUT]);
    return layout
};

export const getDesktopLayoutsByRole = (OBJLAYOUT) => {
    let role = CommonUtils.getRole();
    OBJLAYOUT = OBJLAYOUT.replace('ROLE', role)
    return OBJLAYOUT;
};


export const filterSections = memoizeOne(_filterSections);

export const filterSectionIds = (OBJLAYOUT, role) => {
    return filterSections(OBJLAYOUT, role).map((section) => {
        return section.id;
    });
};

export const updateLayoutConfigFromRemote = (OBJLAYOUT, remoteConfigData) => {
    const isValid = remoteConfigData != null && remoteConfigData['version'] === layoutConfigKey[OBJLAYOUT].layoutVersion && remoteConfigData['config'] != null;
    if (!isValid) {
        console.error('Remote layout config is not valid.');
        return;
    }
    const remoteConfig = remoteConfigData['config'];
    const configKeys = _.keys(remoteConfig);
    const localConfig = updateLayoutConfigFromRemote(OBJLAYOUT) || {};
    // //console.log('Haki_lt.:getLayoutConfigToRemoteSave.:', localConfig)
    _.forEach(configKeys, (configKey) => {
        localConfig[configKey] = remoteConfig[configKey];
    });
    saveConfigToLocalStorage(OBJLAYOUT, localConfig);
};

export const getLayoutConfigToRemoteSave = (OBJLAYOUT) => {
    const localConfig = getConfigFromLocalStorage(OBJLAYOUT) || {};
    // //console.log('Haki_lt.:getLayoutConfigToRemoteSave.:', localConfig)
    return {
        config: {
            version: layoutConfigKey[OBJLAYOUT].layoutVersion,
            config: localConfig,
        },
        typeConfig: TYPE_CONFIG.PRICEBOARD
    };
};



// export const saveLastLayoutIdToLocalStorage = (OBJLAYOUT, layoutId) => {
//     const savedConfig = getConfigFromLocalStorage(OBJLAYOUT) || {};
//     // const key = getKeyLayout(role);
//     // if (savedConfig[key] == null) {
//     //     savedConfig[key] = {};
//     // }
//     // savedConfig[key]['lastLayoutId'] = layoutId;
//     // //console.log('Haki_lt.:saveLastLayoutIdToLocalStorage.:', savedConfig)
//     if (savedConfig[OBJLAYOUT] == null) {
//         savedConfig[OBJLAYOUT] = {};
//     }
//     savedConfig[OBJLAYOUT]['lastLayoutId'] = layoutId;
//     saveConfigToLocalStorage(OBJLAYOUT, savedConfig);
// };

// export const saveWidgetSidebarLayoutToLocalStorage = (OBJLAYOUT, role, layout) => {
//     const savedConfig = getConfigFromLocalStorage(OBJLAYOUT) || {};
//     // const key = getKeyLayout(role);
//     // if (savedConfig[key] == null) {
//     //     savedConfig[key] = {};
//     // }
//     // savedConfig[key]['widgetSidebarLayout'] = layout;
//     if (savedConfig[OBJLAYOUT] == null) {
//         savedConfig[OBJLAYOUT] = {};
//     }
//     savedConfig[OBJLAYOUT]['widgetSidebarLayout'] = layout;
//     saveConfigToLocalStorage(OBJLAYOUT, savedConfig);
// };

export const getKeyLayoutsCustomSave = ((OBJLAYOUT) => {
    let key = OBJLAYOUT && OBJLAYOUT.split('$')
    key = (key && key.length > 0) && key[0]
    if (key) {
        return key
    }
    // alert('getKeyLayoutsCustomSave.:Lỗi data OBJLAYOUT')
    return undefined
})

// tuan.pham: bỏ memoizeOne vì trên server chạy ra kết quả khác vs local -> return grid: null,
export const getLayoutsCustom = memoizeOne((isDeskTop) => {
    // alert('getLayoutsCustom')
    let getLayoutPageCurrent = CommonWidgetUtils.getLayoutsCustomCurrent();
    let layoutCurrent = getLayoutPageCurrent.layoutCurrent;
    return layoutCurrent || undefined
    // let key = getKeyLayoutsCustomSave(OBJLAYOUT)
    // let layoutsCustom = localStorage.getItem(layoutConfigKey[key].configKey)
    // //console.log('Haki_lt.:getLayoutsCustom.:savedConfig 1=', key, layoutConfigKey[key].configKey, layoutsCustom)

    // layoutsCustom = JSON.parse(layoutsCustom)
    // //console.log('Haki_lt.:getLayoutsCustom.:savedConfig 2=', key, JSON.stringify(layoutsCustom))
    // return layoutsCustom || []
});

// export const getLastDesktopLayoutId = (OBJLAYOUT) => {
//     // alert('getLastDesktopLayoutId.:' + OBJLAYOUT)
//     // const key = getKeyLayout(role);
//     const savedConfig = getConfigFromLocalStorage(OBJLAYOUT) || {};
//     if (savedConfig[OBJLAYOUT] && savedConfig[OBJLAYOUT]['lastLayoutId']) {
//         return savedConfig[OBJLAYOUT]['lastLayoutId'];
//     }
//     const layouts = getDesktopLayouts(OBJLAYOUT);
//     // //console.log('Haki_lt.:getLastDesktopLayoutId().:layouts', layouts, OBJLAYOUT)
//     if (layouts && layouts.length > 0) {
//         return layouts[0]['id'];
//     }
//     throw new Error(`No layout config for key: ${OBJLAYOUT}`);
// };

export const getMobileLayoutId = (OBJLAYOUT) => {
    const layouts = getMobileLayouts(OBJLAYOUT);
    const layoutFound = layouts.find(item => item.id === OBJLAYOUT);
    if (OBJLAYOUT && layoutFound) {
        return OBJLAYOUT;
    }

    if (layouts && layouts.length > 0) {
        return layouts[0]['id'];
    }
    throw new Error(`No layout config for key: ${OBJLAYOUT}`);
};

export const getMobileLayout = (OBJLAYOUT) => {
    // const savedConfig = getConfigFromLocalStorage(OBJLAYOUT) || {};
    const layouts = getMobileLayouts(OBJLAYOUT);
    if (OBJLAYOUT) {
        const layout = layouts.find(item => item.id === OBJLAYOUT)
        if (layout) {
            return layout;
        }
    }

    if (layouts && layouts.length > 0) {
        return layouts[0];
    }
    throw new Error(`No layout config for key: ${OBJLAYOUT}`);
};



export const getNotMergedSectionIds = (mergeSections, floatLayout, visibleSectionIds) => {
    // //console.log('Haki_lt.:getNotMergedSectionIds().:', mergeSections, floatLayout, visibleSectionIds)
    let mergedSectionsId = [];

    _.forEach(mergeSections, (value) => {
        mergedSectionsId = mergedSectionsId.concat(value);
    });

    const floatSectionIds = _.map(floatLayout, (layoutDef) => {
        return layoutDef['i'];
    });

    return visibleSectionIds && visibleSectionIds.filter((sectionId) => {
        return !_.includes(mergedSectionsId, sectionId) && !_.includes(floatSectionIds, sectionId);
    });
};

/**
 * Return only grid item which visible to user
 */
export const getSectionsGrid = (sections, sourceGrid, _breakpoint) => {
    const grid = {};
    _.forIn(sourceGrid, (defs, breakpoint) => {
        if (breakpoint == _breakpoint) {
            // //console.log('getSectionsGrid.:defs=', defs)
            const newDefs = [];
            _.forEach(sections, (section) => {
                const def = _.find(defs, (def) => (def['i'] === section));
                // //console.log('getSectionsGrid.:def=', def)
                // //console.log('getSectionsGrid.:def=', def)
                if (def) {
                    newDefs.push(_.cloneDeep(def));
                }
            });
            // //console.log('getSectionsGrid.:newDefs=', newDefs)
            // //console.log('getSectionsGrid.:newDefs=', newDefs)
            grid[breakpoint] = newDefs;
        }
    });
    return grid;
};

// Size default cho widged không có định nghĩa size trước
const defaultDefs = {
    advanceConditionOrder: {  // sectionId
        lg: { x: 0, y: 0, w: 7, h: 17, minW: 7, minH: 17, },
        md: { x: 0, y: 0, w: 6, h: 17, minW: 6, minH: 17, },
        sm: { x: 0, y: 0, w: 8, h: 12, minW: 8, minH: 10, }
    }
};

export const getDefautPriceBoardLayout = (cmdmenu, OBJLAYOUT) => {
    let AccountType = CommonWidgetUtils.getAccountType()
    let width = window.innerWidth

    let layouts = width <= WIDGET_WIDTH.MOBILE.max && width !== 0 ? getMobileLayouts(OBJLAYOUT) : getDesktopLayouts(OBJLAYOUT);
    layouts = getDesktopLayouts(OBJLAYOUT);
    let res;
    // cmdmenu.PAGEHEADLIST.map(function (event) {
    layouts.map(function (item) {
        // if (!res && item && event && event.CMDALLOW == 'Y' && event.ACCTYPE == AccountType && item.id === event.STRPROP) {
        res = item.id
        // }
    })
    // })
    if (!res) {
        cmdmenu.PAGEHEADLIST.map(function (event) {
            if (!res && event && event.CMDALLOW == 'Y' && event.ACCTYPE == AccountType && event.STRPROP.indexOf('#TC') > -1) {
                res = event.STRPROP
            }
        })
    }
    return res;
}

export const createLayoutState = (from, _breakpoint, OBJLAYOUT, layoutCurrent, popoutSectionId, addSectionId) => {
    // alert('createLayoutState' + OBJLAYOUT)
    // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT= 1', OBJLAYOUT, layoutsDefault, layoutsDefault && layoutsDefault.length, _isLayoutDefautl)
    // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT= 1', from, OBJLAYOUT, JSON.stringify(layoutCurrent))
    // Haki_lt.:Tạo layout theo các layout tùy chỉnh
    // //console.log('createLayoutState=customLayout=4', layoutCustom)
    let layout = _.cloneDeep(layoutCurrent)
    if (!layout || !layout.grid) {
        // alert('Lỗi không tìm thấy layout Tùy chỉnh!' + (layoutCurrent && layoutCurrent.length)  + '_' + OBJLAYOUT)
        // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT=2', OBJLAYOUT, layout)
        return null
    }
    // const layout = layoutsCustom[0];
    // let isGridActive = false
    // let gridActive = [_breakpoint]
    // if (layout.gridActive && layout.gridActive.length > 0) {
    //     isGridActive = layout.gridActive.findIndex((e) => e == _breakpoint) > -1
    //     if (!isGridActive) {
    //         layout.gridActive.push(gridActive)
    //         gridActive = layout.gridActive
    //     }
    // }

    let layoutState = _.cloneDeep({
        id: layout.id,
        sourceLayoutId: layout.sourceLayoutId,
        // currentLayoutId: OBJLAYOUT,
        visibleSectionIds: layout.visibleSectionIds || [],
        mergeSections: layout.mergeSections || {},
        floatLayout: layout.floatLayout || [],
        breakpoint: _breakpoint,
        // isCustomLayout: true // Haki_lt.: Show setting widget
    });
    if (addSectionId) {
        layoutState.id = layout.id;
        layoutState.sourceLayoutId = layout.sourceLayoutId;
    }

    // const sourceLayout = _.find(layouts, (el) => (el.id === OBJLAYOUT));

    // Add grid def for visible sections
    // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT=3', layoutState.mergeSections, layoutState.floatLayout, layoutState.visibleSectionIds)
    const visibleWidgets = getNotMergedSectionIds(layoutState.mergeSections, layoutState.floatLayout, layoutState.visibleSectionIds);
    // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT=3.1', visibleWidgets, layoutState.mergeSections, layoutState.floatLayout, layoutState.visibleSectionIds)
    layoutState.grid = getSectionsGrid(visibleWidgets, layout.grid, _breakpoint);
    // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT=4', visibleWidgets, layout.grid, _.cloneDeep(layout.grid), layoutState)
    // return layoutState;
    //Trong trường hợp click custom layout ngay từ đầu, chưa lưu local storage thì sourceLayout = null
    // if (!layout) {
    //     return getDefaultLayoutCustom(OBJLAYOUT);
    // }
    // Calculate new position for missing layout
    let size = { x: 0, y: 0, w: 10, h: 6, minW: 2, minH: 2 };
    _.forIn(layoutState.grid, (defs, breakpoint) => {
        if (breakpoint !== _breakpoint) {
            // Bỏ qua việc khởi tạo dữ liệu khác kích cỡ _breakpoint
            defs = []
        } else {
            let index = visibleWidgets && _.findIndex(visibleWidgets, (section) => (section == popoutSectionId || section == addSectionId))
            if (index > -1) {
                let section = visibleWidgets[index];
                let _DefaultSession = LayoutDesktop.DefaultSession[breakpoint].find((e) => e.i === section)
                if (_DefaultSession) {
                    size.w = _DefaultSession['w'] || size.w;
                    size.h = _DefaultSession['h'] || size.h;
                    size.minW = _DefaultSession['minW'] || size.minW;
                    size.minH = _DefaultSession['minH'] || size.minH;
                }
                // Tính toán để widget xuất hiện giữa màn hình
                size.x = Math.ceil((COLS_WIDGET - size.w) / 2)
                size.y = 3;

                const newDef = { i: section, x: size.x, y: size.y, w: size.w, h: size.h, minW: size.minW, minH: size.minH };
                const existingDefIndex = _.findIndex(defs, (def) => (def['i'] === section));
                if (existingDefIndex < 0) {
                    defs.push(newDef);
                } else {
                    defs[existingDefIndex] = newDef;
                }
            }
        }
    });
    // //console.log('Haki_lt.:createLayoutState().:OBJLAYOUT=1000=', layoutState)
    return layoutState;
};

export const isFloatingSection = (customLayout, sectionId) => {
    return _.findIndex(customLayout.floatLayout, (layoutDef) => {
        return layoutDef['i'] === sectionId;
    }) >= 0;
};

export const findContainingSectionId = (customLayout, sectionId) => {
    let containingSectionId = null;
    _.forEach(customLayout.mergeSections, (subTabs, mainSectionId) => {
        if (mainSectionId === sectionId || _.includes(subTabs, sectionId)) {
            containingSectionId = mainSectionId;
            return false;
        }
    });
    return containingSectionId;
};

export const removeSectionLayout = (customLayout, sectionId) => {
    _.forEach(customLayout.grid, (defs) => {
        const idx = _.findIndex(defs, (def) => (def['i'] === sectionId));
        if (idx >= 0) {
            defs.splice(idx, 1);
        }
    });
};

export const removeFloatingSectionLayout = (customLayout, sectionId) => {
    const idx = _.findIndex(customLayout.floatLayout, (def) => (def['i'] === sectionId));
    if (idx >= 0) {
        customLayout.floatLayout.splice(idx, 1);
    }
};

export const removeAllTab = (customLayout, sectionId) => {
    const containingSectionId = findContainingSectionId(customLayout, sectionId);
    if (containingSectionId === null) {
        return;
    }

    const sectionsToRemove = [containingSectionId, ...customLayout.mergeSections[containingSectionId]];

    // Remove from visible sectionIds
    customLayout.visibleSectionIds = customLayout.visibleSectionIds.filter((element) => {
        return !_.includes(sectionsToRemove, element);
    });

    // Remove all layout
    _.forEach(sectionsToRemove, (sectionToRemove) => {
        removeSectionLayout(customLayout, sectionToRemove);
    });

    // Remove merge section
    delete customLayout.mergeSections[containingSectionId];

    // Remove floating section
    removeFloatingSectionLayout(containingSectionId);
};

export const removeMainTab = (customLayout, sectionId) => {
    if (!customLayout.mergeSections[sectionId] || customLayout.mergeSections[sectionId].length === 0) {
        return;
    }
    const subTab = customLayout.mergeSections[sectionId];
    const nextTabId = subTab[0];

    // Ensure no un-used layout def in grid
    removeSectionLayout(customLayout, nextTabId);
    removeFloatingSectionLayout(customLayout, nextTabId);

    const isSectionFloating = isFloatingSection(customLayout, sectionId);
    if (isSectionFloating) {
        // Replace layoutId in def
        const def = _.find(customLayout.floatLayout, (def) => (def['i'] === sectionId));
        if (def) {
            def['i'] = nextTabId;
        }
    } else {
        // Replace layoutId in def
        _.forEach(customLayout.grid, (defs) => {
            const def = _.find(defs, (def) => (def['i'] === sectionId));
            if (def) {
                def['i'] = nextTabId;
            }
        });
    }

    // Alter mergeSections config
    const newSubTab = [...subTab];
    newSubTab.splice(0, 1);

    delete customLayout.mergeSections[sectionId];
    if (newSubTab.length > 0) {
        customLayout.mergeSections[nextTabId] = newSubTab;
    }
};

export const removeSubTab = (customLayout, sectionId) => {
    _.forEach(customLayout.mergeSections, (value) => {
        const idx = _.findIndex(value, (val) => { return val === sectionId });
        if (idx >= 0) {
            value.splice(idx, 1);
            return false;
        }
    });
};

export const isMergedSection = (customLayout, sectionId) => {
    const containingSectionId = findContainingSectionId(customLayout, sectionId);
    return containingSectionId != null && customLayout.mergeSections[containingSectionId].length > 0;
};

// export const getCurrentLayoutById = (OBJLAYOUT, currentLayoutId) => {
//     let layoutsByRole = getDesktopLayouts(OBJLAYOUT);
//     // check current layout is custom layout ?
//     let isRegisterLayout = layoutsByRole.some((layout) => {
//         return layout.id === currentLayoutId;
//     });

//     if (isRegisterLayout) {
//         let foundLayout = layoutsByRole.find(layout => {
//             return layout.id === currentLayoutId;
//         });
//         return foundLayout;
//     } else {
//         //get custom layout from Local Storage
//         // return getCustomLayout(role);
//         // const key = getKeyLayout(role);
//         let savedConfig = getConfigFromLocalStorage(OBJLAYOUT) || {};

//         if (savedConfig[OBJLAYOUT] && savedConfig[OBJLAYOUT]['layout']) {
//             return savedConfig[OBJLAYOUT]['layout'];
//         }
//         return {
//             id: OBJLAYOUT,
//             grid: null,
//             visibleSectionIds: [],
//             sourceLayoutId: null,
//             mergeSections: {},
//             floatLayout: []
//         };
//     }
// }

// get layout default, sử dụng trong trường hợp đã phân quyền, user chưa chọn layout nào or ko có layout thì gán mặc định
// return a grid, vd : LAYOUT_CS_TRADE_NC
// export const getDefaultLayoutCustom = (OBJLAYOUT) => {
//     let state = reduxStore.getState();
//     // let AccountType = CommonWidgetUtils.getAccountType()
//     let cmdmenu = state.user.userInfo.cmdmenu;
//     let role = state.user.userInfo.role;
//     // let layoutFoundId = getDefautPriceBoardLayout(cmdmenu, role ) // id layout mặc định lấy theo phân quyền
//     let layoutFoundId = getDefautPriceBoardLayout(cmdmenu, OBJLAYOUT) // id layout mặc định lấy theo phân quyền
//     let layout = getCurrentLayoutById(OBJLAYOUT, layoutFoundId);
//     layout.isCustomLayout = true; // set = true để hiện button ( fa-ellipsis-v và fa-times)
//     layout.currentLayoutId = layout.id; // gắn current Id để check ensureCustomLayout
//     return layout;
// }

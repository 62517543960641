import _ from 'lodash'
import { path } from 'utils';
export const menuCustomer = {
    'PRICEBOARD': {
        key: 'CS#PRICEBOARD#CB',
        path: '/priceboard',
    },
    'TRADE#01': {
        key: 'CS#TRADE#CB',
        path: '/webtrade',
    },
    'TRADE#02': {
        key: 'CS#TRADE#NC',
        path: '/protrade',
    },
    'TRADE#04': {
        key: 'CS#TRADE#TPRL_NM',
        path: path.TPRLTRADE_NM,
    },
    'CASHTRANS#01': {
        key: 'CS#CASHTRANS#01',
        path: '/cashtrans/01',
    },
    'ASSET_TOTAL': {
        key: 'CS#ASSET_TOTAL',
        path: '/asset-total',
    },
    'MARKET_WATCH': {
        key: 'CS#MARKET_WATCH',
        path: '/market-watch',
    },
    'AI_TRADING': {
        key: 'CS#AI_TRADING',
        path: '/ai-trading',
    },
    'IBROKER': {
        key: 'CS#IBROKER',
        path: '/i-broker'
    },
    'SYMBOL_DETAIL': {
        key: 'CS#SYMBOL_DETAIL',
        path: '/symbol-detail'
    }
}

export const menuBroker = {
    'PRICEBOARD': {
        key: 'CS#PRICEBOARD#CB',
        path: '/priceboard',
    },
    'TRADE#01': {
        key: 'CS#TRADE#CB',
        path: '/webtrade',
    },
    'TRADE#02': {
        key: 'CS#TRADE#NC',
        path: '/protrade',
    },
    'TRADE#03': {
        key: 'CS#TRADE#TT',
        path: '/putthroughtrade',
    },
    'TRADE#04': {
        key: 'CS#TRADE#TPRL_NM',
        path: path.TPRLTRADE_NM,
    },
    'CASHTRANS#01': {
        key: 'CS#CASHTRANS#01',
        path: '/cashtrans/01',
    },
    'ASSET_TOTAL': {
        key: 'CS#ASSET_TOTAL',
        path: '/asset-total',
    },
    'MARKET_WATCH': {
        key: 'CS#MARKET_WATCH',
        path: '/market-watch',
    },
    'AI_TRADING': {
        key: 'CS#AI_TRADING',
        path: '/ai-trading',
    },
    'CUSTOMER_ORGANIZATION': {
        key: 'CS#CUSTOMER_ORGANIZATION',
        path: '/customer-organization'
    },
    'IBROKER': {
        key: 'CS#IBROKER',
        path: '/i-broker'
    },
    'SYMBOL_DETAIL': {
        key: 'CS#SYMBOL_DETAIL',
        path: '/symbol-detail'
    }
}

export const getMenuActiveByPath = (path) => {
    path = path.trim().toLowerCase();
    let menu = _.filter(menuCustomer, e => path.indexOf(e.path) > -1);
    // //console.log('getMenuActiveByPath', path, menu)
    return menu && menu.length > 0 && menu[0]
}
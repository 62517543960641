import React, { Component } from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { DatePicker } from "../../../components/Input";
import moment from "moment/moment";
import RetryableContent from "../../../components/RetryableContent";
import { inquiryService, userService, } from "../../../services";
import * as actions from '../../../store/actions'
import { CommonUtils, execTypes, LoadStatus, reportTypes, Role, ToastUtil, PREFIX_CUSTODYCD, mDate, KeyCodeUtils } from "../../../utils";

import './TradingValueReport.scss';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import TextToolTip from "../../../components/TextToolTip";
import { ReactComponent as DownloadIconLight } from '../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../assets/icons/new/DownloadIcon_Dark.svg'
import config from '../../../combineConfig';

const totalDefault =
    [
        {
            column: "ten_kh",
            summaryType: "min",
            displayFormat: "total",
            valueFormat: ",##0.##",
        },
        {
            column: "execamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "feeacr",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "refee",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "netvalue",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "commisionExt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]

let columnsDefault =
    [
        /* STT */
        {
            dataField: "index",
            dataType: "string",
            allowReordering: true,
            minWidth: "80",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* số lưu ký */
        {
            dataField: "cust_kh",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Tiểu khoản */
        {
            dataField: "afacctno_ext",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Tên khách hàng */
        {
            dataField: "ten_kh",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* Giá trị giao dịch*/
        {
            dataField: "execamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Phí giao dịch */
        {
            dataField: "feeacr",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Ghi chú*/
        {
            dataField: "activedate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '170',
            width: '170',
            alignment: "center",
            visibleIndex: 10,
        },
    ]


// /* Phí giảm trừ*/
// {
//     dataField: "refee",
//     dataType: "string",
//     allowReordering: true,
//     minWidth: "150",
//     width: '160',
//     visible: true,
//     alignment: "center",
//     visibleIndex: 7,
// },
// /* Doanh thu ròng*/
// {
//     dataField: "netvalue",
//     dataType: "string",
//     allowReordering: true,
//     minWidth: "150",
//     width: '160',
//     visible: true,
//     alignment: "right",
//     visibleIndex: 8,
// },
// /* Hoa hồng tạm tính*/
// {
//     dataField: "commisionExt",
//     dataType: "string",
//     allowReordering: true,
//     minWidth: "150",
//     width: '160',
//     visible: true,
//     alignment: "right",
//     visibleIndex: 9,
// },
class TradingValueReport extends Component {

    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        customers: [],
        custodycd: PREFIX_CUSTODYCD,
        loading: false,
        custodycdParam: '002C',

        brokerAccounts: [],
        brokerAccountSelected: '',
        brokerRoles: [],
        brokerRoleSelected: '',
    };

    minDate = null;

    constructor(props) {
        super(props);
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    genReport = (exptype) => {
        if (!this.applyInput) {
            this.setReloadData(); // do gen báo cáo có sử dụng đầu vào nên cần gọi lại api  nếu input chưa được tìm kiếm
        }
        const { fromDate, toDate, brokerAccountSelected, brokerRoleSelected } = this.state;
        const { userInfo } = this.props;
        // let { custodycd, custodycdParam } = this.state;
        let tlid = userInfo && userInfo.custid;
        // let _custodycd = custodycdParam ? custodycdParam : custodycd
        // let fullname = "ALL"
        // let idcode = "ALL"
        // let mobile = "ALL"
        // let email = "ALL"
        if (brokerAccountSelected) {
            let rptId = '';
            let inpArr = [];

            let fDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
            let tDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);

            rptId = 'RE0088_V';
            inpArr = ['A', '0001', fDate, tDate, brokerAccountSelected, brokerRoleSelected, tlid];
            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    renderSwitch = (dataField) => {
        switch (dataField) {
            case 'index':
                return (e) => e.row.dataIndex + 1;
            case 'cust_kh':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.cust_kh);
            case 'afacctno_ext':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno_ext);
            case 'ten_kh':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.ten_kh);
            case 'execamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.execamt, "PRICE");
            case 'feeacr':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeacr, "PRICE");
            // case 'refee':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.refee, "PRICE");
            // case 'netvalue':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.netvalue, "PRICE");
            // case 'commisionExt':
            //     return (e) => e.row.data.commisionExt !== undefined ? RenderGridCell.renderGridCellPrice(e.row.data.commisionExt, "PRICE") : '';
            case 'activedate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.activedate);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'index':
                return "trading-value-report.table.no";
            case 'cust_kh':
                return "trading-value-report.table.custodycd";
            case 'afacctno_ext':
                return "trading-value-report.table.sub-account";
            case 'ten_kh':
                return "trading-value-report.table.fullname";
            case 'execamt':
                return "trading-value-report.table.trade-value";
            case 'feeacr':
                return "trading-value-report.table.fee";
            // case 'refee':
            //     return "trading-value-report.table.fee-reduction";
            // case 'netvalue':
            //     return "trading-value-report.table.net-sales";
            // case 'commisionExt':
            //     return "trading-value-report.table.commission-provisional";
            case 'activedate':
                return "trading-value-report.table.active-date";
            default:
                return null;
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadBrokerAccount();
        this.loadbrokerRole();
        this.setReloadData('NOT_CALL_API');
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;
        if (language !== prevlanguage) {
            this.setReloadData()
        }
    }
    setBrokerAccountSelected = (e) => {
        this.applyInput = false;
        this._setState({
            brokerAccountSelected: e.target.value
        })
    }
    loadBrokerAccount = () => {
        userService.loadBrokerAccountRef()
            .then((data) => {
                if (data && data.length > 0) {
                    this._setState({
                        brokerAccounts: data,
                        brokerAccountSelected: data[0].custid
                    }, () => {
                        this.setReloadData();
                    });
                }
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-broker-account-reference');
            });
    };

    loadbrokerRole = () => {
        // const { language } = this.props
        userService.getAllCode('REROLE', 'RE')
            .then((data) => {
                const objAll = {
                    cdcontent: "Tất cả",
                    en_cdcontent: "All",
                    cdval: "ALL",
                    cdname: "REROLE",
                    cdtype: "RE",
                    id: "REREROLE_ALL"
                }
                this._setState({
                    brokerRoles: [objAll].concat(data),
                    brokerRoleSelected: objAll.cdval
                });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-broker-role');
            });
    };

    setBrokerRoleSelected = (e) => {
        this.applyInput = false;
        this._setState({
            brokerRoleSelected: e.target.value
        })
    }

    onFromDateChanged = (dates) => {
        this.applyInput = false;
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this.applyInput = false;
        this._setState({ toDate: dates[0] });
    };

    convertData = (data) => {
        let dataResult = data || [];
        if (dataResult && dataResult.length > 0) {
            let groupedData = _(dataResult)
                .groupBy('retypecode') // Nhóm theo loại hình
                .value();
            let groupeDataArr = [];
            Object.keys(groupedData).forEach(key => {
                groupedData[key][0]['commisionExt'] = groupedData[key][0]['commision']; // Trường hoa hồng tạm tính chỉ hiện với dòng đầu tiên trong nhóm loại hình
                groupeDataArr = groupeDataArr.concat(groupedData[key]);
            })
            dataResult = groupeDataArr;
        }
        return dataResult;
    }

    applyInput = false;

    apiLoadAll = async (obj) => {
        this.applyInput = true;
        try {
            // let _obj = {
            //     page: obj.page,
            //     limitRow: obj.limitRow,
            // }
            const { fromDate, toDate, brokerAccountSelected, brokerRoleSelected } = this.state;
            if (brokerAccountSelected) {
                return inquiryService.getTradingValueReport(fromDate, toDate, brokerAccountSelected, brokerRoleSelected)
                    .then((data) => {
                        data = this.convertData(data);
                        // if (data && data.d) {
                        //     // data = data.d
                        //     data = this.convertData(data);
                        // }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-trading-value-report');
                        return []
                    });
            }
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }


    search = () => {
        this.setReloadData();
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    render() {
        const { onReloadData, brokerAccounts, brokerAccountSelected, brokerRoleSelected, brokerRoles } = this.state;
        const { language } = this.props;
        return (
            <RetryableContent className="trading-value-report report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="trading-value-report-content">
                    <div className="action-container">
                        <div className="d-flex container-select flex-wrap">
                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="trading-value-report.table.broker-account-fillter" /></div>
                                <select value={brokerAccountSelected ? brokerAccountSelected : ''} onChange={this.setBrokerAccountSelected} className="custom-form-control" autoFocus={true}>
                                    {(!brokerAccounts || brokerAccounts.length === 0) && (
                                        <FormattedMessage key="empty" id="trading-value-report.table.broker-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {brokerAccounts && brokerAccounts.map((broker) => {
                                        return (
                                            <option key={broker.custid} value={broker.custid}>{broker.custid} - {broker.fullname}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="trading-value-report.table.role-fillter" /></div>
                                <select value={brokerRoleSelected ? brokerRoleSelected : ''} onChange={this.setBrokerRoleSelected} className="custom-form-control">
                                    {(!brokerRoles || brokerRoles.length === 0) && (
                                        <FormattedMessage key="empty" id="trading-value-report.table.role">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {brokerRoles && brokerRoles.map((role) => {
                                        return (
                                            <option key={role.cdval} value={role.cdval}>{language == "vi" ? role.cdcontent : role.en_cdcontent}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-center"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-center"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>
                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>
                        </div>

                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='table-ex-container p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="custodycd" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="trading-value-report-paging" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            apiLoadAll={this.apiLoadAll} // sử dụng Api all: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                            isCustomTopAndBottomSticky={false}
                            legacyMode={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        defaultTheme: defaultTheme,
        curCustomer: curCustomer,
        currentDate: state.app.currentDate,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TradingValueReport));

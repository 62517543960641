import React, { Component } from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";

import RetryableContent from "../../../components/RetryableContent";
import { inquiryService, } from "../../../services";
import * as actions from '../../../store/actions'
import { CommonUtils, execTypes, LoadStatus, reportParams, reportTypes, Role, ToastUtil, PREFIX_CUSTODYCD, KeyCodeUtils } from "../../../utils";

import './CustomerNeedAdditionalMargin.scss';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import TextToolTip from "../../../components/TextToolTip";
import { ReactComponent as DownloadIconLight } from '../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../assets/icons/new/DownloadIcon_Dark.svg'


let columnsDefault =
    [
        /* Số tài khoản */
        {
            dataField: "custodycd",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Tiểu khoản */
        {
            dataField: "producttype",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Tên KH */
        {
            dataField: "fullname",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Loại cảnh báo */
        {
            dataField: "calltype",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* TL thực tế*/
        {
            dataField: "marginrate",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Nợ MR tiền đến hạn*/
        {
            dataField: "dueamount",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Số tiền cần nộp về R an toàn*/
        {
            dataField: "add_to_mrirate",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 7,
        },
        /* Số tiền cần nộp tối đa*/
        {
            dataField: "addvnd",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 8,
        },
        /* Môi giới quản lý*/
        {
            dataField: "refullname",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 9,
        },
        // /* Thời gian tạo Email lần cuối*/
        // {
        //     dataField: "emailsenttime",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "400",
        //     // width: '500',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 10,
        // },
    ]

const totalDefault =
    [
        {
            column: "dueamount",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "add_to_mrirate",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "addvnd",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]
class CustomerNeedAdditionalMargin extends Component {

    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        customers: [],
        custodycd: PREFIX_CUSTODYCD,
        loading: false,
        custodycdParam: '002C'
    };


    constructor(props) {
        super(props);
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    genReport = (exptype) => {
        const { userInfo } = this.props;
        let { custodycd, custodycdParam } = this.state;
        let tlid = userInfo && userInfo.custid;
        let _custodycd = custodycdParam ? custodycdParam : custodycd
        let fullname = "ALL"
        let idcode = "ALL"
        let mobile = "ALL"
        let email = "ALL"

        let rptId = '';
        let inpArr = [];
        // :ret,:custid,:tlid,:offset,:limit
        rptId = 'MR0002';
        inpArr = ['ALL', tlid, 1, 999999999999, reportParams.OUT, reportParams.OUT];
        for (let i = 0; i < inpArr.length; i++) {
            let str = CommonUtils.stringifyInputExcel(inpArr[i]);
            inpArr[i] = str;
        };
        inquiryService.genReport({
            rptId: rptId,
            rptParams: _.join(inpArr, ','),
            exptype: exptype
        }).then(data => {
            ToastUtil.success('common.request-gen-report-success');
        }).catch(error => {
            ToastUtil.errorApi(error, 'common.fail-to-gen-report')
        })
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd);
            case 'producttype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.producttype);
            case 'fullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.fullname);
            case 'calltype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.calltype);
            case 'rtnamt':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rtnamt);
            case 'dueamount':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.dueamount, "PRICE");
            case 'add_to_mrirate':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.add_to_mrirate, "PRICE");
            case 'ovdamount':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.ovdamount, "PRICE");
            case 'addvnd':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.addvnd, "PRICE");
            case 'refullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.refullname);
            case 'mrirate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.mrirate);
            case 'mrmrate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.mrmrate);
            case 'mrlrate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.mrlrate);
            case 'totalvnd':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.totalvnd, "PRICE");
            case 'marginrate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.marginrate);
            case 'mobilesms':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.mobilesms);
            case 'email':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.email);
            case 'smssenttime':
                return (e) => RenderGridCell.renderGridCellCommon((e.row.data.calldate && e.row.data.calldate) ? (e.row.data.calldate + ' ' + e.row.data.calltime) : '');
            // case 'emailsenttime':
            //     return (e) => RenderGridCell.renderGridCellDate(e.row.data.emailsenttime);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'custodycd':
                return "customer-need-addition-margin.table.account";
            case 'producttype':
                return "customer-need-addition-margin.table.sub-account";
            case 'fullname':
                return "customer-need-addition-margin.table.full-name";
            case 'calltype':
                return "customer-need-addition-margin.table.calltype";
            case 'rtnamt':
                return "customer-need-addition-margin.table.warning-value";
            case 'dueamount':
                return "customer-need-addition-margin.table.amount-due";
            case 'add_to_mrirate':
                return "customer-need-addition-margin.table.r-safety";
            case 'ovdamount':
                return "customer-need-addition-margin.table.amount-overdue";
            case 'addvnd':
                return "customer-need-addition-margin.table.additional-deposit";
            case 'refullname':
                return "customer-need-addition-margin.table.refullname";
            case 'mrirate':
                return "customer-need-addition-margin.table.safety-ratio";
            case 'mrmrate':
                return "customer-need-addition-margin.table.alert-ratio";
            case 'mrlrate':
                return "customer-need-addition-margin.table.processing-ratio";
            case 'totalvnd':
                return "customer-need-addition-margin.table.total-amount";
            case 'marginrate':
                return "customer-need-addition-margin.table.actual-rate";
            case 'mobilesms':
                return "customer-need-addition-margin.table.phone";
            case 'email':
                return "customer-need-addition-margin.table.email";
            case 'smssenttime':
                return "customer-need-addition-margin.table.time-to-create-last-sms";
            // case 'emailsenttime':
            //     return "customer-need-addition-margin.table.time-to-create-last-email";
            default:
                return null;
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.setReloadData();
        CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;
        if (language !== prevlanguage) {
            this.setReloadData()
        }
    }

    apiPaging = async (obj) => {
        try {
            let _obj = {
                page: obj.page,
                limitRow: obj.limitRow ? obj.limitRow : 100, //hard nếu ko tìm được limitRow thì 
            }
            return inquiryService.getCustListNeedAdditionalMargin(_obj.page, _obj.limitRow)
                .then((data) => {
                    // if (data && data.d) {
                    //     data = data.d
                    // }
                    return data
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-customers-need-additional-margin');
                    return []
                });
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    search = () => {
        this.setReloadData();
    }



    render() {
        const { curCustomer } = this.props;
        const { onReloadData } = this.state;
        return (
            <RetryableContent className="customer-need-addition-margin report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="customer-need-addition-margin-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>
                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="custodycd" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="customer-need-addition-margin-paging" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            apiLoadAllByPagingRes={this.apiPaging}
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        defaultTheme: defaultTheme,
        curCustomer: curCustomer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerNeedAdditionalMargin));

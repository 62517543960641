import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import { inquiryService, userService } from 'services';
import { ReactComponent as StartActiveIcon } from '../../assets/icons/new/StartActiveIcon.svg'
import { ReactComponent as StartUnActiveIcon } from '../../assets/icons/new/StartUnActiveIcon.svg'
import { CommonWidgetUtils, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from 'utils';
import { connect } from 'react-redux';
import * as actions from "../../store/actions";
import { emitter } from 'utils/EventEmitter';
import './CustomerSelectorNew.scss';

let columnsDefault =
    [
        /* Chi tiết */
        {
            dataField: "action",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "140",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            pointerEvents: "none"
        },
        /* Số lưu ký */
        {
            dataField: "custodycd",
            dataType: "string",
            allowReordering: true,
            minWidth: "140",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Tên khách hàng */
        {
            dataField: "fullName",
            dataType: "string",
            allowReordering: true,
            minWidth: "140",
            width: '140',
            visible: true,
            alignment: "left",
            visibleIndex: 2,
        },
        /* Mã giấy tờ */
        {
            dataField: "idCode",
            dataType: "string",
            allowReordering: true,
            minWidth: "140",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Số điện thoại */
        {
            dataField: "mobile",
            dataType: "string",
            allowReordering: true,
            minWidth: "140",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* Email */
        {
            dataField: "email",
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            width: '200',
            visible: true,
            alignment: "left",
            visibleIndex: 5,
        },
        /* MGQL */
        {
            dataField: "refullname",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '140',
            visible: true,
            alignment: "left",
            visibleIndex: 6,
        },
    ]
class CustomerSelectorNew extends Component {

    state = {
        onReloadData: true,
        favCustomers: [],
        custody: '',
        fullname: '',
        idcode: '',
        mobile: '',
        email: '',
        countFav: 0,
        checkFirst: false
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;
        if (language !== prevlanguage) {
            this.setReloadData()
        }
    }

    returnFavCustomer = (val) => {
        return this.state.favCustomers.some(item => val.custodycd === item.custodycd);
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        this.loadFavCustomers()
        // this.setReloadData()
    }

    renderSwitch = (dataField) => {
        switch (dataField) {
            case 'action':
                return (e) => this.renderDetailCustomer(e.row.data);
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd);
            case 'fullName':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.fullName);
            case 'idCode':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.idCode);
            case 'mobile':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.mobile);
            case 'email':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.email);
            case 'refullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.refullname);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'action':
                return "";
            case 'custodycd':
                return "customer-management.list-customers-table.depository-number";
            case 'fullName':
                return "customer-management.list-customers-table.customer-name";
            case 'idCode':
                return "customer-management.list-customers-table.document-code";
            case 'mobile':
                return "customer-management.list-customers-table.phone-number";
            case 'email':
                return "customer-management.list-customers-table.email";
            case 'refullname':
                return "customer-management.list-customers-table.refullname";
            default:
                return null;
        }
    }

    handleFavoriteCustomer = (customer) => {
        let check = this.returnFavCustomer(customer)
        if (check) {
            this.removeFavoriteCustomer(customer.custid)
        } else {
            this.addFavoriteCustomer(customer)
        }
    }

    renderDetailCustomer = (customer) => {
        return (
            <div className="txt---500-14-20"
                onClick={() => this.handleFavoriteCustomer(customer)}
            >
                {
                    this.returnFavCustomer(customer) ? <StartActiveIcon />
                        : <StartUnActiveIcon />
                }
            </div>
        )
    }

    apiPaging = async (obj) => {
        const { custody, fullname, idcode, mobile, email, favCustomers, checkFirst } = this.state;
        // off khi nhập tìm kiếm mới gọi api ==> Hiện tại api đang giới hạn 200 bản ghi ====> cho tra cứu all
        // const disable = custody || fullname || idcode || mobile || email
        try {
            // if (disable) {
            return inquiryService.getCustomers(custody, fullname, idcode, mobile, email)
                .then((data) => {
                    const result = data.filter(col => {
                        return favCustomers.find(selected => selected.custid === col.custid)
                    })
                    //console.log('laojahackgame======> COUNT FAV', { result, length: result.length });
                    this._setState({ countFav: result.length });
                    data.sort((a, b) => {
                        if (result.find((o) => o.custid === a.custid)) { return -1; }
                        if (result.find((o) => o.custid === a.custid)) { return 1; }
                        return 0;
                    })

                    return data
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-customers-list');
                    return []
                });
            // }
            this._setState({ countFav: 0 });
            if (checkFirst) {
                ToastUtil.error("CM3", 'CM000004');
                this._setState({
                    checkFirst: false
                })
                return []
            }
            return []
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    onCustodyChanged = (event) => {
        this._setState({ custody: event.target.value });
    };

    onFullnameChanged = (event) => {
        this._setState({ fullname: event.target.value });
    };

    onIdCodeChanged = (event) => {
        this._setState({ idcode: event.target.value });
    };

    onMobileChanged = (event) => {
        this._setState({ mobile: event.target.value });
    };

    onEmailChanged = (event) => {
        this._setState({ email: event.target.value });
    };

    loadFavCustomers = () => {
        userService.getBrokerFavCustomer().then(favCustomers => {
            let favCustomersInfo = [];
            favCustomers.forEach(customer => {
                return favCustomersInfo.push(customer.customerInfo);
            });
            this._setState({
                favCustomers: favCustomersInfo
            }, () => {
                this.setReloadData()
            });
        }).catch(error => {

        });
    };
    addFavoriteCustomer = (customer) => {
        userService.addBrokerFavCustomer(customer).then(() => {
            let { favCustomers } = this.state;
            favCustomers = [...favCustomers, customer]
            this._setState({
                favCustomers
            })
            this.setReloadData();
            emitter.emit("RELOAD_FAVORITE_CUSTOMER_LIST");
            ToastUtil.success('common.add-favorite-customer-success');
        }).catch(error => {
            ToastUtil.errorApi(error, 'common.fail-to-add-favorite-customer');
        });
    };
    removeFavoriteCustomer = (custid) => {
        userService.removeBrokerFavCustomer(custid).then(() => {
            let { favCustomers } = this.state;
            favCustomers = favCustomers.filter(customer => {
                return customer.custid !== custid
            });
            this._setState({
                favCustomers
            })
            this.setReloadData()
            emitter.emit("RELOAD_FAVORITE_CUSTOMER_LIST");
            ToastUtil.success('common.remove-favorite-customer-success');
        }).catch(error => {
            ToastUtil.errorApi(error, 'common.fail-to-remove-favorite-customer');
        });
    };

    onRowDblClick = (e) => {
        const customer = e && e.data;
        const { onCustomerDidSelected, currentTypeScreenSelectorAccount } = this.props;
        this.props.setCustodycdCustomerScreen(customer.custodycd, currentTypeScreenSelectorAccount ? currentTypeScreenSelectorAccount : SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL)
        if (onCustomerDidSelected) {
            onCustomerDidSelected();
        }
    }

    search = (e) => {
        this._setState({
            checkFirst: true
        })
        this.setReloadData()
        // this.loadFavCustomers()
    }

    // onCustomerSelected = (customer) => {
    //     const { onCustomerDidSelected, currentTypeScreenSelectorAccount } = this.props;
    //     this.props.setCustodycdCustomerScreen(customer.custodycd, currentTypeScreenSelectorAccount ? currentTypeScreenSelectorAccount : SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL)
    //     if (onCustomerDidSelected) {
    //         onCustomerDidSelected();
    //     }
    // };


    render() {
        const { onReloadData, custody, fullname, idcode, mobile, email } = this.state;
        return (
            <div className='tab-container'>
                <div className="tab-bar">
                    <div className="row gutters-5">
                        <div className="col-12 col-md-10">
                            <div className="row gutters-5 mb-3">

                                <div className="col-6 col-md-2">
                                    <div className="search-container">
                                        <FormattedMessage id="customer-selector.modal.customer.custody">
                                            {(placeholder) => (
                                                <input type="text"
                                                    placeholder={placeholder}
                                                    value={custody}
                                                    onChange={this.onCustodyChanged}
                                                />
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>

                                <div className="col-6 col-md-2">
                                    <div className="search-container">
                                        <FormattedMessage id="customer-selector.modal.customer.name">
                                            {(placeholder) => (
                                                <input type="text"
                                                    placeholder={placeholder}
                                                    value={fullname}
                                                    onChange={this.onFullnameChanged}
                                                />
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>

                                <div className="col-6 col-md-2">
                                    <div className="search-container">
                                        <FormattedMessage id="customer-selector.modal.customer.id-no">
                                            {(placeholder) => (
                                                <input type="text"
                                                    placeholder={placeholder}
                                                    value={idcode}
                                                    onChange={this.onIdCodeChanged}
                                                />
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>

                                <div className="col-6 col-md-2">
                                    <div className="search-container">
                                        <FormattedMessage id="customer-selector.modal.customer.mobile">
                                            {(placeholder) => (
                                                <input type="text"
                                                    placeholder={placeholder}
                                                    value={mobile}
                                                    onChange={this.onMobileChanged}
                                                />
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>

                                <div className="col-6 col-md-2">
                                    <div className="search-container">
                                        <FormattedMessage id="customer-selector.modal.customer.email">
                                            {(placeholder) => (
                                                <input type="text"
                                                    placeholder={placeholder}
                                                    value={email}
                                                    onChange={this.onEmailChanged}
                                                    className="custom-form-control"
                                                />
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <button className="btn-search txt---500-14-20" onClick={this.search}>
                                        <FormattedMessage id="common.search" />
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='h-70 '>
                    <TableExtremeCustom2
                        keyStoreUpdate="custodycd" // key của row 
                        columnsDefault={columnsDefault} // ds cột
                        totalDefault={[]} // ds sum (nếu có)
                        renderSwitch={this.renderSwitch} // func render dữ liệu
                        returnCaption={this.returnCaption} // func render column name
                        isUseSelection={false} // true/false: hiện/ẩn chọn row
                        isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                        addClass="customer-selector-table" // class cha
                        isPager={false} // hiển thị giao diện phân trang
                        apiLoadAll={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                        onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                        defaultPageSizes={100}  //Truyền 
                        showFilter={false}
                        onRowDblClick={this.onRowDblClick}
                    // focusedRowEnabled={true}
                    />
                </div>
                <div className='d-flex' style={{ gap: "4px", marginTop: "5px", marginBottom: "10px" }}>
                    <div>
                        <FormattedMessage id="customer-selector.modal.selected" />
                    </div>
                    <div className=''>{this.state.countFav} <FormattedMessage id="customer-selector.modal.account" /></div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const layoutPage = state.layout.layoutPage
    const currentCustomer = layoutPage[currentLayoutPageActive]
    const currentTypeScreenSelectorAccount = state.layout.typeScreenSelectorAccount
    return {
        currentCustomer: currentCustomer.accountInfo.curCustomer,
        currentAccount: currentCustomer.accountInfo.currentAccountId,
        currentTabActive: state.layout.currentTabActive,
        currentLayoutPageActive: currentLayoutPageActive,
        currentTypeScreenSelectorAccount,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCustodycdCustomerScreen: (custodycd, typeScreen) => dispatch(actions.setCustodycdCustomerScreen(custodycd, typeScreen)),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
        setCurrentAccountLayoutTop: (customer) => dispatch(actions.setCurrentAccountLayoutTop(customer)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelectorNew);

import React, { Component } from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { CommonUtils } from 'utils';

import { FormattedUtcDateTime } from "../../../components/Formating";
import * as actions from "../../../store/actions";

import NotificationOrderConfirm from './NotificationOrderConfirm';
import { TYPE as TYPE_TAB_SECURITY } from 'containers/Security/Security'


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

const LINK_ENABLE = ["401A", "1006"]

class NotificationItem extends Component {
    /*
    {
      "createdAt": 1584680096814,
      "updatedAt": 1584680096814,
      "id": 9,
      "afacctno": "0001001198",
      "custid": "0001001217",
      "templateid": "327C",
      "msgevent": {
        "templateid": "327C",
        "custid": "0001001217",
        "custodycd": "001C122561",
        "afacctno": "0001001198",
        "addvnd": 2909383
      },
      "read": false,
      "template": {
        "templateid": "327C",
        "subject_vi": "Thông báo xử lý bán tài sản đảm bảo",
        "subject_en": "Notice of disposal of security assets",
        "msgcontent_vi": "FSS: Quý khách bị giải chấp kí quỹ số tiền [ADDVND]. Để biết thêm thông tin vui lòng liên hệ môi giới quản lý tài khoản.",
        "msgcontent_en": "FSS: Quý khách bị giải chấp kí quỹ số tiền [ADDVND]. Để biết thêm thông tin vui lòng liên hệ môi giới quản lý tài khoản.",
        "note": null
      }
    }
    */

    genContentMessage = (msgevent, template, allCodeMessage, lang) => {
        const { intl } = this.props;
        var content = template;
        if (msgevent) {
            for (const key in msgevent) {
                if (msgevent.hasOwnProperty(key)) {
                    const element = msgevent[key];
                    const elementAllCode = allCodeMessage && allCodeMessage[element] && allCodeMessage[element][lang] ? allCodeMessage[element][lang] : element;   //giang.ngo tra cứu giá trị trong allCode để lấy giá trị thwo language tương ứng
                    const patternAllCode = new RegExp(`\\[\\{${key}\\}\\]`, 'gi');
                    content = content.replace(patternAllCode, elementAllCode)
                    const pattern = new RegExp(`\\[${key}\\]`, 'gi');
                    let converted = element
                    content = content.replace(pattern, converted)
                }
            }
            return content
        }
    };
    onClickMessLink = (templateid, accountid) => {
        const accountInfo = _.cloneDeep(this.props.accountInfo)
        const keyName = templateid === "401A" ? "menu-sidebar.title-5.2" : "menu-sidebar.title-10"
        accountInfo.currentAccountId = accountid
        localStorage.setItem('TAB_ENABLE_CLICK', TYPE_TAB_SECURITY.CHANGE_PASSWORD_LOGIN)
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: true,
            curScreenModalActive: keyName,
            accountInfo: accountInfo,
        })
    };

    render() {
        const { message, lang, allCodeMessage } = this.props;
        const subject = message.template ? message.template["subject_" + CommonUtils.getLanguageAvailable(lang)] : ''; //language handle
        const template = message.template ? message.template["msgcontent_" + CommonUtils.getLanguageAvailable(lang)] : '';
        const message_content = this.genContentMessage(message.msgevent, template, allCodeMessage, CommonUtils.getLanguageAvailable(lang));
        const templateid = message.templateid;
        const accountid = message.msgevent.acctno;
        return (
            <div className="notification-item" tabIndex="0">
                <div className="inner">
                    {LINK_ENABLE.includes(templateid) && (
                        <NotificationOrderConfirm
                            onClickMessLink={this.onClickMessLink}
                            templateid={templateid}
                            accountid={accountid}
                            subject={subject}
                        />
                    )}
                    <div className="date"><FormattedUtcDateTime value={message.createdAt} /></div>
                    <div className="content">{message_content}</div>
                </div>
            </div >
        );
    }

}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentAccount: (accountId) => dispatch(actions.setCurrentAccount(accountId)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        // onChangeMenuActiveSideBar: (keyName) => dispatch(actions.onChangeMenuActiveSideBar(keyName)),
        // onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationItem));
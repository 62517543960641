import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { ReactComponent as AccountIconDark } from '../../assets/icons/header/AccountIcon_Dark.svg'
import { destroySessionStorage, getSessionStorage } from '../../common/utils'
import { inquiryService, userService } from "../../services";
import * as actions from "../../store/actions";
import { CommonUtils, path, Role, ToastUtil } from "../../utils";
import { SESSION_STORAGE_LIST_MARKET_CHART } from '../../utils/constants'

import './QuickSetting.scss';


class QuickSetting extends Component {

    state = {
        isShowSetting: false,
        accountInfo: null
    };

    languagesInfo = {
        VIETNAM: {
            code: 'vi',
            name: 'settings.config.general-config.languages.vi',
        },
        ENGLISH: {
            code: 'en',
            name: 'settings.config.general-config.languages.en',
        }
    };

    themeInfo = {
        LIGHT: {
            code: "light"
        },
        DARK: {
            code: "dark"
        }
    }

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    getPersionalInfo = () => {
        const { accounts, userInfo } = this.props;
        if (accounts.length > 0) {
            let foundAccount = accounts.find(item => item.custodycd === userInfo.custodycd);
            if (foundAccount) {
                this._setState({ accountInfo: foundAccount });
            }
        }
    }

    getBrokerInfo = () => {
        inquiryService.getBrokerInfo()
            .then((data) => {
                this._setState({ accountInfo: data });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
            });
    }
    
    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { isBroker } = this.props;
        if (isBroker) {
            this.getBrokerInfo();
        } else {
            this.getPersionalInfo();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.mounted = false
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this._setState({ isShowSetting: false })
        }
    }

    toggleQuickSetting = () => {
        this._setState({ isShowSetting: !this.state.isShowSetting })
    }

    switchTheme = (themeCode) => {
        // const { defaultTheme } = this.props;
        // let newTheme = defaultTheme === 'dark' ? 'light' : 'dark';
        // this.state.theme = newTheme;
        //console.log('ha_check_themeCode', themeCode)
        const { isLoggedIn } = this.props;
        if (isLoggedIn) {
            userService.changeTheme(themeCode)
                .catch((error) => {
                    ToastUtil.errorApi(error);
                });
        }
        this.props.changeDefaultTheme(themeCode);
    };

    switchLanguage = (langCode) => {
        const { isLoggedIn } = this.props;
        if (langCode) {
            if (isLoggedIn) {
                userService.changeLanguage(langCode)
                    .catch((error) => {
                        ToastUtil.errorApi(error);
                    });
            }
            this.props.changeLanguage(langCode);
        }
    }

    logoutHandler = () => {
        //xóa list market khi logout
        let listMarketChartFromSessionStorage = getSessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        if (listMarketChartFromSessionStorage && listMarketChartFromSessionStorage.length > 0) {
            destroySessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        }

        localStorage.setItem('persist:user', '');
        CommonUtils.setCookie("authInfo", null, '/', true, '');
        CommonUtils.setIsStep2Authenticated(null, '/', true, '');

        this.props.history.push(path.LOG_OUT);
    };

    render() {
        const { className, defaultTheme, language, userInfo, currentAccount, currentCustomer, isBroker, isLoggedIn } = this.props;
        const { isShowSetting, accountInfo } = this.state;
        let theme = defaultTheme;

        let custodycd = '';
        if (userInfo.role === Role.CUSTOMER) {
            custodycd = userInfo.custodycd;
        } if (userInfo.role === Role.BROKER) {
            custodycd = currentCustomer ? currentCustomer.custodycd : '';
        };

        let userName = '';
        if (isBroker) {
            userName = accountInfo ? accountInfo.brokerName : '';
        } else {
            userName = accountInfo ? accountInfo.name : '';
        }

        return (
            <div className="quick-settings" ref={this.setWrapperRef}>
                <button id="btn-setting-id" className={className} onClick={this.toggleQuickSetting}>
                    <AccountIconDark />
                </button>
                <div className="setting-container" hidden={isShowSetting ? '' : 'hidden'}>

                    <div className="info-container">
                        <div className='avatar-holder text-center'><i className="fas fa-user"></i></div>
                        <div className="info-content text-center">
                            <p className="full-name">{userName}</p>
                            <p> <FormattedMessage id="common.notifications.account" /> : {userInfo.username}</p>
                        </div>
                    </div>

                    <div className="seperate-line"></div>

                    <div className="aditional-info-container">
                        <div className="col-12 quick-setting-mini-title"><FormattedMessage id="common.layout.appearance" /></div>
                        <div className="row glutter-5">
                            {/* <div className="col-7">
                            <FormattedMessage id="settings.config.general-config.enable-dark-mode" />
                        </div>
                        <div className="col-5">
                            <CustomInput
                                type="switch"
                                id="switch-theme-input"
                                onChange={this.switchTheme}
                                checked={isDarkTheme}
                            />                    
                        </div> */}
                            <div className="col-1 offset-2">
                                <input type="radio" name="mode-select"
                                    checked={defaultTheme === this.themeInfo.LIGHT.code}
                                    onChange={this.switchTheme.bind(null, this.themeInfo.LIGHT.code)}
                                >
                                </input>
                            </div>
                            <div className="col-3 def-container text-lang">
                                {/* <i className="fas fa-sun lang-icon"></i> */}
                                <FormattedMessage id="quicksetting.light" />
                            </div>
                            <div className="col-1">
                                <input type="radio" name="mode-select"
                                    checked={defaultTheme === this.themeInfo.DARK.code}
                                    onChange={this.switchTheme.bind(null, this.themeInfo.DARK.code)}
                                >
                                </input>
                            </div>
                            <div className="col-3 def-container text-lang">
                                {/* <i className="fas fa-moon-stars lang-icon"></i> */}
                                <FormattedMessage id="quicksetting.dark" />
                            </div>
                        </div>
                    </div>

                    <div className="seperate-line"></div>

                    <div className="aditional-info-container">
                        <div className="col-12 quick-setting-mini-title"><FormattedMessage id="settings.config.general-config.language" /></div>
                        <div className="row glutter-5">
                            <div className="col-1 offset-2">
                                <input type="radio" name="language-select"
                                    checked={language === this.languagesInfo.VIETNAM.code}
                                    onChange={this.switchLanguage.bind(null, this.languagesInfo.VIETNAM.code)}
                                >
                                </input>
                            </div>
                            <div className="col-3 text-lang">
                                {/* <img className="lang-icon" src={viIcon} alt="Vi" /> */}
                                VIE
                            </div>
                            <div className="col-1">
                                <input type="radio" name="language-select"
                                    checked={language === this.languagesInfo.ENGLISH.code}
                                    onChange={this.switchLanguage.bind(null, this.languagesInfo.ENGLISH.code)}
                                >
                                </input>
                            </div>
                            <div className="col-3 text-lang">
                                ENG
                                {/* <img className="lang-icon" src={enIcon} alt="En" /> */}
                            </div>
                        </div>
                    </div>
                    {isLoggedIn &&
                        <div className="seperate-line"></div>}
                    {isLoggedIn &&
                        <div className="logout-btn-container align-item-center text-center">
                            <div className="logout-btn text-center" onClick={this.logoutHandler}>
                                <FormattedMessage id="logout.title" /><span> </span><i className="fas fa-sign-out" />
                            </div>
                        </div>
                    }

                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        language: state.app.language,
        accounts: state.account.accounts,
        lang: state.app.language,
        defaultTheme: defaultTheme,
        userInfo: userInfo,
        currentAccount: state.account.currentAccount,
        currentCustomer: state.customer.currentCustomer,
        accounts: state.account.accounts,
        isLoggedIn: state.user.isLoggedIn,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeDefaultTheme: (theme) => dispatch(actions.changeDefaultTheme(theme)),
        changeLanguage: (language) => dispatch(actions.changeLanguage(language)),
        logout: () => dispatch(actions.logout()),
    };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickSetting)));

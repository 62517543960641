import { ConnectedRouter as Router } from 'connected-react-router';
// import ConfirmOrder from './Account/Utilities/ConfirmOrder/ConfirmOrder';
// import { DataListMenuSideBar } from '../containers/MenuSideBar/listMapMenuSideBar';
import $ from 'jquery';
import * as queryString from 'query-string';
import React, { Component, Fragment, lazy, Suspense } from 'react';
import { isIOS, isAndroid, isMobile, isTablet } from "react-device-detect";
import { DndProvider } from 'react-dnd';
// import { connect } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import PageVisibility from 'react-page-visibility';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';

import ScreenModal from 'components/ScreenModal/ScreenModal';

// import ModalNotiConfigWeb from 'routes/ConfigWeb/ModalNotiConfigWeb';

import ConfirmModal from "../../src/components/Modal/ConfirmModal"
import BrowserModal from '../../src/containers/Settings/Config/BrowserConfirmModal';
// import PriceBoard from '../routes/PriceBoard';
// import config from '../combineConfig';
// import AsyncComponent from '../components/AsyncComponent';
import CookieManager from "../components/common/CookieManager";
import { CustomToastCloseButton } from '../components/CustomToast';
import ErrorBoundary from "../components/ErrorBoundary";
// import AccountRegister from "../containers/Account/AccountRegister/AccountRegister";
import ErrorScreen from "../components/ErrorScreen";
import ExpiredTokenErrorScreen from "../components/ExpiredTokenErrorScreen";
import GridLoading from "../components/GridLoading";
import SocketManager from "../components/SocketManager";
import ElementResizeListener from '../components/Table/TableExtreme/ElementResizeListener';
// import MenuSideBar from '../containers/MenuSideBar/MenuSideBar';
// import PopupFooterPage from '../containers/PopupFooterPage/PopupFooterPage';
import { userIsAuthenticated, userIsNotAuthenticated } from '../hoc/authentication';
import { dispatch, history } from '../redux';
import ConfigWeb from '../routes/ConfigWeb/ConfigWeb.js';
import Home from '../routes/Home';
import Login from '../routes/Login';
import Logout from '../routes/Logout';
import NotFound from '../routes/NotFound';
import Sso from '../routes/Sso';
import { authService, symbolService } from '../services';
import * as actions from "../store/actions";
import actionTypes from '../store/actions/actionTypes';
import { CommonUtils, genBaseId, getValueFromLocalStorage, KeyCodeUtils, keyFactory, path, Random } from "../utils";
import { GlobalDebug } from "../utils/removeConsoles";
import TradingMoneyBIDV from './Account/Utilities/TradingMoneyBIDV/TradingMoneyBIDV';
// import RecommendCustomer from './RecommendCustomer/RecommendCustomer';
import AppMessageModal from "./AppMessageModal";
// import AppTitle from './AppTitle'
import ChangeCoreModal from './ChangeCoreModal';
// import Trade from "../routes/Trade";
// import Account from "../routes/Account";
// import Settings from "../routes/Settings";
import ChangePinPassModal from "./ChangePinPassModal";
// import CustomerSelectorModal from './CustomerSelector/CustomerSelectorModal';
import HeaderEkyc from './Ekyc/HeaderEkyc';
import Header from "./Header/Header";
// import MenuSideBar from './MenuSideBar/MenuSideBar';
import MenuTool from './MenuSideBar/MenuTool';
import PrivateRoute from "./PrivateRoute"
import retryLoader from './retryLoader'
import { userService } from '../services';
import ModalPayment from './Tools/Payment/ModalPayment';
import ReloadBrowser from './ReloadBrowser'
import { emitter } from 'utils/EventEmitter';

import './App.scss';

import UseAppNotiModal from './UseAppNotiModal';


// import AssetTotalLayout from './AccountManager/AssetTotalLayout';
// import { WIDGET_WIDTH } from "../utils/constants";


// function checkDesktop() {
//     let isDeskTop = window.innerWidth >= WIDGET_WIDTH.DESKTOP.min && window.innerWidth != 0
//     return isDeskTop
// }
let isKeyboardVisible = false;
const globalVar = window._env_
const isDevelopment = process.env.NODE_ENV === "development";
localStorage.setItem("NODE_ENV", process.env.NODE_ENV)
// const PriceBoard = () => import(/* webpackChunkName: "PB" */ "../routes/PriceBoard/index");
// const Trade = () => import(/* webpackChunkName: "TR" */ "../routes/Trade/index");
// const Account = () => import(/* webpackChunkName: "ACC" */ "../routes/Account/index");
// const Settings = () => import(/* webpackChunkName: "ST" */ "../routes/Settings/index");
// const Sidebar = () => import(/* webpackChunkName: "SB" */ "../routes/Sidebar/index");

// const lazyRetry = function (componentImport, name) {
//     // https://www.codemzy.com/blog/fix-chunkloaderror-react
//     return new Promise((resolve, reject) => {
//         // check if the window has already been refreshed
//         const hasRefreshed = JSON.parse(
//             window.sessionStorage.getItem(`retry-lazy-${name}-refreshed`) || 'false'
//         );
//         // try to import the component
//         componentImport().then((component) => {
//             window.sessionStorage.setItem(`retry-lazy-${name}-refreshed`, 'false'); // success so reset the refresh
//             resolve(component);
//         }).catch((error) => {
//             if (!hasRefreshed) { // not been refreshed yet
//                 window.sessionStorage.setItem(`retry-lazy-${name}-refreshed`, 'true'); // we are now going to refresh
//                 return window.location.reload(); // refresh the page
//             }
//             reject(error); // Default error behaviour as already tried refresh
//         });
//     });
// };

const PriceBoard = lazy(() => retryLoader(() => import(/* webpackChunkName: "PR" */ "../routes/PriceBoard/PriceBoard"), 2));
const Trade = lazy(() => retryLoader(() => import(/* webpackChunkName: "TR" */ "../routes/Trade/Trade"), 2));
const TPRLTradeNM = lazy(() => retryLoader(() => import(/* webpackChunkName: "TPRLNM" */ "../routes/TPRLTradeNM/TPRLTradeNM"), 2));
const TPRLTradeOR = lazy(() => retryLoader(() => import(/* webpackChunkName: "TPRLOR" */ "../routes/TPRLTradeOR/TPRLTradeOR"), 2));
const TPRLTradeRP = lazy(() => retryLoader(() => import(/* webpackChunkName: "TPRLRP" */ "../routes/TPRLTradeRP/TPRLTradeRP"), 2));
const MarketWatch = lazy(() => retryLoader(() => import(/* webpackChunkName: "MW" */ "../routes/MarketWatch/MarketWatch"), 2));
const AiTrading = lazy(() => retryLoader(() => import(/* webpackChunkName: "AIT" */ "../routes/AiTrading/AiTrading"), 2));
const Version = lazy(() => retryLoader(() => import(/* webpackChunkName: "VS" */ "../routes/Version/VersionInfo"), 2));
const AssetTotalLayout = lazy(() => retryLoader(() => import(/* webpackChunkName: "AT" */ "./AccountManager/AssetTotalLayout"), 2));
// const CustomerOrganzition = lazy(() => retryLoader(() => import(/* webpackChunkName: "CO" */ "../containers/PlaceOrder/CustomerOrganization/CustomerOrganzition"), 2));
// const iBroker = lazy(() => retryLoader(() => import(/* webpackChunkName: "IB" */ "../routes/iBroker/iBroker"), 2));
const DetailPage = lazy(() => retryLoader(() => import(/* webpackChunkName: "SD" */ "../../src/containers/Trade/SymbolSelector/DetailPage"), 2));
const AccountRegisterEkyc = lazy(() => retryLoader(() => import(/* webpackChunkName: "ARE" */ "../../src/containers/Ekyc/RegisterAccount"), 2));
//const AccountRegisterEkyc = () => import(/* webpackChunkName: "ARE" */ "../routes/AccountRegisterEkyc/index");
// const Account = lazy(() => import(/* webpackChunkName: "TR" */ "../routes/Account/Account"));
// const Settings = lazy(() => import(/* webpackChunkName: "TR" */ "../routes/Settings/Settings"));
// const Sidebar = lazy(() => import(/* webpackChunkName: "TR" */ "../routes/Sidebar/Sidebar"));
const sentryTest = lazy(() => retryLoader(() => import(/* webpackChunkName: "SE" */ "../routes/SentryTest/SentryTest"), 2));


GlobalDebug(isDevelopment); // true = Cho phép ghi log console

const ROUTER_BASE_NAME = globalVar.app.ROUTER_BASE_NAME != null ? '/' + globalVar.app.ROUTER_BASE_NAME : "/"
const IS_ACTIVE_TAB_RETRY_API = globalVar.app.IS_ACTIVE_TAB_RETRY_API || false
const IS_SHOW_POPUP_UPDATE_VERSION = globalVar.app.version && globalVar.app.version.IS_SHOW_POPUP_UPDATE_VERSION || true
const TIMER_CHECK_VERSION = globalVar.app.version && globalVar.app.version.TIMER_CHECK_VERSION || 5 * 60 * 1000
const IS_CALL_API_BY_CONSOLE = globalVar.app.IS_CALL_API_BY_CONSOLE || false

const COUNT_RETRY__RELOAD_MAX = 3
let countRetryReload = 0

class App extends Component {

    browserMovedToBackground = false;

    constructor(props, context) {
        super(props);
        // if (isMobile) {
        //     if (isIOS) {
        //         if (!isTablet) {
        //             window.location.href = "https://apps.apple.com/vn/app/evs-trading/id1609192715";
        //         }
        //     }
        //     if (isAndroid) {
        //         if (!isTablet) {
        //             window.location.href = "https://play.google.com/store/apps/details?id=vn.com.evs.mobiletrading&hl=vi&gl=US";
        //         }
        //     }
        // }

        //Mobile vào chức năng mở tài khoản ekyc trên web thì redirect sang app (mở thằng vào app nếu đã tải)
        // this.checkOpenMobile();

        this.headerRef = React.createRef();
        this.timerCheckVersion = undefined;
        if (/Chrome/.test(navigator.userAgent)) { // Thêm class chrome đối với trình duyệt chrome
            document.documentElement.classList.add('chrome');
        }
        this.listenToTheEmitter()
    }
    _unsubscribe = null;

    state = {
        bootstrapped: false,
        isOpenReloadBrowser: false,
        typeReloadBrowser: undefined,
        isOpenUseAppNotiModal: false
    };

    checkOpenMobile = () => {
        // Listen to visibility change to prevent next url
        window.document.addEventListener("visibilitychange", function (e) {
            this.browserMovedToBackground = window.document.visibilityState === 'hidden' || window.document.visibilityState === 'unloaded';
        });
        window.addEventListener("blur", function (e) {
            this.browserMovedToBackground = true;
        });

        // Lấy id môi giới trên url
        let pathname = history.location.pathname;
        let custodycd = '';

        // alert(this.props.location.search);
        if (this.props.location.search) {
            custodycd = pathname.split('?');
            const queryParams = queryString.parse(this.props.location.search);
            custodycd = queryParams['refcustodycd'] ? queryParams['refcustodycd'] : null;
        }

        let qs = {
            refcustodycd: custodycd || ''
        }

        const options = {

            iosApp: 'XPowerVix://OpenAccount?refcustodycd=' + qs['refcustodycd'],

            iosAppStore: 'https://itunes.apple.com/il/app/xpower-by-vix/id1574124260?mt=8&refcustodycd=' + qs['refcustodycd'],

            // For this, your app need to have category filter: android.intent.category.BROWSABLE
            android: {
                'host': 'OpenAccount?refcustodycd=' + encodeURIComponent(qs['refcustodycd']), // Host/path/querystring part in a custom scheme URL
                // 'action': '', // Equivalent to ACTION in INTENTs
                // 'category': '', // Equivalent to CATEGORY in INTENTs
                // 'component': '', // Equivalent to COMPONENT in INTENTs
                'scheme': 'XPowerVix', // Scheme part in a custom scheme URL
                'package': 'com.vix.vn.mts21', // Package name in Play store
                'fallback': 'https://play.google.com/store/apps/details?id=com.vix.vn.mts21&hl=en&refcustodycd=' + qs['refcustodycd']
            }

        }

        var tryToOpenInMultiplePhases = (urls) => {

            this.browserMovedToBackground = false;

            var currentIndex = 0;
            var redirectTime = new Date();
            window.location = urls[currentIndex++];

            var next = function () {
                if (urls.length > currentIndex) {
                    setTimeout(function () {

                        if (this.browserMovedToBackground) {
                            console.log('Browser moved to the background, we assume that we are done here')
                            return;
                        }

                        if (new Date() - redirectTime > 3000) {
                            console.log('Enough time has passed, the app is probably open');
                        } else {
                            redirectTime = new Date();
                            window.location = urls[currentIndex++];
                            next();
                        }

                    }, 10);
                }
            };

            next();

        };


        if (isAndroid) {
            // const urlOpenAccountAndroid = "intent://SeaStock/" + 'https://seastock.aseansc.com.vn/trade/account-register' + "/#Intent;scheme=openSeaStock;package=vn.com.asean.mobiletrading;end"
            var intent = options.android;
            var intentUrl = 'intent://' + intent.host + '#Intent;' +
                'scheme=' + encodeURIComponent(intent.scheme) + ';' +
                'package=' + encodeURIComponent(intent.package) + ';' +
                (intent.action ? 'action=' + encodeURIComponent(intent.action) + ';' : '') +
                (intent.category ? 'category=' + encodeURIComponent(intent.category) + ';' : '') +
                (intent.component ? 'component=' + encodeURIComponent(intent.component) + ';' : '') +
                (intent.fallback ? 'S.browser_fallback_url=' + encodeURIComponent(intent.fallback) + ';' : '') +
                'end';
            // alert(intentUrl);
            window.location.replace(intentUrl);
        }
        else if (isIOS) {
            // const urlOpenAccountIOS = options.iosApp;
            // window.location.replace(urlOpenAccountIOS);
            // setTimeout(() => {
            //     window.location.replace(
            //         options.iosAppStore
            //     );
            // }, 7000);

            var urls = [];
            if (options.iosApp) {
                urls.push(options.iosApp);
            }
            if (options.iosAppStore) {
                urls.push(options.iosAppStore);
            }
            tryToOpenInMultiplePhases(urls);


        }

    }

    handlePersistorState = () => {
        const { persistor } = this.props;
        let { bootstrapped } = persistor.getState();
        if (bootstrapped) {
            if (this.props.onBeforeLift) {
                Promise.resolve(this.props.onBeforeLift())
                    .then(() => this._setState({ bootstrapped: true }))
                    .catch(() => this._setState({ bootstrapped: true }));
            } else {
                this._setState({ bootstrapped: true });
            }
            this._unsubscribe && this._unsubscribe();
        }
    };

    handleBodyKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        // Tab key
        if (keyCode === KeyCodeUtils.TAB) {
            if (document.body.classList.contains('remove-outline')) {
                document.body.classList.remove('remove-outline');
            }
        }
    };

    handleBodyClick = () => {
        if (!document.body.classList.contains('remove-outline')) {
            document.body.classList.add('remove-outline');
        }
    };

    // checkMenuActiveUrl = () => {
    //     // const { menuHeader, role, isLoggedIn } = this.props
    //     // let activeHeader = menuHeader.activeMenuHeader
    //     // let currentUrl = window.location.pathname
    //     // let objDataListSideBar = DataListMenuSideBar[activeHeader]
    //     // let currentUrlActive = menuHeader.menuSideBar[activeHeader].activeURLSideBar

    //     // let homePage = config.api.HOME_PAGE

    //     // if (currentUrl.startsWith(homePage)) {
    //     //     currentUrl = currentUrl.replace(homePage, '/')
    //     // }
    //     // if (currentUrl === currentUrlActive) {
    //     //     return null
    //     // }
    //     // else {
    //     //     if (isLoggedIn) {
    //     //         for (var key1 in DataListMenuSideBar) {
    //     //             let newDataListMenuSideBar = DataListMenuSideBar[key1]
    //     //             for (var key2 in newDataListMenuSideBar) {
    //     //                 // if (newDataListMenuSideBar[key2].includes(currentUrl)) {
    //     //                 if (currentUrl.includes(newDataListMenuSideBar[key2])) {
    //     //                     this.props.onChangeMenuActive(key1)
    //     //                     this.props.onChangeMenuActiveSideBar(key2)
    //     //                     this.props.onChangeActiveURLSideBar(newDataListMenuSideBar[key2])
    //     //                 }
    //     //             }
    //     //         }
    //     //     }
    //     // }
    // }

    // checkMenuActiveUrl = () => {
    //     const { menuHeader, currentLayoutPageActive } = this.props
    //     let activeHeader = menuHeader.activeMenuHeader
    //     let currentUrl = window.location.pathname

    //     let homePage = config.api.HOME_PAGE

    //     if (currentUrl.startsWith(homePage)) {
    //         currentUrl = currentUrl.replace(homePage, '/')
    //     }
    //     if (currentLayoutPageActive) {
    //         this.changeLayoutPageActive("C#CS#PRICEBOARD#CB")
    //         return
    //     } else {
    //         return
    //     }

    // }

    getCustodycdInUrl = () => {
        let custodycd = '';
        if (this.props.location.search) {
            const queryParams = queryString.parse(this.props.location.search);
            custodycd = queryParams['custodycd'] ? queryParams['custodycd'] : null;
        }
        else {
            let savedCustodycd = sessionStorage.getItem('activeCustID');
            custodycd = savedCustodycd ? savedCustodycd : null;
        }
        let usersTokens = JSON.parse(localStorage.getItem('token-users'));
        let accessToken = usersTokens && usersTokens[`${custodycd}`] ? usersTokens[`${custodycd}`]['access_token'] : '';
        if (!accessToken && custodycd) {
            this.props.logoutSingleUser(custodycd, false);
        }
        return custodycd;
    }

    callbackWindow = (event) => {
        if (!isKeyboardVisible) {
            let scroll = $(window).scrollTop()
            if (scroll > 10) {
                $(window).scrollTop(0)
            }
            //console.log('Scrolling...', scroll);
        }
    }

    setClassBody = (isDeskTop) => {
        let type = isDeskTop ? 'isDeskTop' : 'isMobile'
        if (this.props.defaultTheme === 'dark') {
            document.body.className = 'fss theme-dark ' + type;
            // document.body.style.backgroundColor = "red";
        }
        else {
            document.body.className = 'fss theme-light ' + type
            // document.body.style.backgroundColor = "blue";
        }
        if (isMobile) {
            $(document).on('focusin', 'input, textarea', function () {
                // Virtual keyboard is likely open
                isKeyboardVisible = true;
                // //console.log('Virtual keyboard is open');
                // You can disable scrolling or perform other actions here
            });

            $(document).on('focusout', 'input, textarea', function () {
                isKeyboardVisible = false;
                // Virtual keyboard is likely closed
                // //console.log('Virtual keyboard is closed');
                // You can enable scrolling or perform other actions here
            });
            document.head.insertAdjacentHTML("beforeend", `<style>body{overflow: auto !important;height: calc(100vh + 100px) !important;}</style>`)
            // window.addEventListener('scroll', this.callbackWindow);
        }
        // if (type == "isMobile") {
        //     document.head.insertAdjacentHTML("beforeend", `<style>head, body{height: auto !important;overflow-y: scroll !important;}</style>`)
        // } else {
        //     document.head.insertAdjacentHTML("beforeend", `<style>head, body{height: 100% !important;overflow-y: none !important;}</style>`)
        // }

    }

    setKeyboardVisible = (status) => {
        isKeyboardVisible = status
    }

    setLocalConfigWeb = () => {
        let objConfigWeb = {
            isOpenTrackLogId: globalVar.app.IS_SHOW_TRACK_LOG_ID,
            isOpenErrorBoundary: false,
        }
        let LocalConfigWeb = JSON.parse(localStorage.getItem('LocalConfigWeb'))
        if (!LocalConfigWeb) {
            localStorage.setItem('LocalConfigWeb', JSON.stringify(objConfigWeb))
        }
    }

    loadListApiAfterLogin = () => {
        this.props.fetchAllQuotes();
        this.props.fetchContactInfo();
    }

    loadListApiGuest = () => {
        this.props.fetchAllQuotes(); // Chi tiết mã có hiển thị thông tin cần quote
        this.props.fetchSymbolsListMapping();
        this.props.fetchContactInfo();
        userService.getClientConfig()
            .then((data) => {
                // this.props.setClientConfig(data);
                this.props.setCurrentDate(data.currdate);
            })
            .catch((error) => {
                // ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    storageVersionChanged = ({ key, oldValue, newValue }) => {
        if (key === "versionFs2pro") {
            console.debug("storageVersionChanged by key versionFs2pro");
            const isNewVersion = oldValue !== null && newValue !== null && newValue !== oldValue;
            if (isNewVersion) {
                console.debug("storageVersionChanged by checkVersion=", newValue);
                //show thông báo nâng cấp version, yêu cầu reload lại giao diện
                this._setState({ isOpenReloadBrowser: true, typeReloadBrowser: "updated" })
            }
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    waitingUpdateSystem = () => {
        this._setState({ isOpenReloadBrowser: true, typeReloadBrowser: "waitting_update" })
    }

    listenToTheEmitter = () => {
        emitter.on("waitingUpdateSystem", this.waitingUpdateSystem);
    }

    async componentDidMount() {
        this.mounted = true
        let self = this
        window.addEventListener('storage', this.storageVersionChanged);
        this.timerCheckVersion = setInterval(() => {
            self.checkVersion();
        }, TIMER_CHECK_VERSION)
        sessionStorage.setItem("sidSocket", "")
        // this.props.fetchSymbolsList({ isForce: true });
        this.props.fetchSymbolsList();
        this.props.fetchCorpbondList()
        this.setLocalConfigWeb();
        this.resize('', true); // cập nhật class cho Body
        let custodycd = '';
        if (globalVar.switch.enableSaveLoginAccount) {
            custodycd = this.getCustodycdInUrl();
            if (this.props.location.search) {
                const queryParams = queryString.parse(this.props.location.search);
                let NewAccount = queryParams['NewAccount'] ? queryParams['NewAccount'] : null;
                // if (NewAccount && this.headerRef.current) this.headerRef.current.loginHandler('');
                if (NewAccount) this.props.setLoginPopupOnstart(true, '');
            }
        }
        if (!this.props.appStarted && this.state.bootstrapped) {
            this.props.initializeApp(custodycd);
        };
        this._unsubscribe = this.props.persistor.subscribe(
            this.handlePersistorState
        );
        this.handlePersistorState();

        // Remove outline by default
        document.body.classList.add('remove-outline');

        // if (isIOS) {
        //     document.body.classList.add('ios');
        // }
        // If user press tab key we need to show outline
        // document.body.addEventListener('keydown', this.handleBodyKeyDown);
        // If user click we need to remove outline
        // document.body.addEventListener('click', this.handleBodyClick);
        // cache Data Action
        // Load AllQuotes
        let { isLoggedIn } = this.props
        if (isLoggedIn) {
            this.loadListApiAfterLogin()
        } else {
            this.loadListApiGuest()
        }
        // set BaseId
        let currentBaseID = getValueFromLocalStorage(keyFactory.baseid);
        if (!currentBaseID) {
            genBaseId();
        }
        // window.addEventListener("storage", (e) => {
        //     let currentLanguage = this.props.language
        //     let language = localStorage.getItem('language');
        //     if (language != currentLanguage) {
        //         this.props.changeLanguage(language);
        //     }
        // });
        if (!localStorage.getItem("watchlists")) {
            let items = [];
            localStorage.setItem("watchlists", JSON.stringify(items));
        }

        if (!localStorage.getItem("searchs")) {
            let items = {
                stocks: [],
                news: [],
                func: []
            };
            localStorage.setItem("searchs", JSON.stringify(items));
        }
        //Mở Popup chọn khách hàng (Bằng phím tắt)

        // document.addEventListener("keydown", this.handleKeyPress);

        // emitter.on(OPEN_LOGIN_POP_UP, (custodycd) => {
        //     this.headerRef.current && this.headerRef.current.loginHandler(custodycd);
        // });
        if (IS_CALL_API_BY_CONSOLE) {
            window.callApi = this
        }

        // Kiểm tra để mở modal và check app
        if (isMobile && window.location.pathname.includes(path.ACCOUNT_REGISTER)) {
            this.setState({ isOpenUseAppNotiModal: true });
        }
    }



    componentDidUpdate(prevProps, prevState) {

        let { isLoggedIn } = this.props
        if (prevProps.isLoggedIn !== isLoggedIn) {
            if (isLoggedIn) {
                this.loadListApiAfterLogin()
            } else {
                this.loadListApiGuest()
            }
        }
        // this.checkMenuActiveUrl()
        let url = window.location.href;
        let detectUrl = globalVar.switch.enableSaveLoginAccount ? 'access_token' : 'code';
        if (globalVar.app.IS_LOGIN_POPUP == true && url.indexOf(detectUrl) > -1) {
            if (globalVar.switch.enableSaveLoginAccount) {
                const queryParams = queryString.parse(this.props.location.search);
                let tokendata = {
                    access_token: queryParams['access_token'] ? queryParams['access_token'] : null,
                    refresh_token: queryParams['refresh_token'] ? queryParams['refresh_token'] : null,
                    expires_in: queryParams['expires_in'] ? queryParams['expires_in'] : null,
                    token_type: queryParams['token_type'] ? queryParams['token_type'] : null,
                    ui_locales: queryParams['language'] ? queryParams['language'] : 'vi',
                    ui_theme: queryParams['theme'] ? queryParams['theme'] : 'dark'
                }
                localStorage.setItem('LoginAccessTokenData', JSON.stringify(tokendata));
                if (window.parent) {
                    window.parent.location.href = globalVar.api.REDIRECT_URL;
                    // window.parent.location.href = 'https://trading.bsc.com.vn/websso';
                    // window.parent.location.href = ROUTER_BASE_NAME + 'websso';
                }
            } else {
                let code = url.split('code=').pop().split('&state')[0];
                localStorage.setItem('sso-code', code)
                let stateId = url.split('&state=')[1];
                localStorage.setItem('sso-stateId', stateId)
                if (window.parent) {
                    window.parent.location.href = globalVar.api.REDIRECT_URL;
                    // window.parent.location.href = 'https://trading.bsc.com.vn/websso';
                    // window.parent.location.href = ROUTER_BASE_NAME + 'websso';
                }
                // window.opener.location = ROUTER_BASE_NAME + 'sso'
                // window.close();
            }
        }
        else {
            if (globalVar.app.IS_LOGIN_POPUP == 'local' && url.indexOf(detectUrl) > -1) {
                if (globalVar.switch.enableSaveLoginAccount) {
                    const queryParams = queryString.parse(this.props.location.search);
                    let tokendata = {
                        access_token: queryParams['access_token'] ? queryParams['access_token'] : null,
                        refresh_token: queryParams['refresh_token'] ? queryParams['refresh_token'] : null,
                        expires_in: queryParams['expires_in'] ? queryParams['expires_in'] : null,
                        token_type: queryParams['token_type'] ? queryParams['token_type'] : null,
                        ui_locales: queryParams['language'] ? queryParams['language'] : 'vi',
                        ui_theme: queryParams['ui_theme'] ? queryParams['ui_theme'] : 'dark'
                    }
                    localStorage.setItem('LoginAccessTokenData', JSON.stringify(tokendata));
                    window.parent.location.href = globalVar.api.REDIRECT_URL;
                    // window.opener.location = ROUTER_BASE_NAME + 'websso';
                    // window.opener.location = 'https://trading.bsc.com.vn/websso';
                    window.close();
                } else {
                    let code = url.split('code=').pop().split('&state')[0];
                    localStorage.setItem('sso-code', code)
                    let stateId = url.split('&state=')[1];
                    localStorage.setItem('sso-stateId', stateId)
                    window.parent.location.href = globalVar.api.REDIRECT_URL;
                    // window.opener.location = ROUTER_BASE_NAME + 'websso'
                    // window.opener.location = 'https://trading.bsc.com.vn/websso';
                    window.close();
                }
            }
            else {
                if (prevState.bootstrapped !== this.state.bootstrapped) {
                    if (!this.props.appStarted && this.state.bootstrapped) {
                        let custodycd = globalVar.switch.enableSaveLoginAccount ? this.getCustodycdInUrl() : '';
                        this.props.initializeApp(custodycd);
                    }
                }

            }
        }
        if (prevProps.defaultTheme !== this.props.defaultTheme) {
            // if (this.props.defaultTheme === 'dark') document.body.className = 'fss theme-dark';
            // else document.body.className = 'fss theme-light'
            this.resize('', true);
        }
        if (this.props.widthMenuSideBar !== prevProps.widthMenuSideBar) {
            $('#menuSideBar').css('width', prevProps.widthMenuSideBar);
            // $('.footerPage').css('margin-left', prevProps.widthMenuSideBar)
        }
        // if (this.props.isDeskTop !== prevProps.isDeskTop) {
        //     window.location.reload();
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const props = this.props;
        const state = this.state;


        // if (props.widthMenuSideBar !== nextProps.widthMenuSideBar) {
        //     $('#menuSideBar').css('width', nextProps.widthMenuSideBar);
        //     $('.footerPage').css('margin-left', nextProps.widthMenuSideBar)
        // }
        return props.appStarted !== nextProps.appStarted
            || props.isLoggedIn !== nextProps.isLoggedIn
            // || props.loggedOutByExpired !== nextProps.loggedOutByExpired
            || props.isChangePinPassModalOpen !== nextProps.isChangePinPassModalOpen
            || props.isShowChangeCoreModal !== nextProps.isShowChangeCoreModal
            || props.onSymbolDidSelected !== nextProps.onSymbolDidSelected
            || state.bootstrapped !== nextState.bootstrapped
            || props.loggedOut !== nextProps.loggedOut
            || props.customerEvent !== nextProps.customerEvent
            || props.defaultTheme !== nextProps.defaultTheme
            || props.isOpenBrowerModal !== nextProps.isOpenBrowerModal
            || props.passwordEvent !== nextProps.passwordEvent
            || props.isOpenScreenModal !== nextProps.isOpenScreenModal
            || props.isOpenTradeMoneyBIDV !== nextProps.isOpenTradeMoneyBIDV
            || props.customerSelectorModalOpen !== props.customerSelectorModalOpen
            || props.isDeskTop !== nextProps.isDeskTop
            || props.isOpenPayment !== nextProps.isOpenPayment
            || props.isExpiredTokenErrorScreen !== nextProps.isExpiredTokenErrorScreen
            || state.isOpenReloadBrowser !== nextState.isOpenReloadBrowser
            || state.typeReloadBrowser !== nextState.typeReloadBrowser
            || state.isOpenUseAppNotiModal !== nextState.isOpenUseAppNotiModal
        // || props.widthMenuSideBar !== nextProps.widthMenuSideBar
        // || props.activeMenu !== nextProps.activeMenu
    }

    componentWillUnmount() {
        // document.body.removeEventListener('keydown', this.handleBodyKeyDown);
        // document.body.removeEventListener('keydown', this.handleKeyPress);
        // document.body.removeEventListener('click', this.handleBodyClick);
        // window.removeEventListener('scroll', this.callbackWindow);
        emitter.removeListener("waitingUpdateSystem", this.waitingUpdateSystem);
        window.removeEventListener('storage', this.storageVersionChanged);
        clearInterval(this.timerCheckVersion)
        this.timerCheckVersion = undefined;
        this._unsubscribe && this._unsubscribe();
        this.mounted = false
    }

    onLogout = () => {
        localStorage.setItem('persist:user', '');
        CommonUtils.setCookie("authInfo", null, '/', true, '');
        CommonUtils.setIsStep2Authenticated(null, '/', true, '');
        sessionStorage.setItem('activeCustID', ''); // Xóa thông tin custodycd hiện tại
        sessionStorage.setItem('isTradeAuthenticated', "false")

        history.push(path.LOG_OUT);
    };

    logoutByCustodycd = (custodycd) => {
        this.props.setLoginPopupOnstart(true, custodycd);
        this.props.logoutSingleUser(custodycd, true);
        let logoutCusCode = custodycd + "|" + Random.randomLogoutId();
        localStorage.setItem('LogoutCustodycd', logoutCusCode);
        sessionStorage.setItem('isTradeAuthenticated', "false")
        // history.push(path.PRICEBOARD);
    }

    onReloginTokenExpired = () => {
        let savedCustodycd = sessionStorage.getItem('activeCustID');
        let usersTokens = localStorage.getItem('token-users') ? JSON.parse(localStorage.getItem('token-users')) : {};
        this.props.setIsOpenExpiredTokenErrorScreen(false);
        if (savedCustodycd && usersTokens && !_.isEmpty(usersTokens)) {
            let dataCheck = { accessTokens: [] };
            dataCheck.accessTokens.push(usersTokens[`${savedCustodycd}`]['access_token']);
            authService.checkValidTokens(dataCheck).then(data => {
                // Nhấn login lại nếu accessToken hiện tại còn hiệu lực (với case login lại cùng trên một cửa sổ trình duyệt) ===> login lại sử dụng accesToken hiện tại
                if (data[`${savedCustodycd}`] === true) {
                    this.props.setIsLogout(false);// reset trạng thái login từ nơi khác
                    this.props.loginByTokenData(usersTokens[`${savedCustodycd}`]);
                } else {
                    // logout nếu hết hiệu lực
                    this.logoutByCustodycd(savedCustodycd);
                }
            }).catch(error => {
                console.log('App_checkValidTokens: Err:', error);
                this.logoutByCustodycd(savedCustodycd);
            }
            )
        } else {
            console.log('App_checkValidTokens: Err:', 'no Token or saved custodycd');
            this.logoutByCustodycd(savedCustodycd);
        }


    }


    TokenError = () => {
        return (
            <ErrorScreen
                messageId="common.session-timeout"
                btnMessageId="common.login-again"
                action={this.onLogout}
            />
        )
    }

    closeExpiredTokenError = () => {
        let savedCustodycd = sessionStorage.getItem('activeCustID');
        this.props.setIsOpenExpiredTokenErrorScreen(false)
        this.props.logoutSingleUser(savedCustodycd, true);
        let logoutCusCode = savedCustodycd + "|" + Random.randomLogoutId();
        localStorage.setItem('LogoutCustodycd', logoutCusCode);
        sessionStorage.setItem('isTradeAuthenticated', "false")
    }

    // handleKeyPress = (e) => {
    //     var keyCode = (e.which ? e.which : e.keyCode);
    //     if (keyCode == KeyCodeUtils.B && e.ctrlKey) {
    //         this.props.setIsOpenModalByKey(
    //             "customerSelectorModalOpen",
    //             !this.props.customerSelectorModalOpen
    //         )
    //     }
    // }
    resize = (e, isAddClass) => {
        let isDeskTop = CommonUtils.checkDesktop()
        if (isDeskTop !== this.props.isDeskTop || isAddClass) {
            this.setClassBody(isDeskTop)
            this.props.setIsDeskTop(isDeskTop)
        }
    }

    _getInstrumentsBySymbols = (symbols) => {
        if (symbols && symbols.length > 0) {
            symbolService.getInstrument(symbols).then(data => {
                let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
            })
        }
    }


    callHandleApiInstruments = (type, symbols) => {
        console.debug("callHandleApiInstruments.:instruments [START]")
        if (!type || type == 1) {
            symbolService.getSymbolInfoAll().then(data => {
                console.debug("callHandleApiInstruments.:instruments [res]")
                let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
            })
            return null;
        }
        if (type == 2) {
            symbolService.getSymbolInfo().then(data => {
                console.debug("callHandleApiInstruments.:instruments?brief=true [res]")
                let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
            })
            return null;
        }
        if (symbols && symbols.length > 0) {
            if (type == 3) {
                symbolService.getInstrument(symbols).then(data => {
                    console.debug("callHandleApiInstruments.:instruments?symbol=" + symbols + " [res]")
                    let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                    let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                    dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
                })
            }
            if (type == 4) {
                symbolService.getInstrumentByExchange(symbols).then(data => {
                    console.debug("callHandleApiInstruments.:instruments?exchange=" + symbols + " [res]")
                    let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                    let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                    dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
                })
            }
            return null;
        }
    }

    reloadByCheckVersion = () => {
        countRetryReload++
        if (countRetryReload <= COUNT_RETRY__RELOAD_MAX) {
            this.checkVersion();
            return null;
        }
        console.debug("App.:ActiveTab.:Có lỗi kiểm tra version. countRetryReload=", countRetryReload)
        return null;
        let text = "Có lỗi kiểm tra version. Vui lòng ấn OK để tải lại trang!";
        if (window.confirm(text) == true) {
            window.location.reload(true)
        }
    }

    async checkVersion() {
        if (isDevelopment) {
            return null;
        }
        try {
            let version = document.getElementById("versionFs2pro").value
            if (!version) {
                console.debug("App.:ActiveTab.: Image not contain REACT_APP_VERSION")
                return;
            }
            let newVersion = undefined
            let PUBLIC_URL = document.getElementById("PUBLIC_URL").value || ""
            await fetch(`${PUBLIC_URL}/checkVersion`).then(result => result.json()).then(res => {
                if (res && res.version) {
                    newVersion = res && res.version
                }
            }).catch(error => {
                console.debug("App.:ActiveTab.:Check newVersion=get.:checkVersion.:error=", error)
                this.reloadByCheckVersion()
                // throw (error);
                return null;
            })
            // var params = new URL(window.location.toString()).searchParams
            // var newVersion = params.get("ver");
            console.debug("App.:ActiveTab.:Check newVersion=", newVersion, version)
            if (newVersion) {
                if (newVersion !== version) {
                    console.debug("App.:ActiveTab.:Update newVersion & Reload by newVersion=", newVersion, version)
                    localStorage.setItem("versionFs2pro", newVersion);
                    //show thông báo nâng cấp version, yêu cầu reload lại giao diện
                    let self = this
                    let timer = setTimeout(function () {
                        self._setState({ isOpenReloadBrowser: true, typeReloadBrowser: "updated" });
                        clearTimeout(timer)
                    })
                }
            }
            else {
                console.debug("App.:ActiveTab.:Check newVersion=get.:checkVersion.:error.:newVersion=", newVersion)
                this.reloadByCheckVersion()
            }
        } catch (e) {
            console.debug("App.:ActiveTab.:Check newVersion=checkVersion():error=", e)
            this.reloadByCheckVersion()
        }
    }

    closePopupReloadBrowser = () => {
        this._setState({ isOpenReloadBrowser: false })
    }

    listentoWindow = isVisible => {
        // call api check version
        if (isVisible) {
            this.checkVersion();
        }
        if (IS_ACTIVE_TAB_RETRY_API) {
            // alert('active tab' + isVisible)
            // this._setState({
            //     isWindowInFocus: isVisible,
            // });
            let self = this
            self.props.setActiveTabBrowser(isVisible);
            // if (isVisible) {
            //     // load lại instruments BSCWEB-915
            //     if (0 == 0) {
            //         // reload theo all
            //         self.props.fetchSymbolsList({ isForce: true });
            //     } else {
            //         // reload theo listSymbolsW
            //         let { listSymbolsW } = this.props
            //         // window.location.reload(false)
            //         // reload api instruments
            //         let stocks = listSymbolsW
            //         //console.log('listentoWindow.: PageVisibility.:, listSymbolsW=', listSymbolsW)
            //         this._getInstrumentsBySymbols(stocks)
            //     }
            // }
        }
        return null
    }

    render() {
        //console.log('App.js.:render')
        let { bootstrapped, isOpenReloadBrowser, typeReloadBrowser } = this.state
        let { menuHeader, isLoggedIn, role, isOpenScreenModal, isOpenTradeMoneyBIDV,
            isDeskTop, isOpenPayment, isExpiredTokenErrorScreen, isShowChangeCoreModal, appStarted, loggedOut, loggedOutByExpired, customerEvent, passwordEvent
        } = this.props
        const isAccountRegisterPath = history.location.pathname.indexOf(path.ACCOUNT_REGISTER) > -1;
        // let { widthMenuSideBar } = this.state
        var messageid = "";
        let isCheckMoblieLogout = !isLoggedIn && !isDeskTop
        const logoutHandler = globalVar.switch.enableSaveLoginAccount ? this.onReloginTokenExpired : this.onLogout;
        loggedOut && (messageid = "common.logged-out-by-other")
        loggedOutByExpired && (messageid = "common.logged-out-by-session-expired")
        customerEvent && (messageid = "common.logged-out-by-customer-info-is-processing")
        passwordEvent && (messageid = "common.logged-out-by-password-info-is-processing")
        if (appStarted && bootstrapped) {
            const { isChangePinPassModalOpen, activeMenu } = this.props;
            return (
                <PageVisibility
                    onChange={this.listentoWindow}
                >

                    <DndProvider backend={HTML5Backend}>

                        {this.state.isOpenUseAppNotiModal &&
                            <UseAppNotiModal
                                isOpen={this.state.isOpenUseAppNotiModal}
                                toggle={this.checkOpenMobile}
                                onClose={this.checkOpenMobile}
                                onSubmit={this.checkOpenMobile}
                            />
                        }

                        {IS_SHOW_POPUP_UPDATE_VERSION && isOpenReloadBrowser && <ReloadBrowser
                            isOpen={isOpenReloadBrowser}
                            type={typeReloadBrowser}
                            checkVersion={this.checkVersion}
                            closePopup={this.closePopupReloadBrowser}
                        />}

                        {isChangePinPassModalOpen && <ChangePinPassModal
                            isOpen={isChangePinPassModalOpen}
                        />}
                        {
                            isShowChangeCoreModal && <ChangeCoreModal
                                isOpen={isShowChangeCoreModal}
                            />
                        }
                        {/*<ModalNotiConfigWeb />*/}
                        <AppMessageModal
                            isOpen={false}
                        />
                        <ConfirmModal
                            isOpen={true}
                        />
                        {/* <MenuTool /> */}
                        {
                            isOpenTradeMoneyBIDV &&
                            <TradingMoneyBIDV
                                isOpen={isOpenTradeMoneyBIDV}
                            />
                        }
                        {
                            isOpenPayment &&
                            <ModalPayment
                                isOpen={isOpenPayment}
                            />
                        }
                        {
                            isExpiredTokenErrorScreen && globalVar.switch.enableSaveLoginAccount &&
                            <ExpiredTokenErrorScreen
                                reloginHandler={this.onReloginTokenExpired}
                                closeAction={this.closeExpiredTokenError}
                                isOpen={isExpiredTokenErrorScreen}
                            />
                        }
                        <BrowserModal
                            isOpen={this.props.isOpenBrowerModal}
                            toggle={this.props.closeBrowserModal}
                            isDefaultBrowser={this.props.isDefaultBrowser}
                        />
                        {/* <AppTitle /> */}
                        {(globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') ? <SocketManager /> : (isLoggedIn && (<SocketManager />))}
                        {(globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') ? <CookieManager /> : (isLoggedIn && (<CookieManager />))}

                        {(globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') ? <CookieManager /> : (isLoggedIn && (<CookieManager />))}

                        <Router history={history}>
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                            <Suspense fallback={''}>
                                <div className={isDeskTop ? "main-container isDeskTop" : "main-container isMobile"}>
                                    <ElementResizeListener onResize={(e) => this.resize(e)} />
                                    {
                                        (this.props.loggedOutByExpired ?
                                            <Fragment>
                                                <ErrorBoundary>
                                                    {!globalVar.switch.enableSaveLoginAccount &&
                                                        (<ErrorScreen
                                                            messageId={messageid}
                                                            btnMessageId="common.login-again"
                                                            action={logoutHandler}
                                                        />)}
                                                    {/* :
                                                    (<ExpiredTokenErrorScreen
                                                        reloginHandler={this.onReloginTokenExpired}
                                                        closeAction={this.closeExpiredTokenError}
                                                    />)
                                                } */}
                                                </ErrorBoundary>
                                                <Route path={path.LOG_OUT} component={Logout} />
                                                <Route path={path.SSO} component={Sso} />
                                            </Fragment>
                                            :
                                            (this.props.loggedOut || this.props.customerEvent || this.props.passwordEvent) ?
                                                <Fragment>
                                                    <ErrorBoundary>
                                                        <ErrorScreen
                                                            messageId={messageid}
                                                            btnMessageId="common.login-again"
                                                            action={logoutHandler}
                                                        />
                                                    </ErrorBoundary>
                                                    <Route path={path.LOG_OUT} component={Logout} />
                                                    <Route path={path.SSO} component={Sso} />
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <ErrorBoundary>
                                                        {/* {isLoggedIn && <Header />} */}
                                                        {!isAccountRegisterPath &&
                                                            <Header
                                                                ref={this.headerRef}
                                                                setKeyboardVisible={this.setKeyboardVisible}
                                                            />
                                                        }
                                                        {isAccountRegisterPath &&
                                                            <HeaderEkyc />
                                                        }
                                                    </ErrorBoundary>
                                                    <ErrorBoundary>
                                                        <div className='bodyContainer'>
                                                            {!isAccountRegisterPath &&
                                                                <>
                                                                    <MenuTool />
                                                                </>
                                                            }
                                                            <div className={"content-container" + (isCheckMoblieLogout ? " content-container-top-moblie-logout" : "") + (isAccountRegisterPath ? " content-container-ekyc" : "")} style={{ paddingLeft: isOpenScreenModal ? '0px' : '0px' }}>
                                                                {isOpenScreenModal && <ScreenModal ></ScreenModal>}
                                                                {/* <BrowserRouter> */}
                                                                {/* <ProvideAuth> */}
                                                                {/* <Router> */}
                                                                <Switch>

                                                                    <PrivateRoute path={path.HOME} exact component={Home} isSkipPrivate={true} />
                                                                    {/*  */}
                                                                    <Route path={path.LOGIN} component={userIsNotAuthenticated(Login)} />
                                                                    <Route path={path.LOG_OUT} component={userIsAuthenticated(Logout)} />
                                                                    <Route path={path.SSO} component={Sso} />
                                                                    <Route path={path.CONFIG} component={ConfigWeb} />
                                                                    {/* <Route path={path.ACCOUNT_REGISTER} component={userIsNotAuthenticated(AccountRegisterEkyc)} /> */}
                                                                    <Route path={path.ACCOUNT_REGISTER} component={AccountRegisterEkyc} />
                                                                    {/*  */}
                                                                    <PrivateRoute path={path.PRICEBOARD} component={PriceBoard} isSkipPrivate={true} />
                                                                    {/* <Route path={path.PRICEBOARD} component={userIsNotAuthenticated() ? Trade : ''} /> */}
                                                                    {/*  */}
                                                                    {/* <Route path={path.PROTRADE} component={this.userIsAuthenticated(Trade) ?Trade: 'hehe'} /> */}
                                                                    {/*<PrivateRoute path={path.PUTTHROUGHTRADE} component={Trade} />*/}
                                                                    {/* isSkipPrivate : Bỏ qua việc check Private đối với các layout Widget */}
                                                                    <PrivateRoute path={path.MARKET_WATCH} component={MarketWatch} isSkipPrivate={true} />
                                                                    <PrivateRoute path={path.AI_TRADING} component={AiTrading} />
                                                                    <PrivateRoute path={path.SYMBOL_DETAIL} component={DetailPage} isSkipPrivate={true} />

                                                                    <PrivateRoute path={path.TRADE} component={Trade} />
                                                                    <PrivateRoute path={path.TPRLTRADE_NM} component={TPRLTradeNM} />
                                                                    <PrivateRoute path={path.TPRLTRADE_OR} component={TPRLTradeOR} />
                                                                    <PrivateRoute path={path.TPRLTRADE_RP} component={TPRLTradeRP} />
                                                                    {/*<PrivateRoute path={path.PROTRADE} component={Trade} />*/}
                                                                    <PrivateRoute path={path.CUSTOMETRADE} component={Trade} />
                                                                    <PrivateRoute path={path.ASSETS} component={AssetTotalLayout} />
                                                                    <Route path={path.VERSION} component={Version} />
                                                                    {/*<PrivateRoute path={path.CUSTOMER_ORGANZITION} component={CustomerOrganzition} />*/}
                                                                    {/* <PrivateRoute path={path.IBROKER} component={iBroker} /> */}
                                                                    <PrivateRoute path={path.SENTRY_TEST} component={sentryTest} isSkipPrivate={true} />
                                                                    {/*  */}
                                                                    <PrivateRoute path={path.ERROR} component={this.TokenError} isSkipPrivate={true} />
                                                                    <PrivateRoute component={NotFound} isSkipPrivate={true} />
                                                                </Switch>
                                                                {/* </Router> */}
                                                                {/* </ProvideAuth> */}
                                                                {/* </BrowserRouter> */}
                                                                {/* <Switch>
                                                                <Route path={path.RECOMMEND_CUSTOMER} component={userIsNotAuthenticated(RecommendCustomer)} />
                                                                <Route path={path.HOME} exact component={Home} />
                                                                <Route path={path.LOGIN} component={userIsNotAuthenticated(Login)} />
                                                                <Route path={path.LOG_OUT} component={userIsAuthenticated(Logout)} />
                                                                <Route path={path.SSO} component={Sso} />
                                                                <Route path={path.TRADE} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Trade} />)} />
                                                                <Route path={path.PROTRADE} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Trade} />)} />
                                                                <Route path={path.CUSTOMETRADE} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Trade} />)} />

                                                                <Route path={path.ACCOUNT} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Account} />)} />
                                                                <Route path={path.FDS_ACCOUNT} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Account} />)} />
                                                                <Route path={path.SETTINGS} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Settings} />)} />
                                                                <Route path={path.PRICEBOARD} component={() => <AsyncComponent moduleProvider={PriceBoard} />} />


                                                                <Route path={path.HOME} component={userIsAuthenticated(() => <AsyncComponent moduleProvider={Sidebar} />)} />
                                                                <Route path={path.HOME} exact component={userIsAuthenticated(Home)} />
                                                                <Route path={path.ACCOUNT_REGISTER} component={userIsNotAuthenticated(AccountRegister)} />
                                                                <Route path={path.ERROR} component={this.TokenError} />
                                                                <Route component={NotFound} />
                                                            </Switch> */}
                                                                {/* <div className='popupFooter' style={{ width: widthMenuSideBar }}>
                                                        <PopupFooterPage style={{ width: widthMenuSideBar }} />
                                                    </div>
                                                    
                                                    <FooterPage style={{ left: widthMenuSideBar + ' !important' }} /> */}


                                                            </div>

                                                            {/* <Footer /> */}
                                                            {/* <div style={{ width: "100%", height: "36px", background: "red", position: "fixed", bottom: "2px", zIndex: 10000 }}>aaaaaaa</div> */}

                                                        </div>
                                                        {/* <screenMode></screenMode> */}
                                                    </ErrorBoundary>
                                                    {/* {isLoggedIn && <Header  />} */}
                                                    {/* <Header /> */}

                                                    <ErrorBoundary>
                                                        <ToastContainer
                                                            className="toast-container" toastClassName="toast-item" bodyClassName="toast-item-body"
                                                            autoClose={false} hideProgressBar={true} pauseOnHover={false}
                                                            pauseOnFocusLoss={true} closeOnClick={false} draggable={false}
                                                            closeButton={<CustomToastCloseButton />}
                                                        />
                                                    </ErrorBoundary>
                                                </Fragment>
                                        )
                                    }
                                </div>
                            </Suspense>
                        </Router>
                    </DndProvider >
                </PageVisibility>
            );
        } else {
            return (
                <div className="app-loading">
                    <GridLoading />
                </div>
            );
        }

    }
}

const mapStateToProps = state => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    const activeMenu = (state.user.userInfo && state.user.userInfo.menuHeader) && state.user.userInfo.menuHeader.activeMenuHeader
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
    const isOpenTradeMoneyBIDV = state.layout.isOpenTradeMoneyBIDV
    const isOpenPayment = state.layout.isOpenPayment
    let isDeskTop = state.app.isDeskTop
    // let listSymbolsW = state.symbol.listSymbolsW || []
    return {
        isDeskTop: isDeskTop,
        appStarted: state.app.started,
        isLoggedIn: state.user.isLoggedIn,
        isChangePinPassModalOpen: state.app.isChangePinPassModalOpen,
        isShowChangeCoreModal: state.app.isShowChangeCoreModal,
        pathname: state.router.location.pathname,
        loggedOut: state.app.loggedOut,
        customerEvent: state.customer.customerEvent,
        defaultTheme: defaultTheme,
        activeMenu: activeMenu,
        menuHeader: menuHeader,
        widthMenuSideBar: widthMenuSideBar,
        isOpenBrowerModal: state.app.isOpenBrowerModal,
        isDefaultBrowser: state.app.isDefaultBrowser,
        passwordEvent: state.customer.passwordEvent,
        location: state.router.location,
        role: state.user.userInfo.role,
        loggedOutByExpired: state.app.loggedOutByExpired,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        isOpenTradeMoneyBIDV: isOpenTradeMoneyBIDV,
        isOpenPayment: isOpenPayment,
        isExpiredTokenErrorScreen: state.layout.isExpiredTokenErrorScreen,
        customerSelectorModalOpen: state.layout.listIsOpenModal["C&B"].customerSelectorModalOpen,
        // listSymbolsW: listSymbolsW
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initializeApp: (custodycd) => dispatch(actions.initializeApp(custodycd)),
        logout: () => dispatch(actions.logout()),
        closeBrowserModal: () => dispatch(actions.closeBrowserModal()),
        fetchAllQuotes: () => dispatch(actions.fetchAllQuotes()),
        fetchSymbolsListMapping: () => dispatch(actions.fetchSymbolsListMapping()),
        fetchContactInfo: () => dispatch(actions.fetchContactInfo()),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),

        // onChangeActiveURLSideBar: (activeURL) => dispatch(actions.onChangeActiveURLSideBar(activeURL)),
        // onChangeMenuActiveSideBar: (keyName) => dispatch(actions.onChangeMenuActiveSideBar(keyName)),
        // onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),
        changeLayoutPageActive: (currentTabActive) => dispatch(actions.changeLayoutPageActive(currentTabActive)),
        authorizationSuccess: (data) => dispatch(actions.authorizationSuccess(data)),
        logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
        setLoginPopupOnstart: (isOpen, custodycd) => dispatch(actions.setLoginPopupOnstart(isOpen, custodycd)),
        setIsOpenModalByKey: (key, status) => dispatch(actions.setIsOpenModalByKey(key, status)),
        setIsDeskTop: (isDeskTop) => dispatch(actions.setIsDeskTop(isDeskTop)),
        setIsOpenExpiredTokenErrorScreen: (isOpen) => dispatch(actions.setIsOpenExpiredTokenErrorScreen(isOpen)),
        fetchSymbolsList: (obj) => dispatch(actions.fetchSymbolsList(obj)),
        fetchCorpbondList: (obj) => dispatch(actions.fetchCorpbondList(obj)),
        setClientConfig: (data) => dispatch(actions.setClientConfig(data)),
        setCurrentDate: (currdate) => dispatch(actions.setCurrentDate(currdate)),

        setActiveTabBrowser: (isActiveTabBrowser) => dispatch(actions.setActiveTabBrowser(isActiveTabBrowser)),
        loginByTokenData: (tokenData) => dispatch(actions.loginByTokenData(tokenData)),
        setIsLogout: (isLogout) => dispatch(actions.setIsLogout(isLogout)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { Component } from 'react'
import { push } from 'connected-react-router'
// import {
//     FaTimes
// } from "react-icons/fa";
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import * as actions from "../../store/actions";

import './LoginModal.scss'

const MGS_POST_CLOSE_MODAL = 'MGS_POST_CLOSE_MODAL'
const MGS_POST_CHANGE_LANGUAGE = 'MGS_POST_CHANGE_LANGUAGE'

class LoginModal extends Component {
    state = {

    }
    callbackWindow = (e) => {
        var data = e.data;

        if (data === '') {
            console.info('Listening...');
        }

        if (data) {
            const { action, dataMsg } = data;

            if (action === MGS_POST_CLOSE_MODAL) {
                this.onCloseModalLogin()
            }

            if (action === MGS_POST_CHANGE_LANGUAGE) {
                this.changeLang(dataMsg);  // dataMsg : (vi , en , kr, ...) truyền từ sso xuống
            }

        }
    }
    componentDidMount() {
        window.addEventListener("message", this.callbackWindow);
    }
    componentWillUnmount() { 
        window.removeEventListener("message", this.callbackWindow);
    }
    changeLang = (lang) => {
        // const { language } = this.props;
        // let nextLang = language === 'vi' ? "en" : "vi";
        lang && this.props.changeLanguage(lang);

    }

    onCloseModalLogin = () => {
        this.props.onCloseModalLogin()
    }

    render() {
        let { isOpenModalLogin, urlSSO } = this.props
        return (
            <Modal isOpen={isOpenModalLogin} className='modal-login' centered={true}>
                {/* <div className="btn-close" onClick={this.onCloseModalLogin}>
                    <FaTimes />
                </div> */}
                <div className="modal-login-content">
                    <iframe
                        id='popup-sso'
                        width="100%" height="100%"
                        src={urlSSO}>
                    </iframe>
                </div>
            </Modal >
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.app.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        changeLanguage: (language) => dispatch(actions.changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
import React, { Component } from 'react';
import RetryableContent from 'components/RetryableContent';
import { LoadStatus } from 'utils';
//import samplePDF from './sample.pdf';

import './PDFviewer.scss';
class PDFviewer extends Component {
    constructor(props) {
        super(props);
    }

    initialState = {
        loadStatus: LoadStatus.NONE,
    }
    state = {
        ...this.initialState
    }

    render() {
        let { src, loadStatusPDF } = this.props
        const combinedStatus = loadStatusPDF ? loadStatusPDF : LoadStatus.LOADED;
        return (
            <RetryableContent className="pdf-retry-table" status={combinedStatus} disableRetry={true}>
                <div className="pdf-viewer">
                    {
                        src ? <iframe src={`${src}#toolbar=0`} frameBorder="0" width='100%' height='100%'></iframe> : <iframe src={`${src}#toolbar=0`} frameBorder="0" width='100%' height='100%'></iframe>
                    }
                </div>
            </RetryableContent>
        );
    }

}



export default PDFviewer;

import React, { Component } from 'react';
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { KeyCodeUtils } from "../utils";

import './AppMessageModal.scss';

class AppMessageModal extends Component {

    initialState = {
    };

    state = {
        ...this.initialState
    };


    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handlerKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }

    }

    onConfirm = () => {
        window.location.reload();
    };

    render() {
        const { isOpen, isShowAppMessageModal } = this.props;
        return (
            <Modal isOpen={isOpen || isShowAppMessageModal} className='layout-modal' centered={true}>
                <div className="modal-header">
                    <div className="modal-title app-message-modal-title">
                        <FormattedMessage id="common.app-mesage-modal-title" />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="app-message-modal">
                        <div className="row gutters-5">

                            <div className="col-12">
                                <div className="utilities-form">
                                    <div className="row gutters-5">

                                        <div className="col-12">
                                            <span className="text-center">
                                                {<FormattedMessage id="common.account-info-in-processing" />}
                                            </span>

                                        </div>

                                        <div className="col-12">
                                            <div className="btn-container text-center">
                                                <button ref={this.confirmBtnRef} className="btn-ok" onClick={this.onConfirm}>
                                                    <FormattedMessage id="common.btn-ok" />
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal >
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path))
    };
};
const mapStateToProps = state => {
    const accountEvent = state.customer.accountEvent
    const isShowAppMessageModal = accountEvent != null
    return {
        accountEvent: accountEvent,
        isShowAppMessageModal: isShowAppMessageModal,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppMessageModal);

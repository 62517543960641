import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import moment from 'moment';
import { ReactComponent as NotiIconDark } from '../../assets/icons/header/NotiIcon_Dark.svg';
import { ReactComponent as NotiIcon_Dark_Basic } from '../../assets/icons/header/NotiIcon_Dark_Basic.svg';
import { ReactComponent as NotiIconActive } from '../../assets/icons/header/NotiIconActive.svg';

import { symbolService, notifyService } from "../../services";
import AccountNotifications from "./Notification/AccountNotifications";
import * as actions from "../../store/actions";
import { CommonWidgetUtils, Role, ToastUtil, accountNotifyType, LoadStatus } from "../../utils";
import _ from "lodash";

import './NotificationDropdownNew.scss';
import CustomScrollbars from 'components/CustomScrollbars';
import RetryableContent from 'components/RetryableContent';
import { Fragment } from 'react';
import AnalyticNotificationsNew from './AnalyticNotificationsNew';

const numberRowDefault = 5

const CONTENT_SORT_LENGTH = 100 // Nội dung hiển thị trước của khuyến nghị và khác


const VIEW_MODE = {
    MAIN_SCREEN: 1,
    GROUP_SCREEN: 2,
    DETAIL_SCREEN: 3
}

class NotificationDropdownNew extends Component {

    state = {
        isShowSetting: false,
        isOpenDetailNew: false,
        accountInfo: null,
        userTokens: "{}",
        tabActive: 1,
        stocks: [],
        checkInput: false,
        userInput: "",
        localStocks: [],
        func: [],
        localFunc: [],
        news: [],
        paramNew: {},
        localNews: [],
        accountmessages: [], //data Tab giao dịch

        transaction: [],
        recommendations: [],
        warning: [],
        other: [],
        fetchAccountNotiResult: null,
        unreadMessageNum: 0,
        unreadTransactionMessage: 0, // Số tin giao dịch
        unreadWarningMessage: 0, // Số tin cảnh báo
        confirm_orders_infor_length: 0,

        nextIndexNoti: 0,
        loadStatus: LoadStatus.NONE,
        selectedAnalyticNew: null,

        analyticScreenMode: VIEW_MODE.MAIN_SCREEN,
    };

    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    loadAllData = () => {
        this._setState({ nextIndexNoti: 0 })
        const { language, userInfo, isLoggedIn, currentCustomer, accountInfo } = this.props;
        const { tabActive } = this.state;
        const isBroker = this.isBroker();
        let isFirstLoad = true
        if (tabActive === 1) {
            if (isLoggedIn) {
                if (userInfo && !isBroker) {
                    let custid = currentCustomer ? currentCustomer.custid : userInfo.custid;
                    notifyService.getAllCodeMessage()   //lấy dữ liệu allcode để dịch trong message thông điệp
                        .then((data) => {
                            this.allCodeMessage = data;
                            this.getAccountMessageData(isFirstLoad);
                        })
                        .catch((error) => {
                            //console.log("Cannot get allcode messsage")
                            this.getAccountMessageData(isFirstLoad);
                        });

                    if (accountInfo && accountInfo.accounts && accountInfo.accounts.length > 0) {
                        accountInfo.accounts.forEach(acc => {
                            this.props.getConfirmOrdersInfor(acc.id);   //lấy dữ liệu số lệnh cần xác nhận

                        });
                    }
                }
            }
            else {
                this._setState({ accountmessages: [], unreadTransactionMessage: 0 })
            }
        }
        else if (tabActive === 2) {
            this.getNotiAnalyticRecommedDiff()
        }
        else if (tabActive === 3) {
            if (isLoggedIn) {
                if (userInfo && !isBroker) {
                    this.getAccountMessageData(isFirstLoad);
                }
            }
            else {
                this._setState({ warning: [], unreadWarningMessage: 0 })
            }
        }
        else if (tabActive === 4) {
            this.getNotiAnalyticRecommedDiff()
        }
    };

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        //Với môi giới active tab khuyến nghị khi mở noti
        if (this.isBroker()) {
            this._setState({
                tabActive: 2
            }, () => this.loadAllData())
        }
        else {
            this.loadAllData();
        }
        //this.loadAllData();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.mounted = false
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.clearInput();
            //Với môi giới active tab khuyến nghị khi mở noti
            this._setState({ isShowSetting: false, tabActive: this.isBroker() ? 2 : 1 })
        }
    }

    toggleQuickSetting = () => {
        this._setState({ isShowSetting: !this.state.isShowSetting, nextIndexNoti: 0 })
    }

    toggleModalDetailNew = () => {
        this._setState({ isOpenDetailNew: !this.state.isOpenDetailNew })
    }

    handleCheck(val) {
        return this.state.data.some(item => val.name === item.name);
    }

    clearInput = () => {
        this._setState({ checkInput: false, userInput: "" })
    }

    setAllMessAsRead = () => {
        const { accountmessages } = this.state
        const { currentCustomer, userInfo } = this.props;
        if (accountmessages && accountmessages.length > 0) {
            let custid = currentCustomer ? currentCustomer.custid : userInfo.custid;
            // let transactionTemplateId = accountmessages.map(item => { return item.templateid });
            // transactionTemplateId = _.uniq(transactionTemplateId);
            // notifyService.markAsReadByTemplateId(transactionTemplateId.join(','))
            notifyService.markAsReadMessage()
                .then((data) => {
                    //console.log("Set message as read success");
                    this.getUnreadMessageNum(custid)
                })
                .catch((error) => {
                    //console.log("Cannot set message as read")
                });
        }
    };


    refreshDetailPage = () => {
        this.setState({
            selectedAnalyticNew: null,
            analyticScreenMode: VIEW_MODE.MAIN_SCREEN,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoggedIn, accountNotifyCounter, confirm_orders_infor } = this.props;
        const { isLoggedIn: previsLoggedIn, accountNotifyCounter: prevAccountNotifyCounter, confirm_orders_infor: prev_confirm_orders_infor } = prevProps;
        const { isShowSetting, tabActive } = this.state;
        const { isShowSetting: prevIsShowSetting, tabActive: prevTabActive } = prevState;

        if (tabActive !== prevTabActive) {
            this.refreshDetailPage();
        }

        if (accountNotifyCounter !== prevAccountNotifyCounter) {
            this.loadAllData();
        }

        if (this.props.analyticAction !== prevProps.analyticAction) {
            this.getNotiAnalyticRecommedDiff({ isFirstLoad: true });
        }

        if (isShowSetting !== prevIsShowSetting && isShowSetting) {
            this.loadAllData();
        }

        if (isLoggedIn !== previsLoggedIn) {
            this.loadAllData();
        }

        if (confirm_orders_infor !== prev_confirm_orders_infor) {
            let confirm_orders_infor_length = confirm_orders_infor ? Object.values(this.props.confirm_orders_infor).length : 0
            this._setState({ confirm_orders_infor_length })
        };

        // Đóng thông báo ====> clear số lượng tin mới
        if (isShowSetting !== prevIsShowSetting && !isShowSetting) {
            this.setAllMessAsRead();
        }


    }


    onChangeTabActive = (tab) => {
        this._setState({ tabActive: tab, nextIndexNoti: 0 }, () => {
            this.loadAllData()
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    genderIconHeader = () => {
        const { isShowSetting, unreadWarningMessage, unreadTransactionMessage, confirm_orders_infor_length } = this.state;
        // let sumUnread = unreadWarningMessage + unreadTransactionMessage + confirm_orders_infor_length;
        let sumUnread = unreadWarningMessage + unreadTransactionMessage;
        //console.log("check total noti --- : ", sumUnread)
        if (sumUnread > 0) {
            if (isShowSetting) {
                return <NotiIconActive />
            } else {
                if (this.props.defaultTheme === 'dark') {
                    return <NotiIconDark />
                } else {
                    return <NotiIconDark />
                }
            }
        } else {
            if (isShowSetting) {
                return <NotiIconActive />
            } else {
                if (this.props.defaultTheme === 'dark') {
                    return <NotiIcon_Dark_Basic />
                } else {
                    return <NotiIcon_Dark_Basic />
                }
            }
        }

        // return this.props.defaultTheme === 'dark' ? this.state.isShowSetting ? <NotiIconActive /> : <NotiIconDark /> : this.state.isShowSetting ? <NotiIconActive /> : < NotiIconLight />
    }

    getAccountMessageData = (isFirstLoad, obj = {}) => {
        const { userInfo, accountInfo, currentCustomer } = this.props;

        const { nextIndexNoti, accountmessages, tabActive, warning } = this.state
        // let isLoadMore = false
        let { isLoadMore } = obj
        // let isLoadMore = false
        let custid = currentCustomer ? currentCustomer.custid : userInfo.custid;
        const isBroker = this.isBroker();
        if (isBroker && !currentCustomer) return;
        //message cho tài khoản
        // isAlert = false cho tab giao dịch, = true cho tab cảnh báo
        let isAlert = false
        if (tabActive == 3) {
            isAlert = true
        }

        let body = {
            custid,
            limitRow: numberRowDefault,
            index: nextIndexNoti,
            isAlert
        }

        this._setState({ loadStatus: LoadStatus.LOADING })

        if (tabActive == 1) { // Tab giao dịch
            if (isFirstLoad) {
                this._setState({ accountmessages: [] })
            }
            notifyService.getAccountNotifyMessage(body)
                .then((res) => {
                    if (res && res.d && res.d.length > 0) {
                        let _accountmessages = res.d
                        if (isLoadMore) {
                            _accountmessages = accountmessages.concat(res.d)
                        }
                        this._setState({ accountmessages: _accountmessages, nextIndexNoti: res.nextIndex })
                    }
                    this._setState({
                        fetchAccountNotiResult: true,
                        loadStatus: LoadStatus.LOADED
                    });
                    this.getUnreadMessageNum(custid);
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-notification-list');
                    this._setState({
                        fetchAccountNotiResult: false,
                        loadStatus: LoadStatus.FAIL
                    });
                });
        }


        if (tabActive == 3) { // TAb cảnh báo
            if (isFirstLoad) {
                this._setState({ warning: [] })
            }
            notifyService.getAccountNotifyMessage(body)
                .then((res) => {
                    if (res && res.d && res.d.length > 0) {
                        let data = res.d
                        let realData = data.filter(item => item.templateid == "1001")

                        if (isLoadMore) {
                            realData = warning.concat(realData)
                        }
                        //console.log("binh_realData", realData)
                        this._setState({ warning: realData, nextIndexNoti: res.nextIndex })
                    }
                    this._setState({
                        fetchAccountNotiResult: true,
                        loadStatus: LoadStatus.LOADED
                    });
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-notification-list');
                    this._setState({
                        fetchAccountNotiResult: false,
                        loadStatus: LoadStatus.FAIL
                    });
                });
        }

    }

    extractContent(html) {
        return new DOMParser()
            .parseFromString(html, "text/html")
            .documentElement.textContent;
    }

    convertRecommend = (data) => {
        if (data && data.length > 0) {
            data.forEach(item => {
                let extracContent = item.content ? this.extractContent(item.content) : '';
                if (extracContent && extracContent.length > CONTENT_SORT_LENGTH) {
                    item.contentShort = extracContent.substring(0, CONTENT_SORT_LENGTH);
                } else {
                    item.contentShort = extracContent;
                }
            })
        }
        return data;
    }


    getNotiAnalyticRecommedDiff = (obj = {}) => {
        const { updateAgreementOrderNotifyList, userInfo, currentCustomer, isLoggedIn } = this.props;
        const { nextIndexNoti, accountmessages, tabActive, warning, recommendations, other } = this.state
        // let isLoadMore = false
        let { isLoadMore, isFirstLoad } = obj
        // let isLoadMore = false
        let custid = currentCustomer ? currentCustomer.custid : userInfo.custid;


        // reportidNoti = 1 cho tab khuyến nghị, = 2 cho tab khác
        let reportidNoti = 1
        if (tabActive == 4) {
            reportidNoti = 2
        }
        let body = {
            limitRow: numberRowDefault,
            index: nextIndexNoti,
            reportid: reportidNoti
        }
        const isBroker = this.isBroker();

        if (isFirstLoad && isFirstLoad === true) {
            this._setState({ recommendations: [], nextIndexNoti: 0 });
            body = {
                limitRow: numberRowDefault,
                index: 0,
                reportid: reportidNoti
            }
        }
        this._setState({ loadStatus: LoadStatus.LOADING })
        if (tabActive == 2) { // Tab Khuyến nghị
            if (isLoggedIn && !isBroker) {         //off tạm tránh bị thoát đăng nhập
                // if (false) {

                symbolService.getNotiAnalytic(body)
                    .then((res) => {
                        if (res && res.d && res.d.length > 0) {
                            let data = this.convertRecommend(res.d);
                            if (isLoadMore) {
                                data = recommendations.concat(data)
                            }
                            this._setState({ recommendations: data, nextIndexNoti: res.nextIndex });
                        } else {
                            this._setState({ recommendations: [], nextIndexNoti: 0 });
                        }
                        this._setState({ loadStatus: LoadStatus.LOADED });
                    }).catch(error => {
                        this._setState({ recommendations: [], loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    });
            } else {
                symbolService.getNotiPublicAnalytic(body)
                    .then((res) => {
                        if (res && res.d && res.d.length > 0) {
                            let data = this.convertRecommend(res.d);
                            if (isLoadMore) {
                                data = recommendations.concat(data)
                            }
                            this._setState({ recommendations: data, nextIndexNoti: res.nextIndex });
                        } else {
                            this._setState({ recommendations: [], nextIndexNoti: 0 });
                        }
                        this._setState({ loadStatus: LoadStatus.LOADED });
                    }).catch(error => {
                        this._setState({ recommendations: [], loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    });
            }

        }


        if (tabActive == 4) { // Tab Khác
            if (isLoggedIn && !isBroker) {         //off tạm tránh bị thoát đăng nhập
                // if (false) {
                symbolService.getNotiAnalytic(body)
                    .then((res) => {
                        if (res && res.d && res.d.length > 0) {
                            let data = this.convertRecommend(res.d);
                            if (isLoadMore) {
                                data = other.concat(data)
                            }
                            this._setState({ other: data, nextIndexNoti: res.nextIndex });
                        } else {
                            this._setState({ other: [], nextIndexNoti: 0 });
                        }
                        this._setState({ loadStatus: LoadStatus.LOADED });
                    }).catch(error => {
                        this._setState({ other: [], loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    });
            } else {
                symbolService.getNotiPublicAnalytic(body)
                    .then((res) => {
                        if (res && res.d && res.d.length > 0) {
                            let data = this.convertRecommend(res.d);
                            if (isLoadMore) {
                                data = other.concat(data)
                            }
                            this._setState({ other: data, nextIndexNoti: res.nextIndex });
                        } else {
                            this._setState({ other: [], nextIndexNoti: 0 });
                        }
                        this._setState({ loadStatus: LoadStatus.LOADED });
                    }).catch(error => {
                        this._setState({ other: [], loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    });
            }

        }
    }

    getUnreadMessageNum = (custid) => {
        const { accountmessages } = this.state;
        if (accountmessages && accountmessages.length > 0) {
            let transactionMessages = accountmessages.filter(item => { return item.templateid !== "1001" });
            let warningMessages = accountmessages.filter(item => { return item.templateid === "1001" });
            let transactionTemplateId = transactionMessages.map(item => { return item.templateid });
            transactionTemplateId = _.uniq(transactionTemplateId);

            notifyService.getUnreadMsgNumberByTemplateId(custid, '1001')
                .then(data => {
                    this._setState({
                        unreadWarningMessage: parseFloat(data['unread']['1001']) // Số tin cảnh báo
                    })
                }).catch(err => { });

            notifyService.getUnreadMsgNumber(custid, transactionTemplateId.join(','))
                .then(data => {
                    // let unread = data['unread'];
                    // let unreadNumb = 0;
                    // Object.keys(unread).forEach(key => {
                    //     unreadNumb += parseFloat(unread[`${key}`]);
                    // })
                    this._setState({ unreadTransactionMessage: data && data.unreadMsgNumber ? data.unreadMsgNumber : 0 }); // Số tin giao dịch
                }).catch(err => { });

        }
    };

    getUnreadMsgNumberBroker = (custid) => {
        notifyService.getUnreadMsgNumberBroker(custid)
            .then(data => {
                let unreadMessageBroker = data ? data.unreadMsgNumber : 0
                this._setState({ unreadMessageNumBroker: unreadMessageBroker })
            })
            .catch(err => { })
    };

    deleteAllNotify = () => {
        const { currentCustomer, userInfo } = this.props;
        let custid = currentCustomer ? currentCustomer.custid : userInfo.custid;
        notifyService.deleteNotify()
            .then(() => this.getAccountMessageData(true))
            .catch(err => console.log(err));
    }

    onAnalyticNewsClick = (message) => {
        this._setState({ analyticScreenMode: VIEW_MODE.DETAIL_SCREEN, selectedAnalyticNew: message });
    }

    onAnalyticBackClick = () => {
        if (this.state.analyticScreenMode !== VIEW_MODE.MAIN_SCREEN) {
            this._setState({ analyticScreenMode: VIEW_MODE.MAIN_SCREEN });
        }
    }

    render() {
        const { className, confirm_orders_infor, lang, intl, accountInfo, } = this.props;
        const { isShowSetting, tabActive, stocks, checkInput, userInput, localStocks, func, localFunc, localNews, fetchAccountNotiResult,
            unreadTransactionMessage, unreadWarningMessage, confirm_orders_infor_length, accountmessages, nextIndexNoti, warning, loadStatus, selectedAnalyticNew, analyticScreenMode } = this.state;
        //console.log("data---1: ", isShowSetting);
        var confirm_orders = confirm_orders_infor && Object.values(confirm_orders_infor).sort()
        confirm_orders && confirm_orders.forEach(item => {
            item.template = {
                "templateid": "401A",
                "subject_vi": intl.formatMessage({ id: "common.notifications.subject_401A" }),
                "subject_en": intl.formatMessage({ id: "common.notifications.subject_401A" }),
                "msgcontent_vi": intl.formatMessage({ id: "common.notifications.messagecontent_401A" }),
                "msgcontent_en": intl.formatMessage({ id: "common.notifications.messagecontent_401A" }),
                "note": null
            }
        })
        // let unReadFortransactionTab = unreadTransactionMessage + confirm_orders_infor_length;
        let unReadFortransactionTab = unreadTransactionMessage;
        return (
            <div className="notification-dropdown txt---500-14-20" ref={this.setWrapperRef}>
                <button id="btn-setting-id" className={className} onClick={this.toggleQuickSetting}>
                    {this.genderIconHeader()}
                </button>
                {/* <div className="notification-container" hidden={isShowSetting ? '' : 'hidden'}> */}
                {isShowSetting &&
                    <RetryableContent status={loadStatus} disableRetry={true} className="notification-container">
                        {/*<div className='notification-container'>*/}
                        <Fragment>
                            <div className='headB---700-20-30'> <FormattedMessage id="common.noti" /></div>
                            <div className='container-button'>
                                {!this.isBroker() && (
                                    <div className='number-display'>
                                        {unReadFortransactionTab ? (<div className='transaction-number'>{unReadFortransactionTab}</div>) : ''}

                                        <button
                                            onClick={() => this.onChangeTabActive(1)}
                                            className={'btn-custom' + (tabActive === 1 ? ' active' : '')}>
                                            <FormattedMessage id="common.transaction" />
                                        </button>

                                    </div>
                                )}

                                <button
                                    onClick={() => this.onChangeTabActive(2)}
                                    className={'btn-custom' + (tabActive === 2 ? ' active' : '')}>
                                    <FormattedMessage id="common.recommendations" />
                                </button>

                                {!this.isBroker() && (
                                    <div className='number-display'>
                                        {unreadWarningMessage ? (<div className='transaction-number'>{unreadWarningMessage}</div>) : ''}
                                        <button
                                            onClick={() => this.onChangeTabActive(3)}
                                            className={'btn-custom' + (tabActive === 3 ? ' active' : '')}>
                                            <FormattedMessage id="common.warning" />
                                        </button>
                                    </div>
                                )}


                                <button
                                    onClick={() => this.onChangeTabActive(4)}
                                    className={'btn-custom' + (tabActive === 4 ? ' active' : '')}>
                                    <FormattedMessage id="common.other" />
                                </button>
                            </div>
                            {
                                tabActive === 1 && !this.isBroker() &&
                                <div className='container-list'>
                                    <AccountNotifications
                                        accountmessages={accountmessages}
                                        confirm_orders_infor={confirm_orders}
                                        allCodeMessage={this.allCodeMessage}
                                        lang={lang}
                                        deleteAllNotify={this.deleteAllNotify}
                                        fetchAccountNotiResult={fetchAccountNotiResult}
                                        notifyType={accountNotifyType.TRANSACTION}
                                        nextIndexNoti={nextIndexNoti}
                                        getAccountMessageData={this.getAccountMessageData}
                                        accountInfo={accountInfo}
                                    />
                                </div>
                            }
                            {
                                tabActive === 2 &&
                                <div className='container-list'>
                                    {/*<div className="notification-tab-content">
                                        <div className="flex-scroll-content-account">
                                            <CustomScrollbars className="notifications">
                                                {this.state.recommendations.length > 0 &&
                                                    this.state.recommendations.map((item, i) => (
                                                        <div className='items'>
                                                            <div className='d-flex text-not-active' style={{ gap: "5px" }}>
                                                                <div>{moment(item.updatedAt).format('DD-MM-YYYY')}</div>
                                                                <div>|</div>
                                                                <div>{moment(item.updatedAt).format('HH:mm')}</div>
                                                            </div>
                                                            <div className='subject txt---500-16-24'>{item.subject}</div>
                                                            <div className='txt---400-16-24 text-near-active' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                            {item.filename != "" && <div>{item.subject}</div>}
                                                            <a className='font-italic txt---400-16-24 text-secondary-1' style={{ cursor: "pointer" }} target="_blank" href={item.link} rel="noreferrer">
                                                                {item.link}
                                                            </a>
                                                        </div>
                                                    ))}
                                            </CustomScrollbars>
                                        </div>
                                        {nextIndexNoti > 0 && (
                                            <div className='load-more col-12 text-center txt---500-14-20' onClick={() => this.getNotiAnalyticRecommedDiff({ isLoadMore: true })}>
                                                <FormattedMessage id="common.load-more-detail" />
                                            </div>
                                        )}
                                    </div>*/}
                                    <AnalyticNotificationsNew
                                        selectedAnalyticNew={selectedAnalyticNew}
                                        onAnalyticNewsClick={this.onAnalyticNewsClick}
                                        viewModes={VIEW_MODE}
                                        onAnalyticBackClick={this.onAnalyticBackClick}
                                        analyticScreenMode={analyticScreenMode}
                                        analyticMessages={this.state.recommendations}
                                        nextIndexNoti={nextIndexNoti}
                                        getNotiAnalyticRecommedDiff={this.getNotiAnalyticRecommedDiff}
                                    />
                                </div>
                            }

                            {
                                tabActive === 3 && !this.isBroker() &&
                                <div className='container-list'>
                                    <AccountNotifications
                                        accountmessages={warning}
                                        confirm_orders_infor={confirm_orders}
                                        allCodeMessage={this.allCodeMessage}
                                        lang={lang}
                                        deleteAllNotify={this.deleteAllNotify}
                                        fetchAccountNotiResult={fetchAccountNotiResult}
                                        notifyType={accountNotifyType.MARKET_ALERT}
                                        nextIndexNoti={nextIndexNoti}
                                        getAccountMessageData={this.getAccountMessageData}
                                    />
                                </div>
                            }
                            {
                                tabActive === 4 &&
                                <div className='container-list'>
                                    {/*<div className="notification-tab-content">
                                        <div className="flex-scroll-content-account">
                                            <CustomScrollbars className="notifications">
                                                {this.state.other.length > 0 &&
                                                    this.state.other.map((item, i) => (
                                                        <div className='items'>
                                                            <div className='d-flex text-not-active' style={{ gap: "5px" }}>
                                                                <div>{moment(item.updatedAt).format('DD-MM-YYYY')}</div>
                                                                <div>|</div>
                                                                <div>{moment(item.updatedAt).format('HH:mm')}</div>
                                                            </div>
                                                            <div className='subject txt---500-16-24'>
                                                                {item.subject}
                                                            </div>
                                                            <div className="des-content txt---400-16-24 text-near-active" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                            {item.filename != "" && <div>{item.subject}</div>}
                                                            <a className='font-italic txt---400-16-24 text-secondary-1' style={{ cursor: "pointer" }} target="_blank" href={item.link} rel="noreferrer">
                                                                {item.link}
                                                            </a>
                                                        </div>
                                                    ))}
                                            </CustomScrollbars>
                                            {nextIndexNoti > 0 && (
                                                <div className='load-more col-12 text-center txt---500-14-20' onClick={() => this.getNotiAnalyticRecommedDiff({ isLoadMore: true })}>
                                                    <FormattedMessage id="common.load-more-detail" />
                                                </div>
                                            )}
                                        </div>
                                        </div>*/}
                                    <AnalyticNotificationsNew
                                        selectedAnalyticNew={selectedAnalyticNew}
                                        onAnalyticNewsClick={this.onAnalyticNewsClick}
                                        viewModes={VIEW_MODE}
                                        onAnalyticBackClick={this.onAnalyticBackClick}
                                        analyticScreenMode={analyticScreenMode}
                                        analyticMessages={this.state.other}
                                        nextIndexNoti={nextIndexNoti}
                                        getNotiAnalyticRecommedDiff={this.getNotiAnalyticRecommedDiff}
                                    />
                                </div>
                            }
                        </Fragment>
                        {/*</div>*/}
                    </RetryableContent>
                }
            </div >
        );
    }
}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    //new
    const _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    const _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    return {
        language: state.app.language,
        accounts: state.account.accounts,
        lang: state.app.language,
        defaultTheme: defaultTheme,
        userInfo: userInfo,
        currentAccount: state.account.currentAccount,
        currentCustomer: state.customer.currentCustomer,
        isLoggedIn: state.user.isLoggedIn,
        usersToken: state.user.usersToken,
        accountInfo: _accountInfo,
        accountNotifyCounter: state.app.accountNotifyCounter,
        confirm_orders_infor: state.app.confirm_orders_infor,
        analyticAction: state.app.analyticAction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeDefaultTheme: (theme) => dispatch(actions.changeDefaultTheme(theme)),
        changeLanguage: (language) => dispatch(actions.changeLanguage(language)),
        logout: () => dispatch(actions.logout()),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
        logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
        updateTokenStore: (usersToken) => dispatch(actions.updateTokenStore(usersToken)),

        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setTradeCurrentSymbolById: (symbolId, isRedirect) => dispatch(actions.setTradeCurrentSymbolById(symbolId, isRedirect)),
        setOrderInput: (orderInput, symbol) => dispatch(actions.setTradeOrderInput(orderInput, symbol)),
        setIsOpenTradeMoneyBIDV: (isOpen) => dispatch(actions.setIsOpenTradeMoneyBIDV(isOpen)),
        onChangeMenuActive: (keyName) => dispatch(actions.onChangeMenuActive(keyName)),
        changeLayoutPageActive: (currentTabActive) => dispatch(actions.changeLayoutPageActive(currentTabActive)),
        // updateAccountNotifyList: (custid) => dispatch(actions.updateAccountNotifyList(custid)),
        updateAgreementOrderNotifyList: (custid) => dispatch(actions.updateAgreementOrderNotifyList(custid)),
        getConfirmOrdersInfor: (accountId) => dispatch(actions.getConfirmOrdersInfor(accountId)),

    };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationDropdownNew)));
